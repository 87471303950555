import React from "react";
import { IFormWidgetPropsBase, SelectFormWidget, SelectOption } from "cocoreact";
import { enumOptionsNoneToAll } from "tools/EnumExtension";

export interface EnumOptionsFormWidgetProps extends IFormWidgetPropsBase<any> {
    options: SelectOption[];
    selectAllInsteadNone?: boolean;
}

export default function EnumOptionsFormWidget(props: EnumOptionsFormWidgetProps) {
    const options = React.useMemo(() => {
        if (!props.options) {
            throw new Error("you must provide 'options' to field to use this component")
        }
        if (props.selectAllInsteadNone === true) {
            return enumOptionsNoneToAll(props.options)
        }
        return props.options
    }, [props.options, props.selectAllInsteadNone]);

    return <SelectFormWidget
        {...props}
        options={options}
    />
}