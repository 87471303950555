import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import JSZip from "jszip";
import FileSaver from 'file-saver';
import {
    Box, CircularProgress,
    createStyles, makeStyles, Theme,
    Table, TableBody, Button, Grid
} from "@material-ui/core";
import {
    GetApp as ExportIcon,
    FilterNone as StackIcon
} from "@material-ui/icons";

import { CentralColumnLayout } from "components/Layouts";
import { LoadingDialog, Paper, PaperTitle } from "components/Page";
import { Guid } from "domain/static/Guid";
import useGetContouringWorkshopResults from "domain/hooks/useGetContouringWorkshopResults";
import { Contour, User } from "domain/admin/response/ContouringWorkshopResultsResponse";
import ContourResultRow from "./ContourResultRow";
import ParticipantResultRow, { ParticipantFilterMode, ParticipantFilterRow, ParticipantResult } from "./ParticipantResultRow";
import { useNotificationContext } from "cocoreact";
import { ReadOnlyRoi } from "dline-viewer/dist/data";
import { readonlyRoisToStack, readonlyRoiToImage, loadReadOnlyRoi } from "tools/ContourExtension";
import { FilePartUploader } from "tools/FileExtension";
import { UpdateContouringWorkshopContourStackCommand } from "domain/admin/command/UpdateContouringWorkshopContourStackCommand";
import { sendMessage } from "tools/Message";
import { guidelineInfoLabel } from "tools/StringExtension";
import { imageToNifti, loadItkImage } from "tools/ImageExtension";
import { ContactDialog } from "pages/Contact";

export const useStyles = makeStyles((theme: Theme) => createStyles({
    table: {
        "& tbody tr:nth-child(odd)": {
            backgroundColor: theme.palette.background.default,
        },
    },
}));

function getContourName(contour: Contour) {
    if (contour.anatomicStructureName) {
        return guidelineInfoLabel(contour.anatomicStructureName, contour.volumeType,
            contour.targetTypeName, contour.organizationName);
    }
    return contour.name;
}

function getShortId(id: string) {
    return id.slice(0, 8);
}

function exportResultsAsync(
    participants: User[],
    contours: Contour[],
    progressCallback: (str: string) => void
) {
    progressCallback(`generating results file ...`);

    const editableContours = contours.filter(x => x.isEditable);
    let csvContent = `contour name, participant id, particpant name, dice`;

    for (const contour of editableContours) {
        const contourName = getContourName(contour);

        for (const participantResult of contour.participantResults) {
            const participant = participants.find(x => x.id === participantResult.participantId);
            const participantId = getShortId(participantResult.participantId);
            const particpantName = participant ? `${participant.firstname} ${participant.lastname}` : "unknow"
            const dice = participantResult.dice;

            csvContent += "\n";
            csvContent += `"${contourName}", ${participantId}, "${particpantName}", ${dice}`;
        }
    }

    FileSaver.saveAs(new Blob([csvContent]), "result.csv");
}

async function loadReadOnlyRoiAsNifti(fileId: string) {
    const readonlyRoi = await loadReadOnlyRoi(fileId, fileId, "#fffff");
    const image = readonlyRoiToImage(readonlyRoi);
    return imageToNifti(image);
}

async function loadStackAsNifti(fileId: string) {
    const image = await loadItkImage(fileId);
    return imageToNifti(image);
}

async function generateStackAsNifti(fileIds: string[]) {
    const readonlyRois = [] as ReadOnlyRoi[];
    for (const fileId of fileIds) {
        const readonlyRoi = await loadReadOnlyRoi(fileId, fileId, "#fffff");
        readonlyRois.push(readonlyRoi);
    }
    const stack = readonlyRoisToStack(readonlyRois);
    return imageToNifti(stack);
}

async function exportDatasAsync(
    contours: Contour[],
    progressCallback: (str: string) => void
) {
    const editableContours = contours.filter(x => x.isEditable);
    const rootZip = new JSZip();

    let idx = 0;
    for (const contour of editableContours) {
        idx++;

        progressCallback(`compute contour ${idx}/${editableContours.length} ...`);
        const contourName = getContourName(contour);
        const contourZip = rootZip.folder(contourName);
        if (!contourZip) {
            console.log("failed to create contourZip", contourName)
            continue;
        }

        const refNii = await loadReadOnlyRoiAsNifti(contour.fileId);
        contourZip.file(`reference.nii.gz`, refNii);

        if (contour.stackFileId) {
            const stackNii = await loadStackAsNifti(contour.stackFileId);
            contourZip.file(`participants_stack.nii.gz`, stackNii);
        }

        const contourParticipantsZip = contourZip.folder("participants");
        if (!contourParticipantsZip) {
            console.log("failed to create contourParticipantsZip")
            continue;
        }

        for (let idx = 0; idx < contour.participantResults.length; idx++) {
            const participantResult = contour.participantResults[idx];
            const participantId = getShortId(participantResult.participantId);
            const participantNii = await loadReadOnlyRoiAsNifti(participantResult.fileId);
            contourParticipantsZip.file(`${participantId}.nii.gz`, participantNii);
        }
    }

    progressCallback(`generate contours archive ...`);

    rootZip.generateAsync({ type: "blob" })
        .then(function (content) {
            FileSaver.saveAs(content, "contours.zip");
        });
}

async function updateStacksAsync(
    id: Guid,
    contours: Contour[],
    progressCallback: (str: string) => void
) {
    const editableContours = contours.filter(x => x.isEditable);
    let idx = 0;
    for (const contour of editableContours) {
        idx++;

        progressCallback(`compute stack contour ${idx}/${editableContours.length} ...`);

        const participantsFileIds = contour.participantResults.map(x => x.fileId);
        let fileId = null as Guid | null;
        if (participantsFileIds.length > 0) {
            const stack = await generateStackAsNifti(participantsFileIds);
            const fileUploader = new FilePartUploader(
                "participants_stack.nii.gz",
                "application/octet-stream"
            );
            fileId = await fileUploader.send(stack.buffer);
        }

        progressCallback(`save stack ${idx + 1}/${editableContours.length} ...`);

        const message = new UpdateContouringWorkshopContourStackCommand({
            id, contourId: contour.id, fileId
        });

        await sendMessage(message);
    }
}

export default function ListResultsPanel() {

    const classes = useStyles();
    const { success, error } = useNotificationContext();

    const { id } = useParams<{ id: Guid }>();
    const [loading, results, updateResults] = useGetContouringWorkshopResults(id);

    const nbEditableContours = useMemo(
        () => loading ? 0 : results.contours.filter(x => x.isEditable).length,
        [loading, results]
    );

    const participantsResults = useMemo(() => {
        if (loading) return []
        return results.participants.map(p => {
            const cRes = results.contours
                .filter(c => c.isEditable)
                .map(c => c.participantResults.filter(r => r.participantId === p.id))
                .flat()
            return {
                ...p,
                dices: cRes.map(c => c.dice)
            } as ParticipantResult
        });
    }, [loading, results]);

    const [participantMode, setParticipantMode] = useState("all" as ParticipantFilterMode);
    const [contactOpen, setContactOpen] = useState(false);

    const participantsResultsFiltered =
        participantsResults.filter(x => {
            const nbDice = x.dices.length
            if (participantMode === "all") return true
            if (participantMode === "complete") return nbDice === nbEditableContours
            if (participantMode === "uncomplete") return nbDice > 0 && nbDice !== nbEditableContours
            if (participantMode === "unstarted") return nbDice === 0
            return false
        })

    const [computeState, setComputeState] = useState({
        open: false,
        label: "",
    });

    const onExportResultsHandle = useCallback(async () => {
        await exportResultsAsync(
            results.participants,
            results.contours,
            (str: string) => setComputeState({ open: true, label: str })
        );
        setComputeState({ open: false, label: "" });
    }, [results.contours, results.participants]);

    const onExportDataHandle = useCallback(async () => {
        try {
            await exportDatasAsync(
                results.contours,
                (str: string) => setComputeState({ open: true, label: str })
            );
            setComputeState({ open: false, label: "" });
        }
        catch (e) {
            error("An error occurred while creating contours archive")
        }
    }, [error, results.contours]);

    const onUpdateStacksHandle = useCallback(async () => {
        try {
            await updateStacksAsync(id, results.contours,
                (str: string) => setComputeState({ open: true, label: str })
            );
            setComputeState({ open: false, label: "" });
            updateResults();
            success("Contour stacks updated successfully")
        }
        catch (e) {
            error("An error occurred while updating contours stacks")
        }
    }, [error, id, results.contours, success, updateResults]);

    return <CentralColumnLayout size={"xl"}>

        <Box display="flex" marginY={2} gridGap={16} justifyContent="flex-end">
            <Button
                color="secondary"
                variant="contained"
                startIcon={<ExportIcon />}
                onClick={onExportResultsHandle}
                disabled={computeState.open}
            >
                export results (.csv)
            </Button>

            <Button
                color="secondary"
                variant="contained"
                startIcon={<ExportIcon />}
                onClick={onExportDataHandle}
                disabled={computeState.open}
                style={{ marginRight: "auto" }}
            >
                export data (.zip)
            </Button>

            <Button
                color="primary"
                variant="contained"
                startIcon={<StackIcon />}
                onClick={onUpdateStacksHandle}
                disabled={computeState.open}
            >
                update contour stacks
            </Button>
        </Box>

        <Grid container spacing={2}>
            <Grid item md={12} lg={6}>
                <Paper>
                    <PaperTitle>Contours results</PaperTitle>

                    {loading && <Box textAlign="center" paddingY={2}>
                        <CircularProgress />
                    </Box>}
                    {!loading &&
                        <Table className={classes.table}>
                            <TableBody>
                                {results.contours.map(x =>
                                    <ContourResultRow
                                        key={x.id}
                                        contour={x}
                                        nbParticipant={results.numberOfParticipant}
                                    />
                                )}
                            </TableBody>
                        </Table>
                    }
                </Paper>
            </Grid>


            <Grid item md={12} lg={6}>
                <Paper>
                    <PaperTitle>Participants results</PaperTitle>

                    {loading && <Box textAlign="center" paddingY={2}>
                        <CircularProgress />
                    </Box>}
                    {!loading && <>
                        <ParticipantFilterRow
                            nbContour={participantsResultsFiltered.length}
                            mode={participantMode}
                            onChange={setParticipantMode}
                            onContact={() => setContactOpen(true)}
                        />
                        <Table className={classes.table}>
                            <TableBody>
                                {participantsResultsFiltered.map(x =>
                                    <ParticipantResultRow
                                        key={x.id}
                                        participantResult={x}
                                        nbContours={nbEditableContours}
                                    />
                                )}
                            </TableBody>
                        </Table>
                    </>}
                </Paper>
            </Grid>

        </Grid>

        <LoadingDialog
            open={computeState.open}
            sentence={computeState.label ?? undefined}
        />

        <ContactDialog
            open={contactOpen}
            onClose={() => setContactOpen(false)}
            title="Contact participants"
            initialValue={{
                bcc: participantsResultsFiltered
                    .map(x => `${x.firstname} ${x.lastname} <${x.email}>`)
                    .join(", ")
            }}
            onAfterSend={() => setContactOpen(false)}
        />

    </CentralColumnLayout>
}

