import React from "react";
import { GetClinicalDataImagesQuery } from "domain/admin/query/GetClinicalDataImagesQuery";
import { ClinicalImageItemResponse } from "domain/admin/response/ClinicalImageItemResponse";
import { useMessage } from "tools/Message";
import { Guid } from "domain/static/Guid";

export function buildGetClinicalDataImagesQuery(id: Guid) {
  const query = new GetClinicalDataImagesQuery();
  query.id = id;
  return query;
}

export default function useListClinicalImages(
  id: Guid,
  includeArchived: boolean = false
) {
  const query = React.useMemo(() => buildGetClinicalDataImagesQuery(id), [id]);
  const result = useMessage<ClinicalImageItemResponse[]>(query, []);
  if (!includeArchived) {
    result[1] = result[1].filter((x) => !x.isArchived);
  }
  return result;
}
