import AuthTokenService from "./AuthTokenService";
import HttpClient from "./HttpClient";
import RefreshJwtService from "./RefreshJwtService";
import Serializer from "./Serializer";
import { JwtStorage, ThemeStorage, FormErrorService } from "cocoreact";
import RefreshAccessToken from "./RefreshAccessToken";

// settings file
declare var APP_WEBSITE_NAME: string;
declare var APP_SERVER_PATH: string;

const jwtKey = `${APP_WEBSITE_NAME}_JWT_TOKEN`;
const themeKey = `${APP_WEBSITE_NAME}_THEME_NAME`;
const serverPath = APP_SERVER_PATH;
const jwtRefreshErrorCode = 498;
const formErrorStatus = 417;

const serializer = new Serializer();
const jwtStorage = new JwtStorage(jwtKey, localStorage);
const refreshAccessToken = new RefreshAccessToken(jwtStorage);
const refreshJwtService = new RefreshJwtService(
  jwtRefreshErrorCode,
  jwtStorage,
  serializer,
  refreshAccessToken
);
const authTokenService = new AuthTokenService(jwtStorage, refreshAccessToken);
const httpClient = new HttpClient(serverPath, refreshJwtService);
const formErrorService = new FormErrorService(formErrorStatus, serializer);
const themeStorage = new ThemeStorage(themeKey, localStorage);

// --------------------------------------------------------

export * from "./IAuthPayload";
export {
  formErrorService,
  jwtStorage,
  refreshJwtService,
  authTokenService,
  httpClient,
  serializer,
  themeStorage,
};
