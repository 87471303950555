import {
  Grid3D,
  PolygonVolume,
  ReadOnlyRoi,
  RTStructPolygon,
  RTStructROI,
  VoxelVolumeByBlock,
} from "dline-viewer/dist/data";
import { RTStructLoader } from "dline-viewer/dist/IO";
import {
  ContourStackGenerator,
  RTStructROIToReadOnlyRoi,
} from "dline-viewer/dist/processing";
import { GetFileQuery } from "domain/admin/query/GetFileQuery";
import { Guid } from "domain/static/Guid";
import { HexToRgb } from "./ColorExtension";
import { getFileAsync } from "./FileExtension";

export function parseRTStructRois(arrayBuffer: ArrayBuffer) {
  const loader = new RTStructLoader();
  return loader.ReadRTStruct(arrayBuffer);
}

export function voxelizeRTStructRoi(
  rtStructROI: RTStructROI,
  grid: Grid3D,
  blockSize: number = 10
) {
  const createVoxelVolume = (_grid: Grid3D) =>
    new VoxelVolumeByBlock(blockSize, _grid);

  return RTStructROIToReadOnlyRoi.VoxelizeOne(
    rtStructROI,
    grid,
    createVoxelVolume
  );
}

export async function loadRTStructRoi(
  queryOrId: GetFileQuery | Guid,
  id: Guid,
  color: string,
  onDownloadProgress?: (loaded: number, total: number) => void
) {
  const file = await getFileAsync(queryOrId, onDownloadProgress);
  const dataStr = await file.data.text();
  const data = JSON.parse(dataStr) as RTStructPolygon[];

  const contours = data.map((x) => {
    const contour = new RTStructPolygon();
    contour.numberOfPoints = x.numberOfPoints;
    contour.planeConstant = x.planeConstant;
    contour.points = [...x.points];
    return contour;
  });

  const res = new RTStructROI();
  res.name = id;
  res.color = HexToRgb(color);
  res.contours = contours;

  return res;
}

interface ReadOnlyRoiResponse {
  grid: Grid3D;
  polygons: Record<number, number>[][][];
}

export function createReadOnlyRoi(id: Guid, color: string, grid?: Grid3D) {
  const roi = new ReadOnlyRoi();
  roi.name = id;
  roi.color = HexToRgb(color);
  if (grid) {
    roi.polygonVolume = new PolygonVolume(grid);
  }
  return roi;
}

export async function loadReadOnlyRoi(
  queryOrId: GetFileQuery | Guid,
  id: Guid,
  color: string,
  onDownloadProgress?: (loaded: number, total: number) => void
) {
  const file = await getFileAsync(queryOrId, onDownloadProgress);
  const dataStr = await file.data.text();
  const data = JSON.parse(dataStr) as ReadOnlyRoiResponse;

  const grid = new Grid3D();
  grid.Set(data.grid.size, data.grid.spacing, data.grid.origin);

  const polygons = new PolygonVolume(grid);
  for (let axisIndex = 0; axisIndex < data.polygons.length; axisIndex++) {
    for (
      let sliceIndex = 0;
      sliceIndex < data.polygons[axisIndex].length;
      sliceIndex++
    ) {
      const objArrays = data.polygons[axisIndex][sliceIndex];
      for (const objArray of objArrays) {
        const arr = new Float32Array(Object.values(objArray));
        polygons.AddPolygon(axisIndex, sliceIndex, arr);
      }
    }
  }

  const roi = createReadOnlyRoi(id, color);
  roi.polygonVolume = polygons;

  return roi;
}

export function readonlyRoiToImage(roi: ReadOnlyRoi) {
  const gen = new ContourStackGenerator();
  return gen.GetItkImage(roi.polygonVolume.grid, [roi.polygonVolume]);
}

export function readonlyRoisToStack(rois: ReadOnlyRoi[]) {
  const polygonVolumes = rois.map((x) => x.polygonVolume);
  const gen = new ContourStackGenerator();
  return gen.GetItkImage(rois[0].polygonVolume.grid, polygonVolumes);
}
