import { useEffect, useMemo } from "react";

// settings file
declare var APP_WEBSITE_NAME: string;

export interface TitleProps {
    children: string | string[];
}

export default function Title({ children }: TitleProps) {

    const title = useMemo(() => Array.isArray(children) ? children.join("") : children, [children]);

    useEffect(() => {
        document.title = `${title} - ${APP_WEBSITE_NAME}`;
    }, [title])

    return null;
}