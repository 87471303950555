import { Guid } from "domain/static/Guid";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class UpdateContouringWorkshopCommand implements IMessage {
    id!: Guid;
    name!: string;
    summary!: string;
    anatomicZoneId!: Guid;
    startAt!: Date;
    endAt!: Date;
    coverId!: Guid | null;
    limitOfParticipants!: number;

    public constructor(init?: Partial<UpdateContouringWorkshopCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "PUT" as RequestMethod;

    getPath = () => `/admin/contouring-workshops/${this.id.toString()}`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            name: this.name,
            summary: this.summary,
            anatomicZoneId: this.anatomicZoneId,
            startAt: this.startAt,
            endAt: this.endAt,
            coverId: this.coverId,
            limitOfParticipants: this.limitOfParticipants,
        };
    }
    
    needAuthentication = () => true;
}
