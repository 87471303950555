import { Guid } from "domain/static/Guid";
import { ImagingDefault } from "domain/static/ImagingDefault";
import { Range } from "domain/static/Range";
import { Coordinate } from "domain/static/Coordinate";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class UpdateClinicalImageCommand implements IMessage {
    id!: Guid;
    name!: string;
    imagingModalityId!: Guid;
    fileId!: Guid;
    default!: ImagingDefault;
    windowing!: Range;
    range!: Range;
    spacing!: Coordinate;
    origin!: Coordinate;
    size!: Coordinate;
    lut!: string;

    public constructor(init?: Partial<UpdateClinicalImageCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "PUT" as RequestMethod;

    getPath = () => `/admin/clinical-images/${this.id.toString()}`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            name: this.name,
            imagingModalityId: this.imagingModalityId,
            fileId: this.fileId,
            default: this.default,
            windowing: this.windowing,
            range: this.range,
            spacing: this.spacing,
            origin: this.origin,
            size: this.size,
            lut: this.lut,
        };
    }
    
    needAuthentication = () => true;
}
