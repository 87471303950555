import React from "react";
import { Link } from "react-router-dom";

import {
    createStyles, makeStyles, Theme, IconButton
} from "@material-ui/core";
import { EditIcon } from "App/Theme";

const useStyles = makeStyles((theme: Theme) => createStyles({
    button: {
        padding: theme.spacing(1),
    }
}));

export interface EditTableLinkProps {
    to: string;
}

export default function EditTableLink({ to }: EditTableLinkProps) {

    const styles = useStyles();

    return (
        <Link to={to}>
            <IconButton
                size="small"
                color="secondary"
                className={styles.button}
            >
                <EditIcon />
            </IconButton>
        </Link>
    )
}
