import React, { useMemo } from "react";
import { ITableWidgetPropsBase } from "cocoreact";
import { Typography } from "@material-ui/core";

export interface TextTableWidgetProps extends ITableWidgetPropsBase<any> {}

export default function TextTableWidget({ value: data }: TextTableWidgetProps) {
    const val = useMemo(() => data ?? "", [data]);
    return <Typography>
        {val}
    </Typography>
}
