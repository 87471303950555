import React, { useRef, useCallback, useState } from "react";
import {
    makeStyles, Theme, createStyles,
    Button, alpha, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem
} from "@material-ui/core";
import { IFormWidgetPropsBase } from "cocoreact";

import { Guid, EmptyGuid } from "domain/static/Guid";
import { FileParentType } from "domain/static/FileParentType";
import { CreateFileCommand } from "domain/admin/command/CreateFileCommand";
import { sendMessage } from "tools/Message";
import useFileQueryPath from "tools/useFileQueryPath";
import { MoreIcon } from "App/Theme";
import { readImageAsync } from "tools/FileExtension";
import { FileInput } from "components/Page";

const useStyles = makeStyles((theme: Theme) => createStyles({
    box: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: 150,
        minHeight: 150,
        border: "1px solid",
        borderColor: theme.palette.divider,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[1],
    },
    selectBtn: {
        opacity: 0.8,
    },
    moreBtn: {
        position: "absolute",
        top: theme.spacing(1),
        right: theme.spacing(1),
        backgroundColor: alpha(theme.palette.common.black, 0.4),
        color: theme.palette.getContrastText(theme.palette.secondary.main),
        minWidth: 32,
        maxWidth: 32,
        "&:hover": {
            backgroundColor: alpha(theme.palette.common.black, 0.8),
        }
    },
}));

export interface ImageFormWidgetProps extends IFormWidgetPropsBase<Guid> {
    parentType: FileParentType;
    parentId: Guid;
    accept?: string;
    maxSize?: number;
}

export default function ImageFormWidget({
    value, accept, maxSize, parentType, parentId, onChange, name
}: ImageFormWidgetProps) {
    const classes = useStyles();
    const inputRef = useRef<HTMLInputElement>(null);
    const imageUri = useFileQueryPath(value);

    const inputHandle = useCallback(async (files: FileList | File) => {
        if (files instanceof FileList) return;
        const file = files;

        if (accept && !accept.includes(file.type)) {
            alert(`Inalid image type, it must be in [${accept}] but select image type : ${file.type}`);
            return;
        }
        if (maxSize && file.size > maxSize) {
            alert(`Inalid image size, it must be less than ${maxSize}bytes but select image size : ${file.size}bytes`);
            return;
        }

        const content = await readImageAsync(file);
        const command = new CreateFileCommand({
            fileName: file.name,
            parentId: parentId,
            parentType: parentType,
            mimeType: file.type,
            base64Content: content.split("base64,")[1],
        });
        const response = await sendMessage<{ id: Guid }>(command);
        onChange && onChange(name, response.id);
    }, [accept, maxSize, name, onChange, parentId, parentType]);

    const openClientFileDialog = useCallback(() => inputRef.current?.click(), []);

    const menuRef = useRef<HTMLButtonElement>(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const removeHandle = useCallback((event: any) => {
        event.stopPropagation();
        setMenuOpen(false)
        onChange && onChange(name, EmptyGuid());
    }, [name, onChange]);

    return <div
        className={classes.box}
        style={imageUri ? { backgroundImage: `url("${imageUri}")` } : undefined}
    >
        <FileInput
            ref={inputRef ? inputRef : inputRef}
            onSelect={inputHandle}
            accept={accept ?? "image/jpeg, image/jpg, image/png"}
        />
        <Button
            variant="contained"
            color="secondary"
            className={classes.selectBtn}
            onClick={openClientFileDialog}
        >
            Select an image
        </Button>
        {imageUri &&
            <>
                <Button
                    ref={menuRef}
                    className={classes.moreBtn}
                    onClick={() => setMenuOpen(true)}
                >
                    <MoreIcon />
                </Button>
                <Popper
                    open={menuOpen}
                    anchorEl={menuRef.current}
                    role={undefined}
                    transition={true}
                    disablePortal={true}
                    placement="bottom-end"
                >
                    {({ TransitionProps }) => (
                        <Grow {...TransitionProps}>
                            <Paper>
                                <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                                    <MenuList autoFocusItem={menuOpen}>
                                        <MenuItem onClick={removeHandle}>Remove image</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </>
        }
    </div>
}