import React from "react";
import { Link, useParams } from "react-router-dom";

import { Button } from "@material-ui/core";

import { CentralColumnLayout } from "components/Layouts";
import { fullPath } from "tools/Route";
import { AddIcon } from "App/Theme";
import { Flex, Paper, PaperTitle } from "components/Page";
import GuidelineTable from "./GuidelineTable";
import { Guid } from "domain/static/Guid";

export default function ListGuidelinesPanel() {

    const params = useParams<{ id: Guid }>();

    return <CentralColumnLayout size="md">

        <Flex justifyContent="flex-end">
            <Link to={fullPath("EditArticleNewGuideline", { params: { id: params.id } })}>
                <Button
                    color="secondary"
                    variant="contained"
                    startIcon={<AddIcon />}
                >
                    new guideline
                </Button>
            </Link>
        </Flex>

        <Paper>
            <PaperTitle>
                List of article guidelines
            </PaperTitle>

            <GuidelineTable
                id={params.id}
                editable={true}
            />
        </Paper>

    </CentralColumnLayout>
}
