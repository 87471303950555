import {
	TypeWidgetOptionsBuilder,
	DateTableWidget,
	SwitchTableWidget,
	ButtonTableWidget
} from "cocoreact";
import EnumOptionsTableWidget from "./widgets/EnumOptionsTableWidget";
import ColorTableWidget from "./widgets/ColorTableWidget";
import TextTableWidget from "./widgets/TextTableWidget";
import ClinicalCaseStateTableWidget from "./widgets/ClinicalCaseStateTableWidget";
import ContouringWorkshopStateTableWidget from "./widgets/ContouringWorkshopStateTableWidget";
import UserRoleTableWidget from "./widgets/UserRoleTableWidget";

const tableWidgetOptions = new TypeWidgetOptionsBuilder()
	.set("string", TextTableWidget)
	.set("number", TextTableWidget)
	.set("Date", DateTableWidget)
	.set("boolean", SwitchTableWidget)
	.set("button", ButtonTableWidget)
	.set("SelectItemType", EnumOptionsTableWidget)
	.set("VolumeType", EnumOptionsTableWidget)
	.set("ImagingDefault", EnumOptionsTableWidget)
	.set("ClinicalCaseState", ClinicalCaseStateTableWidget)
	.set("ContouringWorkshopState", ContouringWorkshopStateTableWidget)
	.set("UserRole", UserRoleTableWidget)
	.set("color", ColorTableWidget)
	.build();

export default tableWidgetOptions;
