import { SelectItemType } from "domain/static/SelectItemType";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class SearchSelectItemOptionsQuery implements IMessage {
    type!: SelectItemType;

    public constructor(init?: Partial<SearchSelectItemOptionsQuery>) {
        Object.assign(this, init);
    }

    getMethod = () => "GET" as RequestMethod;

    getPath = () => `/admin/select-items/${this.type.toString()}/options`;

    getQueryString = () => undefined;

    getBody = () => undefined;

    needAuthentication = () => true;
}
