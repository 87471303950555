import { Coordinate } from "domain/static/Coordinate";
import { IField } from "cocoreact";

//Generated code, do not modify

export class ClinicalDataGridResponse {
    spacing!: Coordinate;
    origin!: Coordinate;
    size!: Coordinate;

    public static Fields = {
        spacing: { name: "spacing", type: "Coordinate" },
        origin: { name: "origin", type: "Coordinate" },
        size: { name: "size", type: "Coordinate" },
    } as Record<keyof ClinicalDataGridResponse, IField>;
}
