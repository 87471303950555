import React from "react";
import { useMessage } from "tools/Message";
import { Guid } from "domain/static/Guid";
import { GetUserContouringWorkshopsQuery } from "domain/admin/query/GetUserContouringWorkshopsQuery";
import { ProfileContouringWorkshopItemResponse } from "domain/admin/response/ProfileContouringWorkshopItemResponse";

export default function useGetUserContouringWorkshops(id: Guid) {
  const query = React.useMemo(
    () => new GetUserContouringWorkshopsQuery({ id }),
    [id]
  );
  return useMessage<ProfileContouringWorkshopItemResponse[]>(query, []);
}
