import React from "react";
import { Box } from "@material-ui/core";

import {
  TableOData as CocoTableOData,
  TableODataProps as CocoTableODataProps
} from "cocoreact";

import tableWidgetOptions from "./tableWidgetOptions";
import { ITableODataFieldOptions } from "./TableODataFieldsBuilder";
import { formWidgetOptions } from "components/Form";
import { serializer, httpClient } from "services";
import { IFormFieldOptions } from "components/Form/FormFieldsBuilder";

export interface TableODataProps<T> {
  fields: ITableODataFieldOptions<T>[],
  message: CocoTableODataProps<IFormFieldOptions<T>, T>["message"];
  padding?: CocoTableODataProps<IFormFieldOptions<T>, T>["padding"];
  updateRef?: CocoTableODataProps<IFormFieldOptions<T>, T>["updateRef"];
  loaderSize?: CocoTableODataProps<IFormFieldOptions<T>, T>["loaderSize"];
  classes?: CocoTableODataProps<IFormFieldOptions<T>, T>["classes"];
}

export default function TableOData<T>(props: TableODataProps<T>) {
  return <Box overflow="auto">
    <CocoTableOData<IFormFieldOptions<T>, T>
      {...props}
      noDataLabel={"No data found"}
      bodyWidgetOptions={tableWidgetOptions}
      filterWdigetOptions={formWidgetOptions}
      serializer={serializer}
      httpClient={httpClient}
      rowsPerPageOptions={[10, 30, 50, 100]}
      rowsPerPageLabel={"Item per page "}
      displayedRowLabel={(info) => {
        return `${info.from}-${info.to} over ${info.count} (page ${info.page + 1})`;
      }}
    />
  </Box>
}