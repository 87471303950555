import React from "react";
import { ITableWidgetPropsBase } from "cocoreact";

import { ContouringWorkshopState } from "domain/static/ContouringWorkshopState";
import { ContouringWorkshopStateWidget } from "components/ClinicalData";

export interface ContouringWorkshopStateTableWidgetProps extends ITableWidgetPropsBase<ContouringWorkshopState> {
    dateRangeMin: Date;
    dateRangeMax: Date;
}

export default function ContouringWorkshopStateTableWidget({ value, dateRangeMin, dateRangeMax }: ContouringWorkshopStateTableWidgetProps) {
    return <ContouringWorkshopStateWidget
        state={value}
        startAt={dateRangeMin}
        endAt={dateRangeMax}
    />
}
