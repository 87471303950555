import React, { useMemo, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";

import { CentralColumnLayout } from "components/Layouts";
import { Guid, EmptyGuid } from "domain/static/Guid";
import { fullPath } from "tools/Route";
import { Paper, PaperTitle } from "components/Page";
import { useNotificationContext } from "cocoreact";
import GuidelineForm from "./GuidelineForm";
import { EditIcon } from "App/Theme";

export default function EditGuidelinePanel() {

    const params = useParams<{ id: Guid, guidelineId: Guid | undefined }>();

    const listUrl = useMemo(
        () => fullPath("EditArticleListGuidelines", { params: { id: params.id } }),
        [params.id]
    );

    const history = useHistory();
    const { success } = useNotificationContext();
    const handleAfterSave = useCallback(() => {
        history.push(listUrl);
        success("Guideline's article saved with success");
    }, [history, listUrl, success]);

    const title = (params.guidelineId ? "Edit" : "Create") + " an article guideline";

    return <CentralColumnLayout size="md">

        <Paper>
            <PaperTitle icon={<EditIcon />}>{title}</PaperTitle>

            <GuidelineForm
                articleId={params.id}
                guidelineId={params.guidelineId ?? EmptyGuid()}
                onAfterSave={handleAfterSave}
            />
        </Paper>

    </CentralColumnLayout>
}
