import React, { useEffect, useState } from "react";
import { Box, IconButton } from "@material-ui/core";
import { IField, useNotificationContext } from "cocoreact";
import { remark } from "remark";
import remarkGfm from "remark-gfm";
import remarkHtml from "remark-html";

import { AddIcon, SendIcon } from "App/Theme";
import { Form, FormFieldsBuilder } from "components/Form";
import { SubmitButton } from "components/Buttons";
import { SendMessageCommand } from "domain/admin/command/SendMessageCommand";
import { FromEmailType, FromEmailTypeOptions } from "domain/static/FromEmailType";
import { SelectUserDialog } from "pages/Users";

class SendMessageResponse {
    from!: FromEmailType;
    to!: string;
    cc!: string;
    bcc!: string;
    subject!: string;
    content!: string;

    public static Fields = {
        from: { name: "from", type: "FromEmailType" },
        to: { name: "to", type: "string" },
        cc: { name: "cc", type: "string" },
        bcc: { name: "bcc", type: "string" },
        subject: { name: "subject", type: "string" },
        content: { name: "content", type: "string" },
    } as Record<keyof SendMessageResponse, IField>;
}

function parseEmails(str: string) {
    if (str && str.length > 0) {
        var emails = str
            .replaceAll('\n', ',') // line separator
            .replaceAll(';', ',') // ';' separator
            .split(",") // split emails
            .map(x => x.trim()) // trim each emails
            ;
        return emails;
    }
    return []
}

export interface ContactFormProps {
    initialValue?: Partial<SendMessageResponse>;
    onAfterSend?: () => void;
}

export default function ContactForm({ initialValue, onAfterSend }: ContactFormProps) {

    const { success, error } = useNotificationContext();

    const [entity, setEntity] = useState({
        from: FromEmailType.Contact,
        to: "", cc: "", bcc: "", subject: "", content: "",
    } as SendMessageResponse);

    useEffect(() => {
        if (initialValue) {
            setEntity(e => ({ ...e, ...initialValue }));
        }
    }, [initialValue]);

    const [selectUser, setSelectUser] = useState({
        open: false,
        field: SendMessageResponse.Fields.to,
    });
    const onSelectUserHandle = React.useCallback((field: IField) => {
        setSelectUser({ open: true, field });
    }, []);

    const fields = React.useMemo(() => buildFields(onSelectUserHandle), [onSelectUserHandle]);

    const buildMessage = React.useCallback(async (data: SendMessageResponse) => {
        const command = new SendMessageCommand();
        command.from = data.from;
        command.to = parseEmails(data.to);
        command.cc = parseEmails(data.cc);
        command.bcc = parseEmails(data.bcc);
        command.subject = data.subject;
        command.content = remark()
            .use(remarkGfm)
            .use(remarkHtml)
            .processSync(data.content)
            .toString()
            .replaceAll("\n", "");
        return command;
    }, []);

    const onSuccessHandle = React.useCallback(() => {
        setEntity({
            from: FromEmailType.Contact,
            to: "", cc: "", bcc: "", subject: "", content: "",
        } as SendMessageResponse);
        success("Your message has been sent successfully");
        onAfterSend && onAfterSend();
    }, [success, onAfterSend]);

    const onErrorHandle = React.useCallback(() => {
        error("An error occurred while sending your message");
    }, [error]);

    return <Form<SendMessageResponse>
        initial={entity}
        fields={fields}
        buildMessage={buildMessage}
        onSuccess={onSuccessHandle}
        onError={onErrorHandle}
    >
        {
            ({ loading, data, updateValue }) => <>
                <SelectUserDialog
                    open={selectUser.open}
                    onClose={() => setSelectUser(su => ({ ...su, open: false }))}
                    title={`Select users > ${selectUser.field.name}`}
                    disabled={loading}
                    onSelect={(users) => {
                        var currentValue = (data as any)[selectUser.field.name] as string ?? "";
                        const newValue = users
                            .filter(x => !currentValue.includes(x.email))
                            .map(x => `${x.firstname} ${x.lastname} <${x.email}>`)
                            .join(', ');
                        currentValue = currentValue ? currentValue + ", " : "";
                        updateValue(selectUser.field.name, currentValue + newValue)
                        setSelectUser(su => ({ ...su, open: false }))
                    }}
                />

                <Box marginTop={2} textAlign="right">
                    <SubmitButton loading={loading} icon={<SendIcon />}>
                        send
                    </SubmitButton>
                </Box>
            </>
        }
    </Form>
}

function buildFields(onSelectUser: (field: IField) => void) {
    const builder = new FormFieldsBuilder<SendMessageResponse>();
    const fields = SendMessageResponse.Fields;

    builder
        .initialize(fields)
        .set(fields.from, {
            position: 0,
            options: FromEmailTypeOptions
        })
        .set(fields.to, {
            position: 1,
            type: "string",
            multiline: true,
            minRows: 1,
            maxRows: 10,
            placeholder: "John Doe <john.doe@dline.io>, john.do2@gmail.com",
            required: false,
            startAdornment: <IconButton size="small" onClick={() => onSelectUser(fields.to)}>
                <AddIcon />
            </IconButton>
        })
        .set(fields.cc, {
            position: 2,
            type: "string",
            multiline: true,
            minRows: 1,
            maxRows: 10,
            placeholder: "John Doe <john.doe@dline.io>, john.do2@gmail.com",
            required: false,
            startAdornment: <IconButton size="small" onClick={() => onSelectUser(fields.cc)}>
                <AddIcon />
            </IconButton>
        })
        .set(fields.bcc, {
            position: 3,
            type: "string",
            multiline: true,
            minRows: 1,
            maxRows: 10,
            placeholder: "John Doe <john.doe@dline.io>, john.do2@gmail.com",
            required: false,
            startAdornment: <IconButton size="small" onClick={() => onSelectUser(fields.bcc)}>
                <AddIcon />
            </IconButton>
        })
        .set(fields.subject, {
            position: 4,
        })
        .set(fields.content, {
            position: 5,
            type: "richtext",
        });

    return builder.build();
}
