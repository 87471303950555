import React from "react";

import {
    Typography, TableRow, TableCell,
    makeStyles, Theme, createStyles, MenuItem, TextField, Box, darken, Button
} from "@material-ui/core";

import { EmailIcon } from "App/Theme";
import { Guid } from "domain/static/Guid";

export const useStyles = makeStyles((theme: Theme) => createStyles({
    cell: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    filter_root: {
        display: "flex",
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: darken(theme.palette.background.paper, 0.5),
        gap: theme.spacing(1),
    }
}));


export type ParticipantResult = {
    id: Guid;
    firstname: string;
    lastname: string;
    email: string;
    dices: number[];
}

export type ParticipantResultRowProp = {
    participantResult: ParticipantResult;
    nbContours: number;
}

export default function ParticipantResultRow({ participantResult, nbContours }: ParticipantResultRowProp) {
    const classes = useStyles();

    return <TableRow>
        <TableCell className={classes.cell}>
            <Typography>
                {participantResult.firstname} {participantResult.lastname}
            </Typography>
            <Typography>
                {participantResult.dices.length}/{nbContours}
            </Typography>
        </TableCell>
    </TableRow>
}

export type ParticipantFilterMode = "all" | "complete" | "uncomplete" | "unstarted"

export type ParticipantFilterRowProps = {
    nbContour: number;
    mode: ParticipantFilterMode;
    onChange: (newMode: ParticipantFilterMode) => void;
    onContact: () => void;
}

export function ParticipantFilterRow({ nbContour, mode, onChange, onContact }: ParticipantFilterRowProps) {
    const classes = useStyles();

    return <Box className={classes.filter_root}>
        <TextField
            select
            fullWidth
            value={mode}
            onChange={e => onChange(e.target.value as ParticipantFilterMode)}
            variant="outlined"
            size="small"
        >
            <MenuItem value={"all" as ParticipantFilterMode}>
                Select all participant
            </MenuItem>
            <MenuItem value={"complete" as ParticipantFilterMode}>
                Complete all contour
            </MenuItem>
            <MenuItem value={"uncomplete" as ParticipantFilterMode}>
                Started but not complete
            </MenuItem>
            <MenuItem value={"unstarted" as ParticipantFilterMode}>
                Not started yet
            </MenuItem>
        </TextField>

        <Button
            variant="outlined"
            startIcon={<EmailIcon />}
            style={{ minWidth: 180 }}
            onClick={onContact}

        >
            contact ({nbContour})
        </Button>
    </Box >
}