import { useEntityMessage } from "tools/Message";
import { EmptyGuid, Guid } from "domain/static/Guid";
import { SelectItemType } from "domain/static/SelectItemType";
import { GetSelectItemQuery } from "domain/admin/query/GetSelectItemQuery";
import { SelectItemResponse } from "domain/admin/response/SelectItemResponse";
import { useCallback } from "react";

export function emptySelectItemBuilder(
  initialValue?: Partial<SelectItemResponse>
) {
  let entity = new SelectItemResponse();
  entity.id = EmptyGuid();
  entity.name = initialValue?.name ?? "";
  entity.type = initialValue?.type ?? SelectItemType.None;
  entity.isArchived = false;
  return entity;
}

export function getSelectItemQueryBuilder(id: Guid) {
  return new GetSelectItemQuery({ id });
}

export default function useGetSelectItem(
  id: Guid,
  initialValue?: Partial<SelectItemResponse>
) {
  const emptyBuiler = useCallback(() => emptySelectItemBuilder(initialValue), [
    initialValue,
  ]);

  return useEntityMessage(id, getSelectItemQueryBuilder, emptyBuiler);
}
