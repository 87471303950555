import React from "react";
import {
    ITableWidgetPropsBase,
    SwitchTableWidgetProps,
    DateTableWidgetProps,
    ButtonTableWidgetProps,
    IODataTableField,
    TableODataFieldOptionsBuilder,
    IField,
    SelectOption
} from "cocoreact";

import { IFormFieldOptions } from "components/Form";
import { capitalize } from "tools/StringExtension";
import { EditTableLink } from "components/Buttons";
import { EnumOptionsTableWidgetProps } from "./widgets/EnumOptionsTableWidget";

export interface ITableODataFieldOptions<T> extends IODataTableField<IFormFieldOptions<T>, T> {
    scope?: ITableWidgetPropsBase<T>["scope"];
    style?: ITableWidgetPropsBase<T>["style"];

    labelOn?: SwitchTableWidgetProps["labelOn"];
    labelOff?: SwitchTableWidgetProps["labelOff"];
    format?: DateTableWidgetProps["format"];
    formatter?: DateTableWidgetProps["formatter"];
    title?: ButtonTableWidgetProps<T>["title"];
    href?: ButtonTableWidgetProps<T>["href"];
    onClick?: ButtonTableWidgetProps<T>["onClick"];
    color?: ButtonTableWidgetProps<T>["color"];
    variant?: ButtonTableWidgetProps<T>["variant"];
    size?: ButtonTableWidgetProps<T>["size"];

    options?: EnumOptionsTableWidgetProps["options"];
}

export default class TableODataFieldsBuilder<
    T
    > extends TableODataFieldOptionsBuilder<T, IFormFieldOptions<T>, ITableODataFieldOptions<T>> {

    constructor() {
        super((field) => {
            let label = field.label;
            if (label !== "") {
                label = label ? capitalize(label) : capitalize(field.name);
            }
            let align = field.align;
            if (align === undefined) {
                align = field.type === "number" ? "right" : "left";
            }
            let padding = field.padding;
            if (padding === undefined) {
                padding = (field.type === "button" || field.type === "boolean") ? "none" : "normal";
            }
            let color = field.color;
            let variant = field.variant;
            if (field.type === "button") {
                color = color ? color : "default";
                variant = variant ? variant : "contained";
            }


            const options = {
                ...field,
                label,
                padding,
                align,
                color,
                variant
            } as ITableODataFieldOptions<T>;

            if (!field.filterable) {
                return options;
            }

            let filter = field.filter;
            if (filter === undefined) {
                filter = {};
            }

            filter.type = filter.type ? filter.type : field.type;
            filter.name = filter.name ? filter.name : field.name;
            filter.autoComplete = filter.autoComplete ? filter.autoComplete : "off";

            if (
                ["string", "number", "email"].includes(filter.type) &&
                filter.delayAfterChange === undefined
            ) {
                filter.delayAfterChange = 400;
            }

            return options;
        });
    }

    setFilterSelect(nameOrField: string | IField, options: SelectOption[]) {
        this.set(nameOrField, {
            filterable: true,
            filter: {
                type: "select",
                options: options,
                availableOperators: ["eq"]
            }
        });
        return this;
    }

    addLinkEdit(href: string | ((data: T) => string)) {
        this.custom({
            padding: "none",
            render: ({ data }) => {
                const path = typeof href === "string" ? href : href(data);
                return <EditTableLink to={path} />
            }
        });
        return this;
    }
}
