import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  NotificationContextProvider,
  ErrorBoundary,
} from "cocoreact";

import App from "./App";
import {
  FatalErrorDialog,
  PageNotFoundError,
  PageNotFoundPage
} from "pages/Errors";
import { ThemeContextProvider } from "contexts/Theme";
import { AuthContextProvider } from "contexts/Auth";

export default function AppContainer() {
  return (
    <ThemeContextProvider>
      <CssBaseline />
      <ErrorBoundary component={FatalErrorDialog}>
        <ErrorBoundary component={PageNotFoundPage} renderWhen={(error: any) => error instanceof PageNotFoundError}>
          <NotificationContextProvider autoHideDuration={3000}>
            <AuthContextProvider>
              <App />
            </AuthContextProvider>
          </NotificationContextProvider>
        </ErrorBoundary>
      </ErrorBoundary>
    </ThemeContextProvider>
  )
}
