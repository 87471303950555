import React, { useRef, useCallback, useState } from "react";
import {
    makeStyles, Theme, createStyles,
    Button, IconButton, Typography, FormHelperText, FormControl, InputLabel, Box
} from "@material-ui/core";
import { IFormWidgetPropsBase } from "cocoreact";
import FileSaver from 'file-saver';

import { readFileAsync, FilePartUploader, getFileAsync } from "tools/FileExtension";
import { FileParentType } from "domain/static/FileParentType";
import { Guid, EmptyGuid, IsValidGuid } from "domain/static/Guid";
import { DeleteIcon, DownloadIcon, FileIcon } from "App/Theme";
import { FileInput } from "components/Page";

const useStyles = makeStyles((theme: Theme) => createStyles({
    selectBtn: {
        color: theme.palette.secondary.main,
        height: 48,
        marginTop: theme.spacing(2),
    },
    iconAndFileName: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        height: 48,
        padding: theme.spacing(0, 1),
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: theme.palette.divider,
        borderRadius: theme.shape.borderRadius,
        marginTop: theme.spacing(2),
    },
    progress: {
        top: 0,
        left: 0,
        bottom: 0,
        position: "absolute",
        opacity: 0.15,
        backgroundColor: theme.palette.secondary.main,
    },
    filename: {
        marginLeft: theme.spacing(1),
        marginRight: "auto",
    },
}));

export interface FileFormWidgetProps extends IFormWidgetPropsBase<Guid> {
    parentType: FileParentType;
    parentId: Guid;
    accept?: string;
    maxSize?: number;
    fileName?: string;
    onAfterLoad?: (onChange: (name: string, data: string) => void) => void;
}

export default function FileFormWidget({
    value, accept, maxSize, parentType, parentId, onChange, name, onAfterLoad, fileName: _fileName, error: _error, ...props
}: FileFormWidgetProps) {
    const classes = useStyles();
    const inputRef = useRef<HTMLInputElement>(null);
    const progressRef = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState(false);
    const [fileName, setFileName] = useState(_fileName);
    React.useEffect(() => setFileName(_fileName), [_fileName]);
    const [error, setError] = useState(_error);
    React.useEffect(() => setError(_error), [_error]);

    const inputHandle = useCallback(async (files: FileList | File) => {
        if (files instanceof FileList) return;
        const file = files;

        if (accept && !accept.includes(file.type)) {
            alert(`Inalid image type, it must be in [${accept}] but select image type : ${file.type}`);
            return;
        }
        if (maxSize && file.size > maxSize) {
            alert(`Inalid image size, it must be less than ${maxSize}bytes but select image size : ${file.size}bytes`);
            return;
        }

        if (progressRef.current) progressRef.current.style.width = "0%";
        setLoading(true);
        setError(undefined);
        setFileName(file.name);

        try {
            const content = await readFileAsync(file);

            const fileUploader = new FilePartUploader(
                file.name,
                "application/octet-stream"
            );
            fileUploader.setParent(parentId, parentType);
            fileUploader.onProgress((step: number, total: number) => {
                if (progressRef.current) {
                    const width = (step + 1) / (total + 1) * 100;
                    progressRef.current.style.width = `${Math.round(width)}%`;
                }
            });
            const fileId = await fileUploader.send(content);

            setLoading(false);
            onChange && onChange(name, fileId);
            onChange && onAfterLoad && onAfterLoad(onChange);
        }
        catch (e) {
            setError("Error while loading data !");
            setLoading(false);
        }
    }, [accept, maxSize, name, onChange, parentId, parentType, onAfterLoad]);

    const openClientFileDialog = useCallback(() => inputRef.current?.click(), []);

    const downloadHandle = useCallback(async () => {
        if (!IsValidGuid(value)) return;

        setLoading(true);
        const file = await getFileAsync(value);
        setLoading(false);
        FileSaver.saveAs(file.data, fileName);

    }, [fileName, value]);

    const removeHandle = useCallback(
        () => onChange && onChange(name, EmptyGuid()),
        [name, onChange]
    );

    return <FormControl
        required={props.required}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
        margin={props.margin}
        error={error !== undefined}
        className={props.className}
        style={props.style}
        aria-label={props.label}
    >
        <FileInput
            ref={inputRef ? inputRef : inputRef}
            onSelect={inputHandle}
        />

        {props.label && <InputLabel shrink>{props.label}</InputLabel>}

        {!IsValidGuid(value) && !loading &&
            <Button
                fullWidth
                variant="outlined"
                size="large"
                classes={{ root: classes.selectBtn }}
                onClick={openClientFileDialog}
            >
                Select file
            </Button>
        }

        {(IsValidGuid(value) || loading) &&
            <div className={classes.iconAndFileName}>
                {loading && <div className={classes.progress} ref={progressRef}></div>}
                <FileIcon />
                <Typography
                    className={classes.filename}
                >
                    {fileName}
                </Typography>
                {!loading &&
                    <Box display="flex" gridGap={8}>
                        <IconButton
                            // color="secondary"
                            size="small"
                            onClick={downloadHandle}
                        >
                            <DownloadIcon />
                        </IconButton>

                        <IconButton
                            color="secondary"
                            size="small"
                            onClick={removeHandle}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                }
                {loading && <Typography variant="caption">loading ...</Typography>}
            </div>
        }

        {error && <FormHelperText>{error}</FormHelperText>}

    </FormControl>
}