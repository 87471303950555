import { RequestMethod, IODataMessage, ODataFilter, ODataOrderBy, ODataSelect } from "cocoreact";

//Generated code, do not modify

export class SearchContouringWorkshopsQuery implements IODataMessage {
    top!: number;
    distinct!: boolean;
    filter!: ODataFilter;
    orderBy!: ODataOrderBy;
    select!: ODataSelect;
    skip!: number;

    public constructor(init?: Partial<SearchContouringWorkshopsQuery>) {
        Object.assign(this, init);
    }

    getMethod = () => "GET" as RequestMethod;

    getPath = () => `/admin/contouring-workshops`;

    getQueryString = () => {
        return {
            top: this.top,
            distinct: this.distinct,
            filter: this.filter,
            orderBy: this.orderBy,
            select: this.select,
            skip: this.skip,
        }
    }
    
    getBody = () => undefined;

    needAuthentication = () => true;
}
