import { VolumeType, VolumeTypeOptions } from "domain/static/VolumeType";
import _slugify from "slugify";
import removeMd from "remove-markdown";

export function slugify(str: string): string {
  str = _slugify(str);
  str = str.toLocaleLowerCase();
  str = str.replace(/[^a-z0-9\s]/g, " ");
  str = str.replace(/\s+/g, " ");
  str = str.trim();
  str = str.replace(/ /g, "-");
  return str;
}

export function nl2br(str: string): string {
  return str
    .replaceAll("\n\n\n", "\n")
    .replaceAll("\n\n", "\n")
    .replaceAll("\n", "<br />");
}

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function guidelineInfoLabel(
  anatomicStructureName: string,
  volumeType: VolumeType,
  targetTypeName: string,
  organizationName: string
): string {
  const target =
    volumeType === VolumeType.Target && targetTypeName
      ? ` [${targetTypeName}]`
      : "";
  const organization = organizationName ? ` - ${organizationName}` : "";
  return `${anatomicStructureName} - ${VolumeTypeOptions[volumeType].label}${target}${organization}`;
}

export function contourInfoLabel(
  anatomicStructureName: string,
  volumeType: VolumeType,
  targetTypeName: string
): string {
  const target =
    volumeType === VolumeType.Target && targetTypeName
      ? ` [${targetTypeName}]`
      : "";
  return `${anatomicStructureName}${target}`;
}

export function volumeTypeLabel(volumeType: VolumeType) {
  const vol = VolumeTypeOptions.find((x) => x.value === volumeType);
  return vol ? vol.label : "undefined";
}

export function wrap(str: string, limit: number) {
  return str.slice(0, limit) + (str.length > limit ? "..." : "");
}

export function stripMarkdown(str: string) {
  return removeMd(str);
}

export function toArrayBuffer(str: string) {
  return new TextEncoder().encode(str);
}

export function fromArrayBuffer(buf: ArrayBuffer) {
  return new TextDecoder().decode(buf);
}
