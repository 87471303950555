import { Guid } from "domain/static/Guid";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class GetUserClinicalCasesQuery implements IMessage {
    id!: Guid;

    public constructor(init?: Partial<GetUserClinicalCasesQuery>) {
        Object.assign(this, init);
    }

    getMethod = () => "GET" as RequestMethod;

    getPath = () => `/admin/users/${this.id.toString()}/clinical-cases`;

    getQueryString = () => undefined;

    getBody = () => undefined;

    needAuthentication = () => true;
}
