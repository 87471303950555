import { useCallback } from "react";
import { useEntityMessage } from "tools/Message";
import { Guid } from "domain/static/Guid";
import { GetClinicalCaseUsersQuery } from "domain/admin/query/GetClinicalCaseUsersQuery";
import { UserOptionResponse } from "domain/admin/response/UserOptionResponse";
import { ClinicalCaseUserRole } from "domain/static/ClinicalCaseUserRole";

export function emptyClinicalCaseUsersResponse() {
  return [] as UserOptionResponse[];
}

export default function useGetClinicalCaseUsers(
  id: Guid,
  role: ClinicalCaseUserRole
) {
  const buildQuery = useCallback(
    () => new GetClinicalCaseUsersQuery({ id, role }),
    [id, role]
  );
  return useEntityMessage(id, buildQuery, emptyClinicalCaseUsersResponse);
}
