import React, { useMemo } from "react";

import { Guid } from "domain/static/Guid";
import { fullPath } from "tools/Route";
import { Tabs, Tab } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const ARTICLE_TABS = {
    details: "EditArticle",
    guidelines: "EditArticleListGuidelines",
} as const;

export type ArticleTab = keyof typeof ARTICLE_TABS;

export interface ArticleTabsProps {
    id: Guid;
    tab?: ArticleTab;
}

export default function ArticleTabs({ id, tab }: ArticleTabsProps) {
    const history = useHistory();

    const tabs = useMemo(() => {
        return Object.keys(ARTICLE_TABS).map((key, idx) => {
            return {
                id: idx,
                name: key,
                url: fullPath(ARTICLE_TABS[key as ArticleTab], { params: { id } }),
            }
        })
    }, [id]);

    const value = useMemo(
        () => {
            return tabs.find(x => x.name === tab)?.id ?? 0;
        },
        [tabs, tab]
    );

    return <Tabs
        value={value}
        variant="scrollable"
        aria-label="article tabs"
    >
        {
            tabs.map(tab =>
                <Tab key={tab.id} label={tab.name} onClick={() => history.push(tab.url)} />
            )
        }
    </Tabs>
}