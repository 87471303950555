import React, { useRef, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

import { CentralColumnLayout } from "components/Layouts";
import { Flex, Paper, PaperTitle } from "components/Page";
import { ClinicalContourTable, RTStructLoaderButton } from "components/ClinicalData";
import { Guid } from "domain/static/Guid";
import useGetClinicalDataGrid from "domain/hooks/useGetClinicalDataGrid";
import { AddIcon, ClinicalCaseIcon } from "App/Theme";
import { fullPath } from "tools/Route";

const useStyles = makeStyles((theme: Theme) => createStyles({
    emptyGrid: {
        display: "block",
        padding: theme.spacing(1, 2),
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: theme.palette.error.main,
        borderRadius: theme.shape.borderRadius,
    }
}));

export default function ListClinicalContoursPanel() {

    const classes = useStyles();

    const { id } = useParams<{ id: Guid }>();
    const updateTableRef = useRef<HTMLButtonElement>(null);

    const [gridLoading, grid,] = useGetClinicalDataGrid(id);
    const validGrid = !gridLoading && (grid && grid.spacing);

    const handleRTStructLoaded = useCallback(() => {
        if (updateTableRef.current) {
            updateTableRef.current.click();
        }
    }, []);

    return <CentralColumnLayout size={"lg"}>

        <Flex justifyContent="flex-end">
            {!validGrid &&
                <Typography variant="caption" className={classes.emptyGrid}>
                    You must load at least 1 image to add/import contours
                </Typography>
            }

            {validGrid && <>
                <Link to={fullPath("EditClinicalCaseNewContour", { params: { id } })}>
                    <Button
                        color="secondary"
                        variant="contained"
                        startIcon={<AddIcon />}
                    >
                        new contour
                    </Button>
                </Link>
                <RTStructLoaderButton
                    clinicalDataId={id}
                    grid={grid}
                    onSuccess={handleRTStructLoaded}
                />
            </>}
        </Flex>


        <Paper>
            <PaperTitle icon={<ClinicalCaseIcon />}>
                List of clinical case contours
            </PaperTitle>

            <ClinicalContourTable
                id={id}
                editRouteName={"EditClinicalCaseEditContour"}
                updateRef={updateTableRef}
            />
        </Paper>

    </CentralColumnLayout>
}
