import { Guid } from "domain/static/Guid";
import { ImagingDefault } from "domain/static/ImagingDefault";
import { VolumeType } from "domain/static/VolumeType";
import { Range } from "domain/static/Range";
import { IField } from "cocoreact";

//Generated code, do not modify

export class ClinicalDataSceneResponse {
    globalContentLength!: number;
    id!: Guid;
    images!: Image[];
    contours!: Contour[];

    public static Fields = {
        globalContentLength: { name: "globalContentLength", type: "number" },
        id: { name: "id", type: "Guid" },
        images: { name: "images", type: "Image[]" },
        contours: { name: "contours", type: "Contour[]" },
    } as Record<keyof ClinicalDataSceneResponse, IField>;
}

export class Image {
    id!: Guid;
    name!: string;
    windowing!: Range;
    range!: Range;
    lut!: string;
    fileId!: Guid;
    fileContentLength!: number;
    default!: ImagingDefault;
    imagingModalityName!: string;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        name: { name: "name", type: "string" },
        windowing: { name: "windowing", type: "Range" },
        range: { name: "range", type: "Range" },
        lut: { name: "lut", type: "string" },
        fileId: { name: "fileId", type: "Guid" },
        fileContentLength: { name: "fileContentLength", type: "number" },
        default: { name: "default", type: "ImagingDefault" },
        imagingModalityName: { name: "imagingModalityName", type: "string" },
    } as Record<keyof Image, IField>;
}

export class Contour {
    id!: Guid;
    name!: string;
    fileId!: Guid | null;
    fileContentLength!: number;
    color!: string;
    anatomicStructureName!: string;
    volumeType!: VolumeType;
    targetTypeName!: string;
    organizationName!: string;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        name: { name: "name", type: "string" },
        fileId: { name: "fileId", type: "Guid" },
        fileContentLength: { name: "fileContentLength", type: "number" },
        color: { name: "color", type: "string" },
        anatomicStructureName: { name: "anatomicStructureName", type: "string" },
        volumeType: { name: "volumeType", type: "VolumeType" },
        targetTypeName: { name: "targetTypeName", type: "string" },
        organizationName: { name: "organizationName", type: "string" },
    } as Record<keyof Contour, IField>;
}
