import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { People as EditorIcon } from "@material-ui/icons";
import { AccountCircle as ReviewerIcon } from "@material-ui/icons";

import { CentralColumnLayout } from "components/Layouts";
import { Guid } from "domain/static/Guid";
import { Flex, Paper, PaperTitle } from "components/Page";
import { UsersManager } from "./UsersManager";
import { ClinicalCaseUserRole } from "domain/static/ClinicalCaseUserRole";
import { ContactDialog } from "pages/Contact";
import { Button, CircularProgress } from "@material-ui/core";
import { EmailIcon, SendIcon } from "App/Theme";
import { useCallback } from "react";
import { UserOptionResponse } from "domain/admin/response/UserOptionResponse";
import ConfirmSubmitDialog from "./ConrfirmSubmitDialog";
import useGetClinicalCase from "domain/hooks/useGetClinicalCase";
import { ClinicalCaseState } from "domain/static/ClinicalCaseState";
import { AskClinicalCaseReviewCommand } from "domain/admin/command/AskClinicalCaseReviewCommand";
import { SetStateClinicalCaseCommand } from "domain/admin/command/SetStateClinicalCaseCommand";
import { sendMessage } from "tools/Message";
import { useNotificationContext } from "cocoreact";

export default function ListContributorsPanel() {

    const params = useParams<{ id: Guid }>();
    const { success, error } = useNotificationContext();

    const [contact, setContact] = useState({
        open: false,
        bcc: "",
    });
    const [confirmSubmitOpen, setConfirmSubmitOpen] = useState(false);
    const [isSending, setSending] = useState(false);
    
    const [loadingClinicalCase, clinicalCase, updateClinicalCase] = useGetClinicalCase(params.id);

    const openContactHandle = useCallback((users: UserOptionResponse[]) => {
        const bcc = users.map(x => `${x.firstname} ${x.lastname} <${x.email}>`).join(", ");
        setContact({ open: true, bcc });
    }, []);

    const closeContactHandle = useCallback(() => {
        setContact(c => ({ ...c, open: false }));
    }, []);

    const askReview = useCallback(async () => {
        setSending(true);
        try {
            const message = new AskClinicalCaseReviewCommand({ id: params.id });
            await sendMessage(message);
            updateClinicalCase();
            success("Review request sent successfully");
        }
        catch (e) {
            error("Error while asking clinical case review !");
        }
        finally {
            setSending(false);
        }
    }, [error, params.id, success, updateClinicalCase]);

    const onRequestHandle = useCallback(async (users: UserOptionResponse[]) => {
        if (users.length === 0 && loadingClinicalCase) return;

        if (clinicalCase.state !== ClinicalCaseState.Submitted) {
            setConfirmSubmitOpen(true);
        }
        else {
            await askReview();
        }
    }, [askReview, clinicalCase.state, loadingClinicalCase]);


    const submitAndAskReview = useCallback(async () => {
        setConfirmSubmitOpen(false);
        setSending(true);
        try {
            const message = new SetStateClinicalCaseCommand({ id: params.id });
            message.state = ClinicalCaseState.Submitted;
            await sendMessage(message);
            updateClinicalCase();
            success("Clinical case submitted successfully");
            await askReview();
        }
        catch (e) {
            error("An error occurred while submitting clinical case !");
        }
        finally {
            setSending(false);
        }
    }, [askReview, error, params.id, success, updateClinicalCase]);

    return <CentralColumnLayout size="md">

        <Paper>
            <PaperTitle icon={<EditorIcon />}>Editors</PaperTitle>
            <UsersManager
                id={params.id}
                role={ClinicalCaseUserRole.Editor}
            >
                {(users) => users.length > 0 &&
                    <Flex marginTop={2} justifyContent="start">
                        <Button
                            variant="outlined"
                            startIcon={<EmailIcon />}
                            onClick={() => openContactHandle(users)}
                        >
                            contact
                        </Button>
                    </Flex>
                }
            </UsersManager>
        </Paper>

        <Paper>
            <PaperTitle icon={<ReviewerIcon />}>Reviewers</PaperTitle>
            <UsersManager
                id={params.id}
                role={ClinicalCaseUserRole.Reviewer}
            >
                {(users) => users.length > 0 &&
                    <Flex marginTop={2} justifyContent="space-between">
                        <Button
                            variant="outlined"
                            startIcon={<EmailIcon />}
                            onClick={() => openContactHandle(users)}
                        >
                            contact
                        </Button>

                        <Button
                            variant="outlined"
                            endIcon={isSending ? <CircularProgress color="inherit" size={16} /> : <SendIcon />}
                            onClick={() => onRequestHandle(users)}
                            disabled={isSending}
                        >
                            send a review request
                        </Button>
                    </Flex>
                }
            </UsersManager>
        </Paper>

        <ContactDialog
            open={contact.open}
            onClose={closeContactHandle}
            title="Contact clinical case users"
            initialValue={{ bcc: contact.bcc }}
            onAfterSend={closeContactHandle}
        />

        <ConfirmSubmitDialog
            open={confirmSubmitOpen}
            onClose={() => setConfirmSubmitOpen(false)}
            onAccept={submitAndAskReview}
        />

    </CentralColumnLayout>
}
