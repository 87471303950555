import { UserRole } from "domain/static/UserRole";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class CreateUserCommand implements IMessage {
    firstname!: string;
    lastname!: string;
    email!: string;
    role!: UserRole;
    status!: string;
    abstract!: string;
    professionalLink!: string;
    scientificLink!: string;

    public constructor(init?: Partial<CreateUserCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "POST" as RequestMethod;

    getPath = () => `/admin/users`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            firstname: this.firstname,
            lastname: this.lastname,
            email: this.email,
            role: this.role,
            status: this.status,
            abstract: this.abstract,
            professionalLink: this.professionalLink,
            scientificLink: this.scientificLink,
        };
    }
    
    needAuthentication = () => true;
}
