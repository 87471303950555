//Generated code, do not modify

export enum UserRole {
  None = 0,
  SuperAdmin = 1,
  Admin = 2,
  Standard = 4,
}

export const UserRoleOptions = [
  {
    label: "None",
    value: UserRole.None,
  },
  {
    label: "SuperAdmin",
    value: UserRole.SuperAdmin,
  },
  {
    label: "Admin",
    value: UserRole.Admin,
  },
  {
    label: "Standard",
    value: UserRole.Standard,
  },
];
