import React from "react";
import {
    makeStyles, Theme, createStyles,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    Box, Button
} from "@material-ui/core";
import { CloseIcon } from "App/Theme";

const useStyles = makeStyles((theme: Theme) => createStyles({
    submitBtn: {
        color: theme.palette.warning.main,
        borderColor: theme.palette.warning.main,
    }
}));

export interface ConfirmSubmitDialogProps {
    open: boolean;
    onClose: () => void;
    onAccept: () => void
}

export default function ConfirmSubmitDialog({ open, onClose, onAccept }: ConfirmSubmitDialogProps) {
    const classes = useStyles();

    return <Dialog open={open} onClose={onClose}>
        <DialogTitle>
            Confirmation
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                The clinical case must be submitted to be reviewed but its appears is not.
            </DialogContentText>
            <DialogContentText>
                Do you wan't to submit the clinical case to ask a review ?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Box display="flex" justifyContent="space-between" width="100%" paddingX={2} paddingY={1}>
                <Button
                    variant="outlined"
                    onClick={onClose}
                    startIcon={<CloseIcon />}
                >
                    close
                </Button>
                <Button
                    variant="outlined"
                    onClick={onAccept}
                    className={classes.submitBtn}
                >
                    Yes, submit and ask review
                </Button>
            </Box>
        </DialogActions>
    </Dialog>
}