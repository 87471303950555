import { useEntityMessage } from "tools/Message";
import { EmptyGuid, Guid } from "domain/static/Guid";
import { GetArticleQuery } from "domain/admin/query/GetArticleQuery";
import { ArticleResponse } from "domain/admin/response/ArticleResponse";

export function emptyArticleBuilder() {
  let entity = new ArticleResponse();
  entity.id = EmptyGuid();
  entity.anatomicZoneId = EmptyGuid();
  entity.anatomicZoneName = "";
  entity.title = "";
  entity.authors = "";
  entity.date = "";
  entity.editor = "";
  entity.page = "";
  entity.doi = "";
  entity.pmid = "";
  entity.url = "";
  entity.isArchived = false;
  return entity;
}

export function getArticleQueryBuilder(id: Guid) {
  return new GetArticleQuery({ id });
}

export default function useGetArticle(id: Guid) {
  return useEntityMessage(
    id,
    getArticleQueryBuilder,
    emptyArticleBuilder
  );
}
