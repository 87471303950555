import { Guid } from "domain/static/Guid";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class UpdateSelectItemCommand implements IMessage {
    id!: Guid;
    name!: string;

    public constructor(init?: Partial<UpdateSelectItemCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "PUT" as RequestMethod;

    getPath = () => `/admin/select-items/${this.id.toString()}`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            name: this.name,
        };
    }
    
    needAuthentication = () => true;
}
