import { Guid } from "domain/static/Guid";
import { IField } from "cocoreact";

//Generated code, do not modify

export class ArticleResponse {
    id!: Guid;
    title!: string;
    authors!: string;
    date!: string;
    editor!: string;
    page!: string;
    doi!: string;
    pmid!: string;
    url!: string;
    anatomicZoneId!: Guid;
    anatomicZoneName!: string;
    createdAt!: Date;
    updatedAt!: Date;
    isArchived!: boolean;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        title: { name: "title", type: "string" },
        authors: { name: "authors", type: "string" },
        date: { name: "date", type: "string" },
        editor: { name: "editor", type: "string" },
        page: { name: "page", type: "string" },
        doi: { name: "doi", type: "string" },
        pmid: { name: "pmid", type: "string" },
        url: { name: "url", type: "string" },
        anatomicZoneId: { name: "anatomicZoneId", type: "Guid" },
        anatomicZoneName: { name: "anatomicZoneName", type: "string" },
        createdAt: { name: "createdAt", type: "Date" },
        updatedAt: { name: "updatedAt", type: "Date" },
        isArchived: { name: "isArchived", type: "boolean" },
    } as Record<keyof ArticleResponse, IField>;
}
