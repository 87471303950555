import React from "react";
import { ODataFilter, ODataSelect, ODataOrderBy } from "cocoreact";
import { useODataMessage } from "tools/Message";
import { SearchContouringWorkshopsQuery } from "domain/admin/query/SearchContouringWorkshopsQuery";
import { ContouringWorkshopItemResponse } from "domain/admin/response/ODataContouringWorkshopItemResponse";

export function buildSearchContouringWorkshopsQuery() {
  const query = new SearchContouringWorkshopsQuery();
  query.top = 1000;
  query.skip = 0;
  query.filter = new ODataFilter();
  query.select = new ODataSelect();
  query.orderBy = new ODataOrderBy();
  query.orderBy.set(ContouringWorkshopItemResponse.Fields.name.name);
  return query;
}

export default function useSearchContouringWorkshops(query?: SearchContouringWorkshopsQuery) {
  const _query = React.useMemo(() => query ?? buildSearchContouringWorkshopsQuery(), [
    query,
  ]);
  return useODataMessage<ContouringWorkshopItemResponse>(_query);
}
