import { IODataMessage, OrderDirection, ODataFilterOperator } from "cocoreact";

// settings file
declare var APP_WEBSITE_NAME: string;

function buildKey(base: string, key: string) {
  return `${APP_WEBSITE_NAME}_${base}_${key}`;
}

function loadItem(base: string, key: string) {
  return sessionStorage.getItem(buildKey(base, key)) ?? undefined;
}

function saveItem(base: string, key: string, value: string) {
  sessionStorage.setItem(buildKey(base, key), value);
}

export function loadODataMessage(key: string, message: IODataMessage) {
  const top = loadItem(key, "top");
  const orderByMember = loadItem(key, "orderByMember");
  const orderByDir = loadItem(key, "orderByDir") as OrderDirection | undefined;
  const filterOperators = loadItem(key, "filterOperators");
  const filterValues = loadItem(key, "filterValues");

  if (top) {
    message.top = parseInt(top);
  }
  if (orderByMember && orderByDir) {
    message.orderBy.set(orderByMember, orderByDir);
  }
  if (filterOperators && filterValues) {
    const fOperators = JSON.parse(filterOperators) as Record<
      string,
      ODataFilterOperator
    >;
    const fValues = JSON.parse(filterValues) as Record<string, any>;
    Object.keys(fValues).forEach((k) => {
      message.filter.set(k, k, fOperators[k], fValues[k]);
    });
  }

  return message;
}

export function saveODataMessage(key: string, message: IODataMessage) {
  const top = message.top.toString();
  const orderByMember = message.orderBy.member?.toString() ?? "";
  const orderByDir = message.orderBy.direction?.toString() ?? "";
  const filterOperators = JSON.stringify(message.filter.getOperators());
  const filterValues = JSON.stringify(message.filter.getValues());

  saveItem(key, "top", top);
  saveItem(key, "orderByMember", orderByMember);
  saveItem(key, "orderByDir", orderByDir);
  saveItem(key, "filterOperators", filterOperators);
  saveItem(key, "filterValues", filterValues);
}
