import React, { useMemo } from "react";
import { matchPath, Route, Switch, useLocation, useParams } from "react-router-dom";

import { Guid } from "domain/static/Guid";
import useGetArticle from "domain/hooks/useGetArticle";
import { PageLayout } from "components/Layouts";
import { HeaderTabs, Title } from "components/Page";
import { ArchiveWidget } from "components/ClinicalData";
import { fullPath, RouteName } from "tools/Route";
import ArticleTabs, { ArticleTab } from "./ArticleTabs";
import { DetailsPanel } from "./Details";
import { ListGuidelinesPanel, EditGuidelinePanel } from "./Guidelines";

function getTab(loc: string): ArticleTab {
    if (matchPath(loc, { path: fullPath("EditArticleListGuidelines") })) {
        return "guidelines";
    }
    return "details";
}

function getTitle(loc: string): string {
    const titles = {
        "EditArticleNewGuideline": "Create guideline",
        "EditArticleEditGuideline": "Edit guideline",
        "EditArticleListGuidelines": "Guidelines list",
    } as Record<RouteName, string>;

    for (const [routeName, title] of Object.entries(titles)) {
        if (matchPath(loc, { path: fullPath(routeName as any) })) {
            return title;
        }
    }
    return "Edit";
}

export default function EditArticlePage() {

    const params = useParams<{ id: Guid }>();
    const location = useLocation();

    const [loading, entity] = useGetArticle(params.id);
    const tab = useMemo(() => getTab(location.pathname), [location.pathname]);
    const title = useMemo(() => getTitle(location.pathname), [location.pathname]);

    return <>
        <Title>{title}</Title>

        <PageLayout title="Articles" menuSelected="ListArticles">

            <HeaderTabs
                previousLink={fullPath("ListArticles")}
                title={loading ? "Loading ..." : entity.title}
                tabsComponent={
                    <ArticleTabs id={params.id} tab={tab} />
                }
                chips={loading ? undefined : [
                    entity.isArchived && <ArchiveWidget />
                ]}
            />

            <Switch>
                <Route exact path={fullPath("EditArticle")} component={DetailsPanel} />

                <Route exact path={fullPath("EditArticleListGuidelines")} component={ListGuidelinesPanel} />
                <Route exact path={fullPath("EditArticleNewGuideline")} component={EditGuidelinePanel} />
                <Route exact path={fullPath("EditArticleEditGuideline")} component={EditGuidelinePanel} />
            </Switch>

        </PageLayout>
    </>
}
