import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { CentralColumnLayout, PageLayout } from "components/Layouts";
import { EmptyGuid, Guid } from "domain/static/Guid";
import { fullPath } from "tools/Route";
import ArticleFormDetails from "./ArticleFormDetails";
import { Paper, PaperTitle, Title } from "components/Page";
import { useNotificationContext } from "cocoreact";
import { ArticleIcon } from "App/Theme";

export default function CreateArticlePage() {

    const { success } = useNotificationContext();

    const history = useHistory();
    const handleAfterSave = useCallback((id?: Guid) => {
        if (id) {
            history.push(fullPath("EditArticle", { params: { id } }));
        }
        else {
            history.push(fullPath("ListArticles"));
        }
        success("Article saved with success");
    }, [history, success]);

    const title = "Create an article";

    return <>
        <Title>{title}</Title>

        <PageLayout title="Articles" menuSelected="ListArticles">
            <CentralColumnLayout size="md">

                <Paper>
                    <PaperTitle icon={<ArticleIcon />}>{title}</PaperTitle>

                    <ArticleFormDetails
                        id={EmptyGuid()}
                        onAfterSave={handleAfterSave}
                    />
                </Paper>

            </CentralColumnLayout>
        </PageLayout>
    </>
}
