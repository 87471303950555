import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

import { DownloadIcon, CloseIcon } from "App/Theme";
import { FileInput, LoadingDialog } from "components/Page";
import { FilePartUploader, readFileAsync } from "tools/FileExtension";
import { sendMessage } from "tools/Message";
import { toArrayBuffer } from "tools/StringExtension";
import { Guid } from "domain/static/Guid";
import { BatchCreateClinicalContourCommand, CreateClinicalContourCommand } from "domain/admin/command/BatchCreateClinicalContourCommand";
import { SetArchivingStateAllContoursCommand } from "domain/admin/command/SetArchivingStateAllContoursCommand";
import { ClinicalDataGridResponse } from "domain/admin/response/ClinicalDataGridResponse";
import { parseRTStructRois } from "tools/ContourExtension";
import { RgbToHex } from "tools/ColorExtension";
import { Grid3D, ReadOnlyRoi, RTStructROI } from "dline-viewer/dist/data";

export interface RTStructLoaderButtonProps {
    clinicalDataId: Guid;
    grid: ClinicalDataGridResponse;
    onSuccess: () => void;
}

export default function RTStructLoaderButton({
    clinicalDataId, onSuccess, grid
}: RTStructLoaderButtonProps) {

    const loadRef = React.useRef<HTMLInputElement>(null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [step, setStep] = React.useState("");
    const worker = React.useRef(new Worker(`${process.env.PUBLIC_URL}/workers/viewer.worker.js`));
    const workerGrid = React.useRef(new Grid3D());
    const workerRtstructRois = React.useRef([] as RTStructROI[]);
    const batchCommand = React.useRef(new BatchCreateClinicalContourCommand());

    const handleSelectFile = React.useCallback(() => {
        if (loadRef.current) {
            loadRef.current.click();
        }
    }, []);

    const workerMessageHandler = React.useCallback(async (ev: MessageEvent) => {
        const index = ev.data.index as number;
        const roi = ev.data.roi as ReadOnlyRoi;

        try {
            const fileUploader = new FilePartUploader(
                "ReadOnlyROI_polygonVolume.json",
                "application/json"
            );
            const dataRoi = JSON.stringify(roi.polygonVolume);
            const fileId = await fileUploader.send(toArrayBuffer(dataRoi));

            const contourCommand = new CreateClinicalContourCommand();
            contourCommand.clinicalDataId = clinicalDataId;
            contourCommand.name = roi.name;
            contourCommand.color = RgbToHex(roi.color);
            contourCommand.fileId = fileId;
            contourCommand.linkArticleToClinicalCase = false;

            batchCommand.current.messages.push(contourCommand);
        }
        catch (e) {
            setLoading(false);
            setError(true);
            return;
        }

        if (index < workerRtstructRois.current.length - 1) {
            const nextIndex = index + 1;
            setStep(`Create contours ${nextIndex}/${workerRtstructRois.current.length}...`);
            worker.current.postMessage({
                msg: "RTSTRUCT_TO_READONLY",
                index: nextIndex,
                grid: workerGrid.current,
                roi: workerRtstructRois.current[nextIndex],
            });
        }
        else {
            setStep("Upload contours ...");

            const archiveCommand = new SetArchivingStateAllContoursCommand({
                id: clinicalDataId,
                isArchived: true,
            });
            await sendMessage(archiveCommand);

            await sendMessage(batchCommand.current);

            setLoading(false);

            onSuccess();
        }
    }, [clinicalDataId, onSuccess]);

    const workerErrorHandler = React.useCallback((ev: ErrorEvent) => {
        setLoading(false);
        setError(true);
    }, []);

    const handleOpenFile = React.useCallback(async (files: FileList | File) => {
        if (files instanceof FileList) return;
        const file = files;

        // prepapre data
        setStep("Loading RT Struct file ...");
        setLoading(true);
        const arrayBuffer = await readFileAsync(file);

        setStep("Analyze RT Struct content ...");
        const rtStructRois = parseRTStructRois(arrayBuffer);

        // prepare worker
        workerGrid.current.Set(
            [grid.size.x, grid.size.y, grid.size.z],
            [grid.spacing.x, grid.spacing.y, grid.spacing.z],
            [grid.origin.x, grid.origin.y, grid.origin.z],
        );
        worker.current.onmessage = workerMessageHandler;
        worker.current.onerror = workerErrorHandler;
        workerRtstructRois.current = rtStructRois;
        batchCommand.current = new BatchCreateClinicalContourCommand();
        batchCommand.current.messages = [];

        // start worker
        setStep(`Create contours 1/${workerRtstructRois.current.length}...`);
        worker.current.postMessage({
            msg: "RTSTRUCT_TO_READONLY",
            index: 0,
            grid: workerGrid.current,
            roi: workerRtstructRois.current[0],
        });
    }, [grid, workerErrorHandler, workerMessageHandler]);

    return (
        <>
            <Button
                color="secondary"
                variant="contained"
                startIcon={<DownloadIcon />}
                title="load a RadioTherapie Structure"
                onClick={handleSelectFile}
            >
                load RT Struct
            </Button>

            <FileInput
                ref={loadRef}
                onSelect={handleOpenFile}
            />

            <Dialog open={error}>
                <DialogTitle>
                    An error occured
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Restart the operation or contact your administrator if the error persists.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        startIcon={<CloseIcon />}
                        onClick={() => setError(false)}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <LoadingDialog
                open={loading}
                sentence={step}
            />
        </>
    )
}