import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    Button, CircularProgress, createStyles,
    alpha, makeStyles, Theme, Typography
} from "@material-ui/core";
import clsx from "clsx";
import { useNotificationContext } from "cocoreact";

import SceneConfigurationDrawer from "./SceneConfigurationDrawer";
import { IsEmptyGuid } from "domain/static/Guid";
import { ImagingDefault } from "domain/static/ImagingDefault";
import { OrientationType } from "domain/static/OrientationType";
import { FileParentType } from "domain/static/FileParentType";
import { UpdateClinicalDataSceneCommand } from "domain/admin/command/UpdateClinicalDataSceneCommand";
import { ClinicalDataSceneResponse } from "domain/admin/response/ClinicalDataSceneResponse";
import { RefreshIcon, SaveIcon } from "App/Theme";
import { useViewerContext, ViewerData, ImageData, ContourData } from "contexts/Viewer";
import { DrawingTools, OrientationSelect } from "components/Viewer";
import { CircularProgressWithLabel, Flex } from "components/Page";
import { sendMessage } from "tools/Message";
import { loadItkImage } from "tools/ImageExtension";
import { createReadOnlyRoi, loadReadOnlyRoi } from "tools/ContourExtension";
import { HexToRgb } from "tools/ColorExtension";
import { FilePartUploader } from "tools/FileExtension";
import { toArrayBuffer } from "tools/StringExtension";
import AppViewerHelper from "tools/Viewer";

const HEIGHT_CALC = "calc(100vh - 220px)";
const DRAWER_WIDTH = 380;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        height: HEIGHT_CALC,
        position: "relative",
    },
    mainContainer: {
        display: "flex",
        width: "100%",
        height: HEIGHT_CALC,
    },
    leftContainer: {
        width: "100%",
        height: HEIGHT_CALC,
        boxShadow: "-1px 2px 10px 3px rgba(0, 0, 0, 0.2) inset",
        backgroundColor: theme.palette.type === "dark"
            ? theme.palette.grey[900]
            : theme.palette.grey[300],
    },
    topLeftZone: {
        position: "absolute",
        top: theme.spacing(2),
        left: theme.spacing(2),
        "& > *": {
            display: "inline-flex",
            marginRight: theme.spacing(1),
        },
        "& > :last-child": {
            marginRight: 0,
        }
    },
    middleRightZone: {
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        top: "50%",
        bottom: "50%",
        right: DRAWER_WIDTH + theme.spacing(2),
        transition: theme.transitions.create(["right"]),
    },
    middleRightZone_hidden: {
        right: DRAWER_WIDTH - theme.spacing(8),
    },
    overheadContainer: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "100%",
        color: theme.palette.text.hint,
        backgroundColor: alpha(theme.palette.background.default, 0.9),
        zIndex: theme.zIndex.drawer * 2,
    },
    errorInfo: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    publishRow: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(1, 2),
        borderWidth: 1,
        borderColor: theme.palette.divider,
        borderStyle: "solid",
        borderRadius: theme.shape.borderRadius,
    }
})
);

async function buildViewerData(entity: ClinicalDataSceneResponse) {
    const data = new ViewerData();

    for (const image of entity.images) {
        const img = new ImageData();
        img.id = image.id;
        img.windowing = image.windowing;
        img.lut = image.lut;
        img.default = image.default;
        img.data = null;

        data.images.push(img);
    }

    if (data.getImageByImagingDefault(ImagingDefault.Primary) === undefined) {
        data.images[0].default = ImagingDefault.Primary;
    }

    for (const contour of entity.contours) {
        const c = new ContourData();
        c.id = contour.id;
        c.color = contour.color;
        c.visible = true;
        c.drawing = false;
        c.data = null;

        data.contours.push(c);
    }

    return data;
}

async function buildUpdateCommand(
    ccScene: ClinicalDataSceneResponse,
    viewerData: ViewerData,
    viewerApp: AppViewerHelper
) {
    const fileUploader = new FilePartUploader(
        "ReadOnlyROI_polygonVolume.json",
        "application/json"
    );

    const command = new UpdateClinicalDataSceneCommand({ id: ccScene.id });

    command.images = viewerData.images.map(x => ({
        id: x.id,
        default: x.default,
        lut: x.lut,
        windowing: x.windowing,
    }));

    command.contours = [];
    for (const dataContour of viewerData.contours) {
        const contour = ccScene.contours.find(x => x.id === dataContour.id);
        const color = dataContour.color;

        let fileId = contour ? contour.fileId : null;
        const appContour = viewerApp.getContourById(dataContour.id);
        const polygonVolume = appContour ? appContour.computePolygonVolumeFromDrawing() : null;
        if (polygonVolume) {
            const dataRoi = JSON.stringify(polygonVolume);
            fileUploader.setParent(dataContour.id, FileParentType.ClinicalContour);
            fileId = await fileUploader.send(toArrayBuffer(dataRoi));
        }

        command.contours.push({
            id: dataContour.id,
            color,
            fileId: fileId
        });
    }

    return command;
}

export interface SceneViewerContainerProps {
    loading: boolean;
    entity: ClinicalDataSceneResponse;
    distinctVolumeTypes?: boolean;
}

export default function SceneViewerContainer({
    loading: ccSceneLoading, entity: ccScene, distinctVolumeTypes
}: SceneViewerContainerProps) {

    const classes = useStyles();
    const { success } = useNotificationContext();
    const {
        app: viewerApp,
        data: viewerData,
        dispatcher: viewerDispatcher
    } = useViewerContext();

    const [saveLoading, setSaveLoading] = useState(false);
    const [actionsEnabled, setActionsEnabled] = useState(true);
    const [orientation, setOrientation] = useState(OrientationType.Axial);
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState({
        label: "loading ...",
        value: 0,
        total: 100,
    });

    const containerRef = useRef<HTMLDivElement>(null);
    const viewerContainerRef = useRef<HTMLDivElement>(null);
    const [initialViewerData, setInitialViewerData] = useState(new ViewerData());

    useEffect(() => {
        const _downloadAndInitialize = async () => {
            if (ccScene.images.length === 0) {
                setLoading(false);
                return;
            }

            setProgress({
                label: "loading ...",
                value: 0,
                total: 100,
            });
            setLoading(true);

            const data = await buildViewerData(ccScene);

            const nbImage = ccScene.images.length;
            const nbContour = ccScene.contours.length;

            setProgress({
                label: "loading ...",
                value: 0,
                total: ccScene.globalContentLength,
            });
            let globalLoaded = 0;

            for (let idx = 0; idx < nbImage; idx++) {
                setProgress(p => ({ ...p, label: `image ${idx + 1}/${nbImage}` }));

                data.images[idx].data = await loadItkImage(
                    ccScene.images[idx].fileId,
                    // eslint-disable-next-line no-loop-func
                    (loaded, _) => setProgress(p => ({ ...p, value: globalLoaded + loaded })),
                );
                globalLoaded += ccScene.images[idx].fileContentLength;
            }

            const grid = data.images[0].data.grid;

            for (let idx = 0; idx < nbContour; idx++) {
                setProgress(p => ({ ...p, label: `contour ${idx + 1}/${nbContour}` }));

                const contour = ccScene.contours[idx];
                const fileId = contour.fileId;
                const readonlyRoi = fileId
                    ? await loadReadOnlyRoi(fileId, contour.id, contour.color,
                        // eslint-disable-next-line no-loop-func
                        (loaded, _) => setProgress(p => ({ ...p, value: globalLoaded + loaded })),
                    )
                    : createReadOnlyRoi(contour.id, contour.color, grid);
                data.contours[idx].data = readonlyRoi;

                globalLoaded += contour.fileContentLength;
            }

            setInitialViewerData(data);
            viewerDispatcher({
                type: "INITIALIZE",
                data: data,
            }, "viewer");
        };
        if (!ccSceneLoading
            && !IsEmptyGuid(ccScene.id)
            && viewerData.numberOfImages === 0) {
            _downloadAndInitialize();
        }
    }, [ccScene, ccSceneLoading, viewerData, viewerDispatcher]);

    useEffect(() => {
        const _initializeScene = async (container: HTMLDivElement) => {
            await viewerApp.createScene(orientation, container);
            await viewerApp.getSceneAt(0).autoCenterZoomCamera();
            await viewerApp.setFusionType(viewerData.defaultFusionType);
            setLoading(false);
        };
        if (viewerData.numberOfImages > 0
            && viewerApp.numberOfScenes === 0
            && viewerContainerRef.current) {
            _initializeScene(viewerContainerRef.current);
        }
    }, [orientation, viewerApp, viewerData]);

    const onOrientationChangeHandle = useCallback(async (newOrientation: OrientationType) => {
        setOrientation(newOrientation);
        await viewerApp.getSceneAt(0).setOrientation(newOrientation);
    }, [viewerApp]);

    const onResetHandle = useCallback(async () => {
        if (initialViewerData.images.length === 0) return;

        setActionsEnabled(false);
        for (const image of initialViewerData.images) {
            await viewerApp.setImagesWindowing(image.id, image.windowing);
            await viewerApp.setImagesLutPreset(image.id, image.lut);
        }
        for (const contour of initialViewerData.contours) {
            await viewerApp.setContourColor(contour.id, HexToRgb(contour.color));
        }
        viewerDispatcher({
            type: "INITIALIZE",
            data: initialViewerData
        }, "viewer");
        setActionsEnabled(true);
    }, [initialViewerData, viewerApp, viewerDispatcher]);

    const onSaveHandle = useCallback(async () => {
        if (viewerData.images.length === 0) return;

        setActionsEnabled(false);
        setSaveLoading(true);

        const command = await buildUpdateCommand(ccScene, viewerData, viewerApp);
        await sendMessage(command);
        success("Scene configuration saved with success");

        setSaveLoading(false);
        setActionsEnabled(true);

    }, [viewerData, ccScene, success, viewerApp]);

    return (<>

        <div className={classes.root}>

            {loading &&
                <div className={classes.overheadContainer}>
                    <CircularProgressWithLabel size={80} color="secondary" value={Math.floor(progress.value / progress.total * 100)} />
                    <Typography>{progress.label}</Typography>
                </div>
            }

            {viewerData.loading &&
                <div className={classes.overheadContainer}>
                    <CircularProgress size={80} color="secondary" />
                </div>
            }

            <div ref={containerRef} className={classes.mainContainer}>

                <div className={classes.topLeftZone}>
                    <OrientationSelect value={orientation} onChange={onOrientationChangeHandle} />
                </div>

                <div className={clsx(
                    classes.middleRightZone,
                    !viewerData.getContourDrawing() && classes.middleRightZone_hidden
                )}>
                    <DrawingTools contours={ccScene.contours} />
                </div>

                <div ref={viewerContainerRef} className={classes.leftContainer}></div>

                <SceneConfigurationDrawer
                    container={containerRef.current}
                    images={ccScene.images}
                    contours={ccScene.contours}
                    distinctVolumeTypes={distinctVolumeTypes}
                >
                    <Flex justifyContent="space-between" padding={[1, 2]}>
                        <Button
                            size="large"
                            color="default"
                            variant="text"
                            startIcon={<RefreshIcon />}
                            disabled={!actionsEnabled}
                            onClick={onResetHandle}
                        >
                            Reset
                        </Button>

                        <Button
                            size="large"
                            color="primary"
                            variant="contained"
                            startIcon={
                                saveLoading ? <CircularProgress size={16} color="inherit" /> : <SaveIcon />
                            }
                            disabled={!actionsEnabled}
                            onClick={onSaveHandle}
                        >
                            Save
                        </Button>
                    </Flex>
                </SceneConfigurationDrawer>
            </div>

        </div>
    </>);
}
