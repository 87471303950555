import { GetFileQuery } from "domain/admin/query/GetFileQuery";
import { ItkImageLoader, NIFTIWriter } from "dline-viewer/dist/IO";
import { Guid } from "domain/static/Guid";
import { ItkImage } from "dline-viewer/dist/data";
import { getFileAsync } from "./FileExtension";

export async function loadItkImage(
  queryOrId: GetFileQuery | Guid,
  onDownloadProgress?: (loaded: number, total: number) => void
) {
  const file = await getFileAsync(queryOrId, onDownloadProgress);
  return await new ItkImageLoader().loadImageBlob(file.data, file.filename);
}

export function imageToNifti(itkImage: ItkImage) {
  return NIFTIWriter.GetDataFromItkImage(itkImage);
}
