import React, { useMemo, useState } from "react";
import {
    Box,
    Button,
    createStyles, IconButton, ListItem, ListItemIcon, ListItemText, makeStyles, Theme, Tooltip
} from "@material-ui/core";
import {
    AssessmentOutlined as PresentResultsIcon,
    Assessment as PastResultsIcon,
} from "@material-ui/icons";

import { Guid } from "domain/static/Guid";
import { UserRole } from "domain/static/UserRole";
import { ContouringWorkshopUserResponse } from "domain/admin/response/ContouringWorkshopUserResponse";
import { AddContouringWorkshopUsersCommand } from "domain/admin/command/AddContouringWorkshopUsersCommand";
import { RemoveContouringWorkshopUsersCommand } from "domain/admin/command/RemoveContouringWorkshopUsersCommand";
import useGetContouringWorkshopUsers from "domain/hooks/useGetContouringWorkshopUsers";
import { SearchUserField } from "pages/Users";
import { CloseIcon, EditIcon, EmailIcon } from "App/Theme";
import { sendMessage } from "tools/Message";
import { ContactDialog } from "pages/Contact";
import { ContouringWorkshopUserRole, ContouringWorkshopUserRoleOptions } from "domain/static/ContouringWorkshopUserRole";
import { useNotificationContext } from "cocoreact";
import { enumHasFlag } from "tools/EnumExtension";
import { ContouringWorkshopUserPermission } from "domain/static/ContouringWorkshopUserPermission";
import { UpdateContouringWorkshopUserPermissionCommand } from "domain/admin/command/UpdateContouringWorkshopUserPermissionCommand";

const useStyles = makeStyles((theme: Theme) => createStyles({
    items: {
        display: "flex",
        flexDirection: "column",
        padding: 0,
        margin: 0,
        "& > *": {
            margin: theme.spacing(1, 0, 0, 0),
        }
    },
    item: {
        padding: theme.spacing(1, 2),
        border: "1px solid black",
        borderColor: theme.palette.divider,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.default
    },
}));

interface UserItemProps {
    disabled: boolean;
    id: Guid;
    user: ContouringWorkshopUserResponse;
    updateList: () => void;
}

export function ReferrerItem({ disabled, id, user, updateList }: UserItemProps) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const onRemoveHandle = async () => {
        setLoading(true);
        const message = new RemoveContouringWorkshopUsersCommand({
            id,
            userIds: [user.id],
            role: user.role
        });
        await sendMessage(message);
        updateList();
        setLoading(false);
    }

    return <ListItem className={classes.item}>
        <ListItemText
            primary={`${user.firstname} ${user.lastname}`}
            secondary={`${user.email}`}
        />
        <ListItemIcon>
            <IconButton onClick={onRemoveHandle} disabled={disabled || loading}>
                <CloseIcon />
            </IconButton>
        </ListItemIcon>
    </ListItem>
}

export function OrganizerItem({ disabled, id, user, updateList }: UserItemProps) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const onPermissionHandle = async (flag: ContouringWorkshopUserPermission) => {
        setLoading(true);
        const permission = enumHasFlag(user.permission, flag)
            ? user.permission & ~flag
            : user.permission | flag;
        const message = new UpdateContouringWorkshopUserPermissionCommand({
            id,
            userId: user.id,
            role: user.role,
            permission
        });
        await sendMessage(message);
        updateList();
        setLoading(false);
    }

    const onRemoveHandle = async () => {
        setLoading(true);
        const message = new RemoveContouringWorkshopUsersCommand({
            id,
            userIds: [user.id],
            role: user.role
        });
        await sendMessage(message);
        updateList();
        setLoading(false);
    }

    return <ListItem className={classes.item}>
        <ListItemText
            primary={`${user.firstname} ${user.lastname}`}
            secondary={`${user.email}`}
        />
        <ListItemIcon>
            <IconButton onClick={() => onPermissionHandle(ContouringWorkshopUserPermission.Edit)} disabled={disabled || loading}>
                <Tooltip title="Allow/Refuse contours edition while workshop in not started">
                    <EditIcon color={enumHasFlag(user.permission, ContouringWorkshopUserPermission.Edit) ? "action" : "disabled"} />
                </Tooltip>
            </IconButton>
            <IconButton onClick={() => onPermissionHandle(ContouringWorkshopUserPermission.PresentResults)} disabled={disabled || loading}>
                <Tooltip title="Active/inactive results consultation while workshop is in progress">
                    <PresentResultsIcon color={enumHasFlag(user.permission, ContouringWorkshopUserPermission.PresentResults) ? "action" : "disabled"} />
                </Tooltip>
            </IconButton>
            <IconButton onClick={() => onPermissionHandle(ContouringWorkshopUserPermission.PastResults)} disabled={disabled || loading}>
                <Tooltip title="Active/inactive results consultation while workshop is finish">
                    <PastResultsIcon color={enumHasFlag(user.permission, ContouringWorkshopUserPermission.PastResults) ? "action" : "disabled"} />
                </Tooltip>
            </IconButton>
            <IconButton onClick={onRemoveHandle} disabled={disabled || loading}>
                <CloseIcon />
            </IconButton>
        </ListItemIcon>
    </ListItem>
}

export interface UserManagerProps {
    id: Guid;
    role: ContouringWorkshopUserRole;
    component: React.ComponentType<UserItemProps>;
}

export function UserManager({ id, role, component: Component }: UserManagerProps) {
    const classes = useStyles();

    const [loadingUsers, users, updateUsers] = useGetContouringWorkshopUsers(id, role);
    const [contactOpen, setContactOpen] = useState(false);
    const { error } = useNotificationContext();
    const roleLabel = useMemo(
        () => ContouringWorkshopUserRoleOptions.find(x => x.value === role)?.label.toLowerCase() ?? "user",
        [role]
    );

    const contactBcc = useMemo(() => {
        return users && users.length > 0
            ? { bcc: users.map(x => `${x.firstname} ${x.lastname} <${x.email}>`).join(", ") }
            : undefined
    }, [users])

    const onAddHandle = async (userId: Guid) => {
        try {
            const message = new AddContouringWorkshopUsersCommand({ id });
            message.userIds = [userId];
            message.role = role;
            await sendMessage(message);

            updateUsers();
        }
        catch (e) {
            const errData = JSON.parse((e as any)?.response?.data ?? "{}");
            const message = errData ? errData?.message : "An error occurred !";
            error(message);
        }
    }

    return <>
        <SearchUserField
            onSelect={u => onAddHandle(u.id)}
            role={UserRole.Standard}
            placeholder={`Search a ${roleLabel} ...`}
        />

        <Box className={classes.items} component="ul">
            {users.map(x =>
                <Component key={x.id} id={id} disabled={loadingUsers} user={x} updateList={updateUsers} />
            )}
        </Box>

        {users && users.length > 0 &&
            <Box marginTop={2} display="flex" justifyContent="flex-end">
                <Button
                    variant="outlined"
                    startIcon={<EmailIcon />}
                    onClick={() => setContactOpen(true)}
                >
                    contact
                </Button>
            </Box>
        }

        <ContactDialog
            open={contactOpen}
            onClose={() => setContactOpen(false)}
            title={`Contact ${roleLabel}s`}
            initialValue={contactBcc}
            onAfterSend={() => setContactOpen(false)}
        />
    </>
}
