import { useCallback } from "react";
import { useEntityMessage } from "tools/Message";
import { Guid } from "domain/static/Guid";
import { GetContouringWorkshopUsersQuery } from "domain/admin/query/GetContouringWorkshopUsersQuery";
import { ContouringWorkshopUserResponse } from "domain/admin/response/ContouringWorkshopUserResponse";
import { ContouringWorkshopUserRole } from "domain/static/ContouringWorkshopUserRole";

export function emptyContouringWorkshopUsersResponse() {
  return [] as ContouringWorkshopUserResponse[];
}

export default function useGetContouringWorkshopUsers(
  id: Guid,
  role: ContouringWorkshopUserRole
) {
  const buildQuery = useCallback(
    () => new GetContouringWorkshopUsersQuery({ id, role }),
    [id, role]
  );
  return useEntityMessage(id, buildQuery, emptyContouringWorkshopUsersResponse);
}
