import React from "react";
import { IMessage } from "cocoreact";

import sendMessage from "./sendMessage";
import { Guid, IsEmptyGuid } from "domain/static/Guid";

export default function useEntityMessage<TResponse>(
  id: Guid,
  messageBuilder: (id: Guid) => IMessage,
  defaultValueBuilder: () => TResponse
): [boolean, TResponse, () => void] {
  const isMounted = React.useRef(true);
  const [state, setState] = React.useState({
    loading: true,
    data: defaultValueBuilder(),
    error: null as Error | null,
  });

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const _updateData = React.useCallback(async () => {
    if (IsEmptyGuid(id)) {
      setState({
        loading: false,
        data: defaultValueBuilder(),
        error: null,
      });
    } else {
      setState((s) => {
        return { ...s, loading: true };
      });
      try {
        const message = messageBuilder(id);
        const d = await sendMessage<TResponse>(message);
        if (isMounted.current) {
          setState({ loading: false, data: d, error: null });
        }
      } catch (e) {
        setState((s) => {
          return { loading: false, data: s.data, error: e as any };
        });
      }
    }
  }, [id, defaultValueBuilder, messageBuilder]);

  React.useEffect(() => {
    _updateData();
  }, [_updateData]);

  const updateData = React.useCallback(() => {
    _updateData();
  }, [_updateData]);

  if (state.error) {
    throw state.error;
  }

  return [state.loading, state.data, updateData];
}
