import React from "react";
import { useMessage } from "tools/Message";
import { SelectItemType } from "domain/static/SelectItemType";
import { IdNameResponse } from "domain/admin/response/IdNameResponse";
import { SearchSelectItemOptionsQuery } from "domain/admin/query/SearchSelectItemOptionsQuery";

export default function useListSelectItems(type :SelectItemType) {
  const query = React.useMemo(() => new SearchSelectItemOptionsQuery({type}), [
    type,
  ]);
  return useMessage<IdNameResponse[]>(query, []);
}
