import { Guid } from "domain/static/Guid";
import { SelectItemType } from "domain/static/SelectItemType";
import { IField } from "cocoreact";

//Generated code, do not modify

export class ODataSelectItemItemResponse {
    results!: SelectItemItemResponse[];
    count!: number;

    public static Fields = {
        results: { name: "results", type: "SelectItemItemResponse[]" },
        count: { name: "count", type: "number" },
    } as Record<keyof ODataSelectItemItemResponse, IField>;
}

export class SelectItemItemResponse {
    id!: Guid | null;
    name!: string;
    createdAt!: Date | null;
    updatedAt!: Date | null;
    type!: SelectItemType | null;
    isArchived!: boolean | null;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        name: { name: "name", type: "string" },
        createdAt: { name: "createdAt", type: "Date" },
        updatedAt: { name: "updatedAt", type: "Date" },
        type: { name: "type", type: "SelectItemType" },
        isArchived: { name: "isArchived", type: "boolean" },
    } as Record<keyof SelectItemItemResponse, IField>;
}
