import { useEntityMessage } from "tools/Message";
import { Guid, EmptyGuid } from "domain/static/Guid";
import { GetClinicalContourQuery } from "domain/admin/query/GetClinicalContourQuery";
import { ClinicalContourResponse } from "domain/admin/response/ClinicalContourResponse";

export function emptyClinicalContourBuilder() {
    let entity = new ClinicalContourResponse();
    entity.id = EmptyGuid();
    entity.guidelineId = EmptyGuid();
    entity.fileName = "";
    entity.fileId = EmptyGuid();
    entity.color = "#F44336";
    entity.position = 0;
    entity.isEditable = false;
    entity.isArchived = false;
    return entity;
}

export function getClinicalContourQueryBuilder(id: Guid) {
    return new GetClinicalContourQuery({ id });
  }

export default function useGetClinicalContour(id: Guid) {
    return useEntityMessage(
      id,
      getClinicalContourQueryBuilder,
      emptyClinicalContourBuilder
    );
}