import React from "react";
import { useParams } from "react-router-dom";
import { useNotificationContext } from "cocoreact";

import { Guid } from "domain/static/Guid";
import { CentralColumnLayout } from "components/Layouts";
import { Paper, PaperTitle } from "components/Page";
import { ArticleIcon } from "App/Theme";
import ArticleFormDetails from "../ArticleFormDetails";

export default function DetailsPanel() {

    const { success } = useNotificationContext();
    const params = useParams<{ id: Guid }>();

    const handleAfterSave = () => {
        success("Article saved with success");
    }

    return <CentralColumnLayout size="md">

        <Paper>
            <PaperTitle icon={<ArticleIcon />}>
                Edit article
            </PaperTitle>

            <ArticleFormDetails
                id={params.id}
                onAfterSave={handleAfterSave}
            />
        </Paper>

    </CentralColumnLayout>
}
