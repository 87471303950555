import { useEntityMessage } from "tools/Message";
import { EmptyGuid, Guid } from "domain/static/Guid";
import { GuidelineResponse } from "domain/admin/response/GuidelineResponse";
import { GetGuidelineQuery } from "domain/admin/query/GetGuidelineQuery";
import { VolumeType } from "domain/static/VolumeType";

export function emptyGuidelineBuilder() {
  let entity = new GuidelineResponse();
  entity.id = EmptyGuid();
  entity.content = "";
  entity.anatomicStructureId = EmptyGuid();
  entity.anatomicStructureName = "";
  entity.volumeType = VolumeType.None;
  entity.targetTypeId = EmptyGuid();
  entity.targetTypeName = "";
  entity.isArchived = false;

  return entity;
}

export function getGuidelineQueryBuilder(id: Guid) {
  return new GetGuidelineQuery({ id });
}

export default function useGetGuideline(id: Guid) {
  return useEntityMessage(id, getGuidelineQueryBuilder, emptyGuidelineBuilder);
}
