import { App } from "dline-viewer/dist/app";
import { AppGridEntity } from "dline-viewer/dist/app/Entity/App/AppGridEntity";
import { ReadOnlyRoiEntity } from "dline-viewer/dist/app/Entity/ReadOnlyRoi/ReadOnlyRoiEntity";
import { ReadOnlyRoiNameEntity } from "dline-viewer/dist/app/Entity/ReadOnlyRoi/ReadOnlyRoiNameEntity";
import { ReadOnlyRoiStyleEntity } from "dline-viewer/dist/app/Entity/ReadOnlyRoi/ReadOnlyRoiStyleEntity";
import { RoiEntity } from "dline-viewer/dist/app/Entity/Roi/RoiEntity";
import { RoiNameEntity } from "dline-viewer/dist/app/Entity/Roi/RoiNameEntity";
import { RoiSegmentVolumeEntity } from "dline-viewer/dist/app/Entity/Roi/RoiSegmentVolumeEntity";
import { RoiStyleEntity } from "dline-viewer/dist/app/Entity/Roi/RoiStyleEntity";
import { RoiEditableVoxelVolumeEntity } from "dline-viewer/dist/app/Entity/Roi/RoiEditableVoxelVolumeEntity";
import {
  AppCreateRoiHandler,
  AppReadOnlyRoiHandler,
} from "dline-viewer/dist/app/Handlers";
import {
  ReadOnlyRoi,
  RTStructROI,
  VoxelVolumeByBlock,
} from "dline-viewer/dist/data";
import {
  PolygonVolumeToEditableVoxelVolume,
  RTStructROIToReadOnlyRoi,
  SegmentVolumeToPolygonVolume,
  VoxelSliceFiller,
} from "dline-viewer/dist/processing";

import { OrientationType } from "domain/static/OrientationType";

export type ContourMode = "readonly" | "drawing";

export default class ContourHelper {
  private _app: App;

  private _id: string;
  private _mode: ContourMode;

  private _readonlyRoi: ReadOnlyRoi | null;
  private _readonlyEntities: {
    roi: ReadOnlyRoiEntity;
    name: ReadOnlyRoiNameEntity;
    style: ReadOnlyRoiStyleEntity;
  };

  private _drawingEntities: {
    roi: RoiEntity;
    name: RoiNameEntity;
    style: RoiStyleEntity;
    editVolume: RoiEditableVoxelVolumeEntity;
  };

  constructor(app: App) {
    this._app = app;

    this._id = "undefined";
    this._mode = "readonly";

    this._readonlyRoi = null;
    this._readonlyEntities = {} as any;

    this._drawingEntities = {} as any;
  }

  get id() {
    return this._id;
  }

  get drawingId() {
    return this._drawingEntities.roi.id;
  }

  get mode() {
    return this._mode;
  }

  async setMode(m: ContourMode) {
    if (this._mode === m) return;

    if (m === "drawing" && this._drawingEntities.roi === undefined) {
      await this._updateDrawingFromReadonly();
    }

    await this.setVisible(false); // hide old mode
    this._mode = m;
    await this.setVisible(true); // show new mode
  }

  async fromRtStruct(roi: RTStructROI, appGrid: AppGridEntity) {
    const readonly = RTStructROIToReadOnlyRoi.PolygonOne(
      roi,
      appGrid.grid,
      OrientationType.Axial
    );

    await this.fromReadonly(readonly);
  }

  async fromReadonly(roi: ReadOnlyRoi) {
    this._id = roi.name;
    this._readonlyRoi = roi;

    const handler = new AppReadOnlyRoiHandler(this._app);
    const entity = await handler.InsertReadOnlyRoiOneAsync(roi);

    this._initializeReadonlyEntities(entity.id);
  }

  computePolygonVolumeFromDrawing() {
    const { editVolume } = this._drawingEntities;
    if (!this._readonlyRoi || !editVolume) return undefined;

    const roiSegment = this._app.db.First(
      RoiSegmentVolumeEntity,
      (x) => x.idRoi === editVolume.idRoi
    );
    if (!roiSegment) return undefined;

    const length =
      roiSegment.segmentVolume.GetByteLengthForAxis(0) +
      roiSegment.segmentVolume.GetByteLengthForAxis(1) +
      roiSegment.segmentVolume.GetByteLengthForAxis(2);
    if (length === 0) return null;

    const converter = new SegmentVolumeToPolygonVolume();
    return converter.Convert(roiSegment.segmentVolume);
  }

  private _initializeReadonlyEntities(id: string) {
    const roi = this._app.db.First(ReadOnlyRoiEntity, (x) => x.id === id);

    const name = this._app.db.First(
      ReadOnlyRoiNameEntity,
      (x) => x.idReadOnlyRoi === id
    );

    const style = this._app.db.First(
      ReadOnlyRoiStyleEntity,
      (x) => x.idReadOnlyRoi === id
    );

    this._readonlyEntities = { roi, name, style };
  }

  private _initializeDrawingEntities(id: string) {
    const roi = this._app.db.First(RoiEntity, (x) => x.id === id);

    const name = this._app.db.First(RoiNameEntity, (x) => x.idRoi === id);

    const style = this._app.db.First(RoiStyleEntity, (x) => x.idRoi === id);

    const editVolume = this._app.db.First(
      RoiEditableVoxelVolumeEntity,
      (x) => x.idRoi === id
    );

    this._drawingEntities = { roi, name, style, editVolume };
  }

  private async _updateDrawingFromReadonly() {
    if (!this._readonlyRoi) return;

    await this.initializeEditableVolume();

    const { editVolume } = this._drawingEntities;

    PolygonVolumeToEditableVoxelVolume.VoxelizeTo(
      this._readonlyRoi.polygonVolume,
      OrientationType.Axial,
      editVolume.editableVoxelVolume
    );

    await this._app.derivation.UpdateAsync(editVolume);
  }

  async initializeEditableVolume() {
    if (this._drawingEntities.roi === undefined) {
      const handler = new AppCreateRoiHandler(this._app);
      const roi = await handler.CreateRoiAsync(
        this._id,
        this._readonlyEntities.style.color
      );

      this._initializeDrawingEntities(roi.id);
    }
  }

  async updateEditableVolume(editableVoxelVolume: any) {
    const { editVolume } = this._drawingEntities;

    if (
      editVolume.editableVoxelVolume.voxelVolume instanceof
        VoxelVolumeByBlock &&
      editableVoxelVolume.voxelVolume &&
      editableVoxelVolume.voxelVolume.blocks &&
      editVolume.editableVoxelVolume.voxelVolume.blocks.length ===
        editableVoxelVolume.voxelVolume.blocks.length
    ) {
      const { sliceFlags, tmpSetVoxelWithAxis } =
        editVolume.editableVoxelVolume;
      for (let i = 0; i < 3; i++) {
        sliceFlags[i] = editableVoxelVolume.sliceFlags[i];
        tmpSetVoxelWithAxis[i] = editableVoxelVolume.tmpSetVoxelWithAxis[i];
      }

      const { blocks } = editVolume.editableVoxelVolume.voxelVolume;
      for (let iBlock = 0; iBlock < blocks.length; iBlock++) {
        const block = editableVoxelVolume.voxelVolume.blocks[iBlock];
        if (block.data !== null) {
          blocks[iBlock].all0 = block.all0;
          blocks[iBlock].all1 = block.all1;
          blocks[iBlock].data = [...block.data] as any;
        }
      }

      await this._app.derivation.UpdateAsync(editVolume);
    }
  }

  getReadonlyRoi() {
    return this._readonlyRoi;
  }

  getEditableVoxelVolume() {
    return this._drawingEntities.editVolume;
  }

  async setColor(color: number[]) {
    const { style: readonlyStyle } = this._readonlyEntities;
    readonlyStyle.color = color;
    await this._app.derivation.UpdateAsync(readonlyStyle);

    const { style: drawingStyle } = this._drawingEntities;
    if (drawingStyle) {
      drawingStyle.color = color;
      await this._app.derivation.UpdateAsync(drawingStyle);
    }
  }

  getColor(): number[] {
    const { style } = this._readonlyEntities;
    return style.color;
  }

  async setHighlight(enabled: boolean) {
    const { style } =
      this._mode === "readonly"
        ? this._readonlyEntities
        : this._drawingEntities;

    style.lineWidth = enabled ? 3 : 1;
    await this._app.derivation.UpdateAsync(style);
  }

  async setVisible(visibled: boolean) {
    const { style } =
      this._mode === "readonly"
        ? this._readonlyEntities
        : this._drawingEntities;

    style.visible = visibled;
    await this._app.derivation.UpdateAsync(style);
  }

  async fillSlice() {
    if (this._mode !== "drawing") {
      throw new Error(
        "you must set contour mode to 'drawing' to execute fillSlice method !"
      );
    }

    const { editVolume } = this._drawingEntities;

    this._app.undoRedoDraw.StartVolume(editVolume.editableVoxelVolume);

    const voxelSliceFiller = new VoxelSliceFiller();
    const nbSliceFilled = voxelSliceFiller.Fill(
      editVolume.editableVoxelVolume,
      OrientationType.Axial
    );

    this._app.undoRedoDraw.StopVolume(editVolume.editableVoxelVolume);

    await this._app.derivation.UpdateAsync(editVolume);

    return nbSliceFilled;
  }

  async clean() {
    const { editVolume } = this._drawingEntities;
    if (!editVolume) return;

    this._app.undoRedoDraw.StartVolume(editVolume.editableVoxelVolume);

    editVolume.editableVoxelVolume.Clear();

    const roiSegment = this._app.db.First(
      RoiSegmentVolumeEntity,
      (x) => x.idRoi === editVolume.idRoi
    );
    if (roiSegment) {
      roiSegment.segmentVolume.Clear();
    }

    this._app.undoRedoDraw.StopVolume(editVolume.editableVoxelVolume);

    await this._app.derivation.UpdateAsync(editVolume);
  }
}
