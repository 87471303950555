import React from "react";
import { useHistory } from "react-router-dom";
import {
    Button, ButtonGroup, ClickAwayListener, Grow,
    ListItemIcon,
    ListItemText,
    MenuItem, MenuList, Paper, Popper, withStyles
} from "@material-ui/core";
import FileSaver from 'file-saver';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { Guid } from "domain/static/Guid";
import { ClinicalImageItemResponse } from "domain/admin/response/ClinicalImageItemResponse";
import { SetArchivingStateClinicalImageCommand } from "domain/admin/command/SetArchivingStateClinicalImageCommand";
import { fullPath, RouteName } from "tools/Route";
import { ArchiveIcon, DownloadIcon, EditIcon } from "App/Theme";
import { sendMessage } from "tools/Message";
import { getFileAsync } from "tools/FileExtension";

const MuiListItemIcon = withStyles({
    root: {
        minWidth: 36,
    },
})(ListItemIcon);

export interface ClinicalImageTableItemMenuProps {
    clinicalDataId: Guid;
    image: ClinicalImageItemResponse;
    editRouteName: RouteName;
    updateTable: () => void;

}

export default function ClinicalImageTableItemMenu({
    clinicalDataId, image, editRouteName, updateTable }: ClinicalImageTableItemMenuProps
) {
    const history = useHistory();

    const editPath = React.useMemo(
        () => fullPath(editRouteName, { params: { id: clinicalDataId, imageId: image.id } }),
        [clinicalDataId, editRouteName, image.id]
    );
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);


    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    const handleActionEdit = () => history.push(editPath)

    const handleActionArchive = async () => {
        const command = new SetArchivingStateClinicalImageCommand({
            id: image.id,
            isArchived: !image.isArchived
        });
        await sendMessage(command);
        updateTable();
        setOpen(false);
    }

    const handleActionDownload = async () => {
        const file = await getFileAsync(image.fileId);
        FileSaver.saveAs(file.data, image.fileName);
        setOpen(false);
    }

    return <>
        <ButtonGroup
            color="default"
            variant="outlined"
            size="small"
            ref={anchorRef} aria-label="split button"
        >
            <Button
                color="secondary"
                startIcon={<EditIcon />}
                onClick={handleActionEdit}
            >
                Edit
            </Button>
            <Button
                size="small"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
            >
                <ArrowDropDownIcon />
            </Button>
        </ButtonGroup>
        <Popper
            open={open} anchorEl={anchorRef.current}
            role={undefined} transition disablePortal
            style={{ zIndex: 2000 }}
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu">
                                <MenuItem onClick={handleActionArchive}>
                                    <MuiListItemIcon>
                                        <ArchiveIcon fontSize="small" />
                                    </MuiListItemIcon>
                                    <ListItemText primary={image.isArchived ? "unarchive" : "archive"} />
                                </MenuItem>
                                <MenuItem onClick={handleActionDownload}>
                                    <MuiListItemIcon>
                                        <DownloadIcon fontSize="small" />
                                    </MuiListItemIcon>
                                    <ListItemText primary="download resource" />
                                </MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </>
}