import { useEntityMessage } from "tools/Message";
import { Guid, EmptyGuid } from "domain/static/Guid";
import { GetClinicalImageQuery } from "domain/admin/query/GetClinicalImageQuery";
import { ClinicalImageResponse } from "domain/admin/response/ClinicalImageResponse";
import { ImagingDefault } from "domain/static/ImagingDefault";

export function emptyClinicalImageBuilder() {
    let entity = new ClinicalImageResponse();
    entity.id = EmptyGuid();
    entity.name = "";
    entity.imagingModalityId = EmptyGuid();
    entity.imagingModalityName = "";
    entity.fileId = EmptyGuid();
    entity.default = ImagingDefault.None;
    entity.windowing = undefined as any;
    entity.range = undefined as any;
    entity.spacing = undefined as any;
    entity.origin = undefined as any;
    entity.size = undefined as any;
    entity.lut = undefined as any;
    entity.isArchived = false;
    return entity;
}

export function getClinicalImageQueryBuilder(id: Guid) {
    return new GetClinicalImageQuery({ id });
  }

export default function useGetClinicalImage(id: Guid) {
    return useEntityMessage(
      id,
      getClinicalImageQueryBuilder,
      emptyClinicalImageBuilder
    );
}