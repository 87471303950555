import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

export default function useQueryString<T = any>() {
  const location = useLocation();
  const qs = useMemo(() => queryString.parse(location.search, {
    parseNumbers: true,
  }), [location.search]);

  return qs as unknown as T | undefined;
}