import { Guid } from "domain/static/Guid";
import { ImagingDefault } from "domain/static/ImagingDefault";
import { IField } from "cocoreact";

//Generated code, do not modify

export class ClinicalImageItemResponse {
    id!: Guid;
    name!: string;
    imagingModalityName!: string;
    default!: ImagingDefault;
    createdAt!: Date;
    fileId!: Guid;
    fileName!: string;
    isArchived!: boolean;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        name: { name: "name", type: "string" },
        imagingModalityName: { name: "imagingModalityName", type: "string" },
        default: { name: "default", type: "ImagingDefault" },
        createdAt: { name: "createdAt", type: "Date" },
        fileId: { name: "fileId", type: "Guid" },
        fileName: { name: "fileName", type: "string" },
        isArchived: { name: "isArchived", type: "boolean" },
    } as Record<keyof ClinicalImageItemResponse, IField>;
}
