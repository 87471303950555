import React, { useMemo, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";

import { CentralColumnLayout, PageLayout } from "components/Layouts";
import { Guid, EmptyGuid, IsEmptyGuid, IsGuid } from "domain/static/Guid";
import { fullPath } from "tools/Route";
import SelectItemForm from "./SelectItemForm";
import { Paper, PaperTitle, Title } from "components/Page";
import { useNotificationContext } from "cocoreact";
import useQueryString from "tools/useQueryString";
import { SelectItemResponse } from "domain/admin/response/SelectItemResponse";
import { SelectItemIcon } from "App/Theme";

export default function EditSelectItemPage() {

    const listUrl = useMemo(
        () => fullPath("ListSelectItems"),
        []
    );

    const params = useParams<{ id: Guid }>();
    const id = useMemo(
        () => IsGuid(params.id) ? params.id : EmptyGuid(),
        [params.id]
    );
    const initialValue = useQueryString<SelectItemResponse>();

    const { success } = useNotificationContext();

    const history = useHistory();
    const handleAfterSave = useCallback(() => {
        history.push(listUrl);
        success("Select item saved with success");
    }, [history, listUrl, success]);

    const title = (IsEmptyGuid(id) ? "Create" : "Edit") + " a select item";

    return <>
        <Title>{title}</Title>

        <PageLayout title="Select items" menuSelected="ListSelectItems">
            <CentralColumnLayout size="sm">

                <Paper>
                    <PaperTitle icon={<SelectItemIcon />}>{title}</PaperTitle>

                    <SelectItemForm
                        id={id}
                        initialValue={initialValue}
                        onAfterSave={handleAfterSave}
                    />
                </Paper>

            </CentralColumnLayout>
        </PageLayout>
    </>
}
