import { Guid } from "domain/static/Guid";
import { VolumeType } from "domain/static/VolumeType";
import { IField } from "cocoreact";

//Generated code, do not modify

export class ClinicalContourResponse {
    id!: Guid;
    fileId!: Guid | null;
    fileName!: string;
    guidelineId!: Guid | null;
    articleId!: Guid | null;
    articleTitle!: string;
    articleUrl!: string;
    anatomicStructureName!: string;
    targetTypeName!: string;
    volumeType!: VolumeType;
    organizationName!: string;
    name!: string;
    color!: string;
    position!: number;
    isEditable!: boolean;
    isArchived!: boolean;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        fileId: { name: "fileId", type: "Guid" },
        fileName: { name: "fileName", type: "string" },
        guidelineId: { name: "guidelineId", type: "Guid" },
        articleId: { name: "articleId", type: "Guid" },
        articleTitle: { name: "articleTitle", type: "string" },
        articleUrl: { name: "articleUrl", type: "string" },
        anatomicStructureName: { name: "anatomicStructureName", type: "string" },
        targetTypeName: { name: "targetTypeName", type: "string" },
        volumeType: { name: "volumeType", type: "VolumeType" },
        organizationName: { name: "organizationName", type: "string" },
        name: { name: "name", type: "string" },
        color: { name: "color", type: "string" },
        position: { name: "position", type: "number" },
        isEditable: { name: "isEditable", type: "boolean" },
        isArchived: { name: "isArchived", type: "boolean" },
    } as Record<keyof ClinicalContourResponse, IField>;
}
