import { Guid } from "domain/static/Guid";
import { IField } from "cocoreact";

//Generated code, do not modify

export class ODataArticleItemResponse {
    results!: ArticleItemResponse[];
    count!: number;

    public static Fields = {
        results: { name: "results", type: "ArticleItemResponse[]" },
        count: { name: "count", type: "number" },
    } as Record<keyof ODataArticleItemResponse, IField>;
}

export class ArticleItemResponse {
    id!: Guid;
    title!: string;
    anatomicZoneName!: string;
    createdAt!: Date | null;
    updatedAt!: Date | null;
    isArchived!: boolean | null;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        title: { name: "title", type: "string" },
        anatomicZoneName: { name: "anatomicZoneName", type: "string" },
        createdAt: { name: "createdAt", type: "Date" },
        updatedAt: { name: "updatedAt", type: "Date" },
        isArchived: { name: "isArchived", type: "boolean" },
    } as Record<keyof ArticleItemResponse, IField>;
}
