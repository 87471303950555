import React from "react";

import Table, { TableFieldsBuilder } from "components/Table";
import { Guid } from "domain/static/Guid";
import { ImagingDefaultOptions } from "domain/static/ImagingDefault";
import useListClinicalImages from "domain/hooks/useListClinicalImages";
import { ClinicalImageItemResponse } from "domain/admin/response/ClinicalImageItemResponse";
import { RouteName } from "tools/Route";
import ClinicalImageTableItemMenu from "./ClinicalImageTableItemMenu";

export interface ClinicalImageTableProps {
    id: Guid;
    editRouteName: RouteName;
    editable?: boolean;
}

export default function ClinicalImageTable({ id, editRouteName, editable }: ClinicalImageTableProps) {

    const [, entities, updateEntities] = useListClinicalImages(id, true);

    const fields = React.useMemo(
        () => buildFields(id, editRouteName, editable ?? false, updateEntities),
        [id, editRouteName, editable, updateEntities]
    );

    return (
        <Table<ClinicalImageItemResponse>
            fields={fields}
            data={entities}
        />
    );
}

function buildFields(
    id: Guid,
    editRouteName: RouteName,
    editable: boolean,
    updateTable: () => void
) {
    const builder = new TableFieldsBuilder<ClinicalImageItemResponse>();
    const fields = ClinicalImageItemResponse.Fields;

    builder
        .initialize(fields)
        .hidden(fields.id)
        .hidden(fields.createdAt)
        .hidden(fields.fileId)
        .hidden(fields.fileName)
        .set(fields.name, {
            position: 0,
        })
        .set(fields.imagingModalityName, {
            label: "Modality",
            position: 1
        })
        .set(fields.default, {
            position: 2,
            label: "default",
            options: ImagingDefaultOptions,
        })
        .set(fields.createdAt, {
            label: "Creation Date",
            position: 2,
            format: "Do MMM YYYY - hh:mm",
        })
        .set(fields.isArchived, {
            position: 3
        });

    if (editable) {
        builder.custom({
            padding: "checkbox",
            render: ({ data }) => <ClinicalImageTableItemMenu
                clinicalDataId={id}
                image={data}
                editRouteName={editRouteName}
                updateTable={updateTable}
            />,
        })
    }

    return builder.build();
}
