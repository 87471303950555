import { Guid } from "domain/static/Guid";
import { ImagingDefault } from "domain/static/ImagingDefault";
import { Range } from "domain/static/Range";
import { Coordinate } from "domain/static/Coordinate";
import { IField } from "cocoreact";

//Generated code, do not modify

export class ClinicalImageResponse {
    id!: Guid;
    name!: string;
    imagingModalityId!: Guid;
    imagingModalityName!: string;
    fileId!: Guid;
    fileName!: string;
    default!: ImagingDefault;
    windowing!: Range;
    range!: Range;
    spacing!: Coordinate;
    origin!: Coordinate;
    size!: Coordinate;
    lut!: string;
    isArchived!: boolean;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        name: { name: "name", type: "string" },
        imagingModalityId: { name: "imagingModalityId", type: "Guid" },
        imagingModalityName: { name: "imagingModalityName", type: "string" },
        fileId: { name: "fileId", type: "Guid" },
        fileName: { name: "fileName", type: "string" },
        default: { name: "default", type: "ImagingDefault" },
        windowing: { name: "windowing", type: "Range" },
        range: { name: "range", type: "Range" },
        spacing: { name: "spacing", type: "Coordinate" },
        origin: { name: "origin", type: "Coordinate" },
        size: { name: "size", type: "Coordinate" },
        lut: { name: "lut", type: "string" },
        isArchived: { name: "isArchived", type: "boolean" },
    } as Record<keyof ClinicalImageResponse, IField>;
}
