import { Guid } from "domain/static/Guid";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class UpdateClinicalDataArticlesCommand implements IMessage {
    id!: Guid;
    articleIds!: Guid[];

    public constructor(init?: Partial<UpdateClinicalDataArticlesCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "PUT" as RequestMethod;

    getPath = () => `/admin/clinical-data/${this.id.toString()}/articles`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            articleIds: this.articleIds,
        };
    }
    
    needAuthentication = () => true;
}
