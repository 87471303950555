/* eslint-disable no-unused-vars */
import React, { useCallback } from "react";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { TextField, Theme, makeStyles, createStyles, Checkbox, CircularProgress } from "@material-ui/core";
import { Autocomplete, FilterOptionsState } from "@material-ui/lab";
import { IFormWidgetPropsBase } from "cocoreact";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		groupLabel: {
			fontSize: theme.typography.caption.fontSize,
			textTransform: "uppercase",
			fontStyle: "italic",
			fontWeight: "bold"
		},
		groupOption: {
			paddingLeft: theme.spacing(1) * 3
		}
	} as AutoCompleteStyles)
);

export interface AutoCompleteStyles {
	groupLabel: any;
	groupOption: any;
}

export interface AutoCompleteOption {
	label: any;
	value: any;
}

export interface AutoCompleteFormWidgetProps extends IFormWidgetPropsBase<any> {
	options: AutoCompleteOption[];
	multiple?: boolean;
	noOptionsText?: string;
	groupBy?: (option: AutoCompleteOption) => string;
	loading?: boolean;
	getOptionDisabled?: (option: AutoCompleteOption) => boolean;
	filterOptions?: (options: AutoCompleteOption[], state: FilterOptionsState<AutoCompleteOption>) => AutoCompleteOption[];
	getSelectedOptions?: (options: AutoCompleteOption[], value: any) => AutoCompleteOption[];
	buildValue?: (selectedOptions: AutoCompleteOption[]) => any;
}

function AutoCompleteFormWidgetSingle(
	props: AutoCompleteFormWidgetProps
) {
	const classes = useStyles() as AutoCompleteStyles;

	let value =
		props.options && props.options.find((x) => x.value === props.value);
	if (value === undefined && props.options && props.options.length > 0) {
		value = props.options[0];
	}

	const handleChange = useCallback(
		(_e: any, v: AutoCompleteOption | null) => {
			if (v !== null && props.onChange) {
				props.onChange && props.onChange(props.name, v.value);
			}
		},
		[props]
	);

	return (
		<Autocomplete<AutoCompleteOption>
			options={props.options}
			getOptionLabel={(option) => option.label}
			onChange={handleChange}
			value={value}
			noOptionsText={props.noOptionsText}
			groupBy={props.groupBy}
			getOptionSelected={(option, value) => option.value === value.value}
			filterOptions={props.filterOptions}
			classes={{
				groupLabel: classes.groupLabel,
				option: props.groupBy ? classes.groupOption : undefined
			}}
			getOptionDisabled={props.getOptionDisabled}
			className={props.className}
			style={props.style}
			autoComplete={props.autoComplete !== "off"}
			disabled={props.disabled || props.loading}
			fullWidth={props.fullWidth}
			renderInput={(params) => (
				<TextField
					required={props.required}
					margin={props.margin}
					autoComplete={props.autoComplete}
					autoFocus={props.autoFocus}
					placeholder={props.placeholder}
					color={props.color as any}
					{...params}
					inputProps={{
						...params.inputProps,
						autoComplete: "autocomplete-textfield"
					}}
					InputProps={{
						...params.InputProps,
						endAdornment: (<React.Fragment>
							{props.loading ? <CircularProgress color="inherit" size={20} /> : null}
							{params.InputProps.endAdornment}
						</React.Fragment>)
					}}
					label={props.label}
					aria-label={props.label}
					error={props.error !== undefined}
					helperText={props.error}
				/>
			)}
		/>
	);
}

function AutoCompleteFormWidgetMultiple(
	props: AutoCompleteFormWidgetProps
) {
	const classes = useStyles() as AutoCompleteStyles;

	let value = [] as AutoCompleteOption[];
	if (props.options && props.value) {
		if (props.getSelectedOptions) {
			value = props.getSelectedOptions(props.options, props.value);
		}
		else {
			value = props.options.filter(x => props.value.includes(x.value));
		}
	}

	const handleChange = useCallback(
		(_e: any, v: AutoCompleteOption[]) => {
			const newValue = props.buildValue ? props.buildValue(v) : v.map(x => x.value);
			props.onChange && props.onChange(props.name, newValue);
		},
		[props]
	);

	return (
		<Autocomplete
			multiple={true}
			disableCloseOnSelect={true}
			options={props.options}
			getOptionLabel={(option) => option.label}
			onChange={handleChange}
			value={value}
			noOptionsText={props.noOptionsText}
			groupBy={props.groupBy}
			getOptionSelected={(option, value) => option.value === value.value}
			filterOptions={props.filterOptions}
			classes={{
				groupLabel: classes.groupLabel,
				option: props.groupBy ? classes.groupOption : undefined
			}}
			getOptionDisabled={props.getOptionDisabled}
			className={props.className}
			style={props.style}
			autoComplete={props.autoComplete !== "off"}
			disabled={props.disabled || props.loading}
			fullWidth={props.fullWidth}
			renderOption={(option, { selected }) => (
				<>
					{option.value &&
						<Checkbox
							icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
							checkedIcon={<CheckBoxIcon fontSize="small" />}
							style={{ marginRight: 8 }}
							checked={selected}
						/>
					}
					{option.label}
				</>
			)}
			renderInput={(params) => (
				<TextField
					required={props.required}
					margin={props.margin}
					autoComplete={props.autoComplete}
					autoFocus={props.autoFocus}
					placeholder={props.placeholder}
					color={props.color as any}
					{...params}
					inputProps={{
						...params.inputProps,
						autoComplete: "autocomplete-textfield"
					}}
					InputProps={{
						...params.InputProps,
						endAdornment: (<React.Fragment>
							{props.loading ? <CircularProgress color="inherit" size={20} /> : null}
							{params.InputProps.endAdornment}
						</React.Fragment>)
					}}
					label={props.label}
					aria-label={props.label}
					error={props.error !== undefined}
					helperText={props.error}
				/>
			)}
		/>
	);
}

export default function AutoCompleteFormWidget(
	props: AutoCompleteFormWidgetProps
) {
	if (props.multiple) {
		return <AutoCompleteFormWidgetMultiple {...props} />
	}

	return <AutoCompleteFormWidgetSingle {...props} />
}
