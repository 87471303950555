import React from "react";

import { EmptyGuid, Guid, IsEmptyGuid, IsValidGuid } from "domain/static/Guid";
import { ClinicalContourResponse } from "domain/admin/response/ClinicalContourResponse";
import { GuidelineOptionResponse } from "domain/admin/response/GuidelineOptionResponse";
import { CreateClinicalContourCommand } from "domain/admin/command/CreateClinicalContourCommand";
import { UpdateClinicalContourCommand } from "domain/admin/command/UpdateClinicalContourCommand";
import { SetArchivingStateClinicalContourCommand } from "domain/admin/command/SetArchivingStateClinicalContourCommand";
import { FormFieldsBuilder, Form } from "components/Form";
import useGetClinicalContour from "domain/hooks/useGetClinicalContour";
import { sendMessage } from "tools/Message";
import { LoadingWrapper } from "cocoreact";
import { ArchiveButton, SubmitButton } from "components/Buttons";
import { Flex, Spacer } from "components/Page";
import { FileParentType } from "domain/static/FileParentType";

export interface ClinicalContourFormProps {
    clinicalDataId: Guid;
    contourId: Guid;
    onAfterSave?: () => void;
}

export default function ClinicalContourForm({
    clinicalDataId, contourId, onAfterSave
}: ClinicalContourFormProps) {
    const [loadingEntity, entity, updateEntity] = useGetClinicalContour(contourId);
    (entity as any).linkArticleToClinicalCase = true;

    const fields = React.useMemo(
        () => buildFields(loadingEntity, entity),
        [loadingEntity, entity]
    );

    const buildMessage = React.useCallback((data: ClinicalContourResponse) => {
        return IsEmptyGuid(contourId)
            ? new CreateClinicalContourCommand({ ...data, clinicalDataId })
            : new UpdateClinicalContourCommand({ ...data, id: contourId });
    }, [clinicalDataId, contourId]);

    const handleArchive = React.useCallback(async () => {
        const command = new SetArchivingStateClinicalContourCommand({
            id: contourId,
            isArchived: !entity.isArchived
        });
        await sendMessage(command);
        updateEntity();
    }, [entity.isArchived, contourId, updateEntity]);

    return (
        <LoadingWrapper loading={loadingEntity}>
            <Form<ClinicalContourResponse>
                initial={entity}
                fields={fields}
                buildMessage={buildMessage}
                onSuccess={onAfterSave}
            >
                {
                    ({ loading, data }) => (
                        <Flex marginTop={2}>
                            {!IsEmptyGuid(data.id) &&
                                <ArchiveButton
                                    isArchived={data.isArchived}
                                    disabled={loadingEntity || loading}
                                    onClick={handleArchive}
                                />
                            }
                            <Spacer />
                            <SubmitButton loading={loadingEntity || loading} />
                        </Flex>
                    )
                }
            </Form>
        </LoadingWrapper>
    );
}

function buildFields(
    loadingEntity: boolean,
    entity: ClinicalContourResponse
) {
    const builder = new FormFieldsBuilder<ClinicalContourResponse>();
    const fields = ClinicalContourResponse.Fields;

    const isCreating = IsEmptyGuid(entity.id);

    let guidelineDefault = undefined as GuidelineOptionResponse | undefined;
    if (!loadingEntity && IsValidGuid(entity.guidelineId)) {
        guidelineDefault = {
            id: entity.guidelineId as string,
            articleId: entity.articleId ?? EmptyGuid(),
            articleUrl: entity.articleUrl,
            articleTitle: entity.articleTitle,
            anatomicStructureName: entity.anatomicStructureName,
            volumeType: entity.volumeType,
            targetTypeName: entity.targetTypeName,
            organizationName: entity.organizationName,
        };
    }

    builder
        .initialize(fields)
        .hidden(fields.id)
        .hidden(fields.isArchived)
        .hidden(fields.fileName)
        .hidden(fields.anatomicStructureName)
        .hidden(fields.volumeType)
        .hidden(fields.targetTypeName)
        .hidden(fields.organizationName)
        .hidden(fields.articleId)
        .hidden(fields.articleTitle)
        .hidden(fields.articleUrl)
        .set(fields.name, {
            position: 0,
        })
        .set(fields.fileId, {
            position: 1,
            label: "contour data file",
            type: "file",
            parentId: isCreating ? undefined : entity.id,
            parentType: isCreating ? undefined : FileParentType.ClinicalContour,
            fileName: entity.name + "_ReadOnlyRoi.json",
        })
        .set(fields.guidelineId, {
            position: 2,
            required: false,
            type: "SearchGuideline",
            label: "guideline's article",
            placeholder: "Find a guideline ...",
            noOptionsText: "No guideline found",
            defaultValue: guidelineDefault,
        })
        .set(fields.color, {
            position: 3,
            type: "color",
            label: "color",
        })
        .set(fields.position, {
            position: 4,
        })
        .set(fields.isEditable, {
            position: 5,
        })
        .custom({
            position: 6,
            name: "linkArticleToClinicalCase",
            type: "boolean",
            label: "Link article to ClinicalCase",
        })
        ;

    return builder.build();
}
