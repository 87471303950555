import React from "react";
import { ODataFilter, ODataSelect, ODataOrderBy } from "cocoreact";
import { useODataMessage } from "tools/Message";
import { SearchClinicalCasesQuery } from "domain/admin/query/SearchClinicalCasesQuery";
import { ClinicalCaseItemResponse } from "domain/admin/response/ODataClinicalCaseItemResponse";

export function buildSearchClinicalCasesQuery() {
  const query = new SearchClinicalCasesQuery();
  query.top = 1000;
  query.skip = 0;
  query.filter = new ODataFilter();
  query.select = new ODataSelect();
  query.orderBy = new ODataOrderBy();
  query.orderBy.set(ClinicalCaseItemResponse.Fields.name.name);
  return query;
}

export default function useSearchClinicalCases(query?: SearchClinicalCasesQuery) {
  const _query = React.useMemo(() => query ?? buildSearchClinicalCasesQuery(), [
    query,
  ]);
  return useODataMessage<ClinicalCaseItemResponse>(_query);
}
