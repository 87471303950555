import React from "react";

import { Guid } from "domain/static/Guid";
import Table, { TableFieldsBuilder } from "components/Table";
import { RouteName } from "tools/Route";
import useListClinicalContours from "domain/hooks/useListClinicalContours";
import { CallbackButton } from "components/Buttons";
import { ClinicalContourItemResponse } from "domain/admin/response/ClinicalContourItemResponse";
import { Typography } from "@material-ui/core";
import { guidelineInfoLabel } from "tools/StringExtension";
import ClinicalContourTableItemMenu from "./ClinicalContourTableItemMenu";

export interface ClinicalContourTableProps {
    id: Guid;
    editRouteName: RouteName;
    updateRef?: React.RefObject<HTMLButtonElement>;
}

export default function ClinicalContourTable({ id, editRouteName, updateRef }: ClinicalContourTableProps) {

    const [, entities, updateEntities] = useListClinicalContours(id, true);

    const fields = React.useMemo(
        () => buildFields(id, editRouteName, updateEntities),
        [id, editRouteName, updateEntities]
    );

    return (
        <>
            <CallbackButton callback={updateEntities} ref={updateRef} />

            <Table<ClinicalContourItemResponse>
                fields={fields}
                data={entities}
            />
        </>
    );
}

function buildFields(
    id: Guid,
    editRouteName: RouteName,
    updateTable: () => void
) {
    const builder = new TableFieldsBuilder<ClinicalContourItemResponse>();
    const fields = ClinicalContourItemResponse.Fields;

    builder
        .initialize(fields)
        .hidden(fields.id)
        .hidden(fields.guidelineId)
        .hidden(fields.articleId)
        .hidden(fields.articleTitle)
        .hidden(fields.fileId)
        .hidden(fields.fileName)
        .hidden(fields.createdAt)
        .hidden(fields.anatomicStructureName)
        .hidden(fields.volumeType)
        .hidden(fields.targetTypeName)
        .hidden(fields.organizationName)
        .set(fields.name, {
            position: 0,
        })
        .set(fields.color, {
            position: 1,
            type: "color",
        })
        .custom({
            position: 2,
            label: "guideline",
            render: ({ data }) => {
                if (data.anatomicStructureName) {
                    return <Typography>
                        {guidelineInfoLabel(
                            data.anatomicStructureName,
                            data.volumeType,
                            data.targetTypeName,
                            data.organizationName
                        )}
                    </Typography>
                }
                return <Typography variant="caption" style={{ fontStyle: "italic" }}>
                    undefined
                </Typography>
            }
        })
        ;

    builder.custom({
        padding: "checkbox",
        render: ({ data }) => <ClinicalContourTableItemMenu
            clinicalDataId={id}
            contour={data}
            editRouteName={editRouteName}
            updateTable={updateTable}
        />,
    })

    return builder.build();
}
