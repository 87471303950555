import { Guid } from "domain/static/Guid";
import { ClinicalCaseState } from "domain/static/ClinicalCaseState";
import { IField } from "cocoreact";

//Generated code, do not modify

export class ODataClinicalCaseItemResponse {
    results!: ClinicalCaseItemResponse[];
    count!: number;

    public static Fields = {
        results: { name: "results", type: "ClinicalCaseItemResponse[]" },
        count: { name: "count", type: "number" },
    } as Record<keyof ODataClinicalCaseItemResponse, IField>;
}

export class ClinicalCaseItemResponse {
    id!: Guid | null;
    name!: string;
    summary!: string;
    anatomicZoneName!: string;
    state!: ClinicalCaseState;
    createdAt!: Date | null;
    updatedAt!: Date | null;
    submittedAt!: Date | null;
    isArchived!: boolean;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        name: { name: "name", type: "string" },
        summary: { name: "summary", type: "string" },
        anatomicZoneName: { name: "anatomicZoneName", type: "string" },
        state: { name: "state", type: "ClinicalCaseState" },
        createdAt: { name: "createdAt", type: "Date" },
        updatedAt: { name: "updatedAt", type: "Date" },
        submittedAt: { name: "submittedAt", type: "Date" },
        isArchived: { name: "isArchived", type: "boolean" },
    } as Record<keyof ClinicalCaseItemResponse, IField>;
}
