import React from "react";

import { EmailIcon } from "App/Theme";
import { CentralColumnLayout, PageLayout } from "components/Layouts";
import { Paper, PaperTitle, Title } from "components/Page";
import ContactForm from "./ContactForm";

export default function ContactPage() {

    return <>
        <Title>Contact</Title>

        <PageLayout title="Contact" menuSelected="Contact">
            <CentralColumnLayout size="md">

                <Paper>
                    <PaperTitle icon={<EmailIcon />}>
                        Send an email
                    </PaperTitle>

                    <ContactForm />
                </Paper>

            </CentralColumnLayout>
        </PageLayout>
    </>
}
