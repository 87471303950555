import React from "react";
import { ODataFilter, ODataSelect, ODataOrderBy } from "cocoreact";
import { useODataMessage } from "tools/Message";
import { SearchSelectItemsQuery } from "domain/admin/query/SearchSelectItemsQuery";
import { SelectItemItemResponse } from "domain/admin/response/ODataSelectItemItemResponse";

export function buildSearchSelectItemsQuery() {
  const query = new SearchSelectItemsQuery();
  query.top = 1000;
  query.skip = 0;
  query.filter = new ODataFilter();
  query.select = new ODataSelect();
  query.orderBy = new ODataOrderBy();
  query.orderBy.set(SelectItemItemResponse.Fields.name.name);
  return query;
}

export default function useSearchSelectItems(query?: SearchSelectItemsQuery) {
  const _query = React.useMemo(() => query ?? buildSearchSelectItemsQuery(), [
    query,
  ]);
  return useODataMessage(_query);
}
