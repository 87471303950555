import React from "react";
import { ITableWidgetPropsBase, SelectOption } from "cocoreact";
import { enumValueToString } from "tools/EnumExtension";
import { Typography } from "@material-ui/core";

export interface EnumOptionsTableWidgetProps extends ITableWidgetPropsBase<any> {
    options: SelectOption[];
}

export default function EnumOptionsTableWidget({
    value, options
}: EnumOptionsTableWidgetProps) {
    if (!options) {
        throw new Error("you must provide 'options' to field to use this component")
    }
    return <Typography>
        {enumValueToString(value, options)}
    </Typography>;
}