import { useEntityMessage } from "tools/Message";
import { Guid } from "domain/static/Guid";
import { GetClinicalDataGridQuery } from "domain/admin/query/GetClinicalDataGridQuery";
import { ClinicalDataGridResponse } from "domain/admin/response/ClinicalDataGridResponse";

export function emptyClinicalDataGridBuilder() {
  const entity = new ClinicalDataGridResponse();
  entity.spacing = undefined as any;
  entity.origin = undefined as any;
  entity.size = undefined as any;
  return entity;
}

export function GetClinicalDataGridQueryBuilder(id: Guid) {
  return new GetClinicalDataGridQuery({ id });
}

export default function useGetClinicalDataGrid(id: Guid) {
  return useEntityMessage(
    id,
    GetClinicalDataGridQueryBuilder,
    emptyClinicalDataGridBuilder
  );
}
