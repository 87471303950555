//Generated code, do not modify

export class Coordinate {
  x: number;
  y: number;
  z: number;

  constructor(init?: Coordinate | number[]) {
    this.x = 0.0;
    this.y = 0.0;
    this.z = 0.0;

    if (init) {
      if (Array.isArray(init)) {
        this.x = init[0];
        this.y = init[1];
        this.z = init[2];
      } else if (init) {
        this.x = init.x;
        this.y = init.y;
        this.z = init.z;
      }
    }
  }
}
