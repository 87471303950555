import React, { useCallback, useState } from "react";
import {
    Box, Button, CircularProgress,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    TextField, Typography, TypographyProps, useTheme
} from "@material-ui/core";
import { Close as RejectIcon } from "@material-ui/icons";
import { useNotificationContext } from "cocoreact";

import { formErrorService } from "services";
import { sendMessage } from "tools/Message";
import { PublishIcon, UnpublishIcon, CloseIcon } from "App/Theme";
import { SetStateClinicalCaseCommand } from "domain/admin/command/SetStateClinicalCaseCommand";
import { ClinicalCaseResponse } from "domain/admin/response/ClinicalCaseResponse";
import { ClinicalCaseState } from "domain/static/ClinicalCaseState";
import { format } from "tools/DateExtension";
import { Flex, Spacer } from "components/Page";
import { nl2br } from "tools/StringExtension";

function Paragraph(props: TypographyProps) {
    return <Typography style={{ margin: "8px 0" }} {...props} />
}

interface StateButtonProps {
    disabled?: boolean;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    onClick: () => void;
}

function PublishButton({ onClick, disabled, startIcon, endIcon }: StateButtonProps) {
    return <Button
        size="large"
        color="primary"
        variant="contained"
        disabled={disabled}
        startIcon={startIcon}
        endIcon={endIcon}
        onClick={onClick}
    >
        publish
    </Button>
}

function UnpublishButton({ onClick, disabled, startIcon, endIcon }: StateButtonProps) {
    return <Button
        size="large"
        color="default"
        variant="outlined"
        disabled={disabled}
        startIcon={startIcon}
        endIcon={endIcon}
        onClick={onClick}
    >
        unpublish
    </Button>
}

function SubmitRejectedButton({ onClick, disabled, startIcon, endIcon }: StateButtonProps) {
    const theme = useTheme();
    const color = disabled ? undefined : theme.palette.warning.main;
    const borderColor = disabled ? undefined : theme.palette.warning.main;

    return <Button
        size="large"
        color="default"
        variant="outlined"
        disabled={disabled}
        startIcon={startIcon}
        endIcon={endIcon}
        onClick={onClick}
        style={{ color, borderColor }}
    >
        reject submit
    </Button>
}

export interface StateManagerProps {
    loading: boolean;
    entity: ClinicalCaseResponse;
    onAfterUpdate?: () => void;
}

export default function StateManager({ loading, entity, onAfterUpdate }: StateManagerProps) {

    const { success, error } = useNotificationContext();

    const [commentDialogState, setCommentDialogState] = useState({
        open: false,
        state: ClinicalCaseState.None,
        comment: "",
    });
    const [actionsDisabled, setActionsDisabled] = useState(false);
    const isSubmitted = entity.state === ClinicalCaseState.Submitted;
    const isPublished = entity.state === ClinicalCaseState.Published;

    const updateStateHandle = useCallback(async (state: ClinicalCaseState, comment: string | null) => {
        setActionsDisabled(true);

        const command = new SetStateClinicalCaseCommand();
        command.id = entity.id;
        command.state = state;
        command.comment = comment as any;

        try {
            await sendMessage(command);
            success("The clinical case state has been updated with success");
        }
        catch (e) {
            const err = formErrorService.parse(e.response);
            const message = typeof err === "string" ? err : "An error occured !"
            error(message);
        }

        onAfterUpdate && onAfterUpdate();
        setActionsDisabled(false);

    }, [entity.id, error, onAfterUpdate, success]);

    const validateCommentHandle = useCallback(async () => {
        const state = commentDialogState.state;
        const comment = nl2br(commentDialogState.comment);
        setCommentDialogState({ open: false, state: state, comment: "" });
        await updateStateHandle(state, comment);
    }, [commentDialogState, updateStateHandle]);

    const submitLabel = (
        <>
            <Paragraph>
                The clinical case has been submitted by linked user
                the {format(entity.submittedAt ?? new Date(), "dd MMMM yyyy 'at' hh:mm")}
            </Paragraph>
            <Paragraph>
                You can publish the clinical case if the job done is satisfactory, or reject the submit
                if job required some improvements.
            </Paragraph>
        </>
    );
    const publishLabel = (
        <>
            <Paragraph>
                To make this clinical case scene visible to the publics users, you must publish it.
            </Paragraph>
            <Paragraph>
                To publish, the clinical case must contains a primary image
                and at least one contour.
            </Paragraph>
        </>
    );
    const unpublishLabel = (
        <>
            <Paragraph>
                If you unpublish your clinical case scene, it will be no longer visible by the others public users.
            </Paragraph>
            <Paragraph>
                You will republish it later if you wish.
            </Paragraph>
        </>
    );

    const progressIcon = <CircularProgress size={16} color="inherit" />

    const rejectSubmitButton = <SubmitRejectedButton
        disabled={loading || actionsDisabled}
        startIcon={actionsDisabled ? progressIcon : <RejectIcon />}
        onClick={() => setCommentDialogState({ open: true, state: ClinicalCaseState.Draft, comment: "" })}
    />

    const acceptSubmitButton = <PublishButton
        disabled={loading || actionsDisabled}
        endIcon={actionsDisabled ? progressIcon : <PublishIcon />}
        onClick={() => setCommentDialogState({ open: true, state: ClinicalCaseState.Published, comment: "" })}
    />

    const publishButton = <PublishButton
        disabled={loading || actionsDisabled}
        endIcon={actionsDisabled ? progressIcon : <PublishIcon />}
        onClick={() => updateStateHandle(ClinicalCaseState.Published, null)}
    />

    const unpublishButton = <UnpublishButton
        disabled={loading || actionsDisabled}
        endIcon={actionsDisabled ? progressIcon : <UnpublishIcon />}
        onClick={() => updateStateHandle(ClinicalCaseState.Draft, null)}
    />

    let label = publishLabel;
    let leftAction = null as JSX.Element | null;
    let rightAction = publishButton;

    if (isSubmitted) {
        label = submitLabel;
        leftAction = rejectSubmitButton;
        rightAction = acceptSubmitButton;
    }
    else if (isPublished) {
        label = unpublishLabel;
        rightAction = unpublishButton;
    }

    return <>
        {label}

        <Flex marginTop={2}>
            {leftAction}
            <Spacer />
            {rightAction}
        </Flex>

        <Dialog open={commentDialogState.open}>
            <DialogTitle>
                Confirmation
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {commentDialogState.state === ClinicalCaseState.Draft &&
                        "You will reject the submitted clinical case. To help the user, tell them the reason of your decision."
                    }
                    {commentDialogState.state === ClinicalCaseState.Published &&
                        "You will accept the submitted clinical case. Share a remark if you wan't."
                    }
                </DialogContentText>
                <TextField
                    fullWidth
                    multiline
                    minRows={5}
                    maxRows={10}
                    variant="outlined"
                    placeholder="Explain your decision (optional)"
                    value={commentDialogState.comment}
                    onChange={(e) => setCommentDialogState({ open: true, state: commentDialogState.state, comment: e.target.value })}
                />
            </DialogContent>
            <DialogActions>
                <Box display="flex" width="100%" marginX={2} marginBottom={1}>
                    <Button
                        variant="outlined"
                        startIcon={<CloseIcon />}
                        onClick={() => setCommentDialogState({ open: false, state: commentDialogState.state, comment: "" })}
                    >
                        close
                    </Button>
                    <Spacer />
                    {commentDialogState.state === ClinicalCaseState.Draft &&
                        <SubmitRejectedButton endIcon={<RejectIcon />} onClick={validateCommentHandle} />

                    }
                    {commentDialogState.state === ClinicalCaseState.Published &&
                        <PublishButton endIcon={<PublishIcon />} onClick={validateCommentHandle} />
                    }
                </Box>
            </DialogActions>
        </Dialog>
    </>
}
