import { IdNameResponse } from "domain/admin/response/IdNameResponse";
import { SelectOption } from "cocoreact";
import { EmptyGuid, Guid } from "domain/static/Guid";

export function IdNameToTableOptions(names: IdNameResponse[]) {
  const options = names.map(
    (x) =>
      ({
        label: x.name,
        value: x.name,
      } as SelectOption)
  );

  options.unshift({
    label: "All",
    value: "undefined",
  } as SelectOption);

  return options;
}

export function IdNameToFormOptions(names: IdNameResponse[]) {
  const options = names.map(
    (x) =>
      ({
        label: x.name,
        value: x.id,
      } as SelectOption)
  );

  options.unshift({
    label: "None",
    value: EmptyGuid(),
  } as SelectOption);

  return options;
}

export function AddOptionIfUnvalidIdName<T extends { id: Guid }>(
  options: SelectOption[],
  names: IdNameResponse[],
  entity: T,
  entityFieldId: (entity: T) => Guid,
  entityFieldLabel: (entity: T) => string
) {
  const isAvailable =
    names.find((x) => x.id === entityFieldId(entity)) !== undefined;

  if (!isAvailable) {
    options.unshift({
      label: `${entityFieldLabel(entity)} (no longer available)`,
      value: entityFieldId(entity),
    });
  }

  return options;
}
