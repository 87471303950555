import React, { ReactNode } from "react";
import clsx from "clsx";

import {
  createStyles, makeStyles, Theme
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    padding: theme.spacing(0, 1),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 4),
    },
    margin: theme.spacing(0, "auto"),
    "& > *": {
      marginBottom: theme.spacing(2),
    },
    "& > :last-child": {
      marginBottom: 0,
    }
  },
  sm: {
    maxWidth: 576,
  },
  md: {
    maxWidth: 768,
  },
  lg: {
    maxWidth: 992,
  },
  xl: {
    maxWidth: 1200,
  },
}));

export interface CentralColumnLayoutProps {
  size?: "sm" | "md" | "lg" | "xl";
  className?: string;
  style?: React.CSSProperties;
  children?: ReactNode | ReactNode[];
}

export default function CentralColumnLayout({ size, className, style, children }: CentralColumnLayoutProps) {

  const classes = useStyles();
  const _size = size ? size : "lg";

  return (
    <div className={clsx(classes.container, classes[_size], className)} style={style}>

      {children}

    </div>
  )
}
