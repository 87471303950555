import React from "react";
import { useMessage } from "tools/Message";
import { Guid } from "domain/static/Guid";
import { GetClinicalDataContoursQuery } from "domain/admin/query/GetClinicalDataContoursQuery";
import { ClinicalContourItemResponse } from "domain/admin/response/ClinicalContourItemResponse";

export function buildGetClinicalDataContoursQuery(id: Guid) {
  const query = new GetClinicalDataContoursQuery();
  query.id = id;
  return query;
}

export default function useListClinicalContours(
  id: Guid,
  includeArchived: boolean = false
) {
  const query = React.useMemo(() => buildGetClinicalDataContoursQuery(id), [id]);
  const result = useMessage<ClinicalContourItemResponse[]>(query, []);
  if (!includeArchived) {
    result[1] = result[1].filter((x) => !x.isArchived);
  }
  return result;
}
