import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Switch, Route, RouteProps, Redirect, useLocation } from "react-router-dom";

import { DashboardPage } from "pages/Dashboard";
import { ContactPage } from "pages/Contact";
import { ListSelectItemsPage, EditSelectItemPage } from "pages/SelectItems";
import { ListUsersPage, EditUserPage } from "pages/Users";
import {
  ListArticlesPage,
  CreateArticlePage,
  EditArticlePage
} from "pages/Articles";
import {
  ListClinicalCasesPage,
  CreateClinicalCasePage,
  EditClinicalCasePage
} from "pages/ClinicalCases";
import {
  ListContouringWorkshopsPage,
  CreateContouringWorkshopPage,
  EditContouringWorkshopPage
} from "pages/ContouringWorkshops";

import { ResetPasswordPage, SignInPage } from "pages/Auth";
import { PageNotFoundError } from "pages/Errors";
import { fullPath } from "tools/Route";
import { enumHasFlag } from "tools/EnumExtension";
import { useAuthContext } from "contexts/Auth";
import { UserRole } from "domain/static/UserRole";

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
});

function PrivateRoute(props: RouteProps) {
  const location = useLocation();
  const { payload, mounted } = useAuthContext();

  if (!mounted) {
    return null;
  }
  if (!payload || !enumHasFlag(payload.role, UserRole.Admin)) {
    let queryString = {};
    if (location.pathname !== "/") {
      queryString = { return_to: encodeURI(location.pathname + location.search) };
    }
    const url = fullPath("SignIn", { queryString });
    return <Redirect to={url} />
  }

  return <Route {...props} />
}

export default function App() {

  const classes = useStyles();

  return (
    <div className={classes.root}>

      <Switch>
        <Route exact path={fullPath("SignIn")} component={SignInPage} />
        <Route exact path={fullPath("ResetPassword")} component={ResetPasswordPage} />

        <PrivateRoute exact path={fullPath("Dashboard")} component={DashboardPage} />
        <PrivateRoute exact path={fullPath("Contact")} component={ContactPage} />

        <PrivateRoute exact path={fullPath("ListUsers")} component={ListUsersPage} />
        <PrivateRoute exact path={fullPath("EditUser")} component={EditUserPage} />

        <PrivateRoute exact path={fullPath("ListSelectItems")} component={ListSelectItemsPage} />
        <PrivateRoute exact path={fullPath("EditSelectItem")} component={EditSelectItemPage} />

        <PrivateRoute exact path={fullPath("ListArticles")} component={ListArticlesPage} />
        <PrivateRoute exact path={fullPath("NewArticle")} component={CreateArticlePage} />
        <PrivateRoute path={fullPath("EditArticle")} component={EditArticlePage} />

        <PrivateRoute exact path={fullPath("ListClinicalCases")} component={ListClinicalCasesPage} />
        <PrivateRoute exact path={fullPath("NewClinicalCase")} component={CreateClinicalCasePage} />
        <PrivateRoute path={fullPath("EditClinicalCase")} component={EditClinicalCasePage} />

        <PrivateRoute exact path={fullPath("ListContouringWorkshops")} component={ListContouringWorkshopsPage} />
        <PrivateRoute exact path={fullPath("NewContouringWorkshop")} component={CreateContouringWorkshopPage} />
        <PrivateRoute path={fullPath("EditContouringWorkshop")} component={EditContouringWorkshopPage} />

        <Route path={"/"} render={() => { throw new PageNotFoundError(); }} />

      </Switch>

    </div>
  );

}
