import { Guid } from "domain/static/Guid";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class UpdateArticleCommand implements IMessage {
    id!: Guid;
    title!: string;
    authors!: string;
    date!: string;
    editor!: string;
    page!: string;
    doi!: string;
    pmid!: string;
    url!: string;
    anatomicZoneId!: Guid | null;

    public constructor(init?: Partial<UpdateArticleCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "PUT" as RequestMethod;

    getPath = () => `/admin/articles/${this.id.toString()}`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            title: this.title,
            authors: this.authors,
            date: this.date,
            editor: this.editor,
            page: this.page,
            doi: this.doi,
            pmid: this.pmid,
            url: this.url,
            anatomicZoneId: this.anatomicZoneId,
        };
    }
    
    needAuthentication = () => true;
}
