import React from "react";
import { ODataFilter, ODataSelect, ODataOrderBy } from "cocoreact";
import { useODataMessage } from "tools/Message";
import { SearchArticlesQuery } from "domain/admin/query/SearchArticlesQuery";
import { ArticleItemResponse } from "domain/admin/response/ODataArticleItemResponse";

export function buildSearchArticlesQuery() {
  const query = new SearchArticlesQuery();
  query.top = 1000;
  query.skip = 0;
  query.filter = new ODataFilter();
  query.select = new ODataSelect();
  query.orderBy = new ODataOrderBy();
  query.orderBy.set(ArticleItemResponse.Fields.createdAt.name);
  return query;
}

export default function useSearchArticles(query?: SearchArticlesQuery) {
  const _query = React.useMemo(() => query ?? buildSearchArticlesQuery(), [
    query,
  ]);
  return useODataMessage(_query);
}
