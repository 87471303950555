import React from "react";
import { useMessage } from "tools/Message";
import { Guid } from "domain/static/Guid";
import { GetUserClinicalCasesQuery } from "domain/admin/query/GetUserClinicalCasesQuery";
import { ProfileClinicalCaseItemResponse } from "domain/admin/response/ProfileClinicalCaseItemResponse";

export default function useGetUserClinicalCases(id: Guid) {
  const query = React.useMemo(
    () => new GetUserClinicalCasesQuery({ id }),
    [id]
  );
  return useMessage<ProfileClinicalCaseItemResponse[]>(query, []);
}
