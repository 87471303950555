import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useImmerReducer } from "use-immer";

import { ActionsType, reducer } from "./reducer";
import ViewerContext, { DispatchTarget } from "./ViewerContext";
import ViewerData from "./ViewerData";
import AppViewerHelper from "tools/Viewer";
import { ImagingDefault } from "domain/static/ImagingDefault";
import { HexToRgb } from "tools/ColorExtension";


export interface ViewerContextProviderProps {
    children: React.ReactNode | React.ReactNode[];
}

export default function ViewerContextProvider({
    children
}: ViewerContextProviderProps) {

    const app = useRef(new AppViewerHelper());
    const [data, dataDispatcher] = useImmerReducer<ViewerData, ActionsType>(reducer, new ViewerData());

    useEffect(() => {
        const _app = app.current;
        return () => {
            _app.clear();
        }
    }, []);

    const viewerDispatcher = useCallback(async (action: ActionsType) => {
        switch (action.type) {
            case "INITIALIZE":
                await app.current.initialize(action.data);
                // call data update after viewer initialized beacause data update call scenes initialization
                dataDispatcher(action);
                break;
            case "IMAGE_PRIMARY":
                const primaryImg = data.getImageById(action.imageId);
                if (primaryImg) {
                    await app.current.setImage(ImagingDefault.Primary, primaryImg);
                    if (!data.getImageOverlay()) {
                        await app.current.setImage(ImagingDefault.Overlay, primaryImg);
                    }
                }
                break;
            case "IMAGE_OVERLAY":
                const overlayImg = data.getImageById(action.imageId) ?? data.getImagePrimary();
                await app.current.setImage(ImagingDefault.Overlay, overlayImg);
                break;
            case "IMAGE_WINDOWING":
                await app.current.setImagesWindowing(action.imageId, action.windowing);
                break;
            case "IMAGE_LUT":
                await app.current.setImagesLutPreset(action.imageId, action.lut);
                break;
            case "CONTOUR_COLOR":
                await app.current.setContourColor(action.contourId, HexToRgb(action.color));
                await app.current.draw.updateInteractorsColor();
                break;
            case "CONTOUR_VISIBILITY":
                await app.current.setContourVisible(action.contourId, action.visibled);
                break;
            case "CONTOUR_DRAWING":
                const contourDrawing = action.contourId ? app.current.getContourById(action.contourId) : undefined;
                if (!contourDrawing) {
                    await app.current.draw.off();
                }
                else {
                    await app.current.draw.on(contourDrawing);
                }
                break;
            case "DRAWING_SIZE":
                await app.current.draw.setSize(action.size);
                break;
            case "DRAWING_TYPE":
                await app.current.draw.setErase(action.drawingType === "rubber");
                break;
            case "FUSION_TYPE":
                await app.current.setFusionType(action.fusionType);
                break;
            default:
                console.log("undefined viewer handler for action", action);
                break;
        }
    }, [data, dataDispatcher]);

    const dispatcher = useCallback(async (action: ActionsType, dispathcOn: DispatchTarget = "all") => {
        if (dispathcOn === "data" || dispathcOn === "all") {
            dataDispatcher(action);
        }
        if (dispathcOn === "viewer" || dispathcOn === "all") {
            await viewerDispatcher(action);
        }
    }, [dataDispatcher, viewerDispatcher])

    const value = useMemo(() => ({
        app: app.current,
        data,
        dispatcher,
    }), [data, dispatcher]);

    return (
        <ViewerContext.Provider value={value}>
            {children}
        </ViewerContext.Provider>
    );
}
