import React from "react";

import { TableOData, TableODataFieldsBuilder } from "components/Table";
import { buildSearchUsersQuery } from "domain/hooks/useSearchUsers";
import { fullPath } from "tools/Route";
import { SearchUsersQuery } from "domain/admin/query/SearchUsersQuery";
import { UserItemResponse } from "domain/admin/response/ODataUserItemResponse";
import { Box, IconButton, TextField, Typography } from "@material-ui/core";
import { format } from "tools/DateExtension";
import { ClearIcon } from "App/Theme";

export interface UserTableProps {
    editable?: boolean;
}

export default function UserTable({ editable }: UserTableProps) {

    const fields = React.useMemo(() => buildFields(editable), [editable]);

    const [search, setSearch] = React.useState("");

    const query = React.useMemo(() => {
        const query = buildSearchUsersQuery();
        query.top = 10;
        initializeSearchQuery(query);
        return query;
    }, []);

    const updateTableRef = React.useRef<HTMLButtonElement>(null);

    const timer = React.useRef<NodeJS.Timeout>();

    const updateSearchHandle = React.useCallback((_search: string) => {
        updateSearchQuery(query, _search);
        updateTableRef.current?.click();
    }, [query]);

    const onSearchChangeHandle = React.useCallback((_search: string) => {
        setSearch(_search);
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            updateSearchHandle(_search);
        }, 300);
    }, [updateSearchHandle]);

    const resetSearchHandle = React.useCallback(() => {
        updateSearchHandle("")
        setSearch("");
    }, [updateSearchHandle]);

    return (
        <>
            <Box display="flex" marginBottom={2}>
                <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    placeholder="Search users (firstname, lastname, email)"
                    InputProps={{
                        endAdornment: <IconButton onClick={resetSearchHandle}>
                            <ClearIcon />
                        </IconButton>
                    }}
                    value={search}
                    onChange={e => onSearchChangeHandle(e.target.value)}
                />
            </Box>

            <TableOData<UserItemResponse>
                fields={fields}
                message={query}
                updateRef={updateTableRef}
            />
        </>
    );
}

function initializeSearchQuery(query: SearchUsersQuery) {
    const fields = UserItemResponse.Fields;

    query.orderBy.set(fields.updatedAt.name, "desc");

    query.filter.set("user", [fields.firstname.name, fields.lastname.name, fields.email.name], "contains", "");
    query.filter.set(fields.role.name, fields.role.name, "eq", "undefined");
    query.filter.set(fields.isArchived.name, fields.isArchived.name, "eq", "undefined");
}

function updateSearchQuery(query: SearchUsersQuery, search: string) {
    query.filter.setValue("user", search.toLowerCase());
}

function buildFields(editable?: boolean) {
    const builder = new TableODataFieldsBuilder<UserItemResponse>();
    const fields = UserItemResponse.Fields;

    builder
        .initialize(fields)
        .hidden(fields.id)

        .hidden(fields.email)
        .hidden(fields.lastname)

        .set(fields.firstname, {
            label: "contact",
            position: 1,
            scope: "row",
            sortable: true,
            filterable: false,
            render: ({ data }) => <Typography variant="body1">
                {data.firstname} {data.lastname} ({data.email})
            </Typography>,
        })


        .set(fields.role, {
            position: 4,
            padding: "none",
            sortable: true,
            filterable: false,
        })

        .set(fields.updatedAt, {
            position: 5,
            formatter: (d, f) => format(d, f),
            format: "dd/MM/yyyy",
            sortable: true,
            filterable: false,
        })

        .set(fields.isArchived, {
            position: 6,
            align: "center",
            sortable: false,
            filterable: false,
        })

        ;
    if (editable) {
        builder.addLinkEdit((entity) => {
            return fullPath("EditUser", {
                params: { id: entity.id as string }
            });
        });
    }

    return builder.build();
}
