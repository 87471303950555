import { Guid } from "domain/static/Guid";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class GetFileQuery implements IMessage {
    id!: Guid;

    public constructor(init?: Partial<GetFileQuery>) {
        Object.assign(this, init);
    }

    getMethod = () => "GET" as RequestMethod;

    getPath = () => `/admin/files/${this.id.toString()}`;

    getQueryString = () => undefined;

    getBody = () => undefined;

    needAuthentication = () => false;
}
