import React from "react";
import { Button } from "@material-ui/core";
import { UnlockIcon, LockIcon } from "App/Theme";

export interface ArchiveButtonProps {
    isArchived: boolean;
    onClick: () => void;
    disabled?: boolean;
}

export default function ArchiveButton({
    isArchived, onClick, disabled
}: ArchiveButtonProps) {
    return <Button
        variant="outlined"
        onClick={onClick}
        disabled={disabled}
        startIcon={isArchived ? <UnlockIcon /> : <LockIcon />}
    >
        {isArchived ? "unarchive" : "archive"}
    </Button>
}
