import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";
import { createTheme as createMuiTheme, darken } from "@material-ui/core/styles";
import { indigo } from "@material-ui/core/colors";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { ThemeOptions, Theme } from "@material-ui/core/styles";

import DashboardIcon from "@material-ui/icons/Dashboard";
import MenuIcon from "@material-ui/icons/Menu";
import InfoIcon from "@material-ui/icons/Info";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import ClearIcon from "@material-ui/icons/FormatClear";
import SettingsIcon from "@material-ui/icons/Settings";
import PreviousIcon from "@material-ui/icons/ArrowBack";
import FileIcon from '@material-ui/icons/InsertDriveFile';
import ArchiveIcon from "@material-ui/icons/Archive";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import AddImageIcon from "@material-ui/icons/AddPhotoAlternate";
import TimelineIcon from "@material-ui/icons/Timeline";
import ContactIcon from "@material-ui/icons/ContactMail";
import EmailIcon from "@material-ui/icons/Email";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import SendIcon from "@material-ui/icons/Send";
import LockIcon from "@material-ui/icons/LockOutlined";
import UnlockIcon from "@material-ui/icons/LockOpenOutlined";
import AccountIcon from "@material-ui/icons/AccountCircle";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import BrightnessIcon from "@material-ui/icons/BrightnessMedium";
import MoreIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";
import TimeIcon from "@material-ui/icons/AccessTime";
import ArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import RefreshIcon from "@material-ui/icons/Refresh";
import PublishIcon from "@material-ui/icons/Public";
import UnpublishIcon from "@material-ui/icons/Lock";
import UndoIcon from "@material-ui/icons/Undo";
import RedoIcon from "@material-ui/icons/Redo";
import FillSliceIcon from "@material-ui/icons/Layers";
import MarginIcon from "@material-ui/icons/AllOut";

import SelectItemIcon from "@material-ui/icons/FormatListNumbered";
import ArticleIcon from "@material-ui/icons/MenuBook";
import ClinicalCaseIcon from "@material-ui/icons/FolderShared";
import ContouringWorkshopIcon from "@material-ui/icons/School";
import UserIcon from "@material-ui/icons/PeopleAlt";

function RubberIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <path d="M16.2401 3.5601L21.1901 8.5001C21.9701 9.2901 21.9701 10.5501 21.1901 11.3401L12.0001 20.5301C11.2488 21.2792 10.2311 21.6999 9.1701 21.6999C8.10911 21.6999 7.09141 21.2792 6.3401 20.5301L2.8101 17.0001C2.0301 16.2101 2.0301 14.9501 2.8101 14.1601L13.4101 3.5601C14.2001 2.7801 15.4601 2.7801 16.2401 3.5601V3.5601ZM4.2201 15.5801L7.7601 19.1101C8.5401 19.9001 9.8001 19.9001 10.5901 19.1101L14.1201 15.5801L9.1701 10.6301L4.2201 15.5801V15.5801Z" fill="inherit" />
    </SvgIcon>
}

function PencilIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <path d="M7 14C5.34 14 4 15.34 4 17C4 18.31 2.84 19 2 19C2.92 20.22 4.49 21 6 21C8.21 21 10 19.21 10 17C10 15.34 8.66 14 7 14ZM20.71 4.63L19.37 3.29C19.2775 3.1973 19.1676 3.12375 19.0466 3.07357C18.9257 3.02339 18.796 2.99756 18.665 2.99756C18.534 2.99756 18.4043 3.02339 18.2834 3.07357C18.1624 3.12375 18.0525 3.1973 17.96 3.29L9 12.25L11.75 15L20.71 6.04C20.8027 5.94749 20.8763 5.8376 20.9264 5.71663C20.9766 5.59565 21.0024 5.46597 21.0024 5.335C21.0024 5.20403 20.9766 5.07435 20.9264 4.95338C20.8763 4.83241 20.8027 4.72252 20.71 4.63V4.63Z" fill="inherit" />
    </SvgIcon>
}

function BooleanIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <path d="M15 2c-2.71 0-5.05 1.54-6.22 3.78-1.28.67-2.34 1.72-3 3C3.54 9.95 2 12.29 2 15c0 3.87 3.13 7 7 7 2.71 0 5.05-1.54 6.22-3.78 1.28-.67 2.34-1.72 3-3C20.46 14.05 22 11.71 22 9c0-3.87-3.13-7-7-7zM9 20c-2.76 0-5-2.24-5-5 0-1.12.37-2.16 1-3 0 3.87 3.13 7 7 7-.84.63-1.88 1-3 1zm3-3c-2.76 0-5-2.24-5-5 0-1.12.37-2.16 1-3 0 3.86 3.13 6.99 7 7-.84.63-1.88 1-3 1zm4.7-3.3c-.53.19-1.1.3-1.7.3-2.76 0-5-2.24-5-5 0-.6.11-1.17.3-1.7.53-.19 1.1-.3 1.7-.3 2.76 0 5 2.24 5 5 0 .6-.11 1.17-.3 1.7zM19 12c0-3.86-3.13-6.99-7-7 .84-.63 1.87-1 3-1 2.76 0 5 2.24 5 5 0 1.12-.37 2.16-1 3z" fill="inherit" />
    </SvgIcon>
}

function BooleanUnionIcon(props: SvgIconProps) {
    return <SvgIcon {...props} viewBox="0 0 48 48" strokeWidth={2}>
        <path d="M24.0001 29.4925C13 29.4925 14 16.4925 12 30.4925C12 35.4631 16 41.4925 24.0001 41.4925C32.0156 41.4925 35.5 35.5 36 30.9925C34.5 16.4925 35.0001 29.4925 24.0001 29.4925Z" fill="inherit" />
        <path d="M23.9999 18C35 18 34 31 36 17C36 12.0294 32 6 23.9999 6C15.9844 6 12.5 12.5 12 16.5C13.5 31 12.9999 18 23.9999 18Z" fill="inherit" />
        <path d="M24 30C29.5228 30 31.5 27.5 34.5 24L34.4692 23.9631C31.9796 20.9754 29.5002 18 24 18C18.4772 18 16.5 20.5 13.5 24L13.5308 24.0369C16.0204 27.0246 18.4998 30 24 30Z" fill="inherit" />
        <path d="M24 42C30.6274 42 36 36.6274 36 30C36 23.3726 30.6274 18 24 18C17.3726 18 12 23.3726 12 30C12 36.6274 17.3726 42 24 42Z" stroke="#212121" fill="none" />
        <path d="M24 30C30.6274 30 36 24.6274 36 18C36 11.3726 30.6274 6 24 6C17.3726 6 12 11.3726 12 18C12 24.6274 17.3726 30 24 30Z" stroke="#212121" fill="none" />
    </SvgIcon>
}

function BooleanIntersectionIcon(props: SvgIconProps) {
    return <SvgIcon {...props} viewBox="0 0 48 48" strokeWidth={2}>
        <path d="M24 30C29.5228 30 31.5 27.5 34.5 24L34.4692 23.9631C31.9796 20.9754 29.5002 18 24 18C18.4772 18 16.5 20.5 13.5 24L13.5308 24.0369C16.0204 27.0246 18.4998 30 24 30Z" fill="inherit" />
        <path d="M24 42C30.6274 42 36 36.6274 36 30C36 23.3726 30.6274 18 24 18C17.3726 18 12 23.3726 12 30C12 36.6274 17.3726 42 24 42Z" stroke="#212121" fill="none" />
        <path d="M24 30C30.6274 30 36 24.6274 36 18C36 11.3726 30.6274 6 24 6C17.3726 6 12 11.3726 12 18C12 24.6274 17.3726 30 24 30Z" stroke="#212121" fill="none" />
    </SvgIcon>
}

function BooleanSoustractionIcon(props: SvgIconProps) {
    return <SvgIcon {...props} viewBox="0 0 48 48" strokeWidth={2}>
        <path d="M23.9999 18C35 18 34 31 36 17C36 12.0294 32 6 23.9999 6C15.9844 6 12.5 12.5 12 16.5C13.5 31 12.9999 18 23.9999 18Z" fill="inherit" />
        <path d="M24 42C30.6274 42 36 36.6274 36 30C36 23.3726 30.6274 18 24 18C17.3726 18 12 23.3726 12 30C12 36.6274 17.3726 42 24 42Z" stroke="#212121" fill="none" />
        <path d="M24 30C30.6274 30 36 24.6274 36 18C36 11.3726 30.6274 6 24 6C17.3726 6 12 11.3726 12 18C12 24.6274 17.3726 30 24 30Z" stroke="#212121" fill="none" />
    </SvgIcon>
}

function BooleanExclusionIcon(props: SvgIconProps) {
    return <SvgIcon {...props} viewBox="0 0 48 48" strokeWidth={2}>
        <path d="M24.0001 29.4925C13 29.4925 14 16.4925 12 30.4925C12 35.4631 16 41.4925 24.0001 41.4925C32.0156 41.4925 35.5 35.5 36 30.9925C34.5 16.4925 35.0001 29.4925 24.0001 29.4925Z" fill="inherit" />
        <path d="M23.9999 18C35 18 34 31 36 17C36 12.0294 32 6 23.9999 6C15.9844 6 12.5 12.5 12 16.5C13.5 31 12.9999 18 23.9999 18Z" fill="inherit" />
        <path d="M24 42C30.6274 42 36 36.6274 36 30C36 23.3726 30.6274 18 24 18C17.3726 18 12 23.3726 12 30C12 36.6274 17.3726 42 24 42Z" stroke="#212121" fill="none" />
        <path d="M24 30C30.6274 30 36 24.6274 36 18C36 11.3726 30.6274 6 24 6C17.3726 6 12 11.3726 12 18C12 24.6274 17.3726 30 24 30Z" stroke="#212121" fill="none" />
    </SvgIcon>
}

// --------------------------------------------------------

declare module '@material-ui/core/styles/createMixins' {
    interface Mixins {
        drawer: {
            width: number;
            smallDevice: Breakpoint[];
            largeDevice: Breakpoint[];
            breakpoint: Breakpoint; // first largeDevice
            implementation: "css" | "js"; // "js" to avoid SEO duplication of links
        },
        getTableHeader: (theme: Theme) => CSSProperties,
        viewer: {
            smallWidth: number,
        },
    }
}

// --------------------------------------------------------

function createTheme(options: ThemeOptions) {
    return createMuiTheme({
        mixins: {
            drawer: {
                width: 240,
                smallDevice: ["xs", "sm"],
                largeDevice: ["md", "lg", "xl"],
                breakpoint: "md",
                implementation: "js",
            },
            toolbar: {
                height: 56,
                minHeight: 56,
                // maxHeight: 56, // bug for DatePicker & TimePicker
            },
            viewer: {
                smallWidth: 480,
                // background: "#212121",
            },
            getTableHeader: (theme: Theme) => {
                const bg = darken(theme.palette.background.default, 0.2);
                return {
                    backgroundColor: bg,
                    color: theme.palette.getContrastText(bg),
                }
            },
        },
        ...options,
    });
}

export const darkTheme = createTheme({
    palette: {
        primary: indigo,
        secondary: {
            main: '#00796b',
        },
        type: "dark",
    }
});

export const lightTheme = createTheme({
    palette: {
        primary: indigo,
        secondary: {
            main: '#00796b',
        },
        type: "light",
    }
});

export const themes = {
    dark: darkTheme,
    light: lightTheme
};

export type ThemeName = keyof typeof themes;

export {
    DashboardIcon,
    MenuIcon, InfoIcon,
    AddIcon, SaveIcon, DeleteIcon,
    EditIcon, CloseIcon, ClearIcon,
    PreviousIcon, SettingsIcon,
    FileIcon, ArchiveIcon, UnarchiveIcon,
    AddImageIcon, TimelineIcon,
    ContactIcon, SendIcon, DownloadIcon, EmailIcon,
    LockIcon, UnlockIcon, AccountIcon,
    LogoutIcon,
    BrightnessIcon, MoreIcon,
    SearchIcon, TimeIcon,
    ArrowUpIcon, ArrowDownIcon,
    RefreshIcon, PublishIcon, UnpublishIcon,

    SelectItemIcon, ArticleIcon, ClinicalCaseIcon, ContouringWorkshopIcon,
    UserIcon,

    UndoIcon, RedoIcon, FillSliceIcon, PencilIcon,
    RubberIcon, BooleanIcon, MarginIcon,

    BooleanUnionIcon, BooleanIntersectionIcon,
    BooleanSoustractionIcon, BooleanExclusionIcon
};