import {
  JsonSerializer,
  IConverter,
  DateConverter,
  ODataFilterConverter,
  ODataSelectConverter,
  ODataOrderByConverter,
} from "cocoreact";
import { IsGuid, IsValidGuid } from "domain/static/Guid";

class GuidConverter implements IConverter {
  canRead(_data: any) {
    return false;
  }

  read(data: string) {
    return data;
  }

  canWrite(data: any) {
    return IsGuid(data);
  }

  write(data: string) {
    return IsValidGuid(data) ? data : (undefined as any);
  }
}

export default class Serializer extends JsonSerializer {
  constructor() {
    super([
      new GuidConverter(),
      new DateConverter(),
      new ODataFilterConverter(),
      new ODataSelectConverter(),
      new ODataOrderByConverter(),
    ]);
  }
}
