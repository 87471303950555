import React from "react";

import { BOOLEAN_SELECT_OPTIONS, TableOData, TableODataFieldsBuilder } from "components/Table";
import { buildSearchContouringWorkshopsQuery } from "domain/hooks/useSearchContouringWorkshops";
import { fullPath } from "tools/Route";
import { SelectItemType } from "domain/static/SelectItemType";
import { loadODataMessage, saveODataMessage } from "tools/sessionCache";
import { IdNameResponse } from "domain/admin/response/IdNameResponse";
import { ContouringWorkshopItemResponse } from "domain/admin/response/ODataContouringWorkshopItemResponse";
import { SearchContouringWorkshopsQuery } from "domain/admin/query/SearchContouringWorkshopsQuery";
import { IdNameToTableOptions } from "tools/IdNameOptions";
import { CallbackButton } from "components/Buttons";
import useListSelectItems from "domain/hooks/useListSelectItems";
import ContouringWorkshopStateTableWidget from "components/Table/widgets/ContouringWorkshopStateTableWidget";

export interface ContouringWorkshopTableProps {
    editable?: boolean;
    resetRef?: React.RefObject<HTMLButtonElement>;
}

export default function ContouringWorkshopTable({ resetRef, editable }: ContouringWorkshopTableProps) {

    const [, anatomicZones] = useListSelectItems(SelectItemType.AnatomicZone);
    const fields = React.useMemo(() => buildFields(anatomicZones, editable), [anatomicZones, editable]);

    const query = React.useMemo(() => {
        const query = buildSearchContouringWorkshopsQuery();
        query.top = 10;
        resetQuery(query);
        loadODataMessage("ContouringWorkshopTable", query);
        return query
    }, []);

    const updateTableRef = React.useRef<HTMLButtonElement>(null);
    const resetQueryHandle = React.useCallback(() => {
        resetQuery(query);
        updateTableRef.current?.click();
    }, [query])

    React.useEffect(() => {
        return () => saveODataMessage("ContouringWorkshopTable", query)
    }, [query]);

    return (
        <>
            <CallbackButton callback={resetQueryHandle} ref={resetRef} />

            <TableOData<ContouringWorkshopItemResponse>
                fields={fields}
                message={query}
                updateRef={updateTableRef}
            />
        </>
    );
}

function resetQuery(query: SearchContouringWorkshopsQuery) {
    const fields = ContouringWorkshopItemResponse.Fields;

    query.orderBy.set(fields.name.name);

    query.filter.set(fields.anatomicZoneName.name, fields.anatomicZoneName.name, "eq", "undefined");
    query.filter.set(fields.name.name, fields.name.name, "contains", "");
    query.filter.set(fields.isPublished.name, fields.isPublished.name, "eq", "undefined");
    query.filter.set(fields.isArchived.name, fields.isArchived.name, "eq", "undefined");
}

function buildFields(anatomicZones: IdNameResponse[], editable?: boolean) {
    const builder = new TableODataFieldsBuilder<ContouringWorkshopItemResponse>();
    const fields = ContouringWorkshopItemResponse.Fields;

    const anatomicZonesOptions = IdNameToTableOptions(anatomicZones);

    builder
        .initialize(fields)
        .hidden(fields.id)
        .hidden(fields.summary)
        .hidden(fields.createdAt)
        .hidden(fields.updatedAt)
        .hidden(fields.startAt)
        .hidden(fields.endAt)

        .set(fields.anatomicZoneName, {
            position: 0,
            label: "anatomic zone",
            sortable: true,
        })
        .setFilterSelect(fields.anatomicZoneName, anatomicZonesOptions)

        .set(fields.name, {
            position: 1,
            scope: "row",
            sortable: true,
            filterable: true,
            filter: {
                availableOperators: ["contains", "eq"]
            }
        })

        .set(fields.state, {
            position: 2,
            sortable: false,
            filterable: false,
            render: ({ data, fieldProps }) => <ContouringWorkshopStateTableWidget
                {...fieldProps}
                value={data.state}
                dateRangeMin={data.startAt}
                dateRangeMax={data.endAt}
            />
        })

        .set(fields.isPublished, {
            position: 3,
            align: "center",
        })
        .setFilterSelect(fields.isPublished, BOOLEAN_SELECT_OPTIONS)

        .set(fields.isArchived, {
            position: 4,
            align: "center",
        })
        .setFilterSelect(fields.isArchived, BOOLEAN_SELECT_OPTIONS)

        ;
    if (editable) {
        builder.addLinkEdit((entity) => {
            return fullPath("EditContouringWorkshop", {
                params: {
                    id: entity.id as string
                }
            });
        });
    }

    return builder.build();
}
