import TableMessage from "./TableMessage";
import TableOData from "./TableOData";
import TableFieldsBuilder from "./TableFieldsBuilder";
import TableODataFieldsBuilder from "./TableODataFieldsBuilder";
import useTableUpdateRef from "./useTableUpdateRef";

export { default } from "./Table";

export * from "./Table";
export * from "./TableMessage";
export * from "./TableOData";
export * from "./TableFieldsBuilder";

const BOOLEAN_SELECT_OPTIONS = [
    {
        label: "Tous",
        value: "undefined",
    },
    {
        label: "Oui",
        value: "true",
    },
    {
        label: "Non",
        value: "false",
    },
]

export {
    TableFieldsBuilder,
    TableODataFieldsBuilder,
    TableMessage,
    TableOData,
    useTableUpdateRef,
    BOOLEAN_SELECT_OPTIONS
};
