import React, { useRef, useCallback } from "react";
import { Link } from "react-router-dom";

import { Button } from "@material-ui/core";

import { CentralColumnLayout, PageLayout } from "components/Layouts";
import ClinicalCaseTable from "./ClinicalCaseTable";
import { fullPath } from "tools/Route";
import { AddIcon, ClinicalCaseIcon } from "App/Theme";
import { Flex, Paper, PaperTitle, Title } from "components/Page";
import { ResetFiltersButton } from "components/Buttons";

export default function ListClinicalCasesPage() {

    const resetRef = useRef<HTMLButtonElement>(null);
    const resetQueryHandle = useCallback(() => {
        resetRef.current?.click();
    }, []);

    const title = "List of clinical cases";

    return <>
        <Title>{title}</Title>

        <PageLayout title="Clinical cases" menuSelected="ListClinicalCases">
            <CentralColumnLayout size="xl">

                <Flex justifyContent="space-between">
                    <ResetFiltersButton onClick={resetQueryHandle} />

                    <Link to={fullPath("NewClinicalCase")}>
                        <Button
                            color="secondary"
                            variant="contained"
                            startIcon={<AddIcon />}
                        >
                            new clinical case
                        </Button>
                    </Link>
                </Flex>

                <Paper>
                    <PaperTitle icon={<ClinicalCaseIcon />}>{title}</PaperTitle>

                    <ClinicalCaseTable
                        editable={true}
                        resetRef={resetRef}
                    />
                </Paper>

            </CentralColumnLayout>
        </PageLayout>
    </>
}
