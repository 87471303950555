import React from "react";

import { SearchSelectItemsQuery } from "domain/admin/query/SearchSelectItemsQuery";
import { SelectItemItemResponse } from "domain/admin/response/ODataSelectItemItemResponse";
import { fullPath } from "tools/Route";
import { loadODataMessage, saveODataMessage } from "tools/sessionCache";
import { TableOData, TableODataFieldsBuilder, BOOLEAN_SELECT_OPTIONS } from "components/Table";
import { SelectItemTypeOptions } from "domain/static/SelectItemType";
import { CallbackButton } from "components/Buttons";
import { buildSearchSelectItemsQuery } from "domain/hooks/useSearchSelectItems";

export interface SelectItemTableProps {
    editable?: boolean;
    resetRef?: React.RefObject<HTMLButtonElement>;
}

export default function SelectItemTable({ editable, resetRef }: SelectItemTableProps) {

    const fields = React.useMemo(() => buildFields(editable), [editable]);

    const query = React.useMemo(() => {
        const query = buildSearchSelectItemsQuery();
        query.top = 10;
        resetQuery(query);
        loadODataMessage("SelectItemTable", query);
        return query
    }, []);

    const updateTableRef = React.useRef<HTMLButtonElement>(null);
    const resetQueryHandle = React.useCallback(() => {
        resetQuery(query);
        updateTableRef.current?.click();
    }, [query])

    React.useEffect(() => {
        return () => saveODataMessage("SelectItemTable", query)
    }, [query]);

    return (
        <>
            <CallbackButton callback={resetQueryHandle} ref={resetRef} />

            <TableOData<SelectItemItemResponse>
                fields={fields}
                message={query}
                updateRef={updateTableRef}
            />
        </>
    );
}

function resetQuery(query: SearchSelectItemsQuery) {
    const fields = SelectItemItemResponse.Fields;

    query.orderBy.set(fields.name.name);

    query.filter.set(fields.name.name, fields.name.name, "contains", "");
    query.filter.set(fields.type.name, fields.type.name, "eq", "undefined");
    query.filter.set(fields.isArchived.name, fields.isArchived.name, "eq", "false");
}

function buildFields(editable?: boolean) {
    const builder = new TableODataFieldsBuilder<SelectItemItemResponse>();
    const fields = SelectItemItemResponse.Fields;

    builder
        .initialize(fields)
        .hidden(fields.id)
        .hidden(fields.createdAt)
        .hidden(fields.updatedAt)
        .set(fields.name, {
            position: 0,
            scope: "row",
            sortable: true,
            filterable: true,
            filter: {
                availableOperators: ["contains", "eq"]
            }
        })
        .set(fields.type, {
            position: 1,
            sortable: true,
            filterable: true,
            options: SelectItemTypeOptions,
            filter: {
                options: SelectItemTypeOptions,
                selectAllInsteadNone: true,
                availableOperators: ["eq"],
            }
        })
        .set(fields.isArchived, {
            position: 2,
            align: "center",
        })
        .setFilterSelect(fields.isArchived, BOOLEAN_SELECT_OPTIONS)
        ;
    if (editable) {
        builder.addLinkEdit((entity) => {
            return fullPath("EditSelectItem", {
                params: {
                    id: entity.id as string
                }
            });
        });
    }

    return builder.build();
}
