import React from "react";

import { Box, createStyles, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import { Title } from "components/Page";

// settings file
declare var APP_CONTACT_EMAIL: string;

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        display: "flex",
        justifyContent: "center",
        width: "100vw",
        padding: theme.spacing(0, 2),
    },
    wrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: 380,
    },
    logo: {
        height: theme.spacing(8),
        margin: theme.spacing(5, 0),
    },
    paper: {
        padding: theme.spacing(3, 2),
        marginTop: theme.spacing(3),
    },
    actions: {
        marginTop: theme.spacing(2),
        width: "100%",
        display: "flex",
        justifyContent: "space-around",
        "& a": {
            color: theme.palette.getContrastText(theme.palette.background.paper),
            textDecoration: "underline",
        }
    }
}));

export interface LogLayoutProps {
    title: string;
    children: any;
}

export default function LogLayout({ title, children }: LogLayoutProps) {

    const classes = useStyles();

    const contactEmail = APP_CONTACT_EMAIL;

    return (
        <Box className={classes.container}>
            <Title>{title}</Title>

            <Box className={classes.wrapper}>
                <img src={`${process.env.PUBLIC_URL}/logo192.png`} alt="logo" className={classes.logo} />
                <Typography variant="h5">
                    {title}
                </Typography>

                <Paper className={classes.paper}>
                    {children}
                </Paper>

                <Box className={classes.actions}>
                    <a href={`mailto:${contactEmail}`}>Contact Dline</a>
                </Box>
            </Box>

        </Box>
    );
}