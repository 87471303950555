import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core";

import Toolbar, { ToolbarProps } from "App/Toolbar";
import Drawer, { DrawerProps } from "App/Drawer";

const useStyles = makeStyles((theme: Theme) => createStyles({
    toolbar: theme.mixins.toolbar,
    content: {
        position: "relative",
        flexGrow: 1,
        padding: theme.spacing(2, 0),
        overflow: "auto",
    },
}));

export interface PageLayoutProps {
    title?: ToolbarProps["title"];
    menuSelected?: DrawerProps["menuSelected"];
    children?: React.ReactNode | React.ReactNode[];
}

export default function PageLayout({ title, menuSelected, children }: PageLayoutProps) {

    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(false);

    return <>
        <Toolbar
            title={title}
            setDrawerOpen={setDrawerOpen}
        />

        <Drawer
            menuSelected={menuSelected}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
        />

        <main className={classes.content}>
            <div className={classes.toolbar} />
            {children}
        </main>
    </>

}
