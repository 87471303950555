import React from "react";
import { Link } from "react-router-dom";
import {
    Box, createStyles, darken, IconButton,
    makeStyles, Theme, Typography
} from "@material-ui/core";

import { PreviousIcon } from "App/Theme";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        paddingTop: theme.spacing(2),
        backgroundColor: darken(theme.palette.background.default, 0.2),
        marginTop: theme.spacing(-2),
        marginBottom: theme.spacing(2),
        width: "100%",
        boxShadow: theme.shadows[2],
    },
}));

interface HeaderTabsProps {
    title: string;
    previousLink: string;
    tabsComponent: React.ReactNode;
    chips?: React.ReactNode[];
}

export default function HeaderTabs({ title, previousLink, chips, tabsComponent }: HeaderTabsProps) {
    const classes = useStyles();

    return <Box className={classes.root}>
        <Box marginTop={2} marginBottom={1} marginLeft={4} marginRight={2} display="flex" alignItems="center">

            <Box marginRight={2}>
                <Link to={previousLink}>
                    <IconButton size="small">
                        <PreviousIcon />
                    </IconButton>
                </Link>
            </Box>

            <Typography variant="h5" component="h2">
                {title}
                {chips && chips.map((chip, idx) => chip &&
                    <Box key={idx} marginLeft={2} display="inline">{chip}</Box>
                )}
            </Typography>
        </Box>

        {chips && <Box marginLeft={4} marginBottom={1}></Box>}

        {tabsComponent}
    </Box>
}