import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    Box, ClickAwayListener, createStyles, Grow, IconButton,
    ListItem, ListItemIcon, ListItemText, makeStyles, MenuItem, MenuList, Paper, Popper, Theme
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Container, Draggable, DropResult } from "react-smooth-dnd";
import { DragHandle as MoveIcon } from "@material-ui/icons";

import { Guid } from "domain/static/Guid";
import { UpdateClinicalDataArticlesCommand } from "domain/admin/command/UpdateClinicalDataArticlesCommand";
import { IdNameResponse } from "domain/admin/response/IdNameResponse";
import useGetClinicalDataArticles from "domain/hooks/useGetClinicalDataArticles";
import { SearchArticleField } from "pages/Articles";
import { MoreIcon } from "App/Theme";
import { sendMessage } from "tools/Message";
import { fullPath } from "tools/Route";

const useStyles = makeStyles((theme: Theme) => createStyles({
    items: {
        display: "flex",
        flexDirection: "column",
        padding: 0,
        margin: 0,
        "& > *": {
            margin: theme.spacing(1, 0, 0, 0),
        }
    },
    item_root: {
        padding: theme.spacing(1, 2),
        border: "1px solid black",
        borderColor: theme.palette.divider,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.default,
    },
    item_actions: {
        display: "flex",
        alignItems: "center",
        "& .dnd-handle": {
            cursor: "move",
            opacity: 0.3,
        },
    },
    link: {
        color: theme.palette.text.primary,
    }
}));

interface ArticleItemProps {
    article: IdNameResponse;
    onRemove: (articleId: Guid) => void;
}

function ArticleItem({ article, onRemove }: ArticleItemProps) {
    const classes = useStyles();
    const menuRef = useRef<HTMLButtonElement>(null);
    const [menuOpen, setMenuOpen] = useState(false);

    const openHandle = useCallback((e: any) => {
        setMenuOpen(true);
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }, []);

    const closeHandle = useCallback((e: any) => {
        setMenuOpen(false);
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }, []);

    return <ListItem className={classes.item_root}>
        <ListItemText primary={`${article.name}`} />
        <ListItemIcon className={classes.item_actions}>
            <IconButton
                ref={menuRef}
                onClick={openHandle}
            >
                <MoreIcon />
            </IconButton>

            <Popper
                open={menuOpen}
                anchorEl={menuRef.current}
                role={undefined}
                transition={true}
                disablePortal={false}
                placement="left-start"
                style={{ zIndex: 10000000 }}
            >
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener
                                onClickAway={closeHandle}
                            >
                                <MenuList>
                                    <Link to={fullPath("EditArticle", { params: { id: article.id } })} className={classes.link}>
                                        <MenuItem>
                                            Edit article
                                        </MenuItem>
                                    </Link>
                                    <MenuItem onClick={() => onRemove(article.id)}>
                                        Remove
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

            <MoveIcon className="dnd-handle" onClick={e => {
                e.stopPropagation();
                e.preventDefault();
            }} />
        </ListItemIcon>
    </ListItem>
}

export interface ArticlesManagerProps {
    id: Guid;
}

export default function ArticlesManager({ id }: ArticlesManagerProps) {
    const classes = useStyles();

    const [ccLoading, ccArticles] = useGetClinicalDataArticles(id);
    const [articles, setArticles] = useState<IdNameResponse[]>([]);

    useEffect(() => {
        if (!ccLoading) {
            setArticles(ccArticles);
        }
    }, [ccArticles, ccLoading]);

    const updateMessage = useCallback(async (items: IdNameResponse[]) => {
        const message = new UpdateClinicalDataArticlesCommand({ id });
        message.articleIds = items.map(x => x.id);
        await sendMessage(message);
    }, [id]);

    const onSelectHandle = useCallback(async (u: IdNameResponse) => {
        if (articles.find(x => x.id === u.id)) {
            return;
        }
        const ids = [...articles, u]
        setArticles(ids);
        await updateMessage(ids);
    }, [articles, updateMessage]);

    const onRemoveHandle = useCallback(async (articleId: Guid) => {
        const ids = articles.filter(x => x.id !== articleId);
        await updateMessage(ids);
        setArticles(ids);
    }, [articles, updateMessage]);

    const onDropHandle = useCallback(async ({ addedIndex, removedIndex }: DropResult) => {
        if (addedIndex === null || removedIndex === null) return;

        const ids = [...articles];
        ids.splice(addedIndex, 0, ids.splice(removedIndex, 1)[0]);
        setArticles(ids);
        await updateMessage(ids);
    }, [articles, updateMessage]);

    return <>
        <SearchArticleField onSelect={onSelectHandle} />
        <Box className={classes.items}>
            <Container dragHandleSelector=".dnd-handle" lockAxis="y" onDrop={onDropHandle}>
                {articles.map(x =>
                    <Draggable key={x.id}>
                        <ArticleItem article={x} onRemove={onRemoveHandle} />
                    </Draggable>)}
            </Container>
        </Box>
    </>
}
