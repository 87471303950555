import React from "react";
import { LoadingWrapper } from "cocoreact";

import { CentralColumnLayout, PageLayout } from "components/Layouts";
import { Paper, PaperTitle, Title } from "components/Page";
import Table, { TableFieldsBuilder } from "components/Table";
import useSearchClinicalCases, { buildSearchClinicalCasesQuery } from "domain/hooks/useSearchClinicalCases";
import { ClinicalCaseItemResponse } from "domain/admin/response/ODataClinicalCaseItemResponse";
import useSearchContouringWorkshops, { buildSearchContouringWorkshopsQuery } from "domain/hooks/useSearchContouringWorkshops";
import { ContouringWorkshopItemResponse } from "domain/admin/response/ODataContouringWorkshopItemResponse";
import { ClinicalCaseState } from "domain/static/ClinicalCaseState";
import { fullPath } from "tools/Route";

const ClinicalCaseFields = ClinicalCaseItemResponse.Fields;
const ContouringWorkshopFields = ContouringWorkshopItemResponse.Fields;

const lasSubmittedQuery = buildSearchClinicalCasesQuery();
lasSubmittedQuery.filter.set(ClinicalCaseFields.state.name, ClinicalCaseFields.state.name, "eq", ClinicalCaseState.Submitted);
lasSubmittedQuery.filter.set(ClinicalCaseFields.isArchived.name, ClinicalCaseFields.isArchived.name, "eq", false);
lasSubmittedQuery.orderBy.set(ClinicalCaseFields.submittedAt.name, "desc");

const lastDraftQuery = buildSearchClinicalCasesQuery();
lastDraftQuery.filter.set(ClinicalCaseFields.state.name, ClinicalCaseFields.state.name, "eq", ClinicalCaseState.Draft);
lastDraftQuery.filter.set(ClinicalCaseFields.isArchived.name, ClinicalCaseFields.isArchived.name, "eq", false);
lastDraftQuery.orderBy.set(ClinicalCaseFields.updatedAt.name, "desc");

const lastInProgressQuery = buildSearchContouringWorkshopsQuery();
lastInProgressQuery.filter.set(ContouringWorkshopFields.startAt.name, ContouringWorkshopFields.startAt.name, "le", new Date());
lastInProgressQuery.filter.set(ContouringWorkshopFields.endAt.name, ContouringWorkshopFields.endAt.name, "ge", new Date());
lastInProgressQuery.filter.set(ContouringWorkshopFields.isArchived.name, ContouringWorkshopFields.isArchived.name, "eq", false);
lastInProgressQuery.filter.set(ContouringWorkshopFields.isPublished.name, ContouringWorkshopFields.isPublished.name, "eq", true);
lastInProgressQuery.orderBy.set(ContouringWorkshopFields.updatedAt.name, "desc");

const clinicalCaseFields = buildClinicalCaseFields();
const contouringWorkshopFields = buildContouringWorkshopFields();

export default function DashboardPage() {

    const [loadingSubmitted, submittedEntities] = useSearchClinicalCases(lasSubmittedQuery);
    const [loadingDraft, draftEntities] = useSearchClinicalCases(lastDraftQuery);
    const [loadingInProgress, inProgressEntities] = useSearchContouringWorkshops(lastInProgressQuery);

    return <>
        <Title>Dashboard</Title>

        <PageLayout title="Dline dashboard" menuSelected="Dashboard">
            <CentralColumnLayout size="xl">

                <Paper>
                    <PaperTitle>
                        <em>Clinical cases submitted</em> &nbsp; ({submittedEntities.length})
                    </PaperTitle>

                    <LoadingWrapper loading={loadingSubmitted}>
                        <Table<ClinicalCaseItemResponse>
                            fields={clinicalCaseFields}
                            data={submittedEntities}
                        />
                    </LoadingWrapper>
                </Paper>

                <Paper>
                    <PaperTitle>
                        <em>Clinical cases draft</em> &nbsp; ({draftEntities.length})
                    </PaperTitle>

                    <LoadingWrapper loading={loadingDraft}>
                        <Table<ClinicalCaseItemResponse>
                            fields={clinicalCaseFields}
                            data={draftEntities}
                        />
                    </LoadingWrapper>
                </Paper>

                <Paper>
                    <PaperTitle>
                        <em>Contouring workshops in progress</em> &nbsp; ({inProgressEntities.length})
                    </PaperTitle>

                    <LoadingWrapper loading={loadingInProgress}>
                        <Table<ContouringWorkshopItemResponse>
                            fields={contouringWorkshopFields}
                            data={inProgressEntities}
                        />
                    </LoadingWrapper>
                </Paper>

            </CentralColumnLayout>
        </PageLayout>
    </>
}

function buildClinicalCaseFields() {
    const builder = new TableFieldsBuilder<ClinicalCaseItemResponse>();

    builder
        .initialize(ClinicalCaseFields)
        .hidden(ClinicalCaseFields.id)
        .hidden(ClinicalCaseFields.summary)
        .hidden(ClinicalCaseFields.createdAt)
        .hidden(ClinicalCaseFields.updatedAt)
        .hidden(ClinicalCaseFields.submittedAt)
        .hidden(ClinicalCaseFields.state)
        .hidden(ClinicalCaseFields.isArchived)

        .set(ClinicalCaseFields.anatomicZoneName, {
            position: 0,
            label: "anatomic zone",
        })
        .set(ClinicalCaseFields.name, {
            position: 1,
            scope: "row",
        })
    builder.addLinkEdit((entity) => {
        return fullPath("EditClinicalCase", {
            params: {
                id: entity.id as string
            }
        });
    });
    ;

    return builder.build();
}


function buildContouringWorkshopFields() {
    const builder = new TableFieldsBuilder<ContouringWorkshopItemResponse>();

    builder
        .initialize(ContouringWorkshopFields)
        .hidden(ContouringWorkshopFields.id)
        .hidden(ContouringWorkshopFields.summary)
        .hidden(ContouringWorkshopFields.startAt)
        .hidden(ContouringWorkshopFields.endAt)
        .hidden(ContouringWorkshopFields.state)
        .hidden(ContouringWorkshopFields.createdAt)
        .hidden(ContouringWorkshopFields.updatedAt)
        .hidden(ContouringWorkshopFields.isPublished)
        .hidden(ContouringWorkshopFields.isArchived)

        .set(ContouringWorkshopFields.anatomicZoneName, {
            position: 0,
            label: "anatomic zone",
        })
        .set(ContouringWorkshopFields.name, {
            position: 1,
            scope: "row",
        })
    builder.addLinkEdit((entity) => {
        return fullPath("EditContouringWorkshop", {
            params: {
                id: entity.id as string
            }
        });
    });
    ;

    return builder.build();
}