import React from "react";

import { LoadingWrapper } from "cocoreact";

import { Guid, IsEmptyGuid } from "domain/static/Guid";
import { UserResponse } from "domain/admin/response/UserResponse";
import { CreateUserCommand } from "domain/admin/command/CreateUserCommand";
import { UpdateUserCommand } from "domain/admin/command/UpdateUserCommand";
import { Form, FormFieldsBuilder } from "components/Form";
import { SubmitButton } from "components/Buttons";
import { Flex } from "components/Page";

export interface UserFormProps {
    loading: boolean;
    entity: UserResponse;
    onAfterSave?: (id?: Guid) => void;
}

export default function UserForm({ loading, entity, onAfterSave }: UserFormProps) {

    const fields = React.useMemo(() => buildFields(), []);

    const buildMessageHandle = React.useCallback((data: UserResponse) => {
        return IsEmptyGuid(entity.id)
            ? new CreateUserCommand({ ...data })
            : new UpdateUserCommand({ ...data, id: entity.id })
    }, [entity.id]);

    const onSuccessHandle = React.useCallback((response: any) => {
        if (onAfterSave) {
            onAfterSave(response?.id ?? undefined);
        }
    }, [onAfterSave]);

    return (
        <LoadingWrapper loading={loading}>
            <Form<UserResponse>
                initial={entity}
                fields={fields}
                buildMessage={buildMessageHandle}
                onSuccess={onSuccessHandle}
            >
                {
                    ({ loading: formLoading }) => (
                        <Flex justifyContent="flex-end" marginTop={2}>
                            <SubmitButton loading={formLoading || loading} />
                        </Flex>
                    )
                }
            </Form>
        </LoadingWrapper>
    );
}

function buildFields() {
    const builder = new FormFieldsBuilder<UserResponse>();
    const fields = UserResponse.Fields;

    builder
        .initialize(fields)
        .hidden(fields.id)
        .hidden(fields.locked)
        .hidden(fields.isArchived)
        .set(fields.email, {
            position: 0,
        })
        .set(fields.firstname, {
            position: 1,
        })
        .set(fields.lastname, {
            position: 2,
        })
        .set(fields.role, {
            position: 3,
            required: false,
        })
        .set(fields.status, {
            position: 4,
            placeholder: "Onco radiothérapeute chez PBNA",
            required: false,
        })
        .set(fields.abstract, {
            position: 5,
            type: "richtext",
            placeholder: "",
            required: false,
        })
        .set(fields.professionalLink, {
            position: 5,
            placeholder: "https://www.linkedin.com/in/XXXXX/",
            required: false,
        })
        .set(fields.scientificLink, {
            position: 6,
            placeholder: "https://www.researchgate.net/profile/XXXXX",
            required: false,
        })
        ;

    return builder.build();
}
