import { useEntityMessage } from "tools/Message";
import { EmptyGuid, Guid } from "domain/static/Guid";
import { GetClinicalDataSceneQuery } from "domain/admin/query/GetClinicalDataSceneQuery";
import { ClinicalDataSceneResponse } from "domain/admin/response/ClinicalDataSceneResponse";

export function emptyClinicalDataSceneResponse() {
  let entity = new ClinicalDataSceneResponse();
  entity.id = EmptyGuid();
  entity.globalContentLength = 0;
  entity.images = [];
  entity.contours = [];
  return entity;
}

export function GetClinicalDataSceneQueryBuilder(id: Guid) {
  return new GetClinicalDataSceneQuery({ id });
}

export default function useGetClinicalDataScene(id: Guid) {
  return useEntityMessage(
    id,
    GetClinicalDataSceneQueryBuilder,
    emptyClinicalDataSceneResponse
  );
}
