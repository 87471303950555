import React from "react";

import { LoadingWrapper } from "cocoreact";

import { Guid, IsEmptyGuid } from "domain/static/Guid";
import { SelectItemType } from "domain/static/SelectItemType";
import { FileParentType } from "domain/static/FileParentType";
import { CreateContouringWorkshopCommand } from "domain/admin/command/CreateContouringWorkshopCommand";
import { UpdateContouringWorkshopCommand } from "domain/admin/command/UpdateContouringWorkshopCommand";
import { ContouringWorkshopResponse } from "domain/admin/response/ContouringWorkshopResponse";
import { Form, FormFieldsBuilder } from "components/Form";
import { SubmitButton } from "components/Buttons";
import { Flex } from "components/Page";

export interface ContouringWorkshopFormProps {
    loading: boolean;
    entity: ContouringWorkshopResponse;
    onAfterSave?: (id?: Guid) => void;
}

export default function ContouringWorkshopForm({ loading, entity, onAfterSave }: ContouringWorkshopFormProps) {

    const fields = React.useMemo(() => buildFields(entity), [entity]);

    const buildMessageHandle = React.useCallback((data: ContouringWorkshopResponse) => {
        return IsEmptyGuid(entity.id)
            ? new CreateContouringWorkshopCommand(data)
            : new UpdateContouringWorkshopCommand({ ...data, id: entity.id })
    }, [entity.id]);

    const onSuccessHandle = React.useCallback((response: any) => {
        if (onAfterSave) {
            onAfterSave(response?.id ?? undefined);
        }
    }, [onAfterSave]);

    return (
        <LoadingWrapper loading={loading}>
            <Form<ContouringWorkshopResponse>
                initial={entity}
                fields={fields}
                buildMessage={buildMessageHandle}
                onSuccess={onSuccessHandle}
            >
                {
                    ({ loading: formLoading }) => (
                        <Flex marginTop={2} justifyContent="flex-end">
                            <SubmitButton loading={formLoading || loading} />
                        </Flex>
                    )
                }
            </Form>
        </LoadingWrapper>
    );
}

function buildFields(entity: ContouringWorkshopResponse) {
    const builder = new FormFieldsBuilder<ContouringWorkshopResponse>();
    const fields = ContouringWorkshopResponse.Fields;

    const isCreating = IsEmptyGuid(entity.id);

    builder
        .initialize(fields)
        .hidden(fields.id)
        .hidden(fields.isArchived)
        .hidden(fields.anatomicZoneName)
        .hidden(fields.isPublished)
        .hidden(fields.publishedAt)
        .hidden(fields.author)
        .hidden(fields.state)

        .set(fields.coverId, {
            position: 0,
            type: "image",
            parentId: isCreating ? undefined : entity.id,
            parentType: isCreating ? undefined : FileParentType.ContouringWorkshop,
            accept: "image/jpeg, image/jpg, image/png",
        })
        .attachFieldToSlug(fields.name, fields.slug)
        .set(fields.name, {
            position: 1,
        })
        .set(fields.slug, {
            position: 2,
        })
        .set(fields.anatomicZoneId, {
            position: 3,
            label: "anatomic zone",
            type: "SelectItem",
            selectItemType: SelectItemType.AnatomicZone,
            defaultValue: (
                !isCreating
                    ? { label: entity.anatomicZoneName, value: entity.anatomicZoneId }
                    : undefined
            )
        })
        .set(fields.startAt, {
            position: 4,
        })
        .set(fields.endAt, {
            position: 5,
        })
        .set(fields.endAt, {
            position: 5,
        })
        .set(fields.limitOfParticipants, {
            position: 6,
            label: "limit of participants",
        })
        .set(fields.summary, {
            position: 7,
            type: "richtext",
        })
        ;

    return builder.build();
}
