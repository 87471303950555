import React from "react";
import {
    createStyles, Dialog, DialogContent,
    DialogTitle, makeStyles, Theme
} from "@material-ui/core";

import ContactForm, { ContactFormProps } from "./ContactForm";
import { EmailIcon } from "App/Theme";

const useStyles = makeStyles((theme: Theme) => createStyles({
    title: {
        "& > *": {
            display: "flex",
            alignItems: "center",
        },
        "& > * > svg": {
            marginRight: theme.spacing(1),
        }
    },
    content: {
        marginBottom: theme.spacing(2),
    }
}));

export interface ContactDialogProps extends ContactFormProps {
    open: boolean;
    onClose: () => void;
    title: string;
}

export default function ContactDialog({
    open, onClose, title, ...formProps
}: ContactDialogProps) {
    const classes = useStyles();

    return <Dialog open={open} onClose={onClose}>
        <DialogTitle className={classes.title}>
            <EmailIcon />{title}
        </DialogTitle>
        <DialogContent className={classes.content}>
            <ContactForm {...formProps} />
        </DialogContent>
    </Dialog>
}
