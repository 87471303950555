import { App } from "dline-viewer/dist/app";
import { SceneSliceIndexEntity } from "dline-viewer/dist/app/Entity/Scene/SceneSliceIndexEntity";
import { SceneAxisIndexEntity } from "dline-viewer/dist/app/Entity/Scene/SceneAxisIndexEntity";
import { SceneCameraEntity } from "dline-viewer/dist/app/Entity/Scene/SceneCameraEntity";
import { SceneIndexEntity } from "dline-viewer/dist/app/Entity/Scene/SceneIndexEntity";
import { SceneEntity as _SceneEntity } from "dline-viewer/dist/app/Entity/Scene/SceneEntity";
import { SceneSliceMaxEntity } from "dline-viewer/dist/app/Entity/Scene/SceneSliceMaxEntity";
import { SceneFusionModeEntity } from "dline-viewer/dist/app/Entity/Fusion/SceneFusionModeEntity";
import { ThreeRendererEntity } from "dline-viewer/dist/app/Entity/Three/ThreeRendererEntity";
import { AppCameraHandler } from "dline-viewer/dist/app/Handlers";

import { OrientationType } from "domain/static/OrientationType";
import { FusionType } from "domain/static/FusionType";

export type SceneIndexCallback = (
  orientation: OrientationType,
  idx: number
) => void;

export class SceneEntity extends _SceneEntity {
  onIndexChange?: SceneIndexCallback;
}

export interface SceneProps {
  sliceIndex: number;
  cx: number;
  cy: number;
  width: number;
}

export default class SceneHelper {
  private _app: App;
  private _container: HTMLDivElement;
  private _orientation: OrientationType;
  private _onIndexChange: SceneIndexCallback | undefined;
  private _entities: {
    scene: SceneEntity;
    threeRenderer: ThreeRendererEntity;
    sliceIndex: SceneSliceIndexEntity;
    sliceMax: SceneSliceMaxEntity;
    axisIndex: SceneAxisIndexEntity;
    camera: SceneCameraEntity;
    fusionMode: SceneFusionModeEntity;
  };

  constructor(
    app: App,
    orientation: OrientationType,
    container: HTMLDivElement,
    onIndexChange?: SceneIndexCallback
  ) {
    this._app = app;
    this._orientation = orientation;
    this._container = container;
    this._onIndexChange = onIndexChange;
    this._entities = {} as any;
  }

  clear() {
    const { sceneInteractors } = this._entities.threeRenderer;
    for (const sceneInteractor of sceneInteractors) {
      sceneInteractor.clear();
    }
  }

  async initialize() {
    const sceneId = this._app.db.NewId();
    await this._initializeScene(sceneId);
    this._initializeEntities(sceneId);

    const { threeRenderer } = this._entities;

    if (threeRenderer) {
      // scene background color
      threeRenderer.renderer.setClearColor(0x191919, 0);
    }

    await this.autoCenterZoomCamera();
  }

  private async _initializeScene(sceneId: string) {
    const scene = new SceneEntity();
    scene.id = sceneId;
    scene.container = this._container;
    scene.onIndexChange = this._onIndexChange;

    const axisIndex = new SceneAxisIndexEntity();
    axisIndex.idScene = sceneId;
    axisIndex.axisIndex = this._orientation;

    const allScenes = this._app.db.GetAll(SceneEntity);
    const sceneIndexEntity = new SceneIndexEntity();
    sceneIndexEntity.idScene = sceneId;
    sceneIndexEntity.sceneIndex = allScenes.length;

    await this._app.derivation.DoAsync((transaction) => {
      transaction.Insert(scene as SceneEntity);
      transaction.Insert(axisIndex);
      transaction.Insert(sceneIndexEntity);
    });
  }

  private _initializeEntities(sceneId: string) {
    const scene = this._app.db.First(SceneEntity, (x) => x.id === sceneId);
    const threeRenderer = this._app.db.First(
      ThreeRendererEntity,
      (x) => x.idScene === sceneId
    );
    const sliceIndex = this._app.db.First(
      SceneSliceIndexEntity,
      (x) => x.idScene === sceneId
    );
    const sliceMax = this._app.db.First(
      SceneSliceMaxEntity,
      (x) => x.idScene === sceneId
    );
    const axisIndex = this._app.db.First(
      SceneAxisIndexEntity,
      (x) => x.idScene === sceneId
    );
    const camera = this._app.db.First(
      SceneCameraEntity,
      (x) => x.idScene === sceneId
    );
    const fusionMode = this._app.db.First(
      SceneFusionModeEntity,
      (x) => x.idScene === sceneId
    );

    this._entities = {
      scene,
      threeRenderer,
      sliceIndex,
      sliceMax,
      axisIndex,
      camera,
      fusionMode,
    };
  }

  isOrientation(orientation: OrientationType) {
    return this._orientation === orientation;
  }

  getOrientation() {
    return this._orientation;
  }

  async setFusionType(fusionType: FusionType) {
    const { fusionMode } = this._entities;
    if (!fusionMode) return;

    fusionMode.fusionMode = fusionType;
    await this._app.derivation.UpdateAsync(fusionMode);
  }

  async setSliceIndex(index: number) {
    const { sliceIndex, sliceMax } = this._entities;

    if (index < 0 || index > sliceMax.sliceMax) return;

    sliceIndex.sliceIndex = index;
    await this._app.derivation.UpdateAsync(sliceIndex);
  }

  getSliceIndex() {
    return this._entities.sliceIndex.sliceIndex;
  }

  async setOrientation(orientation: OrientationType) {
    const { axisIndex } = this._entities;

    axisIndex.axisIndex = orientation;

    await this._app.derivation.UpdateAsync(axisIndex);
    this._orientation = orientation;

    await this.autoCenterZoomCamera();
  }

  async setProps(props: SceneProps) {
    const { camera, sliceIndex } = this._entities;

    camera.camera.cx = props.cx;
    camera.camera.cy = props.cy;
    camera.camera.width = props.width;

    sliceIndex.sliceIndex = props.sliceIndex;

    await this._app.derivation.UpdateAsync(camera, sliceIndex);
  }

  async getProps() {
    const { camera, sliceIndex } = this._entities;

    return {
      cx: camera.camera.cx,
      cy: camera.camera.cy,
      width: camera.camera.width,
      sliceIndex: sliceIndex.sliceIndex,
    } as SceneProps;
  }

  async autoCenterZoomCamera() {
    const { scene } = this._entities;

    await new AppCameraHandler(this._app).SetCameraToEntirelySeeAsync(scene.id);
  }
}
