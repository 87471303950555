import { Guid } from "domain/static/Guid";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class GetClinicalDataSceneQuery implements IMessage {
    id!: Guid;

    public constructor(init?: Partial<GetClinicalDataSceneQuery>) {
        Object.assign(this, init);
    }

    getMethod = () => "GET" as RequestMethod;

    getPath = () => `/admin/clinical-data/${this.id.toString()}/scene`;

    getQueryString = () => undefined;

    getBody = () => undefined;

    needAuthentication = () => true;
}
