import { Guid } from "domain/static/Guid";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class UpdateClinicalContourCommand implements IMessage {
    id!: Guid;
    name!: string;
    color!: string;
    position!: number;
    fileId!: Guid | null;
    guidelineId!: Guid | null;
    linkArticleToClinicalCase!: boolean;
    isEditable!: boolean;

    public constructor(init?: Partial<UpdateClinicalContourCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "PUT" as RequestMethod;

    getPath = () => `/admin/clinical-contours/${this.id.toString()}`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            name: this.name,
            color: this.color,
            position: this.position,
            fileId: this.fileId,
            guidelineId: this.guidelineId,
            linkArticleToClinicalCase: this.linkArticleToClinicalCase,
            isEditable: this.isEditable,
        };
    }
    
    needAuthentication = () => true;
}
