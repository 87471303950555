import React, { useRef, useCallback } from "react";
import { Link } from "react-router-dom";

import { Button } from "@material-ui/core";

import { CentralColumnLayout, PageLayout } from "components/Layouts";
import ArticleTable from "./ArticleTable";
import { fullPath } from "tools/Route";
import { AddIcon, ArticleIcon } from "App/Theme";
import { Flex, Paper, PaperTitle, Spacer, Title } from "components/Page";
import { ResetFiltersButton } from "components/Buttons";
import BiblioLoaderButton from "./BiblioLoaderButton";

export default function ListArticlesPage() {

    const updateTableRef = useRef<HTMLButtonElement>(null);
    const resetRef = useRef<HTMLButtonElement>(null);

    const resetQueryHandle = useCallback(() => {
        resetRef.current?.click();
    }, []);

    const updateQueryHandle = useCallback(() => {
        updateTableRef.current?.click();
    }, []);

    const title = "List of articles";

    return <>
        <Title>{title}</Title>

        <PageLayout title="Articles" menuSelected="ListArticles">
            <CentralColumnLayout size="xl">

                <Flex>
                    <ResetFiltersButton onClick={resetQueryHandle} />
                    <Spacer />
                    <Link to={fullPath("NewArticle")}>
                        <Button
                            color="secondary"
                            variant="contained"
                            startIcon={<AddIcon />}
                        >
                            new article
                        </Button>
                    </Link>
                    <BiblioLoaderButton onSuccess={updateQueryHandle} />
                </Flex>

                <Paper>
                    <PaperTitle icon={<ArticleIcon />}>{title}</PaperTitle>

                    <ArticleTable
                        editable={true}
                        resetRef={resetRef}
                        updateRef={updateTableRef}
                    />
                </Paper>

            </CentralColumnLayout>
        </PageLayout>
    </>
}
