import { Guid } from "domain/static/Guid";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class SetPublishingContouringWorkshopCommand implements IMessage {
    id!: Guid;
    isPublished!: boolean;

    public constructor(init?: Partial<SetPublishingContouringWorkshopCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "PATCH" as RequestMethod;

    getPath = () => `/admin/contouring-workshops/${this.id.toString()}/publish`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            isPublished: this.isPublished,
        };
    }
    
    needAuthentication = () => true;
}
