import { Guid } from "domain/static/Guid";
import { VolumeType } from "domain/static/VolumeType";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class UpdateGuidelineCommand implements IMessage {
    id!: Guid;
    articleId!: Guid;
    anatomicStructureId!: Guid;
    volumeType!: VolumeType;
    targetTypeId!: Guid | null;
    organizationId!: Guid | null;
    content!: string;

    public constructor(init?: Partial<UpdateGuidelineCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "PUT" as RequestMethod;

    getPath = () => `/admin/guidelines/${this.id.toString()}`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            articleId: this.articleId,
            anatomicStructureId: this.anatomicStructureId,
            volumeType: this.volumeType,
            targetTypeId: this.targetTypeId,
            organizationId: this.organizationId,
            content: this.content,
        };
    }
    
    needAuthentication = () => true;
}
