import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class SearchGuidelineOptionsQuery implements IMessage {
    keywords!: string;
    top!: number;

    public constructor(init?: Partial<SearchGuidelineOptionsQuery>) {
        Object.assign(this, init);
    }

    getMethod = () => "GET" as RequestMethod;

    getPath = () => `/admin/guidelines/options`;

    getQueryString = () => {
        return {
            keywords: this.keywords,
            top: this.top,
        }
    }
    

    getBody = () => undefined;

    needAuthentication = () => true;
}
