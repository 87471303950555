import React from "react";
import clsx from "clsx";
import { ITableWidgetPropsBase } from "cocoreact";
import { createStyles, alpha, makeStyles, Theme, Typography, Box } from "@material-ui/core";

import { UserRole, UserRoleOptions } from "domain/static/UserRole";
import { enumHasFlag } from "tools/EnumExtension";

const useStyles = makeStyles((theme: Theme) => createStyles({
    wrapper: {
        display: "flex",
        maxWidth: 200,
        flexWrap: "wrap",
        "& > *": {
            margin: theme.spacing(0.5),
        },
    },
    root: {
        border: "1px black solid",
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.divider,
        padding: theme.spacing(0.5, 1),
    },
    Standard: {
        color: theme.palette.text.secondary,
        borderColor: theme.palette.text.secondary,
        backgroundColor: alpha(theme.palette.text.secondary, 0.1),
    },
    Admin: {
        color: theme.palette.warning.dark,
        borderColor: theme.palette.warning.dark,
        backgroundColor: alpha(theme.palette.warning.dark, 0.1),
    },
    SuperAdmin: {
        color: theme.palette.error.dark,
        borderColor: theme.palette.error.dark,
        backgroundColor: alpha(theme.palette.error.dark, 0.1),
    },
}));

export interface UserRoleTableWidgetProps extends ITableWidgetPropsBase<UserRole> { }

export default function UserRoleTableWidget({ value }: UserRoleTableWidgetProps) {
    const classes = useStyles();

    const roles = [] as string[];
    UserRoleOptions.forEach(x => {
        if (x.value === UserRole.None) {
            if (value === x.value) {
                roles.push(x.label);
                return;
            }
        }
        else if (enumHasFlag(value, x.value)) {
            roles.push(x.label);
        }
    });

    return <Box className={classes.wrapper}>
        {roles.map(x =>
            <Typography
                key={x}
                variant="caption"
                className={clsx(classes.root, (classes as any)[x])}
            >
                {x}
            </Typography>
        )}
    </Box>
}
