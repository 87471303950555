import React from "react";
import { Link } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import {
    ITableField,
    ITableWidgetPropsBase,
    SwitchTableWidgetProps,
    DateTableWidgetProps,
    ButtonTableWidgetProps,
    TableFieldOptionsBuilder,
} from "cocoreact";
import { capitalize } from "tools/StringExtension";
import { EditIcon } from "App/Theme";
import { format } from "tools/DateExtension";
import { EnumOptionsTableWidgetProps } from "./widgets/EnumOptionsTableWidget";

export interface ITableFieldOptions<T> extends ITableField<T> {
    scope?: ITableWidgetPropsBase<T>["scope"];
    style?: ITableWidgetPropsBase<T>["style"];

    labelOn?: SwitchTableWidgetProps["labelOn"];
    labelOff?: SwitchTableWidgetProps["labelOff"];
    format?: DateTableWidgetProps["format"];
    formatter?: DateTableWidgetProps["formatter"];
    title?: ButtonTableWidgetProps<T>["title"];
    href?: ButtonTableWidgetProps<T>["href"];
    onClick?: ButtonTableWidgetProps<T>["onClick"];
    color?: ButtonTableWidgetProps<T>["color"];
    variant?: ButtonTableWidgetProps<T>["variant"];
    size?: ButtonTableWidgetProps<T>["size"];

    options?: EnumOptionsTableWidgetProps["options"];
}

export default class TableFieldsBuilder<
    T
    > extends TableFieldOptionsBuilder<T, ITableFieldOptions<T>> {

    constructor() {
        super((field) => {
            let label = field.label;
            if (label !== "") {
                label = label ? capitalize(label) : capitalize(field.name);
            }
            let align = field.align;
            if (align === undefined) {
                align = field.type === "number" ? "right" : "left";
            }
            let padding = field.padding;
            if (padding === undefined) {
                padding = (field.type === "button" || field.type === "boolean") ? "none" : "normal";
            }
            let color = field.color;
            let variant = field.variant;
            if (field.type === "button") {
                color = color ? color : "default";
                variant = variant ? variant : "contained";
            }
            let formatter = field.formatter;
            if (field.type === "Date") {
                formatter = format;
            }
            return {
                ...field,
                label,
                padding,
                align,
                color,
                variant,
                formatter,
            } as ITableFieldOptions<T>;
        });
    }

    addLinkEdit(href: string | ((data: T) => string)) {
        this.custom({
            padding: "none",
            render: ({ data }) => {
                const path = typeof href === "string" ? href : href(data);
                return (
                    <Link to={path}>
                        <IconButton color="secondary" size="small">
                            <EditIcon />
                        </IconButton>
                    </Link>
                );
            }
        });
        return this;
    }

    addActionEdit(onClick: ((data: T) => void)) {
        this.custom({
            padding: "none",
            render: ({ data }) => {
                return (
                    <IconButton color="secondary" size="small" onClick={() => onClick(data)}>
                        <EditIcon />
                    </IconButton>
                );
            }
        });
        return this;
    }
}