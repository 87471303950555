import React from "react";
import { useParams, Link } from "react-router-dom";

import { Button } from "@material-ui/core";

import { Guid } from "domain/static/Guid";
import { Flex, Paper, PaperTitle } from "components/Page";
import { AddIcon, ContouringWorkshopIcon } from "App/Theme";
import { fullPath } from "tools/Route";
import { ClinicalImageTable } from "components/ClinicalData";
import { CentralColumnLayout } from "components/Layouts";

export default function ListClinicalImagesPanel() {

    const { id } = useParams<{ id: Guid }>();

    return <CentralColumnLayout size={"lg"}>

        <Flex justifyContent="flex-end">
            <Link to={fullPath("EditContouringWorkshopNewImage", { params: { id } })}>
                <Button
                    color="secondary"
                    variant="contained"
                    startIcon={<AddIcon />}
                >
                    new image
                </Button>
            </Link>
        </Flex>

        <Paper>
            <PaperTitle icon={<ContouringWorkshopIcon />}>
                List of contouring workshop images
            </PaperTitle>

            <ClinicalImageTable
                id={id}
                editable={true}
                editRouteName={"EditContouringWorkshopEditImage"}
            />
        </Paper>

    </CentralColumnLayout>
}
