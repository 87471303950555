import { useState, useEffect } from "react";
import { Guid, IsValidGuid } from "domain/static/Guid";
import { GetFileQuery } from "domain/admin/query/GetFileQuery";

// settings file
declare var APP_SERVER_PATH: string;

export default function useFileQueryPath(id: Guid) {
  const [path, setPath] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (IsValidGuid(id)) {
      const query = new GetFileQuery({ id });
      const imgPath = APP_SERVER_PATH + query.getPath();
      setPath(imgPath);
    } else {
      setPath(undefined);
    }
  }, [id]);

  return path;
}
