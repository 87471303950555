import { Guid } from "domain/static/Guid";
import { VolumeType } from "domain/static/VolumeType";
import { IField } from "cocoreact";

//Generated code, do not modify

export class ContouringWorkshopResultsResponse {
    id!: Guid;
    limitOfParticipants!: number;
    contours!: Contour[];
    numberOfParticipant!: number;
    participants!: User[];

    public static Fields = {
        id: { name: "id", type: "Guid" },
        limitOfParticipants: { name: "limitOfParticipants", type: "number" },
        contours: { name: "contours", type: "Contour[]" },
        numberOfParticipant: { name: "numberOfParticipant", type: "number" },
        participants: { name: "participants", type: "User[]" },
    } as Record<keyof ContouringWorkshopResultsResponse, IField>;
}

export class Contour {
    numberOfParticipantResult!: number;
    id!: Guid;
    name!: string;
    color!: string;
    isEditable!: boolean;
    anatomicStructureName!: string;
    volumeType!: VolumeType;
    targetTypeName!: string;
    organizationName!: string;
    fileId!: Guid;
    stackFileId!: Guid | null;
    participantResults!: ParticipantResult[];

    public static Fields = {
        numberOfParticipantResult: { name: "numberOfParticipantResult", type: "number" },
        id: { name: "id", type: "Guid" },
        name: { name: "name", type: "string" },
        color: { name: "color", type: "string" },
        isEditable: { name: "isEditable", type: "boolean" },
        anatomicStructureName: { name: "anatomicStructureName", type: "string" },
        volumeType: { name: "volumeType", type: "VolumeType" },
        targetTypeName: { name: "targetTypeName", type: "string" },
        organizationName: { name: "organizationName", type: "string" },
        fileId: { name: "fileId", type: "Guid" },
        stackFileId: { name: "stackFileId", type: "Guid" },
        participantResults: { name: "participantResults", type: "ParticipantResult[]" },
    } as Record<keyof Contour, IField>;
}

export class ParticipantResult {
    participantId!: Guid;
    dice!: number;
    fileId!: Guid;

    public static Fields = {
        participantId: { name: "participantId", type: "Guid" },
        dice: { name: "dice", type: "number" },
        fileId: { name: "fileId", type: "Guid" },
    } as Record<keyof ParticipantResult, IField>;
}

export class User {
    id!: Guid;
    firstname!: string;
    lastname!: string;
    email!: string;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        firstname: { name: "firstname", type: "string" },
        lastname: { name: "lastname", type: "string" },
        email: { name: "email", type: "string" },
    } as Record<keyof User, IField>;
}
