import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useNotificationContext } from "cocoreact";

import { Typography } from "@material-ui/core";

import { Guid } from "domain/static/Guid";
import ContouringWorkshopForm from "../ContouringWorkshopForm";
import { Flex, Paper, PaperTitle, Spacer } from "components/Page";
import useGetContouringWorkshop from "domain/hooks/useGetContouringWorkshop";
import { ArchiveButton } from "components/Buttons";
import { SetArchivingStateContouringWorkshopCommand } from "domain/admin/command/SetArchivingStateContouringWorkshopCommand";
import { sendMessage } from "tools/Message";
import { ArticleIcon, ContouringWorkshopIcon } from "App/Theme";
import { ArticlesManager } from "components/ClinicalData";
import StateManager from "./StateManager";
import { CentralColumnLayout } from "components/Layouts";

export default function DetailsPanel() {

    const { success } = useNotificationContext();

    const params = useParams<{ id: Guid }>();
    const [loadingEntity, entity, updateEntity] = useGetContouringWorkshop(params.id);
    const [actionsDisabled, setActionsDisabled] = useState(false);

    const onAfterSaveHandle = useCallback(() => {
        updateEntity();
        success("Contouring workshop saved with success");
    }, [success, updateEntity]);

    const onArchiveHandle = React.useCallback(async () => {
        setActionsDisabled(true);
        const message = new SetArchivingStateContouringWorkshopCommand({
            id: entity.id,
            isArchived: !entity.isArchived
        });
        await sendMessage(message);
        updateEntity();
        setActionsDisabled(false);
    }, [entity.id, entity.isArchived, updateEntity]);

    return <CentralColumnLayout size={"md"}>

        <Paper>
            <PaperTitle icon={<ContouringWorkshopIcon />}>
                Edit contouring workshop
            </PaperTitle>

            <ContouringWorkshopForm
                loading={loadingEntity}
                entity={entity}
                onAfterSave={onAfterSaveHandle}
            />
        </Paper>

        <Paper>
            <PaperTitle icon={<ArticleIcon />}>Articles</PaperTitle>

            <ArticlesManager id={params.id} />
        </Paper>

        <Paper>
            <StateManager
                loading={loadingEntity}
                entity={entity}
                onAfterUpdate={updateEntity}
            />
        </Paper>

        <Paper>
            <Flex alignItems="center">
                {entity.author &&
                    <Typography color="textSecondary">
                        Author : {entity.author.firstname} {entity.author.lastname}
                    </Typography>
                }
                <Spacer />
                <ArchiveButton
                    isArchived={entity.isArchived}
                    disabled={loadingEntity || actionsDisabled}
                    onClick={onArchiveHandle}
                />
            </Flex>
        </Paper>

    </CentralColumnLayout>
}
