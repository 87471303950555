import React, { useCallback, useMemo } from "react";

import { Guid } from "domain/static/Guid";
import { SelectItemType, SelectItemTypeOptions } from "domain/static/SelectItemType";
import { FormDialog, FormFieldsBuilder } from "components/Form";
import { SelectItemResponse } from "domain/admin/response/SelectItemResponse";
import { CreateSelectItemCommand } from "domain/admin/command/CreateSelectItemCommand";
import { emptySelectItemBuilder } from "domain/hooks/useGetSelectItem";

export interface CreateSelectItemFormDialogProps {
    open: boolean;
    name: string;
    type: SelectItemType;
    onAfterSave?: (id: Guid) => void;
    onClose?: () => void;
}

export default function CreateSelectItemFormDialog({
    open, name, type, onAfterSave, onClose
}: CreateSelectItemFormDialogProps) {

    const initialValue = useMemo(
        () => emptySelectItemBuilder({ name, type }),
        [name, type]
    );
    const fields = useMemo(buildFields, []);

    const buildMessageHandle = useCallback(
        (data: SelectItemResponse) => new CreateSelectItemCommand({ ...data }),
        []
    );

    const onSuccessHandle = useCallback((response: any) => {
        if (!onAfterSave) return;
        if (response && response.id) onAfterSave(response.id);
    }, [onAfterSave]);

    return (
        <FormDialog<SelectItemResponse>
            title="Create a select item"
            fullWidth={true}
            maxWidth="xs"
            open={open}
            onClose={onClose}
            initial={initialValue}
            fields={fields}
            buildMessage={buildMessageHandle}
            onSuccess={onSuccessHandle}
        />
    );
}

function buildFields() {
    const builder = new FormFieldsBuilder<SelectItemResponse>();
    const fields = SelectItemResponse.Fields;

    builder
        .initialize(fields)
        .hidden(fields.id)
        .hidden(fields.isArchived)
        .set(fields.name, {
            position: 0,
        })
        .set(fields.type, {
            position: 1,
            type: "select",
            options: SelectItemTypeOptions,
            disabled: true,
        });

    return builder.build();
}
