import { Guid } from "domain/static/Guid";
import { UserRole } from "domain/static/UserRole";
import { IField } from "cocoreact";

//Generated code, do not modify

export class ODataUserItemResponse {
    results!: UserItemResponse[];
    count!: number;

    public static Fields = {
        results: { name: "results", type: "UserItemResponse[]" },
        count: { name: "count", type: "number" },
    } as Record<keyof ODataUserItemResponse, IField>;
}

export class UserItemResponse {
    id!: Guid;
    isArchived!: boolean;
    firstname!: string;
    lastname!: string;
    email!: string;
    role!: UserRole;
    updatedAt!: Date;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        isArchived: { name: "isArchived", type: "boolean" },
        firstname: { name: "firstname", type: "string" },
        lastname: { name: "lastname", type: "string" },
        email: { name: "email", type: "string" },
        role: { name: "role", type: "UserRole" },
        updatedAt: { name: "updatedAt", type: "Date" },
    } as Record<keyof UserItemResponse, IField>;
}
