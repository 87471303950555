import React, { useCallback, useRef, useState } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from "@material-ui/core";
import { SubmitButton } from "components/Buttons";
import Form, { FormProps } from "./Form";
import { Flex } from "components/Page";

export interface FormDialogProps<T, R = any> extends FormProps<T, R> {
    title: React.ReactNode;
    open: DialogProps["open"];
    onClose?: () => void;
    fullWidth?: DialogProps["fullWidth"];
    maxWidth?: DialogProps["maxWidth"];
    fullScreen?: DialogProps["fullScreen"];
}

export default function FormDialog<T, R = any>({
    title, 
    open, onClose, fullWidth, maxWidth, fullScreen,
    buildMessage, onComplete, ...formProps
}: FormDialogProps<T, R>) {
    const submitRef = useRef<HTMLButtonElement>(null);
    const [loading, setLoading] = useState(false);

    const buildMessageHandle = useCallback(async (data: T) => {
        setLoading(true);
        return buildMessage(data);
    }, [buildMessage]);

    const onCompleteHandle = useCallback(() => {
        setLoading(false);
        onComplete && onComplete();
    }, [onComplete]);

    return (
        <Dialog 
            open={open} 
            onClose={onClose} 
            fullWidth={fullWidth} 
            maxWidth={maxWidth}
            fullScreen={fullScreen}
        >
            <DialogTitle>{title}</DialogTitle>

            <DialogContent>
                <Form<T, R>
                    {...formProps}
                    buildMessage={buildMessageHandle}
                    onComplete={onCompleteHandle}
                >
                    {() => <SubmitButton ref={submitRef} visible={false} />}
                </Form>
            </DialogContent>

            <DialogActions>
                <Flex justifyContent="space-between" width="100%" padding={[1, 2]}>
                    <Button variant="outlined" onClick={onClose}>
                        cancel
                    </Button>
                    <SubmitButton loading={loading} onClick={() => submitRef.current?.click()} />
                </Flex>
            </DialogActions>
        </Dialog>
    );
}
