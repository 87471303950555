import React, { useCallback, useEffect, useMemo, useState } from "react";

import { FormDialog, FormFieldsBuilder } from "components/Form";
import { Guid, IsEmptyGuid, IsValidGuid } from "domain/static/Guid";
import { SelectItemType } from "domain/static/SelectItemType";
import { ArticleResponse } from "domain/admin/response/ArticleResponse";
import { CreateArticleCommand } from "domain/admin/command/CreateArticleCommand";
import { UpdateArticleCommand } from "domain/admin/command/UpdateArticleCommand";
import { emptyArticleBuilder, getArticleQueryBuilder } from "domain/hooks/useGetArticle";
import { sendMessage } from "tools/Message";

export interface ArticleFormDialogProps {
    open: boolean;
    id?: Guid;
    onAfterSave?: (id: Guid) => void;
    onClose?: () => void;
}

export default function ArticleFormDialog({
    open, id, onAfterSave, onClose
}: ArticleFormDialogProps) {

    const [entity, setEntity] = useState(emptyArticleBuilder());

    useEffect(() => {
        const _loadEntity = async (_id: Guid) => {
            const _entity = await sendMessage<ArticleResponse>(getArticleQueryBuilder(_id));
            setEntity(_entity);
        }

        if (id && IsValidGuid(id)) {
            _loadEntity(id);
        }
        else {
            const emptyEntity = emptyArticleBuilder();
            setEntity(emptyEntity)
        }
    }, [id])

    const fields = useMemo(buildFields, []);

    const buildMessageHandle = useCallback((data: ArticleResponse) => {
        return IsEmptyGuid(id)
            ? new CreateArticleCommand(data)
            : new UpdateArticleCommand({ ...data, id })
    }, [id]);

    const onSuccessHandle = useCallback((response: any) => {
        if (!onAfterSave) return;
        if (response && response.id) onAfterSave(response.id);
    }, [onAfterSave]);

    return (
        <FormDialog<ArticleResponse>
            title={`${id ? "Edit" : "Create"} an article`}
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={onClose}
            initial={entity}
            fields={fields}
            buildMessage={buildMessageHandle}
            onSuccess={onSuccessHandle}
        />
    );
}

function buildFields() {
    const builder = new FormFieldsBuilder<ArticleResponse>();
    const fields = ArticleResponse.Fields;

    builder
        .initialize(fields)
        .hidden(fields.id)
        .hidden(fields.createdAt)
        .hidden(fields.updatedAt)
        .hidden(fields.isArchived)
        .hidden(fields.anatomicZoneName)

        .set(fields.title, {
            position: 0,
            autoFocus: true,
            multiline: true,
        })
        .set(fields.anatomicZoneId, {
            position: 1,
            label: "anatomic zone",
            type: "SelectItem",
            selectItemType: SelectItemType.AnatomicZone,
            defaultValue: undefined,
        })
        .set(fields.authors, {
            position: 3,
            multiline: true,
        })
        .set(fields.editor, {
            position: 4,
            label: "Editor / review"
        })
        .set(fields.page, {
            position: 5,
            label: "pages"
        })
        .set(fields.date, {
            position: 6,
        })
        .set(fields.url, {
            position: 7,
            required: false,
        })
        .set(fields.doi, {
            position: 8,
            required: false,
            label: "DOI",
            placeholder: "ex : 10.1016/j.radonc.2003.09.011"
        })
        .set(fields.pmid, {
            position: 9,
            required: false,
            label: "PMID",
            placeholder: "ex : 14644481"
        })
        ;

    return builder.build();
}
