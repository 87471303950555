import React from "react";

import { TableOData, TableODataFieldsBuilder, BOOLEAN_SELECT_OPTIONS } from "components/Table";
import { buildSearchArticlesQuery } from "domain/hooks/useSearchArticles";
import { fullPath } from "tools/Route";
import { SelectItemType } from "domain/static/SelectItemType";
import { loadODataMessage, saveODataMessage } from "tools/sessionCache";
import { SearchArticlesQuery } from "domain/admin/query/SearchArticlesQuery";
import { IdNameResponse } from "domain/admin/response/IdNameResponse";
import { ArticleItemResponse } from "domain/admin/response/ODataArticleItemResponse";
import { IdNameToTableOptions } from "tools/IdNameOptions";
import { CallbackButton } from "components/Buttons";
import useListSelectItems from "domain/hooks/useListSelectItems";

export interface ArticleTableProps {
    editable?: boolean;
    resetRef?: React.RefObject<HTMLButtonElement>;
    updateRef?: React.RefObject<HTMLButtonElement>;
}

export default function ArticleTable({ resetRef, updateRef, editable }: ArticleTableProps) {

    const [, anatomicZones] = useListSelectItems(SelectItemType.AnatomicZone);
    const fields = React.useMemo(() => buildFields(anatomicZones, editable), [anatomicZones, editable]);

    const query = React.useMemo(() => {
        const query = buildSearchArticlesQuery();
        query.top = 10;
        resetQuery(query);
        loadODataMessage("ArticleTable", query);
        return query;
    }, []);

    const updateTableRef = React.useRef<HTMLButtonElement>(null);
    const updateQueryHandle = React.useCallback(() => {
        updateTableRef.current?.click();
    }, []);

    const resetQueryHandle = React.useCallback(() => {
        resetQuery(query);
        updateQueryHandle();
    }, [query, updateQueryHandle]);

    React.useEffect(() => {
        return () => saveODataMessage("ArticleTable", query)
    }, [query]);

    return (
        <>
            <CallbackButton callback={updateQueryHandle} ref={updateRef} />
            <CallbackButton callback={resetQueryHandle} ref={resetRef} />

            <TableOData<ArticleItemResponse>
                fields={fields}
                message={query}
                updateRef={updateTableRef}
            />
        </>
    );
}

function resetQuery(query: SearchArticlesQuery) {
    const fields = ArticleItemResponse.Fields;

    query.orderBy.set(fields.createdAt.name);

    query.filter.set(fields.anatomicZoneName.name, fields.anatomicZoneName.name, "eq", "undefined");
    query.filter.set(fields.title.name, fields.title.name, "contains", "");
    query.filter.set(fields.isArchived.name, fields.isArchived.name, "eq", "false");
}

function buildFields(anatomicZones: IdNameResponse[], editable?: boolean) {
    const builder = new TableODataFieldsBuilder<ArticleItemResponse>();
    const fields = ArticleItemResponse.Fields;

    const anatomicZonesOptions = IdNameToTableOptions(anatomicZones);

    builder
        .initialize(fields)
        .hidden(fields.id)
        .hidden(fields.createdAt)
        .hidden(fields.updatedAt)

        .set(fields.anatomicZoneName, {
            position: 0,
            label: "anatomic zone",
            sortable: true
        })
        .setFilterSelect(fields.anatomicZoneName, anatomicZonesOptions)

        .set(fields.title, {
            position: 1,
            scope: "row",
            sortable: true,
            filterable: true,
            filter: {
                availableOperators: ["contains", "eq"],
                fullWidth: true,
            }
        })
        .set(fields.isArchived, {
            position: 2,
            align: "center",
        })

        .setFilterSelect(fields.isArchived, BOOLEAN_SELECT_OPTIONS)
        ;
    if (editable) {
        builder.addLinkEdit((entity) => {
            return fullPath("EditArticle", {
                params: {
                    id: entity.id as string
                }
            });
        });
    }

    return builder.build();
}
