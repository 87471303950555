import { Guid } from "domain/static/Guid";
import { ContouringWorkshopUserRole } from "domain/static/ContouringWorkshopUserRole";
import { ContouringWorkshopUserPermission } from "domain/static/ContouringWorkshopUserPermission";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class UpdateContouringWorkshopUserPermissionCommand implements IMessage {
    id!: Guid;
    userId!: Guid;
    role!: ContouringWorkshopUserRole;
    permission!: ContouringWorkshopUserPermission;

    public constructor(init?: Partial<UpdateContouringWorkshopUserPermissionCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "PUT" as RequestMethod;

    getPath = () => `/admin/contouring-workshops/${this.id.toString()}/organizers/${this.userId.toString()}/permission`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            role: this.role,
            permission: this.permission,
        };
    }
    
    needAuthentication = () => true;
}
