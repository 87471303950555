import React from "react";

import { LoadingWrapper } from "cocoreact";

import { Guid, IsEmptyGuid } from "domain/static/Guid";
import { SelectItemType } from "domain/static/SelectItemType";
import { CreateArticleCommand } from "domain/admin/command/CreateArticleCommand";
import { UpdateArticleCommand } from "domain/admin/command/UpdateArticleCommand";
import { SetArchivingStateArticleCommand } from "domain/admin/command/SetArchivingStateArticleCommand";
import { ArticleResponse } from "domain/admin/response/ArticleResponse";
import { Form, FormFieldsBuilder } from "components/Form";
import { sendMessage } from "tools/Message";
import { ArchiveButton, SubmitButton } from "components/Buttons";
import { AutoCompleteOption } from "components/Form/widgets/AutoCompleteFormWidget";
import { Flex, Spacer } from "components/Page";
import useGetArticle from "domain/hooks/useGetArticle";

export interface ArticleFormDetailsProps {
    id: Guid;
    onAfterSave?: (id?: Guid) => void;
}

export default function ArticleFormDetails({ id, onAfterSave }: ArticleFormDetailsProps) {

    const [loadingEntity, entity, updateEntity] = useGetArticle(id);

    const fields = React.useMemo(() => buildFields(entity), [entity]);

    const buildMessage = React.useCallback((data: ArticleResponse) => {
        return IsEmptyGuid(id)
            ? new CreateArticleCommand(data)
            : new UpdateArticleCommand({ ...data, id })
    }, [id]);

    const handleArchive = React.useCallback(async () => {
        const command = new SetArchivingStateArticleCommand({
            id,
            isArchived: !entity.isArchived
        });
        await sendMessage(command);
        updateEntity();
    }, [entity.isArchived, id, updateEntity]);

    const handleSuccess = React.useCallback((response: any) => {
        if (!onAfterSave) return;
        else if (response && response.id) onAfterSave(response.id);
        else onAfterSave();
    }, [onAfterSave]);


    return (
        <LoadingWrapper loading={loadingEntity}>
            <Form<ArticleResponse>
                initial={entity}
                fields={fields}
                buildMessage={buildMessage}
                onSuccess={handleSuccess}
            >
                {
                    ({ loading, data }) => (
                        <Flex marginTop={2}>
                            {!IsEmptyGuid(data.id) &&
                                <ArchiveButton
                                    isArchived={data.isArchived}
                                    disabled={loadingEntity || loading}
                                    onClick={handleArchive}
                                />
                            }
                            <Spacer />
                            <SubmitButton loading={loadingEntity || loading} />
                        </Flex>
                    )
                }
            </Form>
        </LoadingWrapper>
    );
}

function buildFields(entity: ArticleResponse) {
    const builder = new FormFieldsBuilder<ArticleResponse>();
    const fields = ArticleResponse.Fields;

    const isCreating = IsEmptyGuid(entity.id);

    let defaultAnatomicZone = undefined as AutoCompleteOption | undefined;
    if (!isCreating) {
        defaultAnatomicZone = {
            label: entity.anatomicZoneName,
            value: entity.anatomicZoneId
        };
    }

    builder
        .initialize(fields)
        .hidden(fields.id)
        .hidden(fields.createdAt)
        .hidden(fields.updatedAt)
        .hidden(fields.isArchived)
        .hidden(fields.anatomicZoneName)

        .set(fields.title, {
            position: 0,
            autoFocus: true,
            multiline: true,
        })
        .set(fields.anatomicZoneId, {
            position: 1,
            label: "anatomic zone",
            type: "SelectItem",
            selectItemType: SelectItemType.AnatomicZone,
            defaultValue: defaultAnatomicZone,
            required: false,
        })
        .set(fields.authors, {
            position: 3,
            multiline: true,
        })
        .set(fields.editor, {
            position: 4,
            label: "Editor / review"
        })
        .set(fields.page, {
            position: 5,
            label: "pages"
        })
        .set(fields.date, {
            position: 6,
        })
        .set(fields.url, {
            position: 7,
            required: false,
        })
        .set(fields.doi, {
            position: 8,
            required: false,
            label: "DOI",
            placeholder: "ex : 10.1016/j.radonc.2003.09.011"
        })
        .set(fields.pmid, {
            position: 9,
            required: false,
            label: "PMID",
            placeholder: "ex : 14644481"
        })
        ;

    return builder.build();
}
