import React, { useMemo, useCallback, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
    Box, Button, ButtonGroup, ClickAwayListener,
    Grid, Grow, MenuItem, MenuList, Popper, Typography,
    Paper as MuiPaper
} from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';


import { CentralColumnLayout, PageLayout } from "components/Layouts";
import { Guid, EmptyGuid, IsEmptyGuid, IsGuid, IsValidGuid } from "domain/static/Guid";
import { fullPath } from "tools/Route";
import { sendMessage } from "tools/Message";
import useQueryString from "tools/useQueryString";
import UserForm from "./UserForm";
import { Flex, Paper, PaperTitle, Title } from "components/Page";
import { useNotificationContext } from "cocoreact";
import { UserResponse } from "domain/admin/response/UserResponse";
import { SetArchivingStateUserCommand } from "domain/admin/command/SetArchivingStateUserCommand";
import { AskPasswordResetQuery } from "domain/public/query/AskPasswordResetQuery";
import useGetUser from "domain/hooks/useGetUser";
import { ArchiveButton } from "components/Buttons";
import { ClinicalCaseIcon, ContouringWorkshopIcon, UnlockIcon, UserIcon } from "App/Theme";
import useGetUserClinicalCases from "domain/hooks/useGetUserClinicalCases";
import useGetUserContouringWorkshops from "domain/hooks/useGetUserContouringWorkshops";
import ClinicalCaseListItem from "components/ClinicalData/ClinicalCaseListItem";
import ContouringWorkshopListItem from "components/ClinicalData/ContouringWorkshopListItem";
import { GetUserResetPasswordTokenQuery } from "domain/admin/query/GetUserResetPasswordTokenQuery";
import { UserResetPasswordTokenResponse } from "domain/admin/response/UserResetPasswordTokenResponse";
import { UnlockUserCommand } from "domain/admin/command/UnlockUserCommand";

// settings file
declare var APP_PUBLIC_PATH: string;

export default function EditUserPage() {

    const listUrl = useMemo(
        () => fullPath("ListUsers"),
        []
    );

    const params = useParams<{ id: Guid }>();
    const id = useMemo(
        () => IsGuid(params.id) ? params.id : EmptyGuid(),
        [params.id]
    );
    const initialValue = useQueryString<UserResponse>();
    const [actionsDisabled, setActionsDisabled] = useState(false);
    const [loadingEntity, entity, updateEntity] = useGetUser(id, initialValue);

    const { success } = useNotificationContext();

    const history = useHistory();
    const handleAfterSave = useCallback(() => {
        history.push(listUrl);
        success("User saved with success");
    }, [history, listUrl, success]);

    const handleArchive = React.useCallback(async () => {
        setActionsDisabled(true);
        const message = new SetArchivingStateUserCommand({
            id: entity.id,
            isArchived: !entity.isArchived
        });
        await sendMessage(message);
        updateEntity();
        setActionsDisabled(false);
    }, [entity.id, entity.isArchived, updateEntity]);

    const handleUnlock = React.useCallback(async () => {
        setActionsDisabled(true);
        const message = new UnlockUserCommand({
            id: entity.id
        });
        await sendMessage(message);
        updateEntity();
        setActionsDisabled(false);
    }, [entity, updateEntity]);

    const askPasswordReset = React.useCallback(async () => {
        setActionsDisabled(true);
        const message = new AskPasswordResetQuery({ email: entity.email });
        await sendMessage(message);
        success("The password request has been send successfully.");
        setActionsDisabled(false);
    }, [entity.email, success]);

    const getResetPassworkToken = React.useCallback(async () => {
        setActionsDisabled(true);
        const message = new GetUserResetPasswordTokenQuery({ id: entity.id });
        const response = await sendMessage<UserResetPasswordTokenResponse>(message);
        const resetPath = fullPath("ResetPassword", {
            queryString: {
                token: response.token
            }
        })
        const resetLink = APP_PUBLIC_PATH + resetPath;
        navigator.clipboard.writeText(resetLink);
        success("User password link copy to clipboard.");
        setActionsDisabled(false);
    }, [entity.id, success]);

    const handleRequestPassword = React.useCallback(async (index: number) => {
        if (index === 0) {
            await askPasswordReset();
        }
        else if (index === 1) {
            getResetPassworkToken();
        }
    }, [askPasswordReset, getResetPassworkToken]);

    const title = (IsEmptyGuid(id) ? "Create" : "Edit") + " a user"

    const userFormPaper = <Paper>
        <PaperTitle icon={<UserIcon />}>{title}</PaperTitle>

        <UserForm
            entity={entity}
            loading={loadingEntity}
            onAfterSave={handleAfterSave}
        />
    </Paper>

    if (IsValidGuid(entity.id) === false) {
        return <>
            <Title>{title}</Title>

            <PageLayout title="Users" menuSelected="ListUsers">
                <CentralColumnLayout size="md">

                    {userFormPaper}

                </CentralColumnLayout>
            </PageLayout>
        </>
    }

    return <>
        <Title>{title}</Title>

        <PageLayout title="Users" menuSelected="ListUsers">
            <CentralColumnLayout size="xl">

                <Grid container spacing={2}>
                    <Grid item md={12} lg={6}>
                        <Box display="flex" flexDirection="column" gridGap={8}>

                            {userFormPaper}

                            <Paper>
                                <Flex justifyContent="space-between">
                                    <ArchiveButton
                                        isArchived={entity.isArchived}
                                        disabled={loadingEntity || actionsDisabled}
                                        onClick={handleArchive}
                                    />

                                    <SplitButton
                                        onClick={handleRequestPassword}
                                        disabled={loadingEntity || actionsDisabled}
                                    />
                                </Flex>
                            </Paper>

                            {entity.locked === true &&
                                <Paper>
                                    <Flex justifyContent="space-between">
                                        <Typography>
                                            This account is currently lock !
                                        </Typography>
                                        <Button
                                            variant="outlined"
                                            onClick={handleUnlock}
                                            disabled={loadingEntity || actionsDisabled}
                                            startIcon={<UnlockIcon />}
                                        >
                                            unlock
                                        </Button>
                                    </Flex>
                                </Paper>
                            }
                        </Box>
                    </Grid>

                    <Grid item md={12} lg={6}>
                        <Box display="flex" flexDirection="column" gridGap={8}>

                            <Paper>
                                <PaperTitle icon={<ClinicalCaseIcon />}>Associated clinical cases</PaperTitle>
                                <ListClinicalCases userId={entity.id} />
                            </Paper>

                            <Paper>
                                <PaperTitle icon={<ContouringWorkshopIcon />}>Associated contouring workshops</PaperTitle>
                                <ListContouringWorkshops userId={entity.id} />
                            </Paper>

                        </Box>
                    </Grid>

                </Grid>

            </CentralColumnLayout>
        </PageLayout>
    </>
}


function SplitButton({ onClick, disabled }: { onClick: (index: number) => void, disabled: boolean }) {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const options = ['send forgot password request', 'get forgot password link'];

    const handleClick = () => {
        onClick(selectedIndex);
    };

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    return <>
        <ButtonGroup variant="outlined" /*color="primary"*/ ref={anchorRef} aria-label="split button" disabled={disabled}>
            <Button onClick={handleClick}>{options[selectedIndex]}</Button>
            <Button
                // color="primary"
                size="small"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
            >
                <ArrowDropDownIcon />
            </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <MuiPaper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu">
                                {options.map((option, index) => (
                                    <MenuItem
                                        key={option}
                                        // disabled={index === 2}
                                        selected={index === selectedIndex}
                                        onClick={(event) => handleMenuItemClick(event, index)}
                                    >
                                        {option}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </MuiPaper>
                </Grow>
            )}
        </Popper>
    </>
}

function ListClinicalCases({ userId }: { userId: Guid }) {
    const [loading, items] = useGetUserClinicalCases(userId);
    if (loading) {
        return <Typography variant="caption">loading ...</Typography>
    }
    if (items.length === 0) {
        return <Typography variant="caption">no items found</Typography>
    }
    return <Box display="flex" flexDirection="column" gridGap={8}>
        {items.map(x => <ClinicalCaseListItem key={x.id} entity={x} />)}
    </Box>
}

function ListContouringWorkshops({ userId }: { userId: Guid }) {
    const [loading, items] = useGetUserContouringWorkshops(userId);
    if (loading) {
        return <Typography variant="caption">loading ...</Typography>
    }
    if (items.length === 0) {
        return <Typography variant="caption">no items found</Typography>
    }
    return <Box display="flex" flexDirection="column" gridGap={8}>
        {items.map(x => <ContouringWorkshopListItem key={x.id} entity={x} />)}
    </Box>
}