import { useEntityMessage } from "tools/Message";
import { EmptyGuid, Guid } from "domain/static/Guid";
import { GetContouringWorkshopQuery } from "domain/admin/query/GetContouringWorkshopQuery";
import { ContouringWorkshopResponse } from "domain/admin/response/ContouringWorkshopResponse";

export function emptyContouringWorkshopBuilder() {
  let entity = new ContouringWorkshopResponse();
  entity.id = EmptyGuid();
  entity.name = "";
  entity.summary = "";
  entity.anatomicZoneId = EmptyGuid();
  entity.anatomicZoneName = "";
  entity.coverId = EmptyGuid();
  entity.startAt = new Date();
  entity.endAt = new Date();
  entity.limitOfParticipants = 100;
  entity.publishedAt = new Date();
  entity.isPublished = false;
  entity.isArchived = false;
  entity.author = null as any;
  return entity;
}

export function getContouringWorkshopQueryBuilder(id: Guid) {
  return new GetContouringWorkshopQuery({ id });
}

export default function useGetContouringWorkshop(id: Guid) {
  return useEntityMessage(
    id,
    getContouringWorkshopQueryBuilder,
    emptyContouringWorkshopBuilder
  );
}
