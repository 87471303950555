import React, { useCallback, useMemo, useState } from "react";
import {
    Button,
    Checkbox, CircularProgress, createStyles,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    alpha, List, ListItem, ListItemIcon, ListItemText,
    makeStyles, Theme, TextField, darken, IconButton, Box
} from "@material-ui/core";
import { IndeterminateCheckBox as IndeterminateCheckBoxIcon } from "@material-ui/icons";
import { useNotificationContext } from "cocoreact";

import { Guid } from "domain/static/Guid";
import { ContouringWorkshopUserRole } from "domain/static/ContouringWorkshopUserRole";
import { ContouringWorkshopUserResponse } from "domain/admin/response/ContouringWorkshopUserResponse";
import { RemoveContouringWorkshopUsersCommand } from "domain/admin/command/RemoveContouringWorkshopUsersCommand";
import { AddIcon, ClearIcon, DeleteIcon, EmailIcon } from "App/Theme";
import { sendMessage } from "tools/Message";
import { ContactDialog } from "pages/Contact";

const useStyles = makeStyles((theme: Theme) => createStyles({
    list: {
        "& > li:nth-child(even)": {
            backgroundColor: theme.palette.background.paper,
        },
        "& > li:nth-child(odd)": {
            backgroundColor: theme.palette.background.default,
        },
        "& > li:nth-child(even).selected": {
            backgroundColor: alpha(theme.palette.secondary.light, 0.5),
        },
        "& > li:nth-child(odd).selected": {
            backgroundColor: alpha(theme.palette.secondary.light, 0.5),
        },
    },
    actions: {
        backgroundColor: darken(theme.palette.background.paper, 0.5),
        borderRadius: theme.shape.borderRadius,
        border: "1px black solid",
        borderColor: theme.palette.divider,
        height: 58,
    }
}));

export interface ParticipantTableProps {
    id: Guid;
    loading: boolean;
    users: ContouringWorkshopUserResponse[];
    onAfterRemove: () => void;
    onAddClick: () => void;
}

export default function ParticipantTable({ id, loading, users: _users, onAfterRemove, onAddClick }: ParticipantTableProps) {

    const classes = useStyles();
    const { success } = useNotificationContext();

    const [selected, setSelected] = useState<Guid[]>([]);
    const [filter, setFilter] = useState("");
    const [confirm, setConfirm] = useState({
        open: false,
        loading: false,
    });
    const [contactOpen, setContactOpen] = useState(false);

    const contactBcc = useMemo(() => {
        const user = selected.length > 0
            ? _users.filter(x => selected.includes(x.id))
            : _users;
        return user.length > 0
            ? { bcc: user.map(x => `${x.firstname} ${x.lastname} <${x.email}>`).join(", ") }
            : undefined
    }, [_users, selected])

    const onSelectHandle = useCallback((id: Guid) => {
        setSelected(prev => {
            if (prev.includes(id)) {
                return prev.filter(x => x !== id);
            }
            else {
                return [...prev, id];
            }
        });
    }, []);

    const onConfirmHandle = useCallback(() => {
        setConfirm({
            open: true,
            loading: false,
        });
    }, []);

    const onRemoveHandle = useCallback(async () => {
        setConfirm({ open: true, loading: true });

        const message = new RemoveContouringWorkshopUsersCommand();
        message.id = id;
        message.role = ContouringWorkshopUserRole.Participant;
        message.userIds = selected;

        await sendMessage(message)

        setConfirm({ open: false, loading: false });
        setSelected([]);
        onAfterRemove();
        success("Participants remove with success");

    }, [id, onAfterRemove, selected, success]);

    if (loading) {
        return <List>
            <ListItem>
                <ListItemText primary="Loading participants ..." />
            </ListItem>
        </List>
    }

    var users = _users;
    if (filter.length > 0) {
        users = _users.filter(x =>
            x.firstname.includes(filter) ||
            x.lastname.includes(filter) ||
            x.email.includes(filter)
        );
    }

    return <>
        <Box
            display="flex"
            padding={1}
            marginBottom={1}
            gridGap={8}
            className={classes.actions}
        >
            {selected.length > 0 && <>
                <Checkbox
                    onClick={() => setSelected([])}
                    icon={selected.length > 0 ? <IndeterminateCheckBoxIcon color="secondary" /> : undefined}
                />
                <Button
                    variant="outlined"
                    startIcon={<EmailIcon />}
                    onClick={() => setContactOpen(true)}
                    style={{ marginLeft: "auto" }}
                >
                    contact ({selected.length})
                </Button>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={onConfirmHandle}
                >
                    delete ({selected.length})
                </Button>
            </>}
            {selected.length === 0 && <>
                <TextField
                    size="small"
                    variant="outlined"
                    label="filter"
                    placeholder="name or email"
                    value={filter}
                    onChange={e => setFilter(e.currentTarget.value)}
                    InputProps={{
                        endAdornment: filter.length > 0
                            ? <IconButton size="small" onClick={() => setFilter("")}><ClearIcon /></IconButton>
                            : undefined
                    }}
                />
                <Button
                    variant="outlined"
                    startIcon={<EmailIcon />}
                    onClick={() => setContactOpen(true)}
                    style={{ marginLeft: "auto", minWidth: 165 }}
                >
                    contact ({_users.length})
                </Button>
                <Button
                    color="secondary"
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={onAddClick}
                >
                    add
                </Button>
            </>}
        </Box>

        <List className={classes.list}>
            {users.length === 0 &&
                <ListItem>
                    <ListItemText primary="No participant found." />
                </ListItem>
            }

            {users.length > 0 &&
                users.map(x => (
                    <ListItem
                        key={x.id}
                        onClick={e => onSelectHandle(x.id)}
                        className={selected.includes(x.id) ? "selected" : undefined}
                    >
                        <ListItemIcon>
                            <Checkbox checked={selected.includes(x.id)} />
                        </ListItemIcon>
                        <ListItemText
                            primary={`${x.firstname} ${x.lastname}`}
                            secondary={x.email}
                        />
                    </ListItem>
                ))
            }
        </List>

        <ContactDialog
            open={contactOpen}
            onClose={() => setContactOpen(false)}
            title="Contact participants"
            initialValue={contactBcc}
            onAfterSend={() => setContactOpen(false)}
        />

        <Dialog open={confirm.open} maxWidth="md">
            <DialogTitle>
                Remove participants confirmation
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure to remove selected particpants from the contouring workshop ?
                </DialogContentText>
                <DialogContentText>
                    <strong>This action will also delete associated participants contours.</strong>
                </DialogContentText>
                <DialogContentText>
                    <em>(You can add them again later if you wish)</em>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Box display="flex" width="100%" paddingX={2} paddingBottom={1}>
                    <Button
                        variant="outlined"
                        onClick={() => setConfirm({ open: false, loading: false })}
                        disabled={confirm.loading}
                    >
                        cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={onRemoveHandle}
                        startIcon={confirm.loading ? <CircularProgress size={16} /> : <DeleteIcon />}
                        disabled={confirm.loading}
                        style={{ marginLeft: 'auto' }}
                    >
                        remove {selected.length} participants
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    </>
}
