import React, { useCallback, useEffect, useMemo, useState } from "react";

import { FormDialog, FormFieldsBuilder } from "components/Form";
import { EmptyGuid, Guid, IsEmptyGuid, IsValidGuid } from "domain/static/Guid";
import { CreateGuidelineCommand } from "domain/admin/command/CreateGuidelineCommand";
import { UpdateGuidelineCommand } from "domain/admin/command/UpdateGuidelineCommand";
import { GuidelineResponse } from "domain/admin/response/GuidelineResponse";
import { IdNameResponse } from "domain/admin/response/IdNameResponse";
import { emptyGuidelineBuilder, getGuidelineQueryBuilder } from "domain/hooks/useGetGuideline";
import { SelectItemType } from "domain/static/SelectItemType";
import { VolumeTypeOptions } from "domain/static/VolumeType";
import { sendMessage } from "tools/Message";

export interface GuidelineFormDialogProps {
    open: boolean;
    id?: Guid;
    article?: IdNameResponse;
    onAfterSave?: (id: Guid) => void;
    onClose?: () => void;
}

export default function GuidelineFormDialog({
    open, id, article, onAfterSave, onClose
}: GuidelineFormDialogProps) {

    const [entity, setEntity] = useState(emptyGuidelineBuilder());

    useEffect(() => {
        const _loadEntity = async (_id: Guid, _articleId: Guid) => {
            const _entity = await sendMessage<GuidelineResponse>(getGuidelineQueryBuilder(_id));
            (_entity as any).articleId = _articleId;
            setEntity(_entity);
        }

        if (id && IsValidGuid(id) && article) {
            _loadEntity(id, article.id);
        }
        else {
            const emptyEntity = emptyGuidelineBuilder();
            (emptyEntity as any).articleId = EmptyGuid();
            setEntity(emptyEntity)
        }
    }, [id, article])

    const fields = useMemo(
        () => buildFields(entity, article),
        [entity, article]
    );

    const buildMessageHandle = useCallback((data: GuidelineResponse) => {
        return !IsValidGuid(id)
            ? new CreateGuidelineCommand({ ...data })
            : new UpdateGuidelineCommand({ ...data, id });
    }, [id]);

    const onSuccessHandle = useCallback((response: any) => {
        if (!onAfterSave) return;
        if (response && response.id) onAfterSave(response.id);
        else if (!IsEmptyGuid(entity.id)) onAfterSave(entity.id);
    }, [entity.id, onAfterSave]);

    return (
        <FormDialog<GuidelineResponse>
            title={`${id ? "Edit" : "Create"} a guideline`}
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={onClose}
            initial={entity}
            fields={fields}
            buildMessage={buildMessageHandle}
            onSuccess={onSuccessHandle}
        />
    );
}

function buildFields(
    entity: GuidelineResponse,
    article?: IdNameResponse
) {
    const builder = new FormFieldsBuilder<GuidelineResponse>();
    const fields = GuidelineResponse.Fields;

    let articleDefault = undefined as IdNameResponse | undefined;
    if (article && IsValidGuid(article.id)) {
        articleDefault = {
            id: article.id,
            name: article.name,
        };
    }

    builder
        .initialize(fields)
        .hidden(fields.id)
        .hidden(fields.anatomicStructureName)
        .hidden(fields.targetTypeName)
        .hidden(fields.organizationName)
        .hidden(fields.isArchived)

        .custom({
            position: 0,
            name: "articleId",
            type: "SearchArticle",
            label: "Article",
            placeholder: "Find an article ...",
            noOptionsText: "No article found",
            defaultValue: articleDefault,
        })

        .set(fields.anatomicStructureId, {
            position: 1,
            label: "anatomic structure",
            type: "SelectItem",
            selectItemType: SelectItemType.AnatomicStructure,
        })
        .set(fields.volumeType, {
            label: "Volume Type",
            position: 2,
            options: VolumeTypeOptions
        })
        .set(fields.targetTypeId, {
            position: 3,
            label: "Target Type",
            type: "SelectItem",
            required: false,
            selectItemType: SelectItemType.TargetType,
        })
        .set(fields.organizationId, {
            position: 4,
            label: "organization",
            type: "SelectItem",
            required: false,
            selectItemType: SelectItemType.Organization,
        })
        .set(fields.content, {
            type: "richtext",
            position: 5,
        })
        ;

    return builder.build();
}
