export function RgbToHex(color: number[]) {
  const componentToHex = (c: number) => {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };
  return (
    "#" +
    componentToHex(color[0]) +
    componentToHex(color[1]) +
    componentToHex(color[2])
  );
}

export function HexToRgb(color: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : [];
}
