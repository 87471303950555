import { Guid } from "domain/static/Guid";
import { ContouringWorkshopUserRole } from "domain/static/ContouringWorkshopUserRole";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class RemoveContouringWorkshopUsersCommand implements IMessage {
    id!: Guid;
    userIds!: Guid[];
    role!: ContouringWorkshopUserRole;

    public constructor(init?: Partial<RemoveContouringWorkshopUsersCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "DELETE" as RequestMethod;

    getPath = () => `/admin/contouring-workshops/${this.id.toString()}/users`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            userIds: this.userIds,
            role: this.role,
        };
    }
    
    needAuthentication = () => true;
}
