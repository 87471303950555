import React from "react";
import { ITableWidgetPropsBase } from "cocoreact";

import { ClinicalCaseState } from "domain/static/ClinicalCaseState";
import { ClinicalCaseStateWidget } from "components/ClinicalData";

export interface ClinicalCaseStateTableWidgetProps extends ITableWidgetPropsBase<ClinicalCaseState> { }

export default function ClinicalCaseStateTableWidget({ value }: ClinicalCaseStateTableWidgetProps) {
    return <ClinicalCaseStateWidget state={value} />
}
