import { Guid } from "domain/static/Guid";
import { ContouringWorkshopState } from "domain/static/ContouringWorkshopState";
import { IField } from "cocoreact";

//Generated code, do not modify

export class ODataContouringWorkshopItemResponse {
    results!: ContouringWorkshopItemResponse[];
    count!: number;

    public static Fields = {
        results: { name: "results", type: "ContouringWorkshopItemResponse[]" },
        count: { name: "count", type: "number" },
    } as Record<keyof ODataContouringWorkshopItemResponse, IField>;
}

export class ContouringWorkshopItemResponse {
    id!: Guid | null;
    name!: string;
    summary!: string;
    anatomicZoneName!: string;
    startAt!: Date;
    endAt!: Date;
    state!: ContouringWorkshopState;
    createdAt!: Date | null;
    updatedAt!: Date | null;
    isPublished!: boolean;
    isArchived!: boolean;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        name: { name: "name", type: "string" },
        summary: { name: "summary", type: "string" },
        anatomicZoneName: { name: "anatomicZoneName", type: "string" },
        startAt: { name: "startAt", type: "Date" },
        endAt: { name: "endAt", type: "Date" },
        state: { name: "state", type: "ContouringWorkshopState" },
        createdAt: { name: "createdAt", type: "Date" },
        updatedAt: { name: "updatedAt", type: "Date" },
        isPublished: { name: "isPublished", type: "boolean" },
        isArchived: { name: "isArchived", type: "boolean" },
    } as Record<keyof ContouringWorkshopItemResponse, IField>;
}
