import React, { useCallback, useMemo } from "react";
import { IField } from "cocoreact";
import { Box, Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import { Link } from "react-router-dom";

import { Form, FormFieldsBuilder } from "components/Form";
import { AuthenticateUserCommand } from "domain/public/command/AuthenticateUserCommand";
import { AuthenticationResponse } from "domain/public/response/AuthenticationResponse";
import { useAuthContext } from "contexts/Auth";
import { fullPath } from "tools/Route";

const useStyles = makeStyles((theme: Theme) => createStyles({
    passwordLink_wrapper: {
        textAlign: "right",
        "& a": {
            color: theme.palette.getContrastText(theme.palette.background.paper),
        }
    },
    passwordLink: {
        color: theme.palette.primary.dark,
        textDecoration: "underline",
        fontSize: theme.typography.caption.fontSize,
    },
    submitBtn: {
        marginTop: theme.spacing(2),
    }
}));

class LoginResponse {
    email!: string;
    password!: string;

    public static Fields = {
        email: { name: "email", type: "email" },
        password: { name: "password", type: "password" },
    } as Record<keyof LoginResponse, IField>;
}

export default function SignInForm() {
    const classes = useStyles();
    const { setToken } = useAuthContext();

    const fields = useMemo(buildField, []);

    const buildMessage = useCallback((data: LoginResponse) => {
        return new AuthenticateUserCommand({
            email: data.email,
            password: data.password,
        });
    }, []);

    const onSuccess = useCallback((response: AuthenticationResponse) => {
        if (response && response.accessToken) {
            setToken(response.accessToken);
        }
    }, [setToken]);


    return (
        <Form<LoginResponse>
            fields={fields}
            initial={{
                email: "",
                password: ""
            }}
            buildMessage={buildMessage}
            onSuccess={onSuccess}
        >
            {() => (
                <>
                    <Box className={classes.passwordLink_wrapper}>
                        <Link to={fullPath("ResetPassword")} className={classes.passwordLink}>
                            Forgot password ?
                        </Link>
                    </Box>

                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submitBtn}
                    >
                        sign in
                    </Button>
                </>
            )}
        </Form>
    );
}

function buildField() {

    const builder = new FormFieldsBuilder<LoginResponse>();

    const fields = LoginResponse.Fields;

    builder
        .initialize(fields)
        .set(fields.email, {
            placeholder: "Enter your email adress",
            label: "email adress",
            position: 0,
        })
        .set(fields.password, {
            position: 1,
        })
        ;

    return builder.build();
}
