import { Guid } from "domain/static/Guid";
import { SelectItemType } from "domain/static/SelectItemType";
import { IField } from "cocoreact";

//Generated code, do not modify

export class SelectItemResponse {
    id!: Guid;
    name!: string;
    type!: SelectItemType;
    isArchived!: boolean;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        name: { name: "name", type: "string" },
        type: { name: "type", type: "SelectItemType" },
        isArchived: { name: "isArchived", type: "boolean" },
    } as Record<keyof SelectItemResponse, IField>;
}
