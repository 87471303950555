import React, { forwardRef, useCallback } from "react";
import { makeStyles, Theme, createStyles, Box, Slider } from '@material-ui/core';
import { OrientationType } from "domain/static/OrientationType";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        width: "100%",
        height: "100%",
        backgroundColor: theme.palette.background.paper,
        display: "block",
        position: "relative",
        overflow: "hidden",
        "& > div, & > div div": {
            width: "100%",
            height: "100%",
        }
    },
    slider: {
        position: "absolute",
        display: "block",
        left: theme.spacing(2),
        right: theme.spacing(2),
        bottom: theme.spacing(1),
    }
}));

export interface ViewerClasses {
    container: string;
    slider: string;
}

export interface ViewerProps {
    orientation: OrientationType;
    value: number;
    dimension: number;
    onSliceChange: (orientation: OrientationType, index: number) => void;
    classes?: Partial<ViewerClasses>;
}

const Viewer = forwardRef((
    { orientation, value, dimension, onSliceChange, classes: _classes }: ViewerProps,
    ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();

    const onChangeHandle = useCallback((_e: any, value: any) => {
        onSliceChange(orientation, value);
    }, [onSliceChange, orientation]);

    return <div ref={ref} className={clsx(classes.container, _classes?.container)}>
        <Box component="span" className={clsx(classes.slider, _classes?.slider)}>
            <Slider
                valueLabelDisplay="auto"
                min={0}
                max={dimension - 1}
                value={value}
                onChange={onChangeHandle}
                color="secondary"
            />
        </Box>
    </div>
});

export default Viewer;