import { SelectItemType } from "domain/static/SelectItemType";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class CreateSelectItemCommand implements IMessage {
    type!: SelectItemType;
    name!: string;

    public constructor(init?: Partial<CreateSelectItemCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "POST" as RequestMethod;

    getPath = () => `/admin/select-items`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            type: this.type,
            name: this.name,
        };
    }
    
    needAuthentication = () => true;
}
