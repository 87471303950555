import React from "react";
import { Box } from "@material-ui/core";

import {
  Table as CocoTable,
  TableProps as CocoTableProps
} from "cocoreact";

import { ITableFieldOptions } from "./TableFieldsBuilder";
import tableWidgetOptions from "./tableWidgetOptions";

export interface TableProps<T> {
  data: T[];
  fields: ITableFieldOptions<T>[];
  padding?: CocoTableProps<T>["padding"];
  className?: CocoTableProps<T>["className"];
}

export default function Table<T>(props: TableProps<T>) {
  return <Box overflow="auto">
    <CocoTable<T>
      {...props}
      noDataLabel={"No data found"}
      widgetOptions={tableWidgetOptions}
    />
  </Box>
}
