import {
    FormFieldOptionsBuilder as CocoFormFieldOptionsBuilder,
    IFormField,
    IFormWidgetPropsBase,
    TextFormWidgetProps,
    SelectFormWidgetProps,
    IField,
} from "cocoreact";

import { AutoCompleteFormWidgetProps } from "./widgets/AutoCompleteFormWidget";
import { DateFormWidgetProps } from "./widgets/DateFormWidget";
import { capitalize, slugify } from "tools/StringExtension";
import { ImageFormWidgetProps } from "./widgets/ImageFormWidget";
import { FileFormWidgetProps } from "./widgets/FileFormWidget";
import { EnumOptionsFormWidgetProps } from "./widgets/EnumOptionsFormWidget";
import { SelectItemFormWidgetProps } from "./widgets/SelectItemFormWidget";
import { SearchGuidelineFormWidgetProps } from "./widgets/SearchGuidelineFormWidget";
import { SearchArticleFormWidgetProps } from "./widgets/SearchArticleFormWidget";

export interface IFormFieldOptions<T> extends IFormField<T> {
    fullWidth?: IFormWidgetPropsBase<T>["fullWidth"];
    color?: IFormWidgetPropsBase<T>["color"];
    margin?: IFormWidgetPropsBase<T>["margin"];
    style?: IFormWidgetPropsBase<T>["style"];

    autoClearAdornment?: TextFormWidgetProps["autoClearAdornment"];
    autoClearIcon?: TextFormWidgetProps["autoClearIcon"];
    options?:
    | SelectFormWidgetProps["options"]
    | AutoCompleteFormWidgetProps["options"]
    | EnumOptionsFormWidgetProps["options"];
    MenuProps?: SelectFormWidgetProps["MenuProps"];
    format?: DateFormWidgetProps["format"];
    minDate?: DateFormWidgetProps["minDate"];
    maxDate?: DateFormWidgetProps["maxDate"];
    multiple?: AutoCompleteFormWidgetProps["multiple"];
    groupBy?: AutoCompleteFormWidgetProps["groupBy"];
    noOptionsText?:
    | AutoCompleteFormWidgetProps["noOptionsText"]
    | SearchGuidelineFormWidgetProps["noOptionsText"]
    | SearchArticleFormWidgetProps["noOptionsText"];
    filterOptions?: AutoCompleteFormWidgetProps["filterOptions"];
    startAdornment?:
    | TextFormWidgetProps["startAdornment"]
    | SelectFormWidgetProps["startAdornment"]
    | DateFormWidgetProps["startAdornment"];

    selectItemType?: SelectItemFormWidgetProps["selectItemType"];
    defaultValue?:
    | SelectItemFormWidgetProps["defaultValue"]
    | SearchGuidelineFormWidgetProps["defaultValue"]
    | SearchArticleFormWidgetProps["defaultValue"];

    multiline?: TextFormWidgetProps["multiline"];
    maxRows?: TextFormWidgetProps["maxRows"];
    minRows?: TextFormWidgetProps["minRows"];

    parentId?: ImageFormWidgetProps["parentId"];
    parentType?: ImageFormWidgetProps["parentType"];
    accept?: ImageFormWidgetProps["accept"];
    maxSize?: ImageFormWidgetProps["maxSize"];

    fileName?: FileFormWidgetProps["fileName"];
    onAfterLoad?: FileFormWidgetProps["onAfterLoad"];

    selectAllInsteadNone?: EnumOptionsFormWidgetProps["selectAllInsteadNone"];
}

export default class FormFieldsBuilder<T>
    extends CocoFormFieldOptionsBuilder<T, IFormFieldOptions<T>> {

    constructor() {
        super(true, (field) => {
            let label = field.label;
            if (label !== "") {
                label = label ? capitalize(label) : capitalize(field.name);
            }
            return {
                ...field,
                label,
                required: field.required !== undefined ? field.required : true,
                margin: field.margin ? field.margin : "normal",
                color: field.color ? field.color : "primary",
                autoComplete: field.autoComplete ? field.autoComplete : "off",
                autoFocus: field.autoFocus ? field.autoFocus : false,
                disabled: field.disabled ? field.disabled : false,
                fullWidth: field.fullWidth !== undefined ? field.fullWidth : true,
                noOptionsText: field.noOptionsText
                    ? capitalize(field.noOptionsText)
                    : undefined
            } as IFormFieldOptions<T>;
        });
    }

    attachFieldToSlug(
        srcformField: IField | string,
        slugFormField: IField | string
    ) {
        const srcName =
            typeof srcformField === "string" ? srcformField : srcformField.name;
        const slugName =
            typeof slugFormField === "string"
                ? slugFormField
                : slugFormField.name;

        this.set(srcformField, {
            onChange: (item: any) => {
                item[slugName] = slugify(item[srcName]);
                return item;
            }
        } as IFormFieldOptions<T>);

        this.set(slugFormField, {
            disabled: true
        } as IFormFieldOptions<T>);

        return this;
    }
}
