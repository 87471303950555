import { Guid } from "domain/static/Guid";
import { VolumeType } from "domain/static/VolumeType";
import { IField } from "cocoreact";

//Generated code, do not modify

export class GuidelineResponse {
    id!: Guid;
    content!: string;
    anatomicStructureId!: Guid;
    anatomicStructureName!: string;
    volumeType!: VolumeType;
    targetTypeId!: Guid;
    targetTypeName!: string;
    organizationId!: Guid;
    organizationName!: string;
    isArchived!: boolean;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        content: { name: "content", type: "string" },
        anatomicStructureId: { name: "anatomicStructureId", type: "Guid" },
        anatomicStructureName: { name: "anatomicStructureName", type: "string" },
        volumeType: { name: "volumeType", type: "VolumeType" },
        targetTypeId: { name: "targetTypeId", type: "Guid" },
        targetTypeName: { name: "targetTypeName", type: "string" },
        organizationId: { name: "organizationId", type: "Guid" },
        organizationName: { name: "organizationName", type: "string" },
        isArchived: { name: "isArchived", type: "boolean" },
    } as Record<keyof GuidelineResponse, IField>;
}
