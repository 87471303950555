import React from "react";
import {
    Box, createStyles,
    IconButton, ListItem, ListItemIcon, ListItemText, makeStyles, Theme
} from "@material-ui/core";

import { Guid } from "domain/static/Guid";
import { UserRole } from "domain/static/UserRole";
import { ClinicalCaseUserRole, ClinicalCaseUserRoleOptions } from "domain/static/ClinicalCaseUserRole";
import { UserOptionResponse } from "domain/admin/response/UserOptionResponse";
import { AddClinicalCaseUsersCommand } from "domain/admin/command/AddClinicalCaseUsersCommand";
import { RemoveClinicalCaseUsersCommand } from "domain/admin/command/RemoveClinicalCaseUsersCommand";
import useGetClinicalCaseUsers from "domain/hooks/useGetClinicalCaseUsers";
import { SearchUserField } from "pages/Users";
import { CloseIcon } from "App/Theme";
import { sendMessage } from "tools/Message";
import { useNotificationContext } from "cocoreact";
import { enumValueToString } from "tools/EnumExtension";

const useStyles = makeStyles((theme: Theme) => createStyles({
    items: {
        display: "flex",
        flexDirection: "column",
        padding: 0,
        margin: 0,
        "& > *": {
            margin: theme.spacing(1, 0, 0, 0),
        }
    },
    item_root: {
        padding: theme.spacing(1, 2),
        border: "1px solid black",
        borderColor: theme.palette.divider,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.default
    },
}));

interface UserItemProps {
    user: UserOptionResponse;
    onRemove: (userId: Guid) => void;
}

function UserItem({ user, onRemove }: UserItemProps) {
    const classes = useStyles();

    return <ListItem className={classes.item_root}>
        <ListItemText
            primary={`${user.firstname} ${user.lastname}`}
            secondary={`${user.email}`}
        />
        <ListItemIcon>
            <IconButton onClick={() => onRemove(user.id)}>
                <CloseIcon />
            </IconButton>
        </ListItemIcon>
    </ListItem>
}

export interface UsersManagerProps {
    id: Guid;
    role: ClinicalCaseUserRole;
    children?: (users: UserOptionResponse[]) => React.ReactNode;
}

export function UsersManager({ id, role, children }: UsersManagerProps) {
    const classes = useStyles();

    const roleStr = enumValueToString(role, ClinicalCaseUserRoleOptions).toLowerCase();
    const [, users, updateUsers] = useGetClinicalCaseUsers(id, role);
    const { error } = useNotificationContext();

    const onAddHandle = async (userId: Guid) => {
        try {
            const message = new AddClinicalCaseUsersCommand({ id });
            message.userIds = [userId];
            message.role = role;
            await sendMessage(message);

            updateUsers();
        }
        catch (e) {
            const errData = JSON.parse((e as any)?.response?.data ?? "{}");
            const message = errData ? errData?.message : "An error occurred !";
            error(message);
        }
    }

    const onRemoveHandle = async (userId: Guid) => {
        const message = new RemoveClinicalCaseUsersCommand({ id });
        message.userIds = [userId];
        message.role = role;
        await sendMessage(message);

        updateUsers();
    }

    return <>
        <SearchUserField
            onSelect={u => onAddHandle(u.id)}
            role={UserRole.Standard}
            placeholder={`Search ${roleStr} ...`}
        />
        <Box className={classes.items} component="ul">
            {users.map(x => <UserItem key={x.id} user={x} onRemove={onRemoveHandle} />)}
        </Box>

        {children ? children(users) : null}
    </>
}
