import React, { Dispatch, useCallback, useEffect, useRef, useState } from "react";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

import { EmptyGuid, Guid, IsValidGuid } from "domain/static/Guid";
import { ClinicalImageResponse } from "domain/admin/response/ClinicalImageResponse";
import { OrientationType } from "domain/static/OrientationType";
import { ImagingDefault } from "domain/static/ImagingDefault";
import { Range } from "domain/static/Range";
import { loadItkImage } from "tools/ImageExtension";
import { ViewerData, ImageData, useViewerContext } from "contexts/Viewer";
import WindowingFormWidget from "components/Form/widgets/WindowingFormWidget";
import SelectLutFormWidget from "components/Form/widgets/SelectLutFormWidget";
import { Coordinate } from "domain/static/Coordinate";
import { OrientationSelect } from "components/Viewer";
import { CircularProgressWithLabel } from "components/Page";

const fields = ClinicalImageResponse.Fields;

const useStyles = makeStyles((theme: Theme) => createStyles({
    viewerWrapper: {
        width: "100%",
        position: "relative",
        boxShadow: "-1px 2px 10px 3px rgba(0, 0, 0, 0.2) inset",
        backgroundColor: theme.palette.type === "dark"
            ? theme.palette.grey[900]
            : theme.palette.grey[300],
    },
    viewerContainer: {
        width: "100%",
        height: 350,
    },
    viewerMessageWrapper: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    error: {
        padding: theme.spacing(1, 3),
        marginLeft: "auto",
        marginRight: "auto",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.palette.error.dark,
        borderRadius: theme.shape.borderRadius,
    },
    topLeftZone: {
        position: "absolute",
        top: theme.spacing(2),
        left: theme.spacing(2),
        "& > *": {
            display: "inline-flex",
            marginRight: theme.spacing(1),
        },
        "& > :last-child": {
            marginRight: 0,
        }
    },
}));

export interface ImageFormViewerProps {
    fileId: Guid;
    windowing?: Range;
    lut?: string;
    updateData: Dispatch<any>;
}

export default function ImageFormViewer({
    fileId, windowing, lut, updateData
}: ImageFormViewerProps) {

    const classes = useStyles();
    const viewerContainerRef = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState(0);
    const [isError, setError] = useState(false);
    const [dataRange, setDataRange] = useState([0, 100]);
    const [orientation, setOrientation] = useState(OrientationType.Axial);
    const initialized = useRef(false);

    const {
        app: viewerApp,
        data: viewerData,
        dispatcher: viewerDispatcher
    } = useViewerContext();

    useEffect(() => {
        const _downloadAndInitialize = async () => {
            try {
                setLoading(true);

                const itkImage = await loadItkImage(fileId, (loaded, total) => {
                    setProgress(loaded / total * 100.0);
                });
                const dataMinMax = itkImage.getDataMinMax();
                const dataRange = new Range(dataMinMax);

                const img = new ImageData();
                img.id = fileId;
                img.default = ImagingDefault.Primary;
                img.data = itkImage;
                img.windowing = windowing ? windowing : dataRange;
                img.lut = lut ? lut : "Grayscale";

                const data = new ViewerData();
                data.images.push(img);

                setDataRange(itkImage.getDataMinMax());
                setLoading(false);

                viewerDispatcher({
                    type: "INITIALIZE",
                    data: data,
                }, "viewer");

                if (!lut || !windowing) {
                    updateData((dd: any) => ({
                        ...dd,
                        [fields.windowing.name]: img.windowing,
                        [fields.lut.name]: img.lut
                    }));
                }

                const grid = itkImage.grid;
                updateData((dd: any) => ({
                    ...dd,
                    [fields.range.name]: dataRange,
                    [fields.spacing.name]: new Coordinate(grid.spacing),
                    [fields.origin.name]: new Coordinate(grid.origin),
                    [fields.size.name]: new Coordinate(grid.size),
                }));
            }
            catch (e) {
                setError(true);
                updateData((dd: any) => ({
                    ...dd,
                    [fields.fileId.name]: EmptyGuid(),
                }))
            }
        }
        if (viewerApp.numberOfImages === 0 && IsValidGuid(fileId) && !initialized.current) {
            initialized.current = true;
            _downloadAndInitialize();
        }
    }, [fileId, loading, lut, updateData, viewerApp, viewerDispatcher, windowing]);

    useEffect(() => {
        const _initializeScene = async (container: HTMLDivElement) => {
            await viewerApp.createScene(OrientationType.Axial, container);
            await viewerApp.getSceneAt(0).autoCenterZoomCamera();
            setLoading(false);
        };
        if (viewerData.numberOfImages > 0
            && viewerApp.numberOfScenes === 0
            && viewerContainerRef.current) {
            _initializeScene(viewerContainerRef.current);
        }
    }, [viewerApp, viewerData]);

    const onWindowingChangeHandle = useCallback(async (_: any, newValue: any) => {
        const windowing = new Range(newValue as number[]);
        updateData((dd: any) => ({
            ...dd,
            [fields.windowing.name]: windowing,
        }));
        viewerDispatcher({
            type: "IMAGE_WINDOWING",
            imageId: fileId,
            windowing: windowing,
        });
    }, [fileId, updateData, viewerDispatcher]);

    const onLutChangeHandle = useCallback(async (_: string, newLut: string) => {
        updateData((dd: any) => ({ ...dd, [fields.lut.name]: newLut }));
        viewerDispatcher({
            type: "IMAGE_LUT",
            imageId: fileId,
            lut: newLut,
        });
    }, [fileId, updateData, viewerDispatcher]);

    const onOrientationChangeHandle = useCallback(async (newOrientation: OrientationType) => {
        setOrientation(newOrientation);
        await viewerApp.getSceneAt(0).setOrientation(newOrientation);
    }, [viewerApp]);

    return <>
        {!isError && !loading && windowing !== undefined &&
            <WindowingFormWidget
                required={true}
                fullWidth={true}
                margin="normal"
                label="windowing"
                type="Windowing"
                name={fields.windowing.name}
                step={1}
                value={[windowing.min, windowing.max]}
                range={dataRange}
                onChange={onWindowingChangeHandle}
            />
        }

        {!isError && !loading && lut !== undefined &&
            <SelectLutFormWidget
                required={true}
                fullWidth={true}
                margin="normal"
                label="Lookup Table"
                type="SelectLut"
                name={fields.lut.name}
                value={lut}
                onChange={onLutChangeHandle}
            />
        }

        <div className={classes.viewerWrapper}>
            {isError &&
                <div className={classes.viewerMessageWrapper}>
                    <Typography variant="body1" display="inline" className={classes.error}>
                        Error while loading data :(
                    </Typography>
                </div>
            }

            {!isError && loading &&
                <div className={classes.viewerMessageWrapper}>
                    <CircularProgressWithLabel color="inherit" value={progress} />
                </div>
            }

            {!loading &&
                <div className={classes.topLeftZone}>
                    <OrientationSelect value={orientation} onChange={onOrientationChangeHandle} />
                </div>
            }

            <div ref={viewerContainerRef} className={classes.viewerContainer}></div>
        </div>
    </>
}
