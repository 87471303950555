import React from "react";
import { ODataFilter, ODataSelect, ODataOrderBy } from "cocoreact";
import { useODataMessage } from "tools/Message";
import { SearchUsersQuery } from "domain/admin/query/SearchUsersQuery";
import { UserItemResponse } from "domain/admin/response/ODataUserItemResponse";

export function buildSearchUsersQuery() {
  const query = new SearchUsersQuery();
  query.top = 1000;
  query.skip = 0;
  query.filter = new ODataFilter();
  query.select = new ODataSelect();
  query.orderBy = new ODataOrderBy();
  query.orderBy.set(UserItemResponse.Fields.email.name);
  return query;
}

export default function useSearchUsers(query?: SearchUsersQuery) {
  const _query = React.useMemo(() => query ?? buildSearchUsersQuery(), [
    query,
  ]);
  return useODataMessage(_query);
}
