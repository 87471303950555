import { useEntityMessage } from "tools/Message";
import { EmptyGuid, Guid } from "domain/static/Guid";
import { GetContouringWorkshopResultsQuery } from "domain/admin/query/GetContouringWorkshopResultsQuery";
import { ContouringWorkshopResultsResponse } from "domain/admin/response/ContouringWorkshopResultsResponse";

export function emptyContouringWorkshopReferrersResponse() {
  let entity = new ContouringWorkshopResultsResponse();
  entity.id = EmptyGuid();
  entity.numberOfParticipant = 0;
  entity.limitOfParticipants = 0;
  entity.contours = [];
  return entity;
}

export function GetContouringWorkshopResultsQueryBuilder(id: Guid) {
  return new GetContouringWorkshopResultsQuery({ id });
}

export default function useGetContouringWorkshopResults(id: Guid) {
  return useEntityMessage(
    id,
    GetContouringWorkshopResultsQueryBuilder,
    emptyContouringWorkshopReferrersResponse
  );
}
