import React from "react";
import { alpha, Button, createStyles, makeStyles, Theme } from "@material-ui/core";

import { ContouringWorkshopState, ContouringWorkshopStateOptions } from "domain/static/ContouringWorkshopState";
import { format, formatDistance } from "tools/DateExtension";

const useStyles = makeStyles((theme: Theme) => createStyles({
    label: {
        textTransform: "none",
    },
    future: {
        color: `${theme.palette.text.primary}!important`,
        borderColor: `${theme.palette.text.primary}!important`,
        backgroundColor: alpha(theme.palette.text.hint, 0.1),
    },
    past: {
        color: `${theme.palette.warning.dark}!important`,
        borderColor: `${theme.palette.warning.dark}!important`,
        backgroundColor: alpha(theme.palette.warning.main, 0.2),
    },
    present: {
        color: `${theme.palette.success.dark}!important`,
        borderColor: `${theme.palette.success.dark}!important`,
        backgroundColor: alpha(theme.palette.success.main, 0.2),
    },
}));

function getStateLabel(state: ContouringWorkshopState) {
    return ContouringWorkshopStateOptions.find(x => x.value === state)?.label ?? "undefined";
}

export interface ContouringWorkshopStateWidgetProps {
    state: ContouringWorkshopState;
    startAt: Date;
    endAt: Date;
}

export default function ContouringWorkshopStateWidget({
    state, startAt, endAt
}: ContouringWorkshopStateWidgetProps) {
    const classes = useStyles();
    var label = "";
    var title = format(startAt, "dd/MM/yyyy-HH:mm") + " to " + format(endAt, "dd/MM/yyyy-HH:mm");

    const stateLabel = getStateLabel(state);
    const className = (classes as any)[stateLabel.toLocaleLowerCase()];

    switch (state) {
        case ContouringWorkshopState.Past: label = "Finish";
            break;
        case ContouringWorkshopState.Future: label = "Start in " + formatDistance(startAt);
            break;
        case ContouringWorkshopState.Present: label = "In progress";
            break;
    }

    return <span title={title} aria-label={title}>
        <Button
            size="small" variant="outlined" color="default"
            className={className}
            classes={{ label: classes.label }}
            disabled
        >
            {label}
        </Button>
    </span>
}
