import React from "react";
import { useHistory } from "react-router-dom";
import {
    Button, ButtonGroup, ClickAwayListener, Grow,
    ListItemIcon,
    ListItemText,
    MenuItem, MenuList, Paper, Popper, withStyles
} from "@material-ui/core";
import FileSaver from 'file-saver';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { Guid, IsValidGuid } from "domain/static/Guid";
import { ClinicalContourItemResponse } from "domain/admin/response/ClinicalContourItemResponse";
import { SetArchivingStateClinicalContourCommand } from "domain/admin/command/SetArchivingStateClinicalContourCommand";
import { fullPath, RouteName } from "tools/Route";
import { ArchiveIcon, ArticleIcon, DownloadIcon, EditIcon } from "App/Theme";
import { sendMessage } from "tools/Message";
import { getFileAsync } from "tools/FileExtension";
import { GuidelineFormDialog, GuidelineFormDialogProps } from "pages/Articles/Guidelines";
import { ArticleFormDialog, ArticleFormDialogProps } from "pages/Articles";

const MuiListItemIcon = withStyles({
    root: {
        minWidth: 36,
    },
})(ListItemIcon);

export interface ClinicalContourTableItemMenuProps {
    clinicalDataId: Guid;
    contour: ClinicalContourItemResponse;
    editRouteName: RouteName;
    updateTable: () => void;

}

export default function ClinicalContourTableItemMenu({
    clinicalDataId, contour, editRouteName, updateTable }: ClinicalContourTableItemMenuProps
) {
    const history = useHistory();

    const editPath = React.useMemo(
        () => fullPath(editRouteName, { params: { id: clinicalDataId, contourId: contour.id } }),
        [clinicalDataId, editRouteName, contour.id]
    );
    const isFile = IsValidGuid(contour.fileId);
    const isGuideline = IsValidGuid(contour.guidelineId) && IsValidGuid(contour.articleId);

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [guidelineDialogProps, setGuidelineDialogProps] = React.useState({
        open: false,
        id: undefined as GuidelineFormDialogProps["id"],
        article: undefined as GuidelineFormDialogProps["article"],
    });
    const [articleDialogProps, setArticleDialogProps] = React.useState({
        open: false,
        id: undefined as ArticleFormDialogProps["id"],
    });

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    const handleActionEdit = () => history.push(editPath)

    const handleActionArchive = async () => {
        const command = new SetArchivingStateClinicalContourCommand({
            id: contour.id,
            isArchived: !contour.isArchived
        });
        await sendMessage(command);
        updateTable();
        setOpen(false);
    }

    const handleActionGuideline = () => {
        setOpen(false);
        if (isGuideline && contour.guidelineId && contour.articleId) {
            setGuidelineDialogProps({
                open: true,
                id: contour.guidelineId,
                article: {
                    id: contour.articleId,
                    name: contour.articleTitle
                }
            });
        }
    }

    const closeGuidelineDialog = () => setGuidelineDialogProps({
        open: false, id: undefined, article: undefined
    });

    const afterSaveGuidelineHandle = () => {
        closeGuidelineDialog();
        updateTable();
    }

    const handleActionArticle = () => {
        setOpen(false);
        if (isGuideline && contour.articleId) {
            setArticleDialogProps({
                open: true,
                id: contour.articleId,
            });
        }
    }

    const closeArticleDialog = () => setArticleDialogProps({
        open: false, id: undefined
    });

    const afterSaveArticleHandle = () => {
        closeArticleDialog();
        updateTable();
    }

    const handleActionDownload = async () => {
        if (isFile && contour.fileId) {
            const file = await getFileAsync(contour.fileId);
            FileSaver.saveAs(file.data, contour.fileName);
        }
        setOpen(false);
    }

    return <>
        <ButtonGroup
            color="default"
            variant="outlined"
            size="small"
            ref={anchorRef} aria-label="split button"
        >
            <Button
                color="secondary"
                startIcon={<EditIcon />}
                onClick={handleActionEdit}
            >
                Edit
            </Button>
            <Button
                size="small"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
            >
                <ArrowDropDownIcon />
            </Button>
        </ButtonGroup>
        <Popper
            open={open} anchorEl={anchorRef.current}
            role={undefined} transition disablePortal
            style={{ zIndex: 2000 }}
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu">
                                <MenuItem onClick={handleActionArchive}>
                                    <MuiListItemIcon>
                                        <ArchiveIcon fontSize="small" />
                                    </MuiListItemIcon>
                                    <ListItemText primary={contour.isArchived ? "unarchive" : "archive"} />
                                </MenuItem>
                                <MenuItem onClick={handleActionArticle} disabled={!isGuideline}>
                                    <MuiListItemIcon>
                                        <ArticleIcon fontSize="small" />
                                    </MuiListItemIcon>
                                    <ListItemText primary="edit article" />
                                </MenuItem>
                                <MenuItem onClick={handleActionGuideline} disabled={!isGuideline}>
                                    <MuiListItemIcon>
                                        <ArticleIcon fontSize="small" />
                                    </MuiListItemIcon>
                                    <ListItemText primary="edit guideline" />
                                </MenuItem>
                                <MenuItem onClick={handleActionDownload} disabled={!isFile}>
                                    <MuiListItemIcon>
                                        <DownloadIcon fontSize="small" />
                                    </MuiListItemIcon>
                                    <ListItemText primary="download resource" />
                                </MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>

        <GuidelineFormDialog
            {...guidelineDialogProps}
            onClose={closeGuidelineDialog}
            onAfterSave={afterSaveGuidelineHandle}
        />

        <ArticleFormDialog
            {...articleDialogProps}
            onClose={closeArticleDialog}
            onAfterSave={afterSaveArticleHandle}
        />
    </>
}