import { Url } from "cocoreact";

const routesConfigurations = {
  Dashboard: new Url("", "/"),
  SignIn: new Url("", "/login"),
  ResetPassword: new Url("", "/reset-password"),

  Contact: new Url("", "/contact"),

  ListUsers: new Url("", "/users"),
  NewUser: new Url("", "/users/new"),
  EditUser: new Url("", "/users/:id"),

  ListSelectItems: new Url("", "/select-items"),
  NewSelectItem: new Url("", "/select-items/new"),
  EditSelectItem: new Url("", "/select-items/:id"),

  ListArticles: new Url("", "/articles"),
  NewArticle: new Url("", "/articles/new"),
  EditArticle: new Url("", "/articles/:id"),
  EditArticleListGuidelines: new Url("", "/articles/:id/guidelines"),
  EditArticleNewGuideline: new Url("", "/articles/:id/guidelines/new"),
  EditArticleEditGuideline: new Url(
    "",
    "/articles/:id/guidelines/:guidelineId"
  ),

  ListClinicalCases: new Url("", "/clinical-cases"),
  NewClinicalCase: new Url("", "/clinical-cases/new"),
  EditClinicalCase: new Url("", "/clinical-cases/:id"),
  EditClinicalCaseListContributors: new Url("", "/clinical-cases/:id/contributors"),
  EditClinicalCaseListImages: new Url("", "/clinical-cases/:id/images"),
  EditClinicalCaseNewImage: new Url("", "/clinical-cases/:id/images/new"),
  EditClinicalCaseEditImage: new Url("", "/clinical-cases/:id/images/:imageId"),
  EditClinicalCaseListContours: new Url("", "/clinical-cases/:id/contours"),
  EditClinicalCaseNewContour: new Url("", "/clinical-cases/:id/contours/new"),
  EditClinicalCaseEditContour: new Url(
    "",
    "/clinical-cases/:id/contours/:contourId"
  ),
  EditClinicalCaseEditScene: new Url("", "/clinical-cases/:id/scene"),

  ListContouringWorkshops: new Url("", "/contouring-workshops"),
  NewContouringWorkshop: new Url("", "/contouring-workshops/new"),
  EditContouringWorkshop: new Url("", "/contouring-workshops/:id"),
  EditContouringWorkshopListContributors: new Url("", "/contouring-workshops/:id/contributors"),
  EditContouringWorkshopListImages: new Url("", "/contouring-workshops/:id/images"),
  EditContouringWorkshopNewImage: new Url("", "/contouring-workshops/:id/images/new"),
  EditContouringWorkshopEditImage: new Url("", "/contouring-workshops/:id/images/:imageId"),
  EditContouringWorkshopListContours: new Url("", "/contouring-workshops/:id/contours"),
  EditContouringWorkshopNewContour: new Url("", "/contouring-workshops/:id/contours/new"),
  EditContouringWorkshopEditContour: new Url(
    "",
    "/contouring-workshops/:id/contours/:contourId"
  ),
  EditContouringWorkshopEditScene: new Url("", "/contouring-workshops/:id/scene"),
  EditContouringWorkshopListResults: new Url("", "/contouring-workshops/:id/results"),
} as const;

export type RouteName = keyof typeof routesConfigurations;

export interface RouteOptions {
  params?: Record<string, string>;
  queryString?: Record<string, string>;
  hash?: string;
}

export function fullPath(name: RouteName, options?: RouteOptions) {
  const url = routesConfigurations[name].clone();

  if (options?.params) {
    url.setParameters(options.params);
  }

  let path = url.fullPath();

  if (options?.queryString) {
    let qs = "";
    for (const [key, value] of Object.entries(options.queryString)) {
      if (qs.length > 0) {
        qs = qs + "&";
      }
      qs = qs + encodeURI(key) + "=" + encodeURI(value);
    }
    path = path + "?" + qs;
  }

  if (options?.hash) {
    const hash = `#${options.hash}`.replace("##", "#");
    path = path + hash;
  }

  return path;
}
