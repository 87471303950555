import React, { useCallback, useState } from "react";
import {
    Button, CircularProgress, Typography, TypographyProps
} from "@material-ui/core";
import { useNotificationContext } from "cocoreact";

import { formErrorService } from "services";
import { sendMessage } from "tools/Message";
import { PublishIcon, UnpublishIcon } from "App/Theme";
import { SetPublishingContouringWorkshopCommand } from "domain/admin/command/SetPublishingContouringWorkshopCommand";
import { ContouringWorkshopResponse } from "domain/admin/response/ContouringWorkshopResponse";
import { Flex, Spacer } from "components/Page";

function Paragraph(props: TypographyProps) {
    return <Typography style={{ margin: "8px 0" }} {...props} />
}

interface StateButtonProps {
    disabled?: boolean;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    onClick: () => void;
}

function PublishButton({ onClick, disabled, startIcon, endIcon }: StateButtonProps) {
    return <Button
        size="large"
        color="primary"
        variant="contained"
        disabled={disabled}
        startIcon={startIcon}
        endIcon={endIcon}
        onClick={onClick}
    >
        publish
    </Button>
}

function UnpublishButton({ onClick, disabled, startIcon, endIcon }: StateButtonProps) {
    return <Button
        size="large"
        color="default"
        variant="outlined"
        disabled={disabled}
        startIcon={startIcon}
        endIcon={endIcon}
        onClick={onClick}
    >
        unpublish
    </Button>
}

export interface StateManagerProps {
    loading: boolean;
    entity: ContouringWorkshopResponse;
    onAfterUpdate?: () => void;
}

export default function StateManager({ loading, entity, onAfterUpdate }: StateManagerProps) {

    const { success, error } = useNotificationContext();

    const [actionsDisabled, setActionsDisabled] = useState(false);

    const updateStateHandle = useCallback(async (isPublished: boolean, comment: string | null) => {
        setActionsDisabled(true);

        const command = new SetPublishingContouringWorkshopCommand();
        command.id = entity.id;
        command.isPublished = isPublished;

        try {
            await sendMessage(command);
            success("The contouing workshop state has been updated with success");
        }
        catch (e) {
            const err = formErrorService.parse(e.response);
            const message = typeof err === "string" ? err : "An error occured !"
            error(message);
        }

        onAfterUpdate && onAfterUpdate();
        setActionsDisabled(false);

    }, [entity.id, error, onAfterUpdate, success]);

    const publishLabel = (
        <>
            <Paragraph>
                To make your contouring workshop visible to the participant users, you must publish it.
            </Paragraph>
            <Paragraph>
                To be publish, the contouring workshop must contains a primary image
                and at least one contour.
            </Paragraph>
        </>
    );
    const unpublishLabel = (
        <>
            <Paragraph>
                If you unpublish your contouring workshop, it will be no longer visible by the participant users.
            </Paragraph>
            <Paragraph>
                You will republish it later if you wish.
            </Paragraph>
        </>
    );

    const progressIcon = <CircularProgress size={16} color="inherit" />

    const publishButton = <PublishButton
        disabled={loading || actionsDisabled}
        endIcon={actionsDisabled ? progressIcon : <PublishIcon />}
        onClick={() => updateStateHandle(true, null)}
    />

    const unpublishButton = <UnpublishButton
        disabled={loading || actionsDisabled}
        endIcon={actionsDisabled ? progressIcon : <UnpublishIcon />}
        onClick={() => updateStateHandle(false, null)}
    />

    return <>
        {entity.isPublished ? unpublishLabel : publishLabel}

        <Flex marginTop={2}>
            <Spacer />
            {entity.isPublished ? unpublishButton : publishButton}
        </Flex>
    </>
}
