import React from "react";
import {
    createStyles, IconButton, ListItem, ListItemAvatar, ListItemIcon, ListItemText,
    makeStyles, Theme
} from "@material-ui/core";
import { Link } from "react-router-dom";

import { EditIcon } from "App/Theme";
import ClinicalCaseStateWidget from "./ClinicalCaseStateWidget";
import { ClinicalCaseState } from "domain/static/ClinicalCaseState";
import { ClinicalCaseUserRole, ClinicalCaseUserRoleOptions } from "domain/static/ClinicalCaseUserRole";
import { Guid } from "domain/static/Guid";
import { fullPath } from "tools/Route";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        padding: theme.spacing(1, 2),
        border: "1px solid black",
        borderColor: theme.palette.divider,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.default
    },
    avatar: {
        minWidth: 100,
        maxWidth: 100,
        width: 100,
        marginRight: theme.spacing(2),
        textAlign: "right",
    }
}));

export interface ClinicalCaseProps {
    id: Guid;
    name: string;
    state: ClinicalCaseState;
    userRole: ClinicalCaseUserRole;
}

export interface ClinicalCaseListItemProps {
    entity: ClinicalCaseProps;
}

export default function ClinicalCaseListItem({ entity }: ClinicalCaseListItemProps) {
    const classes = useStyles();

    const href = fullPath("EditClinicalCase", { params: { id: entity.id } })
    const role = ClinicalCaseUserRoleOptions.find(x => x.value === entity.userRole)?.label ?? "user";

    return <ListItem className={classes.root}>
        <ListItemAvatar className={classes.avatar}>
            <ClinicalCaseStateWidget state={entity.state} />
        </ListItemAvatar>
        <ListItemText
            primary={entity.name}
            secondary={role}
        />
        <ListItemIcon>
            <Link to={href}>
                <IconButton>
                    <EditIcon />
                </IconButton>
            </Link>
        </ListItemIcon>
    </ListItem >
}
