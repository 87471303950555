import {
	TypeWidgetOptionsBuilder,
	TextFormWidget,
	SelectFormWidget,
	SwitchFormWidget
} from "cocoreact";

import DateFormWidget from "./widgets/DateFormWidget";
import AutoCompleteFormWidget from "./widgets/AutoCompleteFormWidget";
import EnumOptionsFormWidget from "./widgets/EnumOptionsFormWidget";
import ImageFormWidget from "./widgets/ImageFormWidget";
import FileFormWidget from "./widgets/FileFormWidget";
import ColorFormWidget from "./widgets/ColorFormWidget";
import SelectLutFormWidget from "./widgets/SelectLutFormWidget";
import SelectItemFormWidget from "./widgets/SelectItemFormWidget";
import SearchGuidelineFormWidget from "./widgets/SearchGuidelineFormWidget";
import SearchArticleFormWidget from "./widgets/SearchArticleFormWidget";
import RichTextFormWidget from "./widgets/RichTextFormWidget";
import UserRoleFormWidget from "./widgets/UserRoleFormWidget";

const formWidgetOptions = new TypeWidgetOptionsBuilder()
	.set("string", TextFormWidget)
	.set("number", TextFormWidget)
	.set("email", TextFormWidget)
	.set("password", TextFormWidget)
	.set("select", SelectFormWidget)
	.set("boolean", SwitchFormWidget)
	.set("Date", DateFormWidget)
	.set("autocomplete", AutoCompleteFormWidget)
	.set("SelectItemType", EnumOptionsFormWidget)
	.set("VolumeType", EnumOptionsFormWidget)
	.set("ImagingDefault", EnumOptionsFormWidget)
	.set("ClinicalCaseState", EnumOptionsFormWidget)
	.set("FromEmailType", EnumOptionsFormWidget)
	.set("UserRole", UserRoleFormWidget)
	.set("image", ImageFormWidget)
	.set("file", FileFormWidget)
	.set("color", ColorFormWidget)
	.set("SelectLut", SelectLutFormWidget)
	.set("SelectItem", SelectItemFormWidget)
	.set("SearchGuideline", SearchGuidelineFormWidget)
	.set("SearchArticle", SearchArticleFormWidget)
	.set("richtext", RichTextFormWidget)
	.build();

export default formWidgetOptions;
