/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { IFormWidgetPropsBase } from "cocoreact";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
	Button, CircularProgress, ClickAwayListener, createStyles,
	FormControl, Grow, IconButton, makeStyles, MenuItem, MenuList,
	Paper, Popper, TextField, Theme, Typography
} from "@material-ui/core";
import ExternalLinkIcon from "@material-ui/icons/TransitEnterexit";

import { sendMessage } from "tools/Message";
import { guidelineInfoLabel } from "tools/StringExtension";
import { GuidelineFormDialogProps, GuidelineFormDialog } from "pages/Articles/Guidelines";
import { AddIcon, MoreIcon } from "App/Theme";
import { Guid, IsValidGuid } from "domain/static/Guid";
import { VolumeType } from "domain/static/VolumeType";
import { GuidelineOptionResponse } from "domain/admin/response/GuidelineOptionResponse";
import { SearchGuidelineOptionsQuery } from "domain/admin/query/SearchGuidelineOptionsQuery";
import { GetGuidelineOptionQuery } from "domain/admin/query/GetGuidelineOptionQuery";

const useOptionItemStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "row",
			width: "100%",
		},
		left: {
			display: "flex",
			flexDirection: "column",
			flexGrow: 1,
			justifyContent: "center",
		},
		right: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
		},
		linkIcon: {
			transform: "rotate(180deg)",
		},
		onlyIcon: {
			margin: theme.spacing(1, 1.5),
		}
	})
);

function GuidelineOptionItem({
	option
}: { option: GuidelineOptionResponse }) {
	const classes = useOptionItemStyles();

	const title = guidelineInfoLabel(option.anatomicStructureName, option.volumeType, option.targetTypeName, option.organizationName);
	const subtitle = option.articleTitle;

	return (
		<div
			className={classes.container}
			title={`${title}\n${subtitle}`}
		>
			<div className={classes.left}>
				<Typography component="span" variant="subtitle1">
					{title}
				</Typography>
				<Typography
					component="span"
					variant="caption"
				>
					{subtitle}
				</Typography>
			</div>
			<div className={classes.right}>
				<a
					href={option.articleUrl}
					target="_blank"
					rel="noopener noreferrer"
					onClick={e => e.stopPropagation()}
					title="Open article"
				>
					<IconButton>
						<ExternalLinkIcon className={classes.linkIcon} />
					</IconButton>
				</a>
			</div>
		</div>
	)
}

function CreateGuidelineOption() {
	const classes = useOptionItemStyles();

	return (
		<div className={classes.container}>
			<div className={classes.left}>
				<Typography component="span" variant="subtitle1">
					Create a new guideline
				</Typography>
			</div>
			<div className={classes.right}>
				<AddIcon className={classes.onlyIcon} color="inherit" />
			</div>
		</div>
	)
}

// --------------------------------------------------------

const useCardStyles = makeStyles((theme: Theme) =>
	createStyles({
		link: {
			position: "relative",
			display: "flex",
			flexDirection: "column",
			color: theme.palette.text.primary,
			padding: theme.spacing(2, 2),
			border: theme.shape.borderRadius,
			"&:hover": {
				backgroundColor: theme.palette.background.default,
			}
		},
		moreBtn: {
			position: "absolute",
			top: theme.spacing(1),
			right: theme.spacing(1),
			color: theme.palette.getContrastText(theme.palette.background.default),
			minWidth: 32,
			maxWidth: 32,
			"&:hover": {
				backgroundColor: theme.palette.background.default,
			}
		},
	})
);

interface GuidelinePreviewCardProps {
	value: GuidelineOptionResponse;
	onDelete: () => void;
	onEdit: (id: Guid) => void;
}

function GuidelinePreviewCard({
	value, onDelete, onEdit
}: GuidelinePreviewCardProps) {

	const classes = useCardStyles();
	const menuRef = useRef<HTMLButtonElement>(null);
	const [menuOpen, setMenuOpen] = useState(false);

	const title = guidelineInfoLabel(
		value.anatomicStructureName,
		value.volumeType,
		value.targetTypeName,
		value.organizationName
	);

	const openHandle = useCallback((e: any) => {
		setMenuOpen(true);
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
	}, []);

	const closeHandle = useCallback((e: any) => {
		setMenuOpen(false);
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
	}, []);

	const subtitle = value.articleTitle;

	return (
		<Paper>
			<a
				href={value.articleUrl}
				target="_blank"
				rel="noopener noreferrer"
				className={classes.link}
			>
				<Typography component="span" variant="subtitle1">
					{title}
				</Typography>
				<Typography
					component="span"
					variant="caption"
				>
					{subtitle}
				</Typography>
			</a>

			<Button
				ref={menuRef}
				className={classes.moreBtn}
				onClick={openHandle}
			>
				<MoreIcon />
			</Button>

			<Popper
				open={menuOpen}
				anchorEl={menuRef.current}
				role={undefined}
				transition={true}
				disablePortal={true}
				placement="bottom-end"
				style={{ zIndex: 10000000 }}
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<Paper>
							<ClickAwayListener
								onClickAway={closeHandle}
							>
								<MenuList>
									<MenuItem
										onClick={(e: any) => {
											closeHandle(e);
											onEdit(value.id);
										}}
									>
										Edit guideline
									</MenuItem>
									<MenuItem onClick={onDelete}>Remove</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</Paper>
	);
}

// --------------------------------------------------------

async function searchGuidelinesByKeywords(keywords: string) {
	if (keywords && keywords.length > 0) {
		const query = new SearchGuidelineOptionsQuery({ keywords, top: 50 });
		const guidelines = await sendMessage<GuidelineOptionResponse[]>(query);
		return guidelines;
	}
	return [];
}

function guidelineOptionInfoToString(option: GuidelineOptionResponse) {
	return guidelineInfoLabel(
		option.anatomicStructureName,
		option.volumeType,
		option.targetTypeName,
		option.organizationName
	);
}

const CREATE_CUSTOM_GUIDELINE_ID = "create-guideline";

function isCustomValue(v: GuidelineOptionResponse): boolean {
	return v.id === CREATE_CUSTOM_GUIDELINE_ID;
}

const CREATE_CUSTOM_GUIDELINE =
	{
		id: CREATE_CUSTOM_GUIDELINE_ID,
		anatomicStructureName: "anatomicStructureName",
		articleTitle: "articleTitle",
		articleUrl: "articleUrl",
		targetTypeName: "targetTypeName",
		volumeType: VolumeType.Anatomy,
	} as GuidelineOptionResponse;

// --------------------------------------------------------

export interface SearchGuidelineFormWidgetProps extends IFormWidgetPropsBase<any> {
	defaultValue?: GuidelineOptionResponse;
	noOptionsText?: string;
}

export default function SearchGuidelineFormWidget(props: SearchGuidelineFormWidgetProps) {
	const timer = useRef<NodeJS.Timeout | null>(null);
	const [loading, setLoading] = useState(false);
	const [options, setOptions] = useState<GuidelineOptionResponse[]>([]);
	const [dialogProps, setDialogProps] = useState({
		open: false,
		id: undefined as GuidelineFormDialogProps["id"],
		article: undefined as GuidelineFormDialogProps["article"],
	});
	const [guideline, setGuideline] = useState<GuidelineOptionResponse | undefined>(undefined);

	useEffect(() => {
		setGuideline(props.defaultValue);
	}, [props.defaultValue]);

	useEffect(() => {
		if (props.value) {
			return;
		}
		setGuideline(undefined);
	}, [props.defaultValue, props.value]);

	const closeDialogHandle = useCallback(
		() => setDialogProps({ open: false, id: undefined, article: undefined }),
		[]
	);

	const onChangeHandle = useCallback(
		(_e: any, option: GuidelineOptionResponse | null) => {
			if (option && isCustomValue(option)) {
				setDialogProps({ open: true, id: undefined, article: undefined });
				setGuideline(undefined);
				return;
			}
			if (option) {
				setGuideline(option);
				setOptions([]);
				props.onChange && props.onChange(props.name, option.id);
				return;
			}
			props.onChange && props.onChange(props.name, undefined);
		},
		[props]
	);

	const inputChangeHandle = useCallback(async (keywords: string) => {
		if (timer.current) {
			clearTimeout(timer.current);
		}
		timer.current = setTimeout(async () => {
			setLoading(true);
			let guidelines = [] as GuidelineOptionResponse[];
			if (keywords && keywords.length > 0) {
				guidelines = await searchGuidelinesByKeywords(keywords);
			}
			setLoading(false);
			setOptions(guidelines);
		}, 400);
	}, []);

	const filterOptionsHandle = useCallback(
		(options: GuidelineOptionResponse[]) => options,
		[]
	);

	const getOptionLabelHandle = useCallback(
		(option: GuidelineOptionResponse) => IsValidGuid(option.id)
			? guidelineOptionInfoToString(option)
			: "",
		[]
	);

	const getOptionSelectedHandle = useCallback(
		(option: GuidelineOptionResponse, value: GuidelineOptionResponse) => option.id === value.id,
		[]
	);

	const renderOptionHandle = useCallback(
		(option: GuidelineOptionResponse) => isCustomValue(option)
			? <CreateGuidelineOption />
			: <GuidelineOptionItem option={option} />,
		[]
	);

	const onEditHandle = useCallback(() => {
		if (guideline) {
			setDialogProps({
				open: true,
				id: guideline.id,
				article: {
					id: guideline.articleId,
					name: guideline.articleTitle,
				},
			});
		}
	}, [guideline]);

	const onAfterSaveHandle = useCallback(async (id: Guid) => {
		setLoading(true);

		const query = new GetGuidelineOptionQuery({ id });
		const guideline = await sendMessage<GuidelineOptionResponse>(query);

		setLoading(false);
		setGuideline(guideline);
		setOptions([]);
		props.onChange && props.onChange(props.name, id);

		closeDialogHandle();

	}, [closeDialogHandle, props]);

	return (
		<>
			{guideline &&
				<FormControl margin="normal" fullWidth={true}>
					<GuidelinePreviewCard
						value={guideline}
						onEdit={onEditHandle}
						onDelete={() => props.onChange && props.onChange(props.name, undefined)}
					/>
				</FormControl>
			}

			{!guideline &&
				<Autocomplete<GuidelineOptionResponse>
					options={[...options, CREATE_CUSTOM_GUIDELINE]}
					noOptionsText={props.noOptionsText}
					className={props.className}
					style={props.style}
					autoComplete={false}
					clearOnBlur={false}
					clearOnEscape={true}
					fullWidth={true}
					onChange={onChangeHandle}
					getOptionLabel={getOptionLabelHandle}
					getOptionSelected={getOptionSelectedHandle}
					renderOption={renderOptionHandle}
					filterOptions={filterOptionsHandle}
					renderInput={(params) => (
						<TextField
							required={props.required}
							margin={props.margin}
							autoComplete={props.autoComplete}
							autoFocus={props.autoFocus}
							placeholder={props.placeholder}
							color={props.color as any}
							onChange={e => inputChangeHandle(e.target.value)}
							{...params}
							inputProps={{
								...params.inputProps,
								autoComplete: "search-guideline-textfield"
							}}
							InputProps={{
								...params.InputProps,
								endAdornment: (<React.Fragment>
									{loading ? <CircularProgress color="inherit" size={20} /> : null}
									{params.InputProps.endAdornment}
								</React.Fragment>)
							}}
							label={props.label}
							aria-label={props.label}
						/>
					)}
				/>
			}

			<GuidelineFormDialog
				{...dialogProps}
				onClose={closeDialogHandle}
				onAfterSave={onAfterSaveHandle}
			/>
		</>
	);
}
