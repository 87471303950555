import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useNotificationContext } from "cocoreact";

import { CentralColumnLayout } from "components/Layouts";
import { Guid, EmptyGuid } from "domain/static/Guid";
import { Paper, PaperTitle } from "components/Page";
import { ClinicalImageForm } from "components/ClinicalData";
import { fullPath } from "tools/Route";
import { ClinicalCaseIcon } from "App/Theme";

export default function EditClinicalImagePanel() {

    const params = useParams<{ id: Guid, imageId: Guid | undefined }>();
    const history = useHistory();
    const { success } = useNotificationContext();

    const handleAfterSave = () => {
        const listUrl = fullPath("EditClinicalCaseListImages", { params: { id: params.id } });
        history.push(listUrl);
        success("Clinical image saved with success");
    }

    const title = (params.imageId ? "Edit" : "Create") + " a clinical case image";

    return <CentralColumnLayout size={"md"}>

        <Paper>
            <PaperTitle icon={<ClinicalCaseIcon />}>{title}</PaperTitle>

            <ClinicalImageForm
                clinicalDataId={params.id}
                imageId={params.imageId ?? EmptyGuid()}
                onAfterSave={handleAfterSave}
            />
        </Paper>

    </CentralColumnLayout>
}
