import React, { useCallback, useRef, useState } from "react";
import {
    Button, makeStyles, Theme, createStyles,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    List, ListItem, ListItemText
} from "@material-ui/core";

import { CloseIcon, DownloadIcon } from "App/Theme";
import { FileInput, LoadingDialog } from "components/Page";
import { ImportZoteroBiblioCommand } from "domain/admin/command/ImportZoteroBiblioCommand";
import { ImportZoteroBiblioResponse } from "domain/admin/response/ImportZoteroBiblioResponse";
import { readFileAsync } from "tools/FileExtension";
import { sendMessage } from "tools/Message";
import { fromArrayBuffer } from "tools/StringExtension";

const useStyles = makeStyles((theme: Theme) => createStyles({
    errorList: {
        backgroundColor: theme.palette.background.default,
        overflowY: "auto",
        maxHeight: 350,
        boxShadow: "0px 2px 10px 3px rgba(0, 0, 0, 0.2) inset",
        "& li": {
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
            borderBottomColor: theme.palette.divider,
        },
        "& li:last-child": {
            borderBottomWidth: 0,
        }
    }
}));

export interface BiblioLoaderButtonProps {
    onSuccess?: VoidFunction;
}

export default function BiblioLoaderButton({ onSuccess }: BiblioLoaderButtonProps) {
    const classes = useStyles();

    const loadRef = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState("");
    const [response, setResponse] = useState<ImportZoteroBiblioResponse | undefined>(undefined);

    const handleSelectFile = useCallback(() => {
        if (loadRef.current) {
            loadRef.current.click();
        }
    }, []);

    const handleOpenFile = useCallback(async (files: FileList | File) => {
        if (files instanceof FileList) return;
        const file = files;

        setStep("Loading Zotero bibliography ...");
        setLoading(true);
        setResponse(undefined);

        const arrayBuffer = await readFileAsync(file);
        const content = fromArrayBuffer(arrayBuffer);

        setStep("Import articles ...");

        const command = new ImportZoteroBiblioCommand({ content });
        const response = await sendMessage<ImportZoteroBiblioResponse>(command);
        setResponse(response);
        setLoading(false);

        onSuccess && onSuccess();

    }, [onSuccess]);

    return (
        <>
            <Button
                color="secondary"
                variant="contained"
                startIcon={<DownloadIcon />}
                title="load a RadioTherapie Structure"
                onClick={handleSelectFile}
            >
                load zotero biblio
            </Button>

            <FileInput
                ref={loadRef}
                onSelect={handleOpenFile}
            />

            <LoadingDialog
                open={loading}
                sentence={step}
            />

            <Dialog open={!!response} onClose={() => setResponse(undefined)}>
                <DialogTitle>Zotero import completed</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Number of articles imported : {response?.numberOfArticleCreated}
                    </DialogContentText>

                    {response?.errors && <>
                        <DialogContentText>
                            Errors :
                        </DialogContentText>

                        <List className={classes.errorList}>
                            {response?.errors.map((x, idx) => (
                                <ListItem key={idx}>
                                    <ListItemText primary={x.name} secondary={x.message} />
                                </ListItem>
                            ))}
                        </List>
                    </>}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        startIcon={<CloseIcon />}
                        onClick={() => setResponse(undefined)}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
