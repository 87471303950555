import React from "react";

import {
    makeStyles, Theme, createStyles,
    Paper as MuiPaper,
    Typography,
    SvgIconProps
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({
    paper: {
        padding: theme.spacing(2),
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            padding: theme.spacing(3),
        },
    },
    paperTitle: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(1),
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            marginBottom: theme.spacing(2),
        },
        "& svg": {
            marginRight: theme.spacing(1),
        }
    }
}));

// --------------------------------------------------------

export interface PaperTitleProps {
    icon?: React.ReactElement<SvgIconProps>;
    children: any;
}

export function PaperTitle({ icon, children }: PaperTitleProps) {
    const classes = useStyles();
    return <Typography variant="h6" className={classes.paperTitle}>
        {icon}{children}
    </Typography>
}

// --------------------------------------------------------

export interface PaperProps {
    children: React.ReactNode | React.ReactNode[];
}

export default function Paper({ children }: PaperProps) {
    const classes = useStyles();
    return <MuiPaper className={classes.paper}>
        {children}
    </MuiPaper>
}
