import { Guid } from "domain/static/Guid";
import { ClinicalCaseState } from "domain/static/ClinicalCaseState";
import { IField } from "cocoreact";

//Generated code, do not modify

export class ClinicalCaseResponse {
    id!: Guid;
    name!: string;
    slug!: string;
    summary!: string;
    anatomicZoneId!: Guid;
    anatomicZoneName!: string;
    state!: ClinicalCaseState;
    coverId!: Guid | null;
    submittedAt!: Date | null;
    publishedAt!: Date | null;
    isArchived!: boolean;
    author!: User;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        name: { name: "name", type: "string" },
        slug: { name: "slug", type: "string" },
        summary: { name: "summary", type: "string" },
        anatomicZoneId: { name: "anatomicZoneId", type: "Guid" },
        anatomicZoneName: { name: "anatomicZoneName", type: "string" },
        state: { name: "state", type: "ClinicalCaseState" },
        coverId: { name: "coverId", type: "Guid" },
        submittedAt: { name: "submittedAt", type: "Date" },
        publishedAt: { name: "publishedAt", type: "Date" },
        isArchived: { name: "isArchived", type: "boolean" },
        author: { name: "author", type: "User" },
    } as Record<keyof ClinicalCaseResponse, IField>;
}

export class User {
    firstname!: string;
    lastname!: string;

    public static Fields = {
        firstname: { name: "firstname", type: "string" },
        lastname: { name: "lastname", type: "string" },
    } as Record<keyof User, IField>;
}
