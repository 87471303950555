import { Guid } from "domain/static/Guid";
import { ContouringWorkshopState } from "domain/static/ContouringWorkshopState";
import { IField } from "cocoreact";

//Generated code, do not modify

export class ContouringWorkshopResponse {
    id!: Guid;
    name!: string;
    slug!: string;
    summary!: string;
    anatomicZoneId!: Guid;
    anatomicZoneName!: string;
    startAt!: Date;
    endAt!: Date;
    state!: ContouringWorkshopState;
    limitOfParticipants!: number;
    coverId!: Guid | null;
    isPublished!: boolean;
    publishedAt!: Date | null;
    isArchived!: boolean;
    author!: User;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        name: { name: "name", type: "string" },
        slug: { name: "slug", type: "string" },
        summary: { name: "summary", type: "string" },
        anatomicZoneId: { name: "anatomicZoneId", type: "Guid" },
        anatomicZoneName: { name: "anatomicZoneName", type: "string" },
        startAt: { name: "startAt", type: "Date" },
        endAt: { name: "endAt", type: "Date" },
        state: { name: "state", type: "ContouringWorkshopState" },
        limitOfParticipants: { name: "limitOfParticipants", type: "number" },
        coverId: { name: "coverId", type: "Guid" },
        isPublished: { name: "isPublished", type: "boolean" },
        publishedAt: { name: "publishedAt", type: "Date" },
        isArchived: { name: "isArchived", type: "boolean" },
        author: { name: "author", type: "User" },
    } as Record<keyof ContouringWorkshopResponse, IField>;
}

export class User {
    firstname!: string;
    lastname!: string;

    public static Fields = {
        firstname: { name: "firstname", type: "string" },
        lastname: { name: "lastname", type: "string" },
    } as Record<keyof User, IField>;
}
