/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo } from "react";
import { IFormWidgetPropsBase } from "cocoreact";

import AutoCompleteFormWidget, { AutoCompleteOption } from "./AutoCompleteFormWidget";
import { UserRole, UserRoleOptions } from "domain/static/UserRole";
import { enumHasFlag } from "tools/EnumExtension";

export interface SelectItemFormWidgetProps extends IFormWidgetPropsBase<UserRole> { }

export default function SelectItemFormWidget(props: SelectItemFormWidgetProps) {
	const options = useMemo(() => {
		const options = UserRoleOptions
			.filter(x => x.value !== UserRole.None)
			.map(x => x as AutoCompleteOption);
		return options;
	}, []);

	const getSelectedOptionsHandler = useCallback((options: AutoCompleteOption[], value: UserRole) => {
		return options.filter(x => enumHasFlag(value, x.value));
	}, []);

	const buildValueHandler = useCallback((selectedOptions: AutoCompleteOption[]) => {
		return selectedOptions.reduce((acc, options) => acc | options.value, UserRole.None);
	}, []);

	return <AutoCompleteFormWidget
		{...props}
		multiple={true}
		options={options}
		getSelectedOptions={getSelectedOptionsHandler}
		buildValue={buildValueHandler}
	/>
}
