import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";

import {
  Drawer as MuiDrawer,
  Hidden, Divider,
  List, ListItem,
  ListItemIcon, ListItemText,
  Theme,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";

import {
  DashboardIcon,
  SelectItemIcon,
  ArticleIcon,
  ClinicalCaseIcon,
  ContouringWorkshopIcon,
  UserIcon,
  EmailIcon,
} from "App/Theme";
import { fullPath, RouteName } from "tools/Route";
import { useThemeContext } from "contexts/Theme";
import { Spacer } from "components/Page";
import { useAuthContext } from "contexts/Auth";

interface IMenuItem {
  label: string;
  icon: JSX.Element;
  name: RouteName;
}

const MENU = [
  [
    {
      label: "Dashboard",
      icon: <DashboardIcon />,
      name: "Dashboard",
    },
  ],
  [
    {
      label: "Select items",
      icon: <SelectItemIcon />,
      name: "ListSelectItems",
    },
    {
      label: "Articles",
      icon: <ArticleIcon />,
      name: "ListArticles",
    },
    {
      label: "Clinical cases",
      icon: <ClinicalCaseIcon />,
      name: "ListClinicalCases",
    },
    {
      label: "Workshops",
      icon: <ContouringWorkshopIcon />,
      name: "ListContouringWorkshops",
    },
    {
      label: "Users",
      icon: <UserIcon />,
      name: "ListUsers",
    },
  ],
  [
    {
      label: "Contact",
      icon: <EmailIcon />,
      name: "Contact",
    },
  ],
] as const;

export type MenuName = typeof MENU[number][number]['name'];

// --------------------------------------------------------

const useLinkStyles = makeStyles((theme: Theme) => createStyles({
  link: {
    color: "inherit",
  },
  selected: {
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette.background.paper,
    "& span": {
      fontWeight: 500,
    },
    "& svg": {
      color: theme.palette.secondary.light,
    }
  }
}));

interface MenuItemProps {
  item: IMenuItem;
  selected?: boolean;
}

function MenuItem({ item, selected }: MenuItemProps) {
  const classes = useLinkStyles();

  const className = clsx(classes.link, selected ? classes.selected : undefined)

  return (
    <Link to={fullPath(item.name)} className={className}>
      <ListItem button>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.label} />
      </ListItem>
    </Link>
  );
}

// --------------------------------------------------------

interface MenuItemListProps {
  items: IMenuItem[];
  selected?: MenuName;
}

function MenuItemList({ items, selected }: MenuItemListProps) {
  return <>
    <Divider />
    <List>
      {items.map((item, idx) =>
        <MenuItem key={idx} item={item} selected={item.name === selected} />
      )}
    </List>
  </>
}

// --------------------------------------------------------

const useStyles = makeStyles((theme: Theme) => createStyles({
  drawer: {
    zIndex: theme.zIndex.appBar - 1,
    [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
      width: theme.mixins.drawer.width,
      flexShrink: 0,
    },
  },
  paper: {
    width: theme.mixins.drawer.width,
  },
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "left",
    padding: theme.spacing(4, 2),
    "& .avatarContainer": {
      borderRadius: "50%",
      backgroundColor: theme.palette.background.default,
      display: "flex",
      justifyContent: "center",
      alignItems: "left",
      width: 80,
      boxShadow: theme.shadows[10]
    },
    "& .avatar": {
      margin: theme.spacing(2),
    }
  }
}));

declare var APP_DEPLOY_DATE: string;
declare var APP_COMMIT_NUMBER: string;

function AppInfo() {
  return <p style={{ textAlign: "center" }}>
    {APP_COMMIT_NUMBER}-{APP_DEPLOY_DATE}
  </p>
}

function Header() {
  const classes = useStyles();
  const { payload } = useAuthContext();
  return <div className={classes.header}>
    <div className="avatarContainer">
      <img src={process.env.PUBLIC_URL + "/logo192.png"} alt="logo" className="avatar" height={48} />
    </div>
    <p></p>
    <span>{payload?.firstname} {payload?.lastname}</span>
  </div>
}

export interface DrawerProps {
  menuSelected?: MenuName;
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
}

export default function Drawer({ menuSelected, drawerOpen, setDrawerOpen }: DrawerProps) {

  const classes = useStyles();
  const { theme } = useThemeContext();

  const drawerMenu = React.useMemo(() => MENU.map((m, idx) =>
    <MenuItemList key={idx} items={m as any} selected={menuSelected} />
  ), [menuSelected]);

  const drawer = React.useMemo(() => {
    return (<>
      <Header />
      {drawerMenu}
      <Spacer />
      <AppInfo />
    </>)
  }, [drawerMenu]);

  return (
    <nav className={classes.drawer}>

      <Hidden
        only={theme.mixins.drawer.largeDevice}
        implementation={theme.mixins.drawer.implementation}
      >
        <MuiDrawer
          closeAfterTransition={true}
          open={drawerOpen}
          anchor="left"
          variant="temporary"
          classes={{ paper: classes.paper }}
          onClose={() => setDrawerOpen(false)}
        >
          {drawer}
        </MuiDrawer>
      </Hidden>

      <Hidden
        only={theme.mixins.drawer.smallDevice}
        implementation={theme.mixins.drawer.implementation}
      >
        <MuiDrawer
          open
          variant="permanent"
          classes={{ paper: classes.paper }}
        >
          {drawer}
        </MuiDrawer>
      </Hidden>

    </nav>
  )
}