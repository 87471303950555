import React from "react";
import { Redirect } from "react-router-dom";

import { useAuthContext } from "contexts/Auth";
import { fullPath } from "tools/Route";
import { useSearchParams } from "tools/useSearchParams";
import { enumHasFlag } from "tools/EnumExtension";
import { LogLayout } from "components/Layouts";
import SignInForm from "./SignInForm";
import { UserRole } from "domain/static/UserRole";

export default function SignInPage() {

    const { return_to } = useSearchParams<{ return_to: string }>();
    const { mounted, payload } = useAuthContext();

    const returnTo = return_to ?? fullPath("Dashboard");

    if (mounted && payload && enumHasFlag(payload.role, UserRole.Admin)) {
        return <Redirect to={returnTo} />
    }

    return <LogLayout title="Sign in to Dline">
        <SignInForm />
    </LogLayout>
}
