import React from "react";
import {
    Dialog, DialogContent, CircularProgress,
    Typography, makeStyles, createStyles, Theme, SvgIconProps
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({
    content: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        textAlign: "center",
    },
    loaderContainer: {
        margin: theme.spacing(2),
    }
}));

export interface LoadingDialogProps {
    open: boolean;
    sentence?: string;
    icon?: React.ReactElement<SvgIconProps>;
}

export default function LoadingDialog({ open, sentence, icon }: LoadingDialogProps) {
    const styles = useStyles();

    return (
        <Dialog open={open}>

            <DialogContent className={styles.content}>

                <div className={styles.loaderContainer}>
                    {icon ? icon : <CircularProgress color="secondary" size={64} />}
                </div>

                {sentence &&
                    <Typography>
                        {sentence}
                    </Typography>
                }

            </DialogContent>

        </Dialog>
    );
}