import { FromEmailType } from "domain/static/FromEmailType";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class SendMessageCommand implements IMessage {
    from!: FromEmailType;
    to!: string[];
    cc!: string[];
    bcc!: string[];
    subject!: string;
    content!: string;

    public constructor(init?: Partial<SendMessageCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "POST" as RequestMethod;

    getPath = () => `/admin/send-message`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            from: this.from,
            to: this.to,
            cc: this.cc,
            bcc: this.bcc,
            subject: this.subject,
            content: this.content,
        };
    }
    
    needAuthentication = () => true;
}
