import React from "react";
import { LoadingWrapper } from "cocoreact";

import { sendMessage } from "tools/Message";
import { Form, FormFieldsBuilder } from "components/Form";
import { ArchiveButton, SubmitButton } from "components/Buttons";
import { Guid, IsEmptyGuid, EmptyGuid } from "domain/static/Guid";
import useGetGuideline from "domain/hooks/useGetGuideline";
import { GuidelineResponse } from "domain/admin/response/GuidelineResponse";
import { CreateGuidelineCommand } from "domain/admin/command/CreateGuidelineCommand";
import { UpdateGuidelineCommand } from "domain/admin/command/UpdateGuidelineCommand";
import { SetArchivingStateGuidelineCommand } from "domain/admin/command/SetArchivingStateGuidelineCommand";
import { SelectItemType } from "domain/static/SelectItemType";
import { VolumeTypeOptions, VolumeType } from "domain/static/VolumeType";
import { Flex, Spacer } from "components/Page";

export interface GuidelineFormProps {
    articleId: Guid;
    guidelineId: Guid;
    onAfterSave?: () => void;
}

export default function GuidelineForm({ articleId, guidelineId, onAfterSave }: GuidelineFormProps) {

    const [targetTypeEnabled, setTargetTypeEnabled] = React.useState(false);

    const [loadingEntity, entity, updateEntity] = useGetGuideline(guidelineId);

    React.useEffect(() => {
        if (!loadingEntity) {
            setTargetTypeEnabled(entity.volumeType === VolumeType.Target);
        }
    }, [loadingEntity, entity]);

    const fields = React.useMemo(
        () => buildFields(entity, targetTypeEnabled, setTargetTypeEnabled),
        [entity, targetTypeEnabled]
    );

    const buildMessage = React.useCallback((data: GuidelineResponse) => {
        return IsEmptyGuid(guidelineId)
            ? new CreateGuidelineCommand({ ...data, articleId })
            : new UpdateGuidelineCommand({ ...data, articleId, id: guidelineId })
    }, [guidelineId, articleId]);

    const handleArchive = React.useCallback(async () => {
        const command = new SetArchivingStateGuidelineCommand({
            id: guidelineId,
            isArchived: !entity.isArchived
        });
        await sendMessage(command);
        updateEntity();
    }, [entity.isArchived, guidelineId, updateEntity]);

    return (
        <LoadingWrapper loading={loadingEntity}>
            <Form<GuidelineResponse>
                initial={entity}
                fields={fields}
                buildMessage={buildMessage}
                onSuccess={onAfterSave}
            >
                {
                    ({ loading, data }) => (
                        <Flex marginTop={2}>
                            {!IsEmptyGuid(data.id) &&
                                <ArchiveButton
                                    isArchived={data.isArchived}
                                    disabled={loadingEntity || loading}
                                    onClick={handleArchive}
                                />
                            }
                            <Spacer />
                            <SubmitButton loading={loadingEntity || loading} />
                        </Flex>
                    )
                }
            </Form>
        </LoadingWrapper>
    );
}

function buildFields(
    entity: GuidelineResponse,
    targetTypeEnabled: boolean,
    setTargetTypeEnabled: React.Dispatch<React.SetStateAction<boolean>>,
) {
    const builder = new FormFieldsBuilder<GuidelineResponse>();
    const fields = GuidelineResponse.Fields;

    const isCreating = IsEmptyGuid(entity.id);

    builder
        .initialize(fields)
        .hidden(fields.id)
        .hidden(fields.anatomicStructureName)
        .hidden(fields.targetTypeName)
        .hidden(fields.organizationName)
        .hidden(fields.isArchived)

        .set(fields.anatomicStructureId, {
            position: 1,
            label: "anatomic structure",
            type: "SelectItem",
            selectItemType: SelectItemType.AnatomicStructure,
            defaultValue: (
                !isCreating
                    ? { label: entity.anatomicStructureName, value: entity.anatomicStructureId }
                    : undefined
            ),
        })
        .set(fields.volumeType, {
            label: "Volume Type",
            position: 2,
            options: VolumeTypeOptions,
            onChange: (data) => {
                const enabled = data.volumeType === VolumeType.Target;
                if (!enabled) {
                    data.targetTypeId = EmptyGuid();
                }
                setTargetTypeEnabled(enabled);
                return data;
            }
        })
        .set(fields.targetTypeId, {
            position: 3,
            label: "Target Type",
            type: "SelectItem",
            required: targetTypeEnabled,
            disabled: !targetTypeEnabled,
            selectItemType: SelectItemType.TargetType,
            defaultValue: (
                !isCreating
                    ? { label: entity.targetTypeName, value: entity.targetTypeId }
                    : undefined
            ),
        })
        .set(fields.organizationId, {
            position: 4,
            label: "organization",
            type: "SelectItem",
            required: false,
            selectItemType: SelectItemType.Organization,
            defaultValue: (
                !isCreating
                    ? { label: entity.organizationName, value: entity.organizationId }
                    : undefined
            ),
        })
        .set(fields.content, {
            type: "richtext",
            position: 5,
        })
        ;

    return builder.build();
}
