import React from "react";
import { Link } from "react-router-dom";

import { Button } from "@material-ui/core";

import { CentralColumnLayout, PageLayout } from "components/Layouts";
import UserTable from "./UserTable";
import { fullPath } from "tools/Route";
import { AddIcon, UserIcon } from "App/Theme";
import { Flex, Paper, PaperTitle, Title } from "components/Page";

export default function ListUsersPage() {

    const title = "List of users";

    return <>
        <Title>{title}</Title>

        <PageLayout title="Users" menuSelected="ListUsers">
            <CentralColumnLayout size="xl">

                <Flex justifyContent="flex-end">

                    <Link to={fullPath("NewUser")}>
                        <Button
                            color="secondary"
                            variant="contained"
                            startIcon={<AddIcon />}
                        >
                            new user
                        </Button>
                    </Link>
                </Flex>

                <Paper>
                    <PaperTitle icon={<UserIcon />}>{title}</PaperTitle>

                    <UserTable
                        editable={true}
                    />
                </Paper>

            </CentralColumnLayout>
        </PageLayout>
    </>
}
