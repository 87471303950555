import { Guid } from "domain/static/Guid";
import { ClinicalCaseUserRole } from "domain/static/ClinicalCaseUserRole";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class GetClinicalCaseUsersQuery implements IMessage {
    id!: Guid;
    role!: ClinicalCaseUserRole;

    public constructor(init?: Partial<GetClinicalCaseUsersQuery>) {
        Object.assign(this, init);
    }

    getMethod = () => "GET" as RequestMethod;

    getPath = () => `/admin/clinical-cases/${this.id.toString()}/users`;

    getQueryString = () => {
        return {
            role: this.role,
        }
    }
    

    getBody = () => undefined;

    needAuthentication = () => true;
}
