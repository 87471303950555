import React from "react";
import { alpha, Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import { LockIcon } from "App/Theme";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        color: `${theme.palette.text.hint}!important`,
        borderColor: `${theme.palette.text.hint}!important`,
        backgroundColor: alpha(theme.palette.text.hint, 0.1),
    },
}));

export default function ArchiveWidget() {
    const classes = useStyles();

    return <Button variant="outlined" color="default" size="small" disabled
        classes={{ disabled: classes.root }}
    >
        <LockIcon />
    </Button>
}
