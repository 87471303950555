import React, { useMemo } from "react";
import { matchPath, Route, Switch, useLocation, useParams } from "react-router-dom";

import { Guid } from "domain/static/Guid";
import useGetClinicalCase from "domain/hooks/useGetClinicalCase";
import { PageLayout } from "components/Layouts";
import { HeaderTabs, Title } from "components/Page";
import { ArchiveWidget, ClinicalCaseStateWidget } from "components/ClinicalData";
import { fullPath, RouteName } from "tools/Route";
import ClinicalCaseTabs, { ClinicalCaseTab } from "./ClinicalCaseTabs";
import { DetailsPanel } from "./Details";
import { ListContributorsPanel } from "./Contributors";
import { EditClinicalImagePanel, ListClinicalImagesPanel } from "./ClinicalImages";
import { EditClinicalContourPanel, ListClinicalContoursPanel } from "./ClinicalContours";
import { EditScenePanel } from "./Scene";
import { ClinicalCaseState } from "domain/static/ClinicalCaseState";
import { IconButton } from "@material-ui/core";
import { Public as PublicIcon } from "@material-ui/icons";
import { LockIcon } from "App/Theme";

// settings file
declare var APP_PUBLIC_PATH: string;

function getTab(loc: string): ClinicalCaseTab {
    const tabs = {
        "EditClinicalCaseListContributors": "contributors",
        "EditClinicalCaseListContours": "contours",
        "EditClinicalCaseListImages": "images",
        "EditClinicalCaseEditScene": "scene",
    } as Record<RouteName, ClinicalCaseTab>;
    for (const [routeName, tabName] of Object.entries(tabs)) {
        if (matchPath(loc, { path: fullPath(routeName as RouteName) })) {
            return tabName;
        }
    }
    return "details";
}

function getTitle(loc: string): string {
    const titles = {
        "EditClinicalCaseListContributors": "Contributors list",
        "EditClinicalCaseNewContour": "Create contours",
        "EditClinicalCaseEditContour": "Edit contours",
        "EditClinicalCaseListContours": "Contours list",
        "EditClinicalCaseNewImage": "Create image",
        "EditClinicalCaseEditImage": "Edit image",
        "EditClinicalCaseListImages": "Images list",
        "EditClinicalCaseEditScene": "Edit scene",
    } as Record<RouteName, string>;

    for (const [routeName, title] of Object.entries(titles)) {
        if (matchPath(loc, { path: fullPath(routeName as any) })) {
            return title;
        }
    }
    return "Edit";
}

export default function EditClinicalCasePage() {

    const params = useParams<{ id: Guid }>();
    const location = useLocation();

    const [loading, entity] = useGetClinicalCase(params.id);
    const tab = useMemo(() => getTab(location.pathname), [location.pathname]);
    const title = useMemo(() => getTitle(location.pathname), [location.pathname]);

    return <>
        <Title>{loading ? "Loading ..." : title + " - " + entity.name}</Title>

        <PageLayout title="Clinical cases" menuSelected="ListClinicalCases">

            <HeaderTabs
                previousLink={fullPath("ListClinicalCases")}
                title={loading ? "Loading ..." : entity.name}
                tabsComponent={
                    <ClinicalCaseTabs id={params.id} tab={tab} />
                }
                chips={loading ? undefined : [
                    <ClinicalCaseStateWidget state={entity.state} />,
                    //entity.isArchived && <ArchiveWidget />,
                    entity.state === ClinicalCaseState.Published /*&& !entity.isArchived*/ &&
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${APP_PUBLIC_PATH}/atlas/${entity.slug}`}
                    >
                        <IconButton size="small">
                            { entity.isArchived ? <LockIcon /> : <PublicIcon /> }
                        </IconButton>
                    </a>
                ]}
            />

            <Switch>
                <Route exact path={fullPath("EditClinicalCase")} component={DetailsPanel} />

                <Route exact path={fullPath("EditClinicalCaseListContributors")} component={ListContributorsPanel} />

                <Route exact path={fullPath("EditClinicalCaseListImages")} component={ListClinicalImagesPanel} />
                <Route exact path={fullPath("EditClinicalCaseNewImage")} component={EditClinicalImagePanel} />
                <Route exact path={fullPath("EditClinicalCaseEditImage")} component={EditClinicalImagePanel} />

                <Route exact path={fullPath("EditClinicalCaseListContours")} component={ListClinicalContoursPanel} />
                <Route exact path={fullPath("EditClinicalCaseNewContour")} component={EditClinicalContourPanel} />
                <Route exact path={fullPath("EditClinicalCaseEditContour")} component={EditClinicalContourPanel} />

                <Route exact path={fullPath("EditClinicalCaseEditScene")} component={EditScenePanel} />
            </Switch>

        </PageLayout>
    </>
}
