import React, { useCallback } from "react";
import { ColorResult } from "react-color";
import { IFormWidgetPropsBase } from "cocoreact";
import {
    FormControl, FormHelperText,
    InputLabel, makeStyles, createStyles,
    Theme,
} from "@material-ui/core";
import { ColorPicker } from "components/Page";

const useStyles = makeStyles((theme: Theme) => createStyles({
    picker: {
        marginTop: theme.spacing(3),
    },
    test: {
        boxShadow: theme.shadows[10]
    }
}));

export interface ColorFormWidgetProps extends IFormWidgetPropsBase<string> { }

export default function ColorFormWidget({
    name, value, onChange, ...props
}: ColorFormWidgetProps) {
    const classes = useStyles();

    const changeHandle = useCallback((color: ColorResult) => {
        onChange && onChange(name, color.hex);
    }, [name, onChange]);

    return <FormControl
        required={props.required}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
        margin={props.margin}
        error={props.error !== undefined}
        className={props.className}
        style={props.style}
        aria-label={props.label}
    >
        {props.label && <InputLabel shrink>{props.label}</InputLabel>}

        <ColorPicker
            className={classes.picker}
            color={value}
            onChange={changeHandle}
        />

        {props.error && <FormHelperText>A color is required</FormHelperText>}

    </FormControl>
}