import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { CentralColumnLayout, PageLayout } from "components/Layouts";
import { EmptyGuid, Guid } from "domain/static/Guid";
import { fullPath } from "tools/Route";
import ContouringWorkshopForm from "./ContouringWorkshopForm";
import { Paper, PaperTitle, Title } from "components/Page";
import { useNotificationContext } from "cocoreact";
import useGetClinicalCase from "domain/hooks/useGetContouringWorkshop";
import { ContouringWorkshopIcon } from "App/Theme";

export default function CreateContouringWorkshopPage() {

    const [loadingEntity, entity] = useGetClinicalCase(EmptyGuid());

    const { success } = useNotificationContext();

    const history = useHistory();
    const handleAfterSave = useCallback((id?: Guid) => {
        if (id) {
            history.push(fullPath("EditContouringWorkshop", { params: { id } }));
        }
        else {
            history.push(fullPath("ListContouringWorkshops"));
        }
        success("Contouring workshop saved with success");
    }, [history, success]);

    const title = "Create a contouring workshop";

    return <>
        <Title>{title}</Title>

        <PageLayout title="Contouring workshops" menuSelected="ListContouringWorkshops">
            <CentralColumnLayout size="md">

                <Paper>
                    <PaperTitle icon={<ContouringWorkshopIcon />}>{title}</PaperTitle>

                    <ContouringWorkshopForm
                        loading={loadingEntity}
                        entity={entity}
                        onAfterSave={handleAfterSave}
                    />
                </Paper>

            </CentralColumnLayout>
        </PageLayout>
    </>
}
