import React from "react";
import { Area, AreaChart } from "recharts";

import {
    Box, Typography, TableRow, TableCell,
    LinearProgress, useTheme,
    makeStyles, Theme, createStyles
} from "@material-ui/core";

import { guidelineInfoLabel } from "tools/StringExtension";
import { Contour } from "domain/admin/response/ContouringWorkshopResultsResponse";

export const useStyles = makeStyles((theme: Theme) => createStyles({
    cellName: {
        display: "flex",
    },
    color: {
        height: 24,
        width: 24,
        borderRadius: "50%",
        margin: theme.spacing(0, 2),
    },
}));

export type ContourResultRowProp = {
    contour: Contour;
    nbParticipant: number;
}

export default function ContourResultRow({ contour, nbParticipant }: ContourResultRowProp) {
    const classes = useStyles();
    const theme = useTheme();

    const dices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 1].map(x => ({
        name: "" + x / 10,
        count: 0
    }));
    contour.participantResults.forEach(x => {
        const val = Math.round(x.dice * 10);
        dices[val].count++;
    });
    const dicesColor = theme.palette.secondary.light;

    return <TableRow>
        <TableCell className={classes.cellName}>
            <Box className={classes.color} style={{ backgroundColor: contour.color }} />
            <Typography>
                {contour.anatomicStructureName
                    ? guidelineInfoLabel(contour.anatomicStructureName, contour.volumeType, contour.targetTypeName, contour.organizationName)
                    : contour.name}
            </Typography>
        </TableCell>
        <TableCell>
            {contour.isEditable && <Box display="flex" alignItems="center">
                <Box width="100%">
                    <LinearProgress
                        color="secondary"
                        variant="determinate"
                        value={contour.numberOfParticipantResult / nbParticipant * 100} />
                </Box>
                <Box minWidth={60} marginLeft={1}>
                    <Typography variant="caption">
                        {contour.numberOfParticipantResult} / {nbParticipant}
                    </Typography>
                </Box>
            </Box>}
            {contour.isEditable === false &&
                <Typography variant="caption">not editable</Typography>}
        </TableCell>
        <TableCell padding="checkbox">
            {contour.isEditable && <Box display="flex">
                <AreaChart width={100} height={50} data={dices}>
                    <defs>
                        <linearGradient id="colorCount" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={dicesColor} stopOpacity={0.9} />
                            <stop offset="95%" stopColor={dicesColor} stopOpacity={0.2} />
                        </linearGradient>
                    </defs>
                    <Area
                        type="monotone"
                        dataKey="count"
                        stroke={dicesColor}
                        fillOpacity={1}
                        fill="url(#colorCount)"
                        animationDuration={300} />
                </AreaChart>
            </Box>}
        </TableCell>
    </TableRow>;
}
