import {
  useODataMessage as cocoUseODataMessage,
  IODataMessage,
} from "cocoreact";

import { serializer, httpClient } from "services";

const defaultODataResult = { d: { __count: "0", results: [] } };

export default function useODataMessage<TResponse = any>(
  message: IODataMessage
) {
  return cocoUseODataMessage<TResponse>(
    message,
    defaultODataResult,
    serializer,
    httpClient
  );
}
