import React from "react";
import { Button, ButtonProps, CircularProgress, SvgIconProps } from "@material-ui/core";
import { SaveIcon } from "App/Theme";

export interface SubmitButtonProps {
    loading?: boolean;
    children?: React.ReactNode;
    className?: string;
    variant?: ButtonProps["variant"];
    onClick?: ButtonProps["onClick"];
    visible?: boolean;
    disabled?: boolean;
    icon?: React.ReactElement<SvgIconProps>;
}

const SubmitButton = React.forwardRef<HTMLButtonElement, SubmitButtonProps>((
    { loading, children, className, variant, onClick, visible, disabled, icon }, ref
) => {
    return <Button
        ref={ref}
        type="submit"
        color="primary"
        disabled={disabled || loading}
        variant={variant ?? "contained"}
        onClick={onClick}
        endIcon={loading
            ? <CircularProgress size={18} />
            : (icon ? icon : <SaveIcon />)
        }
        className={className}
        style={{ display: visible === false ? "none" : "" }}
    >
        {children ?? "Save"}
    </Button>
});

export default SubmitButton;