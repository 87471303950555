import React from "react";

import {
  AppBar, Toolbar as MuiToolbar,
  Typography, IconButton,
  Menu, MenuItem, ListItemIcon, ListItemText, Fab,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

import {
  MenuIcon,
  AccountIcon, LogoutIcon,
  BrightnessIcon
} from "../Theme";
import { useThemeContext } from "contexts/Theme";
import { useAuthContext } from "contexts/Auth";

const useProfileMenuStyles = makeStyles((theme: Theme) => createStyles({
  menu: {
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    "& p": {
      fontSize: theme.typography.h6.fontSize,
    }
  },
  link: {
    color: "inherit",
  }
}));

interface ProfileMenuProps {
  id: string;
  anchorEl: null | Element | ((element: Element) => Element);
  keepMounted: boolean;
  onClose: () => void;
}

function ProfileMenu(props: ProfileMenuProps) {

  const classes = useProfileMenuStyles();
  const { setToken, payload } = useAuthContext();
  const { name: themeName, set: setTheme } = useThemeContext();

  const handleClick = React.useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLElement
    if (target.closest("ul > a")) {
      props.onClose();
    }
  }, [props]);

  const changeTheme = React.useCallback(() => {
    setTheme(themeName === "dark" ? "light" : "dark");
  }, [setTheme, themeName]);

  const handleLogout = React.useCallback(() => setToken(null), [setToken]);

  return (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={Boolean(props.anchorEl)}
      {...props}
      className={classes.menu}
      onClick={handleClick}
    >
      {payload &&
        <div className={classes.header}>
          <Fab size="small" disabled>
            {payload.firstname[0].toUpperCase()} {payload.lastname[0].toUpperCase()}
          </Fab>
          <p>
            {payload.firstname} {payload.lastname}
          </p>
        </div>
      }

      <MenuItem onClick={changeTheme}>
        <ListItemIcon>
          <BrightnessIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={themeName === "dark" ? "Switch light" : "Switch dark"} />
      </MenuItem>

      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </MenuItem>
    </Menu>
  );
}

// --------------------------------------------------------

const useToolbarStyles = makeStyles((theme: Theme) => createStyles({
  appBar: {
    zIndex: theme.zIndex.appBar,
    marginLeft: theme.mixins.drawer.width,
    [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
      width: `calc(100% - ${theme.mixins.drawer.width}px)`,
    },
  },
  toolbar: {
    backgroundColor: theme.palette.primary.dark,
    paddingLeft: 0,
    [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
      paddingLeft: theme.spacing(4),
    }
  },
  menuButton: {
    marginRight: theme.spacing(1) * 2,
    [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
      display: "none",
    },
  },
  rightActions: {
    marginLeft: "auto",
  },
}));

export interface ToolbarProps {
  title?: string;
  setDrawerOpen: (open: boolean) => void;
}

export default function Toolbar({ title, setDrawerOpen }: ToolbarProps) {

  const classes = useToolbarStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = React.useCallback(() => setAnchorEl(null), []);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <MuiToolbar className={classes.toolbar}>

        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={() => setDrawerOpen(true)}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>

        <Typography variant="h5" color="inherit" noWrap>
          {title ?? "Admin"}
        </Typography>

        <div className={classes.rightActions}>
          <IconButton
            aria-controls="toolbar-profile-menu"
            onClick={handleClick}
          >
            <AccountIcon />
          </IconButton>

          <ProfileMenu
            id="toolbar-profile-menu"
            keepMounted={true}
            anchorEl={anchorEl}
            onClose={handleClose}
          />
        </div>

      </MuiToolbar>
    </AppBar>
  )
}
