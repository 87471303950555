import { Guid } from "domain/static/Guid";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class BatchCreateClinicalContourCommand implements IMessage {
    messages!: CreateClinicalContourCommand[];

    public constructor(init?: Partial<BatchCreateClinicalContourCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "POST" as RequestMethod;

    getPath = () => `/admin/batch/clinical-contours`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            messages: this.messages,
        };
    }
    
    needAuthentication = () => true;
}

export class CreateClinicalContourCommand {
    clinicalDataId!: Guid;
    name!: string;
    color!: string;
    position!: number;
    fileId!: Guid | null;
    guidelineId!: Guid | null;
    linkArticleToClinicalCase!: boolean;
    isEditable!: boolean;
}
