import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class ImportZoteroBiblioCommand implements IMessage {
    content!: string;

    public constructor(init?: Partial<ImportZoteroBiblioCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "POST" as RequestMethod;

    getPath = () => `/admin/articles/import-zotero`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            content: this.content,
        };
    }
    
    needAuthentication = () => true;
}
