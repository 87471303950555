import React from "react";

import { LoadingWrapper } from "cocoreact";

import { Guid, IsEmptyGuid } from "domain/static/Guid";
import { SelectItemType } from "domain/static/SelectItemType";
import { FileParentType } from "domain/static/FileParentType";
import { CreateClinicalCaseCommand } from "domain/admin/command/CreateClinicalCaseCommand";
import { UpdateClinicalCaseCommand } from "domain/admin/command/UpdateClinicalCaseCommand";
import { ClinicalCaseResponse } from "domain/admin/response/ClinicalCaseResponse";
import { Form, FormFieldsBuilder } from "components/Form";
import { SubmitButton } from "components/Buttons";
import { Flex } from "components/Page";

export interface ClinicalCaseFormProps {
    loading: boolean;
    entity: ClinicalCaseResponse;
    onAfterSave?: (id?: Guid) => void;
}

export default function ClinicalCaseForm({ loading, entity, onAfterSave }: ClinicalCaseFormProps) {

    const fields = React.useMemo(() => buildFields(entity), [entity]);

    const buildMessageHandle = React.useCallback((data: ClinicalCaseResponse) => {
        return IsEmptyGuid(entity.id)
            ? new CreateClinicalCaseCommand(data)
            : new UpdateClinicalCaseCommand({ ...data, id: entity.id })
    }, [entity.id]);

    const onSuccessHandle = React.useCallback((response: any) => {
        if (onAfterSave) {
            onAfterSave(response?.id ?? undefined);
        }
    }, [onAfterSave]);

    return (
        <LoadingWrapper loading={loading}>
            <Form<ClinicalCaseResponse>
                initial={entity}
                fields={fields}
                buildMessage={buildMessageHandle}
                onSuccess={onSuccessHandle}
            >
                {
                    ({ loading: formLoading }) => (
                        <Flex marginTop={2} justifyContent="flex-end">
                            <SubmitButton loading={formLoading || loading} />
                        </Flex>
                    )
                }
            </Form>
        </LoadingWrapper>
    );
}

function buildFields(entity: ClinicalCaseResponse) {
    const builder = new FormFieldsBuilder<ClinicalCaseResponse>();
    const fields = ClinicalCaseResponse.Fields;

    const isCreating = IsEmptyGuid(entity.id);

    builder
        .initialize(fields)
        .hidden(fields.id)
        .hidden(fields.isArchived)
        .hidden(fields.anatomicZoneName)
        .hidden(fields.submittedAt)
        .hidden(fields.publishedAt)
        .hidden(fields.state)
        .hidden(fields.author)
        .set(fields.coverId, {
            position: 0,
            type: "image",
            parentId: isCreating ? undefined : entity.id,
            parentType: isCreating ? undefined : FileParentType.ClinicalCase,
            accept: "image/jpeg, image/jpg, image/png",
        })
        .attachFieldToSlug(fields.name, fields.slug)
        .set(fields.name, {
            position: 1,
        })
        .set(fields.slug, {
            position: 2,
        })
        .set(fields.anatomicZoneId, {
            position: 3,
            label: "anatomic zone",
            type: "SelectItem",
            selectItemType: SelectItemType.AnatomicZone,
            defaultValue: (
                !isCreating
                    ? { label: entity.anatomicZoneName, value: entity.anatomicZoneId }
                    : undefined
            )
        })
        .set(fields.summary, {
            position: 4,
            type: "richtext",
        })
        ;

    return builder.build();
}
