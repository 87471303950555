import { useMessage as cocoUseMessage, IMessage } from "cocoreact";

import { serializer, httpClient } from "services";

export default function useMessage<TResponse = any>(
  message: IMessage,
  initialValue: TResponse
) {
  return cocoUseMessage<TResponse>(
    message,
    initialValue,
    serializer,
    httpClient
  );
}
