import React, { useMemo } from "react";
import { Route, Switch, useParams, matchPath, useLocation } from "react-router-dom";

import { Guid } from "domain/static/Guid";
import useGetContouringWorkshop from "domain/hooks/useGetContouringWorkshop";
import { PageLayout } from "components/Layouts";
import { HeaderTabs, Title } from "components/Page";
import { ArchiveWidget, ContouringWorkshopStateWidget } from "components/ClinicalData";
import { fullPath, RouteName } from "tools/Route";
import ContouringWorkshopTabs, { ContouringWorkshopTab } from "./ContouringWorkshopTabs";
import { DetailsPanel } from "./Details";
import { ListContributorsPanel } from "./Contributors";
import { ListClinicalImagesPanel, EditClinicalImagePanel } from "./ClinicalImages";
import { EditClinicalContourPanel, ListClinicalContoursPanel } from "./ClinicalContours";
import { EditScenePanel } from "./Scene";
import { ListResultsPanel } from "./Results";


function getTab(loc: string): ContouringWorkshopTab {
    const tabs = {
        "EditContouringWorkshopListContributors": "contributors",
        "EditContouringWorkshopListContours": "contours",
        "EditContouringWorkshopListImages": "images",
        "EditContouringWorkshopListResults": "results",
        "EditContouringWorkshopEditScene": "scene",
    } as Record<RouteName, ContouringWorkshopTab>;
    for (const [routeName, tabName] of Object.entries(tabs)) {
        if (matchPath(loc, { path: fullPath(routeName as RouteName) })) {
            return tabName;
        }
    }
    return "details";
}

function getTitle(loc: string): string {
    const titles = {
        "EditContouringWorkshopListContributors": "Contributors list",
        "EditContouringWorkshopNewContour": "Create contours",
        "EditContouringWorkshopEditContour": "Edit contours",
        "EditContouringWorkshopListContours": "Contours list",
        "EditContouringWorkshopNewImage": "Create image",
        "EditContouringWorkshopEditImage": "Edit image",
        "EditContouringWorkshopListImages": "Images list",
        "EditContouringWorkshopListResults": "Particpants results",
        "EditContouringWorkshopEditScene": "Edit scene",
    } as Record<RouteName, string>;

    for (const [routeName, title] of Object.entries(titles)) {
        if (matchPath(loc, { path: fullPath(routeName as any) })) {
            return title;
        }
    }
    return "Edit";
}

export default function EditContouringWorkshopPage() {

    const params = useParams<{ id: Guid }>();
    const location = useLocation();

    const [loading, entity] = useGetContouringWorkshop(params.id);
    const tab = useMemo(() => getTab(location.pathname), [location.pathname]);
    const title = useMemo(() => getTitle(location.pathname), [location.pathname]);

    return <>
        <Title>{loading ? "Loading ..." : title + " - " + entity.name}</Title>

        <PageLayout title={"Contouring workshops"} menuSelected="ListContouringWorkshops">

            <HeaderTabs
                previousLink={fullPath("ListContouringWorkshops")}
                title={loading ? "Loading ..." : entity.name}
                tabsComponent={
                    <ContouringWorkshopTabs id={params.id} tab={tab} />
                }
                chips={loading ? undefined : [
                    <ContouringWorkshopStateWidget
                        state={entity.state}
                        startAt={entity.startAt}
                        endAt={entity.endAt}
                    />,
                    entity.isArchived && <ArchiveWidget />
                ]}
            />

            <Switch>
                <Route exact path={fullPath("EditContouringWorkshop")} component={DetailsPanel} />

                <Route exact path={fullPath("EditContouringWorkshopListContributors")} component={ListContributorsPanel} />

                <Route exact path={fullPath("EditContouringWorkshopListImages")} component={ListClinicalImagesPanel} />
                <Route exact path={fullPath("EditContouringWorkshopNewImage")} component={EditClinicalImagePanel} />
                <Route exact path={fullPath("EditContouringWorkshopEditImage")} component={EditClinicalImagePanel} />

                <Route exact path={fullPath("EditContouringWorkshopListContours")} component={ListClinicalContoursPanel} />
                <Route exact path={fullPath("EditContouringWorkshopNewContour")} component={EditClinicalContourPanel} />
                <Route exact path={fullPath("EditContouringWorkshopEditContour")} component={EditClinicalContourPanel} />

                <Route exact path={fullPath("EditContouringWorkshopEditScene")} component={EditScenePanel} />

                <Route exact path={fullPath("EditContouringWorkshopListResults")} component={ListResultsPanel} />
            </Switch>

        </PageLayout>
    </>
}
