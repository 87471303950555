import React from "react";
import clsx from "clsx";
import { Box, makeStyles, Theme, createStyles, } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({
    spacingElementRow: {
        "& > *": {
            marginLeft: theme.spacing(2),
        },
        "& > :first-child": {
            marginLeft: 0,
        },
    },
    spacingElementColumn: {
        "& > *": {
            marginTop: theme.spacing(2),
        },
        "& > :first-child": {
            marginTop: 0,
        },
    }
}));

type Spacing124 = number | [number, number] | [number, number, number, number];

function getSpacing(spacing: Spacing124 | undefined, index2: number, index4: number, defaultValue: number | undefined) {
    if (!spacing) {
        return defaultValue;
    }
    if (Array.isArray(spacing)) {
        return spacing.length === 2 ? spacing[index2] : spacing[index4];
    }
    else {
        return spacing;
    }
}

export interface FlexProps {
    column?: boolean;
    margin?: Spacing124;
    marginTop?: number;
    marginBottom?: number;
    marginLeft?: number;
    marginRight?: number;
    padding?: Spacing124;
    paddingTop?: number;
    paddingBottom?: number;
    paddingLeft?: number;
    paddingRight?: number;
    width?: React.CSSProperties["width"];
    alignItems?: React.CSSProperties["alignItems"];
    justifyContent?: React.CSSProperties["justifyContent"];
    children: any;
    className?: string;
}

export default function Flex({
    children, column, alignItems, justifyContent, width,
    margin, marginBottom, marginTop, marginRight, marginLeft,
    padding, paddingBottom, paddingTop, paddingRight, paddingLeft,
    className
}: FlexProps) {
    const classes = useStyles();
    const _marginTop = getSpacing(margin, 0, 0, marginTop);
    const _marginRight = getSpacing(margin, 1, 1, marginRight);
    const _marginBottom = getSpacing(margin, 0, 2, marginBottom);
    const _marginLeft = getSpacing(margin, 1, 3, marginLeft);
    const _paddingTop = getSpacing(padding, 0, 0, paddingTop);
    const _paddingRight = getSpacing(padding, 1, 1, paddingRight);
    const _paddingBottom = getSpacing(padding, 0, 2, paddingBottom);
    const _paddingLeft = getSpacing(padding, 1, 3, paddingLeft);

    return <Box
        display="flex"
        flexDirection={column === true ? "column" : "row"}
        alignItems={alignItems}
        justifyContent={justifyContent}
        width={width}
        marginTop={_marginTop}
        marginRight={_marginRight}
        marginBottom={_marginBottom}
        marginLeft={_marginLeft}
        paddingTop={_paddingTop}
        paddingRight={_paddingRight}
        paddingBottom={_paddingBottom}
        paddingLeft={_paddingLeft}
        className={clsx(column === true ? classes.spacingElementColumn : classes.spacingElementRow, className)}
    >
        {children}
    </Box>
}
