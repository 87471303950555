import React from "react";

import { LoadingWrapper } from "cocoreact";

import { Guid, IsEmptyGuid } from "domain/static/Guid";
import { SelectItemTypeOptions } from "domain/static/SelectItemType";
import { SelectItemResponse } from "domain/admin/response/SelectItemResponse";
import { CreateSelectItemCommand } from "domain/admin/command/CreateSelectItemCommand";
import { UpdateSelectItemCommand } from "domain/admin/command/UpdateSelectItemCommand";
import { SetArchivingStateSelectItemCommand } from "domain/admin/command/SetArchivingStateSelectItemCommand";
import { sendMessage } from "tools/Message";
import { Form, FormFieldsBuilder } from "components/Form";
import { ArchiveButton, SubmitButton } from "components/Buttons";
import { Flex, Spacer } from "components/Page";
import useGetSelectItem from "domain/hooks/useGetSelectItem";

export interface SelectItemFormProps {
    id: Guid;
    initialValue?: Partial<SelectItemResponse>;
    onAfterSave?: (id: Guid | undefined) => void;
}

export default function SelectItemForm({ id, initialValue, onAfterSave }: SelectItemFormProps) {

    const [loadingEntity, entity, updateEntity] = useGetSelectItem(id, initialValue);

    const fields = React.useMemo(() => buildFields(id), [id]);

    const buildMessage = React.useCallback((data: SelectItemResponse) => {
        return IsEmptyGuid(id)
            ? new CreateSelectItemCommand({ ...data })
            : new UpdateSelectItemCommand({ ...data, id })
    }, [id]);

    const handleArchive = React.useCallback(async () => {
        const command = new SetArchivingStateSelectItemCommand({
            id,
            isArchived: !entity.isArchived
        });
        await sendMessage(command);
        updateEntity();
    }, [entity.isArchived, id, updateEntity]);

    const handleAfterSave = React.useCallback((response: any) => {
        if (!onAfterSave) return;
        if (response && response.id) {
            onAfterSave(response.id);
        }
        else {
            onAfterSave(undefined);
        }
    }, [onAfterSave]);

    return (
        <LoadingWrapper loading={loadingEntity}>
            <Form<SelectItemResponse>
                initial={entity}
                fields={fields}
                buildMessage={buildMessage}
                onSuccess={handleAfterSave}
            >
                {
                    ({ loading, data }) => (
                        <Flex marginTop={2}>
                            {!IsEmptyGuid(data.id) &&
                                <ArchiveButton
                                    isArchived={data.isArchived}
                                    disabled={loadingEntity || loading}
                                    onClick={handleArchive}
                                />
                            }
                            <Spacer />
                            <SubmitButton loading={loadingEntity || loading} />
                        </Flex>
                    )
                }
            </Form>
        </LoadingWrapper>
    );
}

function buildFields(id: Guid) {
    const builder = new FormFieldsBuilder<SelectItemResponse>();
    const fields = SelectItemResponse.Fields;

    builder
        .initialize(fields)
        .hidden(fields.id)
        .hidden(fields.isArchived)
        .set(fields.name, {
            position: 0,
        })
        .set(fields.type, {
            position: 1,
            type: "select",
            options: SelectItemTypeOptions,
            disabled: !IsEmptyGuid(id),
        });

    return builder.build();
}
