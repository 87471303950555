import React from "react";
import { LoadingWrapper } from "cocoreact";

import { Guid, IsEmptyGuid, IsValidGuid } from "domain/static/Guid";
import { SelectItemType } from "domain/static/SelectItemType";
import { FileParentType } from "domain/static/FileParentType";
import { ClinicalImageResponse } from "domain/admin/response/ClinicalImageResponse";
import { CreateClinicalImageCommand } from "domain/admin/command/CreateClinicalImageCommand";
import { UpdateClinicalImageCommand } from "domain/admin/command/UpdateClinicalImageCommand";
import { SetArchivingStateClinicalImageCommand } from "domain/admin/command/SetArchivingStateClinicalImageCommand";
import useGetClinicalImage from "domain/hooks/useGetClinicalImage";
import { FormFieldsBuilder, Form } from "components/Form";
import { sendMessage } from "tools/Message";
import { ArchiveButton, SubmitButton } from "components/Buttons";
import { ImagingDefaultOptions } from "domain/static/ImagingDefault";
import ImageFormViewer from "./ImageFormViewer";
import { ViewerContextProvider } from "contexts/Viewer";
import { Flex, Spacer } from "components/Page";

export interface ClinicalImageFormProps {
    clinicalDataId: Guid;
    imageId: Guid;
    onAfterSave?: () => void;
}

export default function ClinicalImageForm({
    clinicalDataId, imageId, onAfterSave
}: ClinicalImageFormProps) {
    const [loadingEntity, entity, updateEntity] = useGetClinicalImage(imageId);

    const fields = React.useMemo(() => buildFields(entity), [entity]);

    const buildMessage = React.useCallback((data: ClinicalImageResponse) => {
        return IsEmptyGuid(imageId)
            ? new CreateClinicalImageCommand({ ...data, clinicalDataId, })
            : new UpdateClinicalImageCommand({ ...data, id: imageId })
    }, [clinicalDataId, imageId]);

    const handleArchive = React.useCallback(async () => {
        const command = new SetArchivingStateClinicalImageCommand({
            id: imageId,
            isArchived: !entity.isArchived
        });
        await sendMessage(command);
        updateEntity();
    }, [entity.isArchived, imageId, updateEntity]);

    return (
        <LoadingWrapper loading={loadingEntity}>
            <Form<ClinicalImageResponse>
                initial={entity}
                fields={fields}
                buildMessage={buildMessage}
                onSuccess={onAfterSave}
            >
                {
                    ({ loading, data, setData }) => (
                        <>
                            {IsValidGuid(data.fileId) &&
                                <ViewerContextProvider>
                                    <ImageFormViewer
                                        fileId={data.fileId}
                                        windowing={data.windowing ?? undefined}
                                        lut={data.lut ?? undefined}
                                        updateData={setData}
                                    />
                                </ViewerContextProvider>
                            }

                            <Flex marginTop={2}>
                                {!IsEmptyGuid(data.id) &&
                                    <ArchiveButton
                                        isArchived={data.isArchived}
                                        disabled={loadingEntity || loading}
                                        onClick={handleArchive}
                                    />
                                }
                                <Spacer />
                                <SubmitButton
                                    loading={loadingEntity || loading}
                                    disabled={!IsValidGuid(data.fileId)}
                                />
                            </Flex>
                        </>
                    )
                }
            </Form>
        </LoadingWrapper>
    );
}


function buildFields(entity: ClinicalImageResponse) {
    const builder = new FormFieldsBuilder<ClinicalImageResponse>();
    const fields = ClinicalImageResponse.Fields;

    const isCreating = IsEmptyGuid(entity.id);

    builder
        .initialize(fields)
        .hidden(fields.id)
        .hidden(fields.isArchived)
        .hidden(fields.imagingModalityName)
        .hidden(fields.fileName)
        .hidden(fields.windowing)
        .hidden(fields.range)
        .hidden(fields.spacing)
        .hidden(fields.origin)
        .hidden(fields.size)
        .hidden(fields.lut);

    builder
        .set(fields.name, {
            position: 0,
        })
        .set(fields.imagingModalityId, {
            position: 1,
            label: "image modality",
            type: "SelectItem",
            selectItemType: SelectItemType.ImagingModality,
            defaultValue: (
                !isCreating
                    ? { label: entity.imagingModalityName, value: entity.imagingModalityId }
                    : undefined
            ),
        })
        .set(fields.default, {
            position: 2,
            label: "is default ?",
            options: ImagingDefaultOptions
        })
        .set(fields.fileId, {
            position: 3,
            label: "image data file",
            type: "file",
            parentId: isCreating ? undefined : entity.id,
            parentType: isCreating ? undefined : FileParentType.ClinicalImage,
            fileName: entity.fileName,
            onChange: (entity: ClinicalImageResponse) => {
                if (entity.fileId) {
                    entity.windowing = undefined as any;
                    entity.range = undefined as any;
                    entity.spacing = undefined as any;
                    entity.origin = undefined as any;
                    entity.size = undefined as any;
                    entity.lut = undefined as any;
                }
                return entity;
            }
        })
        ;

    return builder.build();
}
