import React from "react";

import { Guid } from "domain/static/Guid";
import Table, { TableFieldsBuilder } from "components/Table";
import { fullPath } from "tools/Route";
import useListGuidelines from "domain/hooks/useListGuidelines";
import { GuidelineItemResponse } from "domain/admin/response/GuidelineItemResponse";
import { VolumeTypeOptions } from "domain/static/VolumeType";

export interface GuidelineTableProps {
    id: Guid;
    editable?: boolean;
}

export default function GuidelineTable({ id, editable }: GuidelineTableProps) {

    const fields = React.useMemo(() => buildFields(id, editable), [id, editable]);

    const [, entities,] = useListGuidelines(id, true);

    return (
        <Table<GuidelineItemResponse>
            fields={fields}
            data={entities}
        />
    );
}

function buildFields(id: Guid, editable?: boolean) {
    const builder = new TableFieldsBuilder<GuidelineItemResponse>();
    const fields = GuidelineItemResponse.Fields;

    builder
        .initialize(fields)
        .hidden(fields.id)
        .hidden(fields.createdAt)
        .hidden(fields.updatedAt)

        .set(fields.anatomicStructureName, {
            position: 1,
            label: "anatomic structure",
        })
        .set(fields.volumeType, {
            position: 2,
            label: "volume",
            options: VolumeTypeOptions,
        })
        .set(fields.targetTypeName, {
            position: 3,
            label: "target",
        })
        .set(fields.organizationName, {
            position: 4,
            label: "organization",
        })
        .set(fields.isArchived, {
            position: 5,
            align: "center",
        })
        ;

    if (editable) {
        builder.addLinkEdit((entity) => {
            return fullPath("EditArticleEditGuideline", {
                params: {
                    id: id,
                    guidelineId: entity.id as string,
                }
            });
        });
    }

    return builder.build();
}
