import { Guid } from "domain/static/Guid";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class GetGuidelineOptionQuery implements IMessage {
    id!: Guid;

    public constructor(init?: Partial<GetGuidelineOptionQuery>) {
        Object.assign(this, init);
    }

    getMethod = () => "GET" as RequestMethod;

    getPath = () => `/admin/guidelines/options/${this.id.toString()}`;

    getQueryString = () => undefined;

    getBody = () => undefined;

    needAuthentication = () => true;
}
