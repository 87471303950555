import { Guid } from "domain/static/Guid";
import { VolumeType } from "domain/static/VolumeType";
import { IField } from "cocoreact";

//Generated code, do not modify

export class GuidelineItemResponse {
    id!: Guid | null;
    anatomicStructureName!: string;
    volumeType!: VolumeType;
    targetTypeName!: string;
    organizationName!: string;
    createdAt!: Date | null;
    updatedAt!: Date | null;
    isArchived!: boolean | null;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        anatomicStructureName: { name: "anatomicStructureName", type: "string" },
        volumeType: { name: "volumeType", type: "VolumeType" },
        targetTypeName: { name: "targetTypeName", type: "string" },
        organizationName: { name: "organizationName", type: "string" },
        createdAt: { name: "createdAt", type: "Date" },
        updatedAt: { name: "updatedAt", type: "Date" },
        isArchived: { name: "isArchived", type: "boolean" },
    } as Record<keyof GuidelineItemResponse, IField>;
}
