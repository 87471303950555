import React from "react";
import { useParams } from "react-router-dom";

import { SceneViewerContainer } from "components/ClinicalData";
import { Guid } from "domain/static/Guid";
import { ViewerContextProvider } from "contexts/Viewer";
import useGetClinicalDataScene from "domain/hooks/useGetClinicalDataScene";
import { CentralColumnLayout } from "components/Layouts";

export default function EditScenePage() {

    const { id } = useParams<{ id: Guid }>();
    const [loading, entity] = useGetClinicalDataScene(id);

    return <CentralColumnLayout size={"xl"}>
        <ViewerContextProvider>
            <SceneViewerContainer loading={loading} entity={entity} />
        </ViewerContextProvider>
    </CentralColumnLayout>
}
