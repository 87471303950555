import React, { useCallback, useRef } from "react";
import { Link } from "react-router-dom";

import { Button } from "@material-ui/core";

import { CentralColumnLayout, PageLayout } from "components/Layouts";
import SelectItemTable from "./SelectItemTable";
import { fullPath } from "tools/Route";
import { AddIcon, SelectItemIcon } from "App/Theme";
import { Flex, Paper, PaperTitle, Title } from "components/Page";
import { ResetFiltersButton } from "components/Buttons";

export default function ListSelectItemsPage() {

    const resetRef = useRef<HTMLButtonElement>(null);
    const resetQueryHandle = useCallback(() => {
        resetRef.current?.click();
    }, []);

    const title = "List of select items";

    return <>
        <Title>{title}</Title>

        <PageLayout title="Select items" menuSelected="ListSelectItems">
            <CentralColumnLayout size="xl">

                <Flex justifyContent="space-between">
                    <ResetFiltersButton onClick={resetQueryHandle} />

                    <Link to={fullPath("NewSelectItem")}>
                        <Button
                            color="secondary"
                            variant="contained"
                            startIcon={<AddIcon />}
                        >
                            new select item
                        </Button>
                    </Link>
                </Flex>

                <Paper>
                    <PaperTitle icon={<SelectItemIcon />}>{title}</PaperTitle>

                    <SelectItemTable
                        editable={true}
                        resetRef={resetRef}
                    />
                </Paper>

            </CentralColumnLayout>
        </PageLayout>
    </>
}
