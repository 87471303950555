import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Typography } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";

import { LogLayout } from "components/Layouts";
import AskResetPasswordForm from "./AskResetPasswordForm";
import { useSearchParams } from "tools/useSearchParams";
import { fullPath } from "tools/Route";
import ResetPasswordForm from "./ResetPasswordForm";

export default function ResetPasswordPage() {

    const { token } = useSearchParams<{ token: string }>();
    const [confirm, setConfirm] = useState(false);

    const title = token ? "Change password" : "Reset your password";

    return <LogLayout title={title}>

        {confirm && <Box display="flex" flexDirection="column" alignItems="center">
            <DoneIcon color="primary" />
            <p></p>
            {!token &&
                <Typography variant="body1" component="p">
                    If your email adress is registered then you will receive a link to reset your password.
                    If it doesn’t appear within a few minutes, check your spam folder.
                </Typography>
            }
            {token &&
                <Typography variant="body1" component="p">
                    Your password has been reset. You can now use it to sign in.
                </Typography>
            }
            <p></p>
            <Link to={fullPath("SignIn")} style={{ width: "100%" }}>
                <Button
                    fullWidth
                    type="submit"
                    variant="outlined"
                >
                    return to sign in
                </Button>
            </Link>
        </Box>}

        {!token && !confirm && <>
            <Typography variant="body1" component="p">
                Enter your user account's verified email address
                and we will send you a password reset link.
            </Typography>
            <AskResetPasswordForm onAfterSubmit={() => setConfirm(true)} />
        </>}

        {token && !confirm && <>
            <ResetPasswordForm token={token} onAfterSubmit={() => setConfirm(true)} />
        </>}

    </LogLayout>
}
