import React from "react";
import { alpha, Button, createStyles, makeStyles, Theme } from "@material-ui/core";

import { ClinicalCaseState, ClinicalCaseStateOptions } from "domain/static/ClinicalCaseState";

const useStyles = makeStyles((theme: Theme) => createStyles({
    label: {
        textTransform: "none",
    },
    draft: {
        color: `${theme.palette.text.primary}!important`,
        borderColor: `${theme.palette.text.primary}!important`,
        backgroundColor: alpha(theme.palette.text.hint, 0.1),
    },
    submitted: {
        color: `${theme.palette.warning.dark}!important`,
        borderColor: `${theme.palette.warning.dark}!important`,
        backgroundColor: alpha(theme.palette.warning.main, 0.1),
    },
    published: {
        color: `${theme.palette.success.dark}!important`,
        borderColor: `${theme.palette.success.dark}!important`,
        backgroundColor: alpha(theme.palette.success.main, 0.1),
    },
}));

function getStateLabel(state: ClinicalCaseState) {
    return ClinicalCaseStateOptions.find(x => x.value === state)?.label ?? "undefined";
}

export interface ClinicalCaseStateWidgetProps {
    state: ClinicalCaseState;
}

export default function ClinicalCaseStateWidget({ state }: ClinicalCaseStateWidgetProps) {
    const classes = useStyles();

    const stateLabel = getStateLabel(state);
    const className = (classes as any)[stateLabel.toLocaleLowerCase()];

    return <Button
        size="small" variant="outlined" color="default"
        disabled
        className={className}
        classes={{ label: classes.label }}
    >
        {stateLabel}
    </Button>
}
