import SparkMD5 from "spark-md5";

import { sendMessage } from "./Message";
import { CreateFileCommand } from "domain/admin/command/CreateFileCommand";
import { AssembleFilePartsCommand } from "domain/admin/command/AssembleFilePartsCommand";
import { CreateFilePartCommand } from "domain/admin/command/CreateFilePartCommand";
import { GetFileQuery } from "domain/admin/query/GetFileQuery";
import { FileParentType } from "domain/static/FileParentType";
import { Guid } from "domain/static/Guid";
import { httpClient, serializer } from "services";

export async function getFileAsync(
  queryOrId: GetFileQuery | Guid,
  onDownloadProgress?: (loaded: number, total: number) => void
) {
  const query =
    queryOrId instanceof GetFileQuery
      ? queryOrId
      : new GetFileQuery({ id: queryOrId });

  const request = serializer.serializeMessage(query);
  const config = httpClient.buildConfiguration(request);
  config.responseType = "blob";
  if (onDownloadProgress) {
    config.onDownloadProgress = (progressEvent: ProgressEvent) => {
      onDownloadProgress(progressEvent.loaded, progressEvent.total);
    };
  }
  const response = await httpClient.fetchRequest(config);

  let filename = "";
  const contentDisposition = response.headers["content-disposition"];
  if (contentDisposition) {
    filename = contentDisposition;
    filename = filename.replace("attachment;", "");
    filename = filename.replace("filename=", "");
    filename = filename.replace(/"/g, ""); // remove all "
    filename = filename.replace(/ /g, ""); // remove all space
  }

  if (!filename) {
    throw new Error(
      "undefined filename inside content-disposition response header"
    );
  }

  return { filename, data: response.data as Blob };
}

export function readFileAsync(
  file: File,
  callbackProgress?: (content: ProgressEvent<FileReader>) => void
): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    if (callbackProgress) {
      reader.onprogress = (ev: ProgressEvent<FileReader>) => {
        callbackProgress(ev);
      };
    }

    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result as ArrayBuffer);
      }
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
}

export function readImageAsync(
  file: File,
  callbackProgress?: (content: ProgressEvent<FileReader>) => void
): Promise<string> {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    if (callbackProgress) {
      reader.onprogress = (ev: ProgressEvent<FileReader>) => {
        callbackProgress(ev);
      };
    }

    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result.toString());
      }
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
}

export class FilePartUploader {
  _mimeType: string;
  _filename: string;
  _maxPartSize: number;
  _parentId: Guid | undefined;
  _parentType: FileParentType | undefined;

  _onProgress: ((step: number, total: number) => void) | undefined;

  constructor(
    fileName: string,
    mimeType: string,
    maxPartSize: number | undefined = undefined
  ) {
    this._filename = fileName;
    this._mimeType = mimeType;

    const _1Mo = 1048576;
    this._maxPartSize = maxPartSize ?? 2 * _1Mo;

    this._parentId = undefined;
    this._parentType = undefined;
    this._onProgress = undefined;
  }

  setParent(id: Guid, type: FileParentType) {
    this._parentId = id;
    this._parentType = type;
  }

  onProgress(cb: (partIndex: number, totalPart: number) => void) {
    this._onProgress = cb;
  }

  async send(data: ArrayBuffer) {
    const nbPart = this._getNbPart(data);

    if (nbPart === 1) {
      return await this._sendFull(data);
    }

    return await this._sendPart(data, nbPart);
  }

  async _sendFull(data: ArrayBuffer) {
    this._onProgress && this._onProgress(1, 1);

    const command = new CreateFileCommand({
      fileName: this._filename,
      mimeType: this._mimeType,
      parentId: this._parentId,
      parentType: this._parentType,
      base64Content: this._toBase64(data),
    });
    const file = await sendMessage<{ id: Guid }>(command);
    return file.id;
  }

  async _sendPart(data: ArrayBuffer, nbPart: number) {
    // first part : get a fileId

    this._onProgress && this._onProgress(0, nbPart);

    const startPart = this._getPart(data, 0);
    const startCommand = new CreateFileCommand({
      fileName: this._filename,
      mimeType: this._mimeType,
      parentId: this._parentId,
      parentType: this._parentType,
      base64Content: this._toBase64(startPart),
    });
    const file = await sendMessage<{ id: Guid }>(startCommand);

    // parts : send all file parts

    for (var iPart = 1; iPart < nbPart; iPart++) {
      this._onProgress && this._onProgress(iPart, nbPart);

      const part = this._getPart(data, iPart);

      const command = new CreateFilePartCommand({
        fileId: file.id,
        partIndex: iPart,
        base64Content: this._toBase64(part),
      });
      await sendMessage<{ id: Guid }>(command);
    }

    // end part : assembly parts & checksum

    this._onProgress && this._onProgress(nbPart, nbPart);

    const endCommand = new AssembleFilePartsCommand({
      fileId: file.id,
      checksum: this._getChecksum(data),
    });
    await sendMessage(endCommand);

    return file.id;
  }

  _toBase64(buffer: ArrayBuffer) {
    let binary = "";
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  _getChecksum(data: ArrayBuffer) {
    const spark = new SparkMD5.ArrayBuffer();
    spark.append(data);
    return btoa(spark.end(true));
  }

  _getNbPart(data: ArrayBuffer) {
    return this._maxPartSize <= 0
      ? 1
      : Math.ceil(data.byteLength / this._maxPartSize);
  }

  _getPart(data: ArrayBuffer, partIndex: number) {
    const start = partIndex * this._maxPartSize;
    const end = start + this._maxPartSize;
    return data.slice(start, Math.min(end, data.byteLength));
  }
}
