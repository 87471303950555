import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
    makeStyles, Theme, createStyles,
    Typography, TypographyProps
} from "@material-ui/core";
import clsx from "clsx";
import remarkHtml from "remark-html";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        "& p": {
            margin: theme.spacing(1, 0),
        },
        "& h4, & h5": {
            margin: theme.spacing(2, 0, 1, 0),
        },
        "& table": {
            margin: theme.spacing(1, 0),
            borderCollapse: "collapse",
            width: "100%",
        },
        "& table thead": {
            backgroundColor: theme.palette.grey[200],
            border: `1px ${theme.palette.divider} solid`,
        },
        "& table th": {
            padding: theme.spacing(2),
        },
        "& table tr": {
            border: `1px ${theme.palette.divider} solid`,
        },
        "& table tbody td": {
            verticalAlign: "top",
            padding: theme.spacing(1, 2),
        },
        "& a": {
            color: theme.palette.primary.main,
            fontWeight: theme.typography.button.fontWeight,
        },
        "& a:hover": {
            textDecoration: "underline",
        },
        "& ul": {
            paddingLeft: theme.spacing(3),
            margin: theme.spacing(1, 0),
            listStyle: "circle inside",
        },
        "& ul ul": {
            listStyle: "disc inside",
        }
    },
}));

const __testExternaLink__ = document.createElement('a');

const isExternalLink = (url: string) => {
    if (url.slice(0, 7) === "mailto:") {
        return false;
    }
    __testExternaLink__.href = url;
    return __testExternaLink__.host !== window.location.host;
};

function linkTargetHandler(
    href: string,
    _children: Array<any>,
    _title: string | null
) {
    return isExternalLink(href) ? "_blank" : undefined;
}

export interface MarkdownProps {
    variant?: TypographyProps["variant"];
    className?: TypographyProps["className"];
    component?: any;
    color?: TypographyProps["color"];
    children: any;
}

export default function Markdown({ variant, className, component, children, color }: MarkdownProps) {
    const classes = useStyles();
    return <Typography
        variant={variant ?? "body1"}
        component={component ?? "div"}
        className={clsx(classes.root, className)}
        color={color}
    >
        <ReactMarkdown
            remarkPlugins={[remarkGfm, remarkHtml]}
            linkTarget={linkTargetHandler}
            children={children}
        />
    </Typography>
}