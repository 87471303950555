import React, { useCallback, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { Container, Draggable, DropResult } from "react-smooth-dnd";

import { SelectFormWidget, SelectOption } from "cocoreact";
import {
    Collapse, createStyles, darken, Divider, Drawer, DrawerProps,
    IconButton, List, ListItem, ListItemAvatar,
    ListItemIcon, ListItemText, makeStyles,
    Menu, MenuItem, Tab, Tabs, Theme, Typography, useTheme
} from "@material-ui/core";
import { DragHandle as MoveIcon } from "@material-ui/icons";

import { ArrowDownIcon, ArrowUpIcon, PencilIcon } from "App/Theme";
import { ImagingDefault } from "domain/static/ImagingDefault";
import { EmptyGuid, Guid } from "domain/static/Guid";
import { VolumeType } from "domain/static/VolumeType";
import { Range } from "domain/static/Range";
import { Contour, Image } from "domain/admin/response/ClinicalDataSceneResponse";
import { ColorPicker, ColorPickerProps, ColorResult, Spacer } from "components/Page";
import SelectLutFormWidget from "components/Form/widgets/SelectLutFormWidget";
import ColorTableWidget from "components/Table/widgets/ColorTableWidget";
import WindowingFormWidget from "components/Form/widgets/WindowingFormWidget";
import { contourInfoLabel } from "tools/StringExtension";
import { useViewerContext, ImageData, ContourData } from "contexts/Viewer";
import { ContourHelper } from "tools/Viewer";
import { EditableVoxelVolume } from "dline-viewer/dist/data";

const DRAWER_WIDTH = 380;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            width: DRAWER_WIDTH,
            flexShrink: 0,
            zIndex: theme.zIndex.appBar - 1,
        },
        drawerPaper: {
            width: DRAWER_WIDTH,
        },
        tabs: {
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
            borderBottomColor: theme.palette.divider,
        },
        footer: {
            borderTopWidth: 1,
            borderTopStyle: "solid",
            borderTopColor: theme.palette.divider,
        },
        images_container: {
            display: "flex",
            flexDirection: "column",
            padding: theme.spacing(1, 2),
            overflowX: "hidden",
        },
        images_header: {
            fontSize: theme.typography.h6.fontSize,
            marginTop: theme.spacing(2),
            paddingBottom: theme.spacing(1),
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
            borderBottomColor: theme.palette.divider,
        },
        contours_container: {
            display: "flex",
            flexDirection: "column",
        },
        contour_group: {
            minHeight: 64,
            maxHeight: 64,
            backgroundColor: darken(theme.palette.background.paper, 0.2),
            "&:hover": {
                backgroundColor: theme.palette.background.default,
            }
        },
        contour_item: {
            minHeight: 64,
            maxHeight: 64,
            "& .dnd-handle": {
                cursor: "move",
                opacity: 0.3,
            },
        },
        contour_drawing: {
            backgroundColor: darken(theme.palette.background.paper, 0.1),
        }
    })
);

// ----------------------------------------------


interface ConfigurationImageProps {
    image?: Image;
    imageData?: ImageData;
    options: SelectOption[];
    imagingDefault: ImagingDefault;
}

function ConfigurationImage({
    image, imageData, options, imagingDefault
}: ConfigurationImageProps) {

    const windowing = imageData ? [imageData.windowing.min, imageData.windowing.max] : [0, 100];
    const range = image ? [image.range.min, image.range.max] : [0, 100];
    const lutName = imageData ? imageData.lut : "Grayscale";

    const { dispatcher } = useViewerContext();

    const onImageChange = useCallback((_: any, value: Guid) => {
        dispatcher({
            type: imagingDefault === ImagingDefault.Primary ? "IMAGE_PRIMARY" : "IMAGE_OVERLAY",
            imageId: value,
        });
    }, [dispatcher, imagingDefault]);

    const onWindowingChange = useCallback((_: any, value: number[]) => {
        if (!image) return;
        dispatcher({
            type: "IMAGE_WINDOWING",
            imageId: image.id,
            windowing: new Range(value),
        });
    }, [dispatcher, image]);

    const onLutChange = useCallback((_: any, value: string) => {
        if (!image) return;
        dispatcher({
            type: "IMAGE_LUT",
            imageId: image.id,
            lut: value,
        });
    }, [dispatcher, image]);

    return (
        <>
            <SelectFormWidget
                margin="normal"
                fullWidth={true}
                name="id"
                type="undefined"
                label="Image"
                value={image?.id ?? EmptyGuid()}
                options={options}
                onChange={onImageChange}
            />

            {image && <WindowingFormWidget
                fullWidth={true}
                disabled={!image}
                margin="normal"
                name="windowing"
                type="undefined"
                label="Windowing"
                step={1}
                value={windowing}
                range={range}
                onChange={onWindowingChange}
            />}

            {image && <SelectLutFormWidget
                margin="normal"
                fullWidth={true}
                disabled={!image}
                name="lut"
                type="undefined"
                label="Lookup Table"
                value={lutName}
                onChange={onLutChange}
            />}
        </>
    )
}

interface ConfigurationImagesProps {
    images: Image[];
}

function ConfigurationImages({ images }: ConfigurationImagesProps) {

    const classes = useStyles();

    const { data: viewerData } = useViewerContext();

    const primaryImageData = useMemo(() => viewerData.getImagePrimary(), [viewerData]);
    const primaryImage = primaryImageData ? images.find(x => x.id === primaryImageData.id) : undefined;

    const overlayImageData = useMemo(() => viewerData.getImageOverlay(), [viewerData]);
    const overlayImage = overlayImageData ? images.find(x => x.id === overlayImageData.id) : undefined;

    const options = images.map(x => ({
        value: x.id,
        label: `[${x.imagingModalityName}] ${x.name}`,
    } as SelectOption));

    const primaryOptions = options.map(x => ({ ...x } as SelectOption));
    primaryOptions.unshift({
        value: EmptyGuid(),
        label: "None",
    });

    let overlayOptions = options.map(x => ({ ...x } as SelectOption));
    if (primaryImageData) {
        overlayOptions = overlayOptions.filter(x => x.value !== primaryImageData.id);
    }
    overlayOptions.unshift({
        value: EmptyGuid(),
        label: "None",
    });

    return <div className={classes.images_container}>

        <Typography component="h4" className={classes.images_header}>
            Primary
        </Typography>

        <ConfigurationImage
            image={primaryImage}
            imageData={primaryImageData}
            options={primaryOptions}
            imagingDefault={ImagingDefault.Primary}
        />

        <Typography component="h4" className={classes.images_header}>
            Overlay
        </Typography>

        <ConfigurationImage
            image={overlayImage}
            imageData={overlayImageData}
            options={overlayOptions}
            imagingDefault={ImagingDefault.Overlay}
        />

    </div>
}

// ----------------------------------------------

const useColorStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            marginLeft: -5,
            overflow: "visible", // triangle
            backgroundColor: "transparent"
        },
        menu: {
            padding: 0,
            backgroundColor: theme.palette.background.default,
        },
        menuItem: {
            padding: 0,
            overflow: "visible", // triangle
            "&:hover": {
                backgroundColor: "transparent"
            }
        }
    })
);

interface ColorPickerPopupProps {
    anchorEl: HTMLElement | null;
    color: string;
    onChange: ColorPickerProps["onChange"];
    onClose: () => void;
}

function ColorPickerPopup({ anchorEl, color, onChange, onClose }: ColorPickerPopupProps) {
    const classes = useColorStyles();
    const theme = useTheme();

    return <Menu
        keepMounted
        id="color-picker-popup"
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
        }}
        classes={{
            paper: classes.paper,
            list: classes.menu,
        }}
    >
        <MenuItem className={classes.menuItem}>
            <ColorPicker
                color={color}
                onChange={onChange}
                triangle="top-left"
                styles={{
                    default: {
                        card: {
                            maxWidth: DRAWER_WIDTH - 48,
                        },
                        triangle: {
                            borderColor: `transparent transparent ${theme.palette.background.default}`
                        }
                    }
                }}
            />
        </MenuItem>
    </Menu>
}

interface ContourItemProps {
    contour: Contour;
    onClickColor: (contour: Contour, el: HTMLButtonElement) => void;
}

function ContourItem({ contour, onClickColor }: ContourItemProps) {

    const classes = useStyles();
    const colorRef = useRef<HTMLButtonElement>(null);
    const worker = useRef(new Worker(`${process.env.PUBLIC_URL}/workers/viewer.worker.js`));

    const { data: viewerData, dispatcher: viewerDispatcher, app: viewerApp } = useViewerContext();
    const contourData = viewerData.getContourById(contour.id) as ContourData;
    const contourHelper = viewerApp.getContourById(contour.id) as ContourHelper;

    const workerMessageHandler = React.useCallback(async (ev: MessageEvent) => {
        const editableVoxelVolume = ev.data.editableVoxelVolume as EditableVoxelVolume;
        if (contourHelper) {
            await contourHelper.updateEditableVolume(editableVoxelVolume);

            await viewerDispatcher({
                type: "CONTOUR_DRAWING",
                contourId: contourData.drawing ? null : contour.id,
            });
            viewerDispatcher({
                type: "LOADING",
                loading: false,
            }, "data");
        }
    }, [contour.id, contourData.drawing, contourHelper, viewerDispatcher]);

    const onClickHandle = useCallback(async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        event.preventDefault();
        const readonlyRoi = contourHelper ? contourHelper.getReadonlyRoi() : null;
        if (readonlyRoi && contourHelper.mode !== "drawing") {
            viewerDispatcher({
                type: "LOADING",
                loading: true,
            }, "data");
            await contourHelper.initializeEditableVolume();

            worker.current.onmessage = workerMessageHandler;
            worker.current.postMessage({
                msg: "POLYGON_TO_VOXEL",
                polygonVolume: readonlyRoi.polygonVolume,
            });
        }
        else if (contourHelper && contourHelper.mode === "drawing") {
            await viewerDispatcher({
                type: "CONTOUR_DRAWING",
                contourId: contourData.drawing ? null : contour.id,
            });
        }
    }, [contour.id, contourData.drawing, contourHelper, viewerDispatcher, workerMessageHandler]);

    const onColorHandle = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        event.preventDefault();
        colorRef.current && onClickColor(contour, colorRef.current)
    }, [contour, onClickColor]);

    const label = contour.anatomicStructureName
        ? contourInfoLabel(contour.anatomicStructureName, contour.volumeType, contour.targetTypeName)
        : contour.name;

    const className = clsx(
        classes.contour_item,
        contourData.drawing ? classes.contour_drawing : undefined,
    );

    return <ListItem button className={className} onClick={onClickHandle}>
        <ListItemAvatar>
            <IconButton
                ref={colorRef}
                size="small"
                onClick={onColorHandle}
            >
                <ColorTableWidget name="color" type="undefined" value={contourData.color} />
            </IconButton>
        </ListItemAvatar>
        <ListItemText primary={label} secondary={contour.organizationName} />
        <ListItemIcon style={{ justifyContent: "space-between" }}>
            <MoveIcon className="dnd-handle" onClick={e => {
                e.stopPropagation();
                e.preventDefault();
            }} />
            {contourData.drawing && <PencilIcon />}
        </ListItemIcon>
    </ListItem>
}

interface ContoursOrderedListProps {
    contours: Contour[];
    onClickColor: ContourItemProps["onClickColor"];
}

function ContoursOrderedList({ contours, onClickColor }: ContoursOrderedListProps) {
    const { dispatcher: viewerDispatcher, data: viewerData } = useViewerContext();

    const orderedContours = useMemo(() => {
        return contours.sort((a, b) => {
            const aIdx = viewerData.contours.findIndex(x => x.id === a.id);
            const bIdx = viewerData.contours.findIndex(x => x.id === b.id);
            return aIdx - bIdx;
        })
    }, [contours, viewerData.contours]);

    const onDropHandle = useCallback(({ addedIndex, removedIndex }: DropResult) => {
        if (addedIndex === null || removedIndex === null) return;

        // convert local group index to global contour list index
        const id = contours[removedIndex].id
        const removedPos = viewerData.contours.findIndex(x => x.id === id);
        const addedPos = removedPos - (removedIndex - addedIndex);

        viewerDispatcher({
            type: "CONTOUR_MOVE",
            addedIndex: addedPos,
            removedIndex: removedPos,
        }, "data");
    }, [contours, viewerData.contours, viewerDispatcher]);

    return <List style={{ padding: 0 }}>
        <Container dragHandleSelector=".dnd-handle" lockAxis="y" onDrop={onDropHandle}>
            {orderedContours.map((c) =>
                <Draggable key={c.id}>
                    <ContourItem
                        contour={c}
                        onClickColor={onClickColor}
                    />
                </Draggable>
            )}
        </Container>
    </List>
}

interface ContoursGroupProps {
    label: string;
    contours: Contour[];
    defaultCollapsed?: boolean;
    onClickColor: ContourItemProps["onClickColor"];
}

function ContoursGroup({ label, onClickColor, contours, defaultCollapsed }: ContoursGroupProps) {
    const classes = useStyles();
    const [collapsed, setCollapsed] = useState(defaultCollapsed ?? true);

    const text = <Typography variant="button" component="p">
        {label} ({contours.length})
    </Typography>

    return <>
        <ListItem className={classes.contour_group} button onClick={() => setCollapsed(!collapsed)}>
            <ListItemText primary={text} />
            <ListItemIcon>
                {collapsed ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </ListItemIcon>
        </ListItem>

        <Collapse in={collapsed && contours.length > 0}>
            <ContoursOrderedList contours={contours} onClickColor={onClickColor} />
        </Collapse>

        <Divider />
    </>
}

interface ConfigurationContoursProps {
    contours: Contour[];
    distinctVolumeTypes?: boolean;
}

function ConfigurationContours({ contours, distinctVolumeTypes }: ConfigurationContoursProps) {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [colorContour, setColorContour] = useState<null | Contour>(null);

    const { dispatcher: viewerDispatcher } = useViewerContext();

    const onClickColorHandle = (contour: Contour, el: HTMLButtonElement) => {
        setColorContour(contour);
        setAnchorEl(el);
    }

    const handleColorChange = (color: ColorResult) => {
        if (colorContour) {
            viewerDispatcher({
                type: "CONTOUR_COLOR",
                color: color.hex,
                contourId: colorContour.id,
            });
        }
        handleColorClose();
    }

    const handleColorClose = () => {
        setAnchorEl(null);
        setColorContour(null);
    }

    if (distinctVolumeTypes === false) {
        return <div className={classes.contours_container}>
            <ColorPickerPopup
                color={colorContour?.color ?? "#ffffff"}
                anchorEl={anchorEl}
                onChange={handleColorChange}
                onClose={handleColorClose}
            />
            <ContoursOrderedList
                contours={contours}
                onClickColor={onClickColorHandle}
            />
        </div>
    }

    const contours_target = contours.filter(x => x.volumeType === VolumeType.Target);
    const contours_anatomy = contours.filter(x => x.volumeType === VolumeType.AtRisk || x.volumeType === VolumeType.Anatomy);
    const contours_unset = contours.filter(x => !x.volumeType);

    return <div className={classes.contours_container}>
        <ColorPickerPopup
            color={colorContour?.color ?? "#ffffff"}
            anchorEl={anchorEl}
            onChange={handleColorChange}
            onClose={handleColorClose}
        />
        <ContoursGroup
            label="target"
            contours={contours_target}
            onClickColor={onClickColorHandle}
        />
        <ContoursGroup
            label="at risk / Anatomy"
            contours={contours_anatomy}
            onClickColor={onClickColorHandle}
        />
        <ContoursGroup
            label="unclassified"
            contours={contours_unset}
            onClickColor={onClickColorHandle}
            defaultCollapsed={false}
        />
    </div>
}

// ----------------------------------------------

export interface SceneConfigurationDrawerProps {
    container: DrawerProps["container"];
    images: Image[];
    contours: Contour[];
    distinctVolumeTypes?: boolean;
    children?: React.ReactNode | React.ReactNode[];
}

export default function SceneConfigurationDrawer({
    container, images, contours, children, distinctVolumeTypes
}: SceneConfigurationDrawerProps) {

    const classes = useStyles();

    const [tab, setTab] = useState(0);

    return (
        <Drawer
            variant="permanent"
            anchor="right"
            className={classes.drawer}
            classes={{
                paper: classes.drawerPaper,
            }}
            PaperProps={{ style: { position: 'absolute' } }}
            BackdropProps={{ style: { position: 'absolute' } }}
            ModalProps={{
                container: container,
                style: { position: 'absolute' }
            }}
        >
            <Tabs
                value={tab}
                aria-label="scene tabs"
                variant="fullWidth"
                className={classes.tabs}
            >
                <Tab key={0} label="images" onClick={() => setTab(0)} />
                <Tab key={1} label="contours" onClick={() => setTab(1)} />
            </Tabs>

            <div style={{
                overflow: "hidden",
                overflowY: "auto",
            }}>

                {tab === 0 &&
                    <ConfigurationImages images={images} />
                }

                {tab === 1 &&
                    <ConfigurationContours contours={contours} distinctVolumeTypes={distinctVolumeTypes} />
                }

            </div>

            <Spacer />

            {children &&
                <div className={classes.footer}>
                    {children}
                </div>
            }
        </Drawer>
    );
}