import React, { useCallback } from "react";
import { IFormWidgetPropsBase } from "cocoreact";
import {
    FormControl, FormHelperText,
    InputLabel, Slider
} from "@material-ui/core";

export interface WindowingFormWidgetProps extends IFormWidgetPropsBase<number[]> {
    range: number[];
    step?: number;
}

export default function WindowingFormWidget({
    name, value, range, step, onChange, ...props
}: WindowingFormWidgetProps) {

    const handleChange = useCallback((e: any, value: any) => {
        onChange && onChange(name, value);
    }, [name, onChange]);

    return <FormControl
        required={props.required}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
        margin={props.margin}
        error={props.error !== undefined}
        className={props.className}
        style={{ marginBottom: 0, ...props.style }}
        aria-label={props.label}
    >
        {props.label && <InputLabel shrink>{props.label}</InputLabel>}

        <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
            <Slider
                color={props.color as any}
                valueLabelDisplay="auto"
                step={step}
                disabled={props.disabled}
                value={value}
                min={range[0]}
                max={range[1]}
                onChange={handleChange}
            />
        </div>

        {props.error && <FormHelperText>Invalid windowing</FormHelperText>}

    </FormControl>
}