import React from "react";
import {
    Avatar, Paper, Typography,
    createStyles, makeStyles,
    Theme, SvgIconProps
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({
    main: {
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        marginTop: theme.spacing(-9),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        maxWidth: 400,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(2),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    title: {
        textAlign: "center",
    },
}));

export interface CentralPaperLayoutProps {
    title: string;
    icon: React.ReactElement<SvgIconProps>;
    children?: React.ReactNode | React.ReactNode[];
}

export default function CentralPaperLayout({ title, icon, children }: CentralPaperLayoutProps) {

    const classes = useStyles();

    return (
        <main className={classes.main}>

            <Paper className={classes.paper}>

                <Avatar className={classes.avatar}>
                    {icon}
                </Avatar>

                <Typography component="h1" variant="h5" className={classes.title}>
                    {title}
                </Typography>

                {children}

            </Paper>

        </main>
    );
}
