import React from "react";

export interface FileInputProps {
    onSelect: (files: FileList | File) => void;
    multiple?: boolean;
    accept?: string;
}

const FileInput = React.forwardRef<HTMLInputElement, FileInputProps>((
    { onSelect, multiple, accept }, ref
) => {
    const handleClick = React.useCallback(
        (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
            e.currentTarget.value = ""
        },
        []
    );

    const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files === null) {
            return;
        }
        if (multiple === true) {
            onSelect(files);
        }
        else {
            onSelect(files[0]);
        }
    }, [multiple, onSelect]);

    return (
        <input
            ref={ref}
            type="file"
            multiple={multiple}
            accept={accept}
            onChange={handleChange}
            onClick={handleClick}
            style={{ display: "none" }}
        />
    )
});

export default FileInput;
