import React from "react";
import { Button } from "@material-ui/core";
import { ClearIcon } from "App/Theme";

export interface ResetFiltersButtonProps {
    onClick: () => void;
}

export default function ResetFiltersButton({
    onClick
}: ResetFiltersButtonProps) {
    return <Button
        variant="text"
        onClick={onClick}
        startIcon={<ClearIcon />}
    >
        Reset filters
    </Button>
}