import React, { useCallback, useEffect, useState } from "react";
import {
    alpha, Button, Checkbox, createStyles, Dialog,
    DialogActions, DialogContent, DialogTitle, IconButton,
    List, ListItem, ListItemIcon, ListItemText, makeStyles,
    TextField, Theme
} from "@material-ui/core";
import { IndeterminateCheckBox as IndeterminateCheckBoxIcon } from "@material-ui/icons";

import { Flex } from "components/Page";
import { buildSearchUsersQuery } from "domain/hooks/useSearchUsers";
import { UserItemResponse } from "domain/admin/response/ODataUserItemResponse";
import { sendMessage } from "tools/Message";
import { Guid } from "domain/static/Guid";
import { AddIcon, ClearIcon } from "App/Theme";
import { UserRole } from "domain/static/UserRole";

const useStyles = makeStyles((theme: Theme) => createStyles({
    dialogContent: {
        padding: theme.spacing(2, 0),
        height: 500,
        minHeight: 500,
        maxHeight: 500,
    },
    list: {
        "& > li:nth-child(even)": {
            backgroundColor: theme.palette.background.paper,
        },
        "& > li:nth-child(odd)": {
            backgroundColor: theme.palette.background.default,
        },
        "& > li:nth-child(even).selected": {
            backgroundColor: alpha(theme.palette.secondary.light, 0.5),
        },
        "& > li:nth-child(odd).selected": {
            backgroundColor: alpha(theme.palette.secondary.light, 0.5),
        },
    },
}));

export interface SelectUserDialogProps {
    open: boolean;
    title: string;
    disabled: boolean;
    onClose: () => void;
    onSelect: (selectedUsers: UserItemResponse[]) => (void | Promise<void>);
    actionLabel?: string;
    exclude?: Guid[];
}

export default function SelectUserDialog({
    open, title, disabled, onClose, onSelect, actionLabel, exclude
}: SelectUserDialogProps) {

    const classes = useStyles();

    const [users, setUsers] = useState<UserItemResponse[]>([]);
    const [selectedIds, setSelectedIds] = useState<Guid[]>([]);
    const [filter, setFilter] = useState("");

    useEffect(() => {
        setSelectedIds([]);
        setFilter("");
    }, [open]);

    useEffect(() => {
        const _initializeUsers = async () => {
            const query = buildSearchUsersQuery();
            query.top = 100000;
            const fields = UserItemResponse.Fields;
            query.orderBy.set(fields.email.name);
            query.filter.set(fields.firstname.name, fields.firstname.name, "contains", "");
            query.filter.set(fields.lastname.name, fields.lastname.name, "contains", "");
            query.filter.set(fields.email.name, fields.email.name, "contains", "");
            query.filter.set(fields.role.name, fields.role.name, "has" as any, UserRole.Standard.toString());
            query.filter.set(fields.isArchived.name, fields.isArchived.name, "eq", "false");

            const response = await sendMessage<any>(query);
            setUsers(response.d.results)
        }
        _initializeUsers();
    }, []);

    const onSelectUser = useCallback((id: Guid) => {
        setSelectedIds(prev => {
            if (prev.includes(id)) {
                return prev.filter(x => x !== id);
            }
            else {
                return [...prev, id];
            }
        });
    }, []);

    const onSelectHandle = useCallback(async () => {
        var res = onSelect(users.filter(x => selectedIds.includes(x.id)));
        if (res instanceof Promise) {
            await res;
        }
    }, [onSelect, selectedIds, users]);

    var filteredUsers = users;
    if (filter.length > 0) {
        filteredUsers = users.filter(x =>
            x.firstname.includes(filter) ||
            x.lastname.includes(filter) ||
            x.email.includes(filter)
        );
    }
    if (exclude) {
        filteredUsers = filteredUsers.filter(x => !exclude.includes(x.id))
    }

    return <Dialog open={open} onClose={disabled ? undefined : onClose} scroll="paper" fullWidth maxWidth="sm">
        <DialogTitle>
            {title}
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContent}>
            <Flex padding={[0, 2, 1, 2]}>
                <Checkbox
                    icon={selectedIds.length > 0
                        ? <IndeterminateCheckBoxIcon color="secondary" />
                        : undefined}
                    disabled={selectedIds.length === 0}
                    onClick={() => setSelectedIds([])}
                />
                <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="filter"
                    placeholder="name or email"
                    value={filter}
                    onChange={e => setFilter(e.currentTarget.value)}
                    InputProps={{
                        endAdornment: filter.length > 0
                            ? <IconButton size="small" onClick={() => setFilter("")}><ClearIcon /></IconButton>
                            : undefined
                    }}
                />
            </Flex>
            <List className={classes.list}>
                {filteredUsers.map(x => (
                    <ListItem
                        key={x.id}
                        onClick={e => onSelectUser(x.id)}
                        className={selectedIds.includes(x.id) ? "selected" : undefined}
                    >
                        <ListItemIcon>
                            <Checkbox checked={selectedIds.includes(x.id)} />
                        </ListItemIcon>
                        <ListItemText
                            primary={`${x.firstname} ${x.lastname}`}
                            secondary={x.email}
                        />
                    </ListItem>
                ))}
            </List>
        </DialogContent>
        <DialogActions>
            <Flex justifyContent="space-between" width="100%" padding={[1, 2]}>
                <Button
                    variant="outlined"
                    onClick={onClose}
                    disabled={disabled}
                >
                    cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    startIcon={<AddIcon />}
                    disabled={selectedIds.length === 0 || disabled}
                    onClick={onSelectHandle}
                >
                    {actionLabel ? actionLabel : "select"} ({selectedIds.length})
                </Button>
            </Flex>
        </DialogActions>
    </Dialog>
}
