import { useEntityMessage } from "tools/Message";
import { EmptyGuid, Guid } from "domain/static/Guid";
import { GetUserQuery } from "domain/admin/query/GetUserQuery";
import { UserResponse } from "domain/admin/response/UserResponse";
import { useCallback } from "react";
import { UserRole } from "domain/static/UserRole";

export function emptyUserBuilder(
  initialValue?: Partial<UserResponse>
) {
  let entity = new UserResponse();
  entity.id = EmptyGuid();
  entity.firstname = initialValue?.firstname ?? "";
  entity.lastname = initialValue?.lastname ?? "";
  entity.lastname = initialValue?.lastname ?? "";
  entity.status = initialValue?.status ?? "";
  entity.abstract = initialValue?.abstract ?? "";
  entity.professionalLink = initialValue?.professionalLink ?? "";
  entity.scientificLink = initialValue?.scientificLink ?? "";
  entity.role = UserRole.Standard;
  entity.isArchived = false;
  return entity;
}

export function getUserQueryBuilder(id: Guid) {
  return new GetUserQuery({ id });
}

export default function useGetUser(
  id: Guid,
  initialValue?: Partial<UserResponse>
) {
  const emptyBuiler = useCallback(() => emptyUserBuilder(initialValue), [
    initialValue,
  ]);

  return useEntityMessage(id, getUserQueryBuilder, emptyBuiler);
}
