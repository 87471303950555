import { Guid } from "domain/static/Guid";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class SetArchivingStateClinicalCaseCommand implements IMessage {
    id!: Guid;
    isArchived!: boolean;

    public constructor(init?: Partial<SetArchivingStateClinicalCaseCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "PATCH" as RequestMethod;

    getPath = () => `/admin/clinical-cases/${this.id.toString()}`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            isArchived: this.isArchived,
        };
    }
    
    needAuthentication = () => true;
}
