//Generated code, do not modify

export enum SelectItemType {
  None = 0,
  AnatomicZone = 1,
  ImagingModality = 2,
  AnatomicStructure = 3,
  Organization = 4,
  TargetType = 5,
}

export const SelectItemTypeOptions = [
  {
    label: "None",
    value: SelectItemType.None,
  },
  {
    label: "AnatomicZone",
    value: SelectItemType.AnatomicZone,
  },
  {
    label: "ImagingModality",
    value: SelectItemType.ImagingModality,
  },
  {
    label: "AnatomicStructure",
    value: SelectItemType.AnatomicStructure,
  },
  {
    label: "Organization",
    value: SelectItemType.Organization,
  },
  {
    label: "TargetType",
    value: SelectItemType.TargetType,
  },
];
