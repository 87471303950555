import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { ITableWidgetPropsBase } from "cocoreact";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        height: 24,
        width: 24,
        borderRadius: "50%",
    }
}));

export interface ColorTableWidgetProps extends ITableWidgetPropsBase<string> { }

export default function ColorTableWidget({ value }: ColorTableWidgetProps) {
    const classes = useStyles();
    return <div className={classes.root} style={{ backgroundColor: value }}></div>
}