import { useEntityMessage } from "tools/Message";
import { Guid } from "domain/static/Guid";
import { GetClinicalDataArticlesQuery } from "domain/admin/query/GetClinicalDataArticlesQuery";
import { IdNameResponse } from "domain/admin/response/IdNameResponse";

export function emptyClinicalDataArticlesResponse() {
  return [] as IdNameResponse[];
}

export function GetClinicalDataArticlesQueryBuilder(id: Guid) {
  return new GetClinicalDataArticlesQuery({ id });
}

export default function useGetClinicalDataArticles(id: Guid) {
  return useEntityMessage(
    id,
    GetClinicalDataArticlesQueryBuilder,
    emptyClinicalDataArticlesResponse
  );
}
