import { useEntityMessage } from "tools/Message";
import { EmptyGuid, Guid } from "domain/static/Guid";
import { GetClinicalCaseQuery } from "domain/admin/query/GetClinicalCaseQuery";
import { ClinicalCaseResponse } from "domain/admin/response/ClinicalCaseResponse";
import { ClinicalCaseState } from "domain/static/ClinicalCaseState";

export function emptyClinicalCaseBuilder() {
  let entity = new ClinicalCaseResponse();
  entity.id = EmptyGuid();
  entity.name = "";
  entity.summary = "";
  entity.anatomicZoneId = EmptyGuid();
  entity.anatomicZoneName = "";
  entity.coverId = EmptyGuid();
  entity.state = ClinicalCaseState.None;
  entity.isArchived = false;
  entity.author = null as any;
  return entity;
}

export function getClinicalCaseQueryBuilder(id: Guid) {
  return new GetClinicalCaseQuery({ id });
}

export default function useGetClinicalCase(id: Guid) {
  return useEntityMessage(
    id,
    getClinicalCaseQueryBuilder,
    emptyClinicalCaseBuilder
  );
}
