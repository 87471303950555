import { Guid } from "domain/static/Guid";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class UpdateContouringWorkshopContourStackCommand implements IMessage {
    id!: Guid;
    contourId!: Guid;
    fileId!: Guid | null;

    public constructor(init?: Partial<UpdateContouringWorkshopContourStackCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "POST" as RequestMethod;

    getPath = () => `/admin/contouring-workshops/${this.id.toString()}/contours/${this.contourId.toString()}/stack`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            fileId: this.fileId,
        };
    }
    
    needAuthentication = () => true;
}
