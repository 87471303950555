import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import { Guid } from "domain/static/Guid";
import { ContouringWorkshopUserRole } from "domain/static/ContouringWorkshopUserRole";
import useGetContouringWorkshop from "domain/hooks/useGetContouringWorkshop";
import useGetContouringWorkshopUsers from "domain/hooks/useGetContouringWorkshopUsers";
import { UserItemResponse } from "domain/admin/response/ODataUserItemResponse";
import { AddContouringWorkshopUsersCommand } from "domain/admin/command/AddContouringWorkshopUsersCommand";
import { CentralColumnLayout } from "components/Layouts";
import { Paper, PaperTitle } from "components/Page";
import ParticipantTable from "./ParticipantTable";
import { OrganizerItem, ReferrerItem, UserManager } from "./UserManager";
import { useNotificationContext } from "cocoreact";
import { sendMessage } from "tools/Message";
import { SelectUserDialog } from "pages/Users";
import { Box, Grid } from "@material-ui/core";

export default function ListContributorsPanel() {

    const { id } = useParams<{ id: Guid }>();

    const [add, setAdd] = useState({ open: false })
    const [, entity] = useGetContouringWorkshop(id);
    const [loading, users, updateUsers] = useGetContouringWorkshopUsers(id, ContouringWorkshopUserRole.Participant);

    return <>
        <CentralColumnLayout size={"xl"}>

            <Grid container spacing={2}>

                <Grid item md={12} lg={6}>
                    <Box display="flex" flexDirection="column" gridGap={8}>
                        <Paper>
                            <PaperTitle>Organizers</PaperTitle>
                            <UserManager
                                id={id}
                                role={ContouringWorkshopUserRole.Organizer}
                                component={OrganizerItem}
                            />
                        </Paper>

                        <Paper>
                            <PaperTitle>Referrers</PaperTitle>
                            <UserManager
                                id={id}
                                role={ContouringWorkshopUserRole.Referrer}
                                component={ReferrerItem}
                            />
                        </Paper>
                    </Box>
                </Grid>

                <Grid item md={12} lg={6}>
                    <Box display="flex" flexDirection="column" gridGap={8}>
                        <Paper>
                            <PaperTitle>Participants ({users.length}/{entity.limitOfParticipants})</PaperTitle>
                            <ParticipantTable
                                id={id}
                                loading={loading}
                                users={users}
                                onAddClick={() => setAdd({ open: true })}
                                onAfterRemove={updateUsers}
                            />
                        </Paper>
                    </Box>
                </Grid>
            </Grid>

        </CentralColumnLayout>

        <AddParticipantDialog
            id={id}
            open={add.open}
            onClose={() => setAdd({ open: false })}
            onAfterAdd={updateUsers}
            exclude={users.map(x => x.id)}
        />
    </>
}

interface AddParticipantDialogProps {
    id: Guid;
    open: boolean;
    onClose: () => void;
    onAfterAdd: () => void;
    exclude: Guid[];
}

function AddParticipantDialog({ id, open, onClose, onAfterAdd, exclude }: AddParticipantDialogProps) {

    const { success, error } = useNotificationContext();
    const [loading, setLoading] = useState(false);

    const onAddHandle = useCallback(async (users: UserItemResponse[]) => {
        setLoading(true);

        const message = new AddContouringWorkshopUsersCommand();
        message.id = id;
        message.role = ContouringWorkshopUserRole.Participant;
        message.userIds = users.map(x => x.id);

        try {
            await sendMessage(message);

            onClose();
            onAfterAdd();
            setLoading(false);
            success("Participants added with success");
        }
        catch (e) {
            setLoading(false);
            const errData = JSON.parse((e as any)?.response?.data ?? "{}");
            const message = errData ? errData?.message : "An error occurred !";
            error(message);
        }
    }, [id, onAfterAdd, onClose, success, error]);

    return <SelectUserDialog
        open={open}
        onClose={onClose}
        title="Add particpants on contouring workshop"
        disabled={loading}
        onSelect={onAddHandle}
        actionLabel="add"
        exclude={exclude}
    />
}