import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { CentralColumnLayout, PageLayout } from "components/Layouts";
import { EmptyGuid, Guid } from "domain/static/Guid";
import { fullPath } from "tools/Route";
import ClinicalCaseForm from "./ClinicalCaseForm";
import { Paper, PaperTitle, Title } from "components/Page";
import { useNotificationContext } from "cocoreact";
import useGetClinicalCase from "domain/hooks/useGetClinicalCase";
import { ClinicalCaseIcon } from "App/Theme";

export default function CreateClinicalCasePage() {

    const [loadingEntity, entity] = useGetClinicalCase(EmptyGuid());

    const { success } = useNotificationContext();

    const history = useHistory();
    const handleAfterSave = useCallback((id?: Guid) => {
        if (id) {
            history.push(fullPath("EditClinicalCase", { params: { id } }));
        }
        else {
            history.push(fullPath("ListClinicalCases"));
        }
        success("Clinical case saved with success");
    }, [history, success]);

    const title = "Create a clinical case";

    return <>
        <Title>{title}</Title>

        <PageLayout title="Clinical cases" menuSelected="ListClinicalCases">
            <CentralColumnLayout size="md">

                <Paper>
                    <PaperTitle icon={<ClinicalCaseIcon />}>{title}</PaperTitle>

                    <ClinicalCaseForm
                        loading={loadingEntity}
                        entity={entity}
                        onAfterSave={handleAfterSave}
                    />
                </Paper>

            </CentralColumnLayout>
        </PageLayout>
    </>
}
