import React, { useCallback, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress, ListItemText, TextField } from "@material-ui/core";

import { SearchArticleOptionsQuery } from "domain/admin/query/SearchArticleOptionsQuery";
import { IdNameResponse } from "domain/admin/response/IdNameResponse";
import { sendMessage } from "tools/Message";

export interface SearchArticleFieldProps {
    onSelect: (item: IdNameResponse) => void;
    placeholder?: string;
}

export default function SearchArticleField({ onSelect, placeholder }: SearchArticleFieldProps) {
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<IdNameResponse[]>([]);

    const searchHandle = useCallback(async (keywords: string) => {
        keywords = keywords.trim();
        if (!keywords) return;

        setLoading(true);
        const query = new SearchArticleOptionsQuery({ top: 50, keywords });
        const items = await sendMessage<IdNameResponse[]>(query)
        setOptions(items)
        setLoading(false);
    }, []);

    return (
        <Autocomplete<IdNameResponse>
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            options={options}
            value={null}
            inputValue={inputValue}
            loading={loading}
            clearOnBlur
            clearOnEscape
            noOptionsText={"no article found"}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(e, newValue) => {
                e.preventDefault();
                e.stopPropagation();
                newValue && onSelect(newValue);
                setOptions([]);
                setInputValue("")
            }}
            onInputChange={(_e, newInputValue) => {
                searchHandle(newInputValue);
                setInputValue(newInputValue);
            }}
            getOptionLabel={option =>
                typeof option === 'string' ? option : `${option.name}`
            }
            renderOption={option =>
                <ListItemText primary={option.name} />
            }
            renderInput={params =>
                <TextField
                    {...params}
                    label={placeholder ?? "Search an article"}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />}
        />
    )
}
