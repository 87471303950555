/* eslint-disable no-unused-vars */
import React, { useCallback } from "react";
import { IFormWidgetPropsBase } from "cocoreact";
// import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns";
// import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { TextField } from "@material-ui/core";
import { format } from "tools/DateExtension";

export interface DateFormWidgetProps extends IFormWidgetPropsBase<Date> {
	format?: string;
	minDate?: Date;
	maxDate?: Date;
	startAdornment?: React.ReactNode;
}

export default function DateFormWidget(props: DateFormWidgetProps) {
	const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		try {
			var d = new Date(Date.parse(e.target.value));
			props.onChange && props.onChange(props.name, d);
		}
		catch (e) { }
	}, [props]);

	return <TextField
		type="datetime-local"
		required={props.required}
		fullWidth={props.fullWidth}
		margin={props.margin}
		disabled={props.disabled}
		autoFocus={props.autoFocus}
		color={props.color as any}
		InputLabelProps={{ shrink: true }}
		aria-label={props.label}
		label={props.label}
		value={format(props.value ?? new Date(), "yyyy-MM-dd'T'HH:mm")}
		onChange={handleChange}
		error={props.error !== undefined}
		helperText={props.error}
		className={props.className}
		style={props.style}
	/>

	// const handleChange = useCallback(
	// 	(d: MaterialUiPickersDate) => {
	// 		if (d !== null && props.onChange) {
	// 			props.onChange(props.name, d);
	// 		}
	// 	},
	// 	[props]
	// );

	// return (
	// 	<MuiPickersUtilsProvider utils={DateFnsUtils}>
	// 		<DatePicker
	// 			required={props.required}
	// 			fullWidth={props.fullWidth}
	// 			margin={props.margin}
	// 			disabled={props.disabled}
	// 			autoFocus={props.autoFocus}
	// 			color={props.color as any}
	// 			InputLabelProps={{ shrink: true }}
	// 			aria-label={props.label}
	// 			label={props.label}
	// 			value={props.value}
	// 			minDate={props.minDate}
	// 			maxDate={props.maxDate}
	// 			format={props.format}
	// 			onChange={handleChange}
	// 			error={props.error !== undefined}
	// 			helperText={props.error}
	// 			className={props.className}
	// 			style={props.style}
	// 			InputProps={{
	// 				startAdornment: props.startAdornment,
	// 				onClick: (e) => {
	// 					if (
	// 						props.startAdornment &&
	// 						(e.target as any).nodeName !== "INPUT"
	// 					) {
	// 						e.stopPropagation();
	// 						e.preventDefault();
	// 					}
	// 				}
	// 			}}
	// 		/>
	// 	</MuiPickersUtilsProvider>
	// );
}
