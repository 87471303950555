import React from "react";
import { useMessage } from "tools/Message";
import { Guid } from "domain/static/Guid";
import { GetArticleGuidelinesQuery } from "domain/admin/query/GetArticleGuidelinesQuery";
import { GuidelineItemResponse } from "domain/admin/response/GuidelineItemResponse";

export function buildGetArticleGuidelinesQuery(id: Guid) {
  const query = new GetArticleGuidelinesQuery();
  query.id = id;
  return query;
}

export default function useListGuidelines(
  id: Guid,
  includeArchived: boolean = false
) {
  const query = React.useMemo(() => buildGetArticleGuidelinesQuery(id), [id]);
  const result = useMessage<GuidelineItemResponse[]>(query, []);
  if (!includeArchived) {
    result[1] = result[1].filter((x) => !x.isArchived);
  }
  return result;
}
