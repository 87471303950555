import React from "react";

export interface CallbackButtonProps {
    callback: () => void;
}

const CallbackButton = React.forwardRef<HTMLButtonElement, CallbackButtonProps>(
    ({ callback }, ref) => (
        <button
            onClick={callback}
            ref={ref}
            style={{ display: "none" }}
        />
    )
);

export default CallbackButton;