/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { IFormWidgetPropsBase } from "cocoreact";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Button, CircularProgress, ClickAwayListener, createStyles, FormControl, Grow, makeStyles, MenuItem, MenuList, Paper, Popper, TextField, Theme, Typography } from "@material-ui/core";

import { sendMessage } from "tools/Message";
import { AddIcon, MoreIcon } from "App/Theme";
import { Guid, IsValidGuid } from "domain/static/Guid";
import { ArticleFormDialog, ArticleFormDialogProps } from "pages/Articles";
import { IdNameResponse } from "domain/admin/response/IdNameResponse";
import { SearchArticleOptionsQuery } from "domain/admin/query/SearchArticleOptionsQuery";
import { GetArticleOptionQuery } from "domain/admin/query/GetArticleOptionQuery";

const useOptionItemStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "row",
			width: "100%",
		},
		left: {
			display: "flex",
			flexDirection: "column",
			flexGrow: 1,
			justifyContent: "center",
		},
		right: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
		},
		onlyIcon: {
			margin: theme.spacing(1, 1.5),
		}
	})
);

export function CreateArticleOption() {
	const classes = useOptionItemStyles();

	return (
		<div className={classes.container}>
			<div className={classes.left}>
				<Typography component="span" variant="subtitle1">
					Create a new article
				</Typography>
			</div>
			<div className={classes.right}>
				<AddIcon className={classes.onlyIcon} color="inherit" />
			</div>
		</div>
	)
}

// --------------------------------------------------------

const useCardStyles = makeStyles((theme: Theme) =>
	createStyles({
		label: {
			position: "relative",
			display: "flex",
			color: theme.palette.text.primary,
			padding: theme.spacing(2, 6, 2, 2),
			border: theme.shape.borderRadius,
		},
		moreBtn: {
			position: "absolute",
			top: theme.spacing(1.5),
			right: theme.spacing(1),
			color: theme.palette.getContrastText(theme.palette.background.default),
			minWidth: 32,
			maxWidth: 32,
			"&:hover": {
				backgroundColor: theme.palette.background.default,
			}
		},
	})
);

interface ArticlePreviewCardProps {
	value: IdNameResponse;
	onDelete: () => void;
	onEdit: (id: Guid) => void;
}

function ArticlePreviewCard({
	value, onDelete, onEdit
}: ArticlePreviewCardProps) {

	const classes = useCardStyles();
	const menuRef = useRef<HTMLButtonElement>(null);
	const [menuOpen, setMenuOpen] = useState(false);

	const openHandle = useCallback((e: any) => {
		setMenuOpen(true);
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
	}, []);

	const closeHandle = useCallback((e: any) => {
		setMenuOpen(false);
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
	}, []);

	return (
		<Paper>
			<Typography component="span" variant="subtitle1" className={classes.label}>
				{value.name}
			</Typography>

			<Button
				ref={menuRef}
				className={classes.moreBtn}
				onClick={openHandle}
			>
				<MoreIcon />
			</Button>

			<Popper
				open={menuOpen}
				anchorEl={menuRef.current}
				role={undefined}
				transition={true}
				disablePortal={true}
				placement="bottom-end"
				style={{ zIndex: 10000000 }}
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<Paper>
							<ClickAwayListener
								onClickAway={closeHandle}
							>
								<MenuList>
									<MenuItem
										onClick={(e: any) => {
											closeHandle(e);
											onEdit(value.id);
										}}
									>
										Edit article
									</MenuItem>
									<MenuItem onClick={onDelete}>Remove</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</Paper>
	);
}

// --------------------------------------------------------

async function searchArticlesByKeywords(keywords: string) {
	if (keywords && keywords.length > 0) {
		const query = new SearchArticleOptionsQuery({ keywords, top: 50 });
		return await sendMessage<IdNameResponse[]>(query);
	}
	return [];
}

const CREATE_CUSTOM_ARTICLE_ID = "create-guideartcileline";

function isCustomValue(v: IdNameResponse): boolean {
	return v.id === CREATE_CUSTOM_ARTICLE_ID;
}

const CREATE_CUSTOM_ARTICLE =
	{
		id: CREATE_CUSTOM_ARTICLE_ID,
		name: "create an article"
	} as IdNameResponse;

// --------------------------------------------------------

export interface SearchArticleFormWidgetProps extends IFormWidgetPropsBase<any> {
	defaultValue?: IdNameResponse;
	noOptionsText?: string;
}

export default function SearchArticleFormWidget(props: SearchArticleFormWidgetProps) {
	const timer = useRef<NodeJS.Timeout | null>(null);
	const [loading, setLoading] = useState(false);
	const [options, setOptions] = useState<IdNameResponse[]>([]);
	const [dialogProps, setDialogProps] = useState({
		open: false,
		id: undefined as ArticleFormDialogProps["id"],
	});
	const [article, setArticle] = useState<IdNameResponse | undefined>(undefined);

	useEffect(() => {
		setArticle(props.defaultValue);
	}, [props.defaultValue])

	useEffect(() => {
		if (props.value) {
			return;
		}
		setArticle(undefined);
	}, [props.defaultValue, props.value]);

	const closeDialogHandle = useCallback(
		() => setDialogProps({ open: false, id: undefined }),
		[]
	);

	const onChangeHandle = useCallback(
		(_e: any, option: IdNameResponse | null) => {
			if (option && isCustomValue(option)) {
				setDialogProps({ open: true, id: undefined });
				setArticle(undefined);
				return;
			}
			if (option) {
				setArticle(option);
				setOptions([]);
				props.onChange && props.onChange(props.name, option.id);
				return;
			}
			props.onChange && props.onChange(props.name, undefined);
		},
		[props]
	);

	const inputChangeHandle = useCallback(async (keywords: string) => {
		if (timer.current) {
			clearTimeout(timer.current);
		}
		timer.current = setTimeout(async () => {
			setLoading(true);
			let articles = [] as IdNameResponse[];
			if (keywords && keywords.length > 0) {
				articles = await searchArticlesByKeywords(keywords);
			}
			setLoading(false);
			setOptions(articles);
		}, 400);
	}, []);

	const filterOptionsHandle = useCallback(
		(options: IdNameResponse[]) => options,
		[]
	);

	const getOptionLabelHandle = useCallback(
		(option: IdNameResponse) => IsValidGuid(option.id) ? option.name : "",
		[]
	);

	const getOptionSelectedHandle = useCallback(
		(option: IdNameResponse, value: IdNameResponse) => option.id === value.id,
		[]
	);

	const renderOptionHandle = useCallback(
		(option: IdNameResponse) => isCustomValue(option)
			? <CreateArticleOption />
			: option.name,
		[]
	);

	const onEditHandle = useCallback(() => {
		if (article) {
			setDialogProps({
				open: true,
				id: article.id,
			});
		}
	}, [article]);

	const onAfterSaveHandle = useCallback(async (id: Guid) => {
		setLoading(true);

		const query = new GetArticleOptionQuery({ id });
		const article = await sendMessage<IdNameResponse>(query);

		setLoading(false);
		setArticle(article);
		setOptions([]);
		props.onChange && props.onChange(props.name, id);

		closeDialogHandle();

	}, [closeDialogHandle, props]);


	return (
		<>
			{article &&
				<FormControl margin="normal" fullWidth={true}>
					<ArticlePreviewCard
						value={article}
						onEdit={onEditHandle}
						onDelete={() => props.onChange && props.onChange(props.name, undefined)}
					/>
				</FormControl>
			}

			{!article &&
				<Autocomplete<IdNameResponse>
					options={[...options, CREATE_CUSTOM_ARTICLE]}
					noOptionsText={props.noOptionsText}
					className={props.className}
					style={props.style}
					autoComplete={false}
					clearOnBlur={false}
					clearOnEscape={true}
					fullWidth={true}
					onChange={onChangeHandle}
					getOptionLabel={getOptionLabelHandle}
					getOptionSelected={getOptionSelectedHandle}
					renderOption={renderOptionHandle}
					filterOptions={filterOptionsHandle}
					renderInput={(params) => (
						<TextField
							required={props.required}
							margin={props.margin}
							autoComplete={props.autoComplete}
							autoFocus={props.autoFocus}
							placeholder={props.placeholder}
							color={props.color as any}
							onChange={e => inputChangeHandle(e.target.value)}
							{...params}
							inputProps={{
								...params.inputProps,
								autoComplete: "search-article-textfield"
							}}
							InputProps={{
								...params.InputProps,
								endAdornment: (<React.Fragment>
									{loading ? <CircularProgress color="inherit" size={20} /> : null}
									{params.InputProps.endAdornment}
								</React.Fragment>)
							}}
							label={props.label}
							aria-label={props.label}
						/>
					)}
				/>
			}

			<ArticleFormDialog
				{...dialogProps}
				onClose={closeDialogHandle}
				onAfterSave={onAfterSaveHandle}
			/>
		</>
	);
}
