import { UserRole } from "domain/static/UserRole";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class SearchUserOptionsQuery implements IMessage {
    keywords!: string;
    top!: number;
    role!: UserRole | null;

    public constructor(init?: Partial<SearchUserOptionsQuery>) {
        Object.assign(this, init);
    }

    getMethod = () => "GET" as RequestMethod;

    getPath = () => `/admin/users/options`;

    getQueryString = () => {
        return {
            keywords: this.keywords,
            top: this.top,
            role: this.role,
        }
    }
    

    getBody = () => undefined;

    needAuthentication = () => true;
}
