import React, { useCallback, useEffect, useRef } from "react";
import { IFormWidgetPropsBase } from "cocoreact";
import {
    FormControl, FormHelperText,
    InputLabel, makeStyles, createStyles,
    Theme, Select, MenuItem
} from "@material-ui/core";
import { LUT, LUTColorMaps } from "dline-viewer/dist/data";
import { LUTColorComputer } from "dline-viewer/dist/processing";

export const LUT_NAMES = LUTColorMaps.getPresetNames();

const useStyles = makeStyles((theme: Theme) => createStyles({
    select: {
        "& input": {
            display: "none",
        }
    },
    canvas: {
        width: "100%",
        height: theme.spacing(3),
    }
}));

function LutWidget({ name }: { name: string }) {
    const classes = useStyles();
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (canvasRef.current) {
            const lut = new LUT();
            lut.setPreset(name);
            LUTColorComputer.fillCanvasElement(lut, canvasRef.current);
        }
    }, [name]);

    return (
        <canvas className={classes.canvas} ref={canvasRef}></canvas>
    );
}

export interface SelectLutFormWidgetProps extends IFormWidgetPropsBase<string> { }

export default function SelectLutFormWidget({
    name, value, onChange, ...props
}: SelectLutFormWidgetProps) {
    const classes = useStyles();

    const handleChange = useCallback((event: any) => {
        onChange && onChange(name, event.target.value)
    }, [name, onChange]);

    return <FormControl
        required={props.required}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
        margin={props.margin}
        error={props.error !== undefined}
        className={props.className}
        style={props.style}
        aria-label={props.label}
    >
        {props.label && <InputLabel shrink id="select-lut-form-label">{props.label}</InputLabel>}

        <Select
            labelId="select-lut-form-label"
            value={value}
            onChange={handleChange}
            className={classes.select}
            disableUnderline={true}
        >
            {LUT_NAMES.map((lutName, idx) => (
                <MenuItem key={idx} value={lutName}>
                    <LutWidget name={lutName} />
                </MenuItem>
            ))}
        </Select>

        {props.error && <FormHelperText>A lut is required</FormHelperText>}

    </FormControl>
}