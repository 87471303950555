import React, { useRef, useCallback } from "react";
import { Link } from "react-router-dom";

import { Button } from "@material-ui/core";

import { CentralColumnLayout, PageLayout } from "components/Layouts";
import ContouringWorkshopTable from "./ContouringWorkshopTable";
import { fullPath } from "tools/Route";
import { AddIcon, ContouringWorkshopIcon } from "App/Theme";
import { Flex, Paper, PaperTitle, Title } from "components/Page";
import { ResetFiltersButton } from "components/Buttons";

export default function ListContouringWorkshopsPage() {

    const resetRef = useRef<HTMLButtonElement>(null);
    const resetQueryHandle = useCallback(() => {
        resetRef.current?.click();
    }, []);

    const title = "List of contouring workshops";

    return <>
        <Title>{title}</Title>

        <PageLayout title="Contouring workshops" menuSelected="ListContouringWorkshops">
            <CentralColumnLayout size="xl">

                <Flex justifyContent="space-between">
                    <ResetFiltersButton onClick={resetQueryHandle} />

                    <Link to={fullPath("NewContouringWorkshop")}>
                        <Button
                            color="secondary"
                            variant="contained"
                            startIcon={<AddIcon />}
                        >
                            new contouring workshop
                        </Button>
                    </Link>
                </Flex>

                <Paper>
                    <PaperTitle icon={<ContouringWorkshopIcon />}>{title}</PaperTitle>

                    <ContouringWorkshopTable
                        editable={true}
                        resetRef={resetRef}
                    />
                </Paper>

            </CentralColumnLayout>
        </PageLayout>
    </>
}
