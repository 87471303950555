import React, { useCallback, useEffect, useState } from "react"
import {
    Box, Button, createStyles,
    Dialog, DialogActions, DialogContent, DialogTitle,
    FormControl, FormControlLabel,
    IconButton, ListSubheader, makeStyles,
    MenuItem, Radio, Slider, SvgIconProps, TextField, Theme, ThemeProvider,
    Tooltip, Typography, withStyles
} from "@material-ui/core"
import clsx from "clsx";

import {
    PencilIcon, RedoIcon, RubberIcon,
    UndoIcon, FillSliceIcon, MarginIcon,
    BooleanIcon, lightTheme, BooleanUnionIcon,
    BooleanIntersectionIcon, BooleanSoustractionIcon,
    BooleanExclusionIcon
} from "App/Theme";
import { EmptyGuid, Guid, IsValidGuid } from "domain/static/Guid";
import { VolumeType } from "domain/static/VolumeType";
import { Spacer } from "components/Page";
import { contourInfoLabel } from "tools/StringExtension";
import { DrawingType, useViewerContext } from "contexts/Viewer";
import { CloseIcon, useNotificationContext } from "cocoreact";
import { BooleanType } from "tools/Viewer";

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        zIndex: theme.zIndex.appBar - 2,
        "& > *": {
            marginTop: theme.spacing(1),
        },
        "& > :nth-child(1)": {
            marginTop: 0,
        }
    },
    sliderWrapper: {
        height: 150,
        minHeight: 150,
        maxHeight: 150,
        padding: theme.spacing(1.5, 0.5),
        display: "flex",
        justifyContent: "center",
        backgroundColor: theme.palette.grey[400],
        borderRadius: theme.shape.borderRadius,
        "&:hover": {
            backgroundColor: theme.palette.grey[100],
        }
    },
    action_btn: {
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.grey[400],
        "&:hover": {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.grey[100],
        },
    },
    action_btnSelected: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        "&:hover": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
    },
    dialog_title: {
        "& > *": {
            display: "flex",
            alignItems: "center",
        },
        "& > * > svg": {
            marginRight: theme.spacing(1),
        }
    },
    dialog_content: {
        padding: theme.spacing(2, 3),
        borderColor: theme.palette.divider,
        borderStyle: "solid",
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 0,
        borderRightWidth: 0,
    },
    dialog_actions: {
        padding: theme.spacing(2),
    },
    contourSelect_item: {
        paddingLeft: theme.spacing(4),
    },
    contourSelectItem: {
        display: "flex",
        alignItems: "center",
    },
    contourSelectItem_color: {
        width: theme.spacing(2),
        height: theme.spacing(2),
        marginRight: theme.spacing(1),
        borderRadius: "50%",
    },
    margins_mainGrid: {
        display: "grid",
        gridTemplateColumns: "1fr 2fr",
        gridGap: theme.spacing(2),
        margin: theme.spacing(0, 0, 2, 2),
    },
    margins_geometryGrid: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: theme.spacing(0.5),
    },
    margins_isoLabel: {
        display: "flex",
        alignItems: "center",
    },
    boolean_btnRow: {
        display: "flex",
        justifyContent: "space-around",
        margin: theme.spacing(0, 0, 1, 0)
    },
    boolean_btn: {
        '& svg': {
            fontSize: "4em",
        }
    },
    boolean_btnSelected: {
        backgroundColor: theme.palette.action.selected,
    },
    close_btn: {
        padding: theme.spacing(1),
        color: theme.palette.grey[100],
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
            color: theme.palette.grey[100],
            backgroundColor: theme.palette.secondary.light,
        },
    }
}));

// --------------------------------------------------------

const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.grey[100],
    },
    arrow: {
        color: theme.palette.grey[100],
    },
}))(Tooltip);

interface ActionButtonProps<T> {
    type: T;
    current?: T;
    label: string;
    icon: React.ReactElement<SvgIconProps>;
    onClick: (type: T) => void;
    className?: string;
}

function ActionButton<T>({ type, label, icon, onClick, current, className }: ActionButtonProps<T>) {
    const classes = useStyles();

    return <LightTooltip
        arrow
        placement="left"
        title={<Typography noWrap>{label}</Typography>}
    >
        <IconButton
            onClick={() => onClick(type)}
            className={clsx(
                className ?? classes.action_btn,
                current === type ? classes.action_btnSelected : undefined
            )}
        >
            {icon}
        </IconButton>
    </LightTooltip>
}

// --------------------------------------------------------

export type ProcessType = "undo" | "redo" | "fillSlice" | "margin" | "boolean";

export interface IProcess {
    type: ProcessType;
}
export interface Process_undo extends IProcess {
    type: "undo";
}
export interface Process_redo extends IProcess {
    type: "redo";
}
export interface Process_fillSlice extends IProcess {
    type: "fillSlice";
    targetContourId: Guid;
}
export interface Process_margin extends IProcess {
    type: "margin";
    sourceContourId: Guid;
    targetContourId: Guid;
    margins: number[]; // x6
}
export interface Process_boolean extends IProcess {
    type: "boolean";
    source1ContourId: Guid;
    source2ContourId: Guid;
    targetContourId: Guid;
    mode: BooleanType;
}

export type ProcessProps =
    | Process_undo
    | Process_redo
    | Process_fillSlice
    | Process_margin
    | Process_boolean;

// --------------------------------------------------------

interface Contour {
    id: Guid;
    name: string;
    color: string;
    anatomicStructureName: string | null;
    volumeType: VolumeType;
    targetTypeName: string;
}

// --------------------------------------------------------

interface ContourSelectItemProps {
    contour: Contour;
}

function ContourSelectItem({ contour }: ContourSelectItemProps) {
    const classes = useStyles();

    const { data: viewerData } = useViewerContext();

    const contourData = viewerData.getContourById(contour.id);
    const color = contourData?.color ?? contour.color;

    const label = contour.anatomicStructureName
        ? contourInfoLabel(contour.anatomicStructureName, contour.volumeType, contour.targetTypeName)
        : contour.name;

    return <Box className={classes.contourSelectItem}>
        <Box className={classes.contourSelectItem_color} style={{ backgroundColor: color }} />
        {label}
    </Box>
}

interface ContourSelectProps {
    contours: Contour[];
    onChange: (value: Guid) => void;
    label: string;
    value: Guid;
}

function ContourSelect({ contours, onChange, ...props }: ContourSelectProps) {
    const classes = useStyles();

    const target = contours.filter(x => x.volumeType && x.volumeType === VolumeType.Target);
    const atRiskAndAnatomy = contours?.filter(x =>
        x.volumeType &&
        (x.volumeType === VolumeType.AtRisk || x.volumeType === VolumeType.Anatomy)
    );
    const unset = contours.filter(x => !x.volumeType);

    const onChangeHandle = (e: any) => {
        const value = e.target.value;
        value && onChange(value);
    }

    return <TextField
        select
        fullWidth
        onChange={onChangeHandle}
        variant="outlined"
        margin="dense"
        {...props}
    >
        <MenuItem value={EmptyGuid()}><em>Choose a contour ...</em></MenuItem>

        <ListSubheader disableSticky>Target</ListSubheader>
        {target.map(x => <MenuItem key={x.id} value={x.id} className={classes.contourSelect_item}>
            <ContourSelectItem contour={x} />
        </MenuItem>)}

        <ListSubheader disableSticky>At risk / Anatomy</ListSubheader>
        {atRiskAndAnatomy.map(x => <MenuItem key={x.id} value={x.id} className={classes.contourSelect_item}>
            <ContourSelectItem contour={x} />
        </MenuItem>)}

        <ListSubheader disableSticky>Unclassified</ListSubheader>
        {unset.map(x => <MenuItem key={x.id} value={x.id} className={classes.contourSelect_item}>
            <ContourSelectItem contour={x} />
        </MenuItem>)}
    </TextField>
}

interface MarginInputProps {
    label?: string;
    disabled?: boolean;
    value: number[];
    index: number;
    onChange: (value: number, index: number) => void;
}

function MarginInput({ label, disabled, value, index, onChange }: MarginInputProps) {
    return <TextField
        type="number"
        variant="outlined"
        margin="dense"
        label={label}
        disabled={disabled}
        value={"" + value[index]}
        onChange={e => onChange(parseFloat(e.target.value), index)}
        InputProps={{ inputProps: { step: 0.1 } }}
    />
}

interface BooleanButtonProps {
    title: string;
    icon: React.ReactElement<SvgIconProps>;
    type: BooleanType;
    value: BooleanType;
    onClick: (value: BooleanType) => void;
}

function BooleanButton({ title, type, icon, value, onClick }: BooleanButtonProps) {
    const classes = useStyles();

    const className = clsx(
        classes.boolean_btn,
        value === type ? classes.boolean_btnSelected : undefined
    );

    return <Button className={className} variant="outlined" title={title} onClick={() => onClick(type)}>
        {icon}
    </Button>
}

// --------------------------------------------------------

interface ProcessDialogProps {
    open: boolean;
    contours: Contour[];
    drawingId: Guid;
    onClose: () => void;
    onProcess: (props: ProcessProps) => void;
}

function MarginDialog({
    open, onClose, contours, onProcess, drawingId
}: ProcessDialogProps) {
    const classes = useStyles();

    const [state, setState] = useState({
        isotropicEnabled: true,
        isotropicValue: 0.0,
        sourceContourId: EmptyGuid(),
        targetContourId: EmptyGuid(),
        margins: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
    });

    useEffect(() => {
        if (open) {
            setState({
                isotropicEnabled: true,
                isotropicValue: 0.0,
                sourceContourId: EmptyGuid(),
                targetContourId: drawingId,
                margins: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
            });
        }
    }, [drawingId, open]);

    const onMarginChange = (value: number, index: number) => {
        setState(s => {
            const margins = s.margins;
            margins[index] = value;
            return { ...s, margins };
        });
    }

    const onApplyHandle = useCallback(() => {
        let margins = state.margins;
        if (state.isotropicEnabled) {
            margins = margins.map(x => state.isotropicValue)
        }
        onProcess({
            type: "margin",
            sourceContourId: state.sourceContourId,
            targetContourId: state.targetContourId,
            margins,
        } as Process_margin);
    }, [onProcess, state]);

    const applyDisabled = !IsValidGuid(state.sourceContourId)
        || !IsValidGuid(state.targetContourId);

    return <Dialog
        open={open}
        onClose={onClose}
        fullWidth
    >
        <DialogTitle className={classes.dialog_title}>
            <MarginIcon />
            Margins on the structure
        </DialogTitle>

        <DialogContent className={classes.dialog_content}>
            <FormControl fullWidth margin="dense">
                <ContourSelect
                    label="Compute margin from"
                    contours={contours}
                    value={state.sourceContourId}
                    onChange={value => setState(s => ({ ...s, sourceContourId: value }))}
                />
            </FormControl>

            <FormControl fullWidth margin="dense">
                <Typography>Geometry [mm]</Typography>

                <Box className={classes.margins_mainGrid}>
                    <Box className={classes.margins_isoLabel}>
                        <FormControlLabel
                            control={<Radio checked={state.isotropicEnabled} />}
                            label="Isotropic"
                            onClick={() => setState(s => ({ ...s, isotropicEnabled: true }))}
                        />
                    </Box>
                    <Box>
                        <MarginInput
                            value={[state.isotropicValue]}
                            index={0}
                            onChange={value => setState(s => ({ ...s, isotropicValue: value }))}
                            disabled={!state.isotropicEnabled}
                        />
                    </Box>

                    <Box className={classes.margins_isoLabel}>
                        <FormControlLabel
                            control={<Radio checked={!state.isotropicEnabled} />}
                            label="Anisotropic"
                            onClick={() => setState(s => ({ ...s, isotropicEnabled: false }))}
                        />
                    </Box>
                    <Box className={classes.margins_geometryGrid}>
                        <MarginInput label="anterior" index={0} value={state.margins}
                            disabled={state.isotropicEnabled} onChange={onMarginChange} />
                        <MarginInput label="posterior" index={1} value={state.margins}
                            disabled={state.isotropicEnabled} onChange={onMarginChange} />
                        <MarginInput label="left" index={2} value={state.margins}
                            disabled={state.isotropicEnabled} onChange={onMarginChange} />
                        <MarginInput label="right" index={3} value={state.margins}
                            disabled={state.isotropicEnabled} onChange={onMarginChange} />
                        <MarginInput label="superior" index={4} value={state.margins}
                            disabled={state.isotropicEnabled} onChange={onMarginChange} />
                        <MarginInput label="inferior" index={5} value={state.margins}
                            disabled={state.isotropicEnabled} onChange={onMarginChange} />
                    </Box>
                </Box>
            </FormControl>

            <FormControl fullWidth margin="dense">
                <ContourSelect
                    label="Structure target"
                    contours={contours}
                    value={state.targetContourId}
                    onChange={value => setState(s => ({ ...s, targetContourId: value }))}
                />
            </FormControl>
        </DialogContent>

        <DialogActions className={classes.dialog_actions}>
            <Button variant="outlined" onClick={onClose}>
                close
            </Button>
            <Spacer />
            <Button
                variant="contained"
                color="primary"
                disabled={applyDisabled}
                onClick={onApplyHandle}
            >
                Apply
            </Button>
        </DialogActions>
    </Dialog>
}

function BooleanDialog({
    open, onClose, contours, onProcess, drawingId
}: ProcessDialogProps) {
    const classes = useStyles();
    const [state, setState] = useState({
        mode: "union" as BooleanType,
        source1ContourId: EmptyGuid(),
        source2ContourId: EmptyGuid(),
        targetContourId: EmptyGuid(),
    });

    useEffect(() => {
        if (open) {
            setState({
                mode: "union",
                source1ContourId: EmptyGuid(),
                source2ContourId: EmptyGuid(),
                targetContourId: drawingId,
            });
        }
    }, [drawingId, open]);

    const onModeChange = (mode: BooleanType) => setState(s => ({ ...s, mode }));

    const onApplyHandle = useCallback(() => {
        onProcess({ type: "boolean", ...state } as Process_boolean);
    }, [onProcess, state]);

    const applyDisabled = !IsValidGuid(state.source1ContourId)
        || !IsValidGuid(state.source2ContourId)
        || !IsValidGuid(state.targetContourId)
        || (
            state.source1ContourId === state.targetContourId
            && state.source2ContourId === state.targetContourId
        );

    return <Dialog
        open={open}
        onClose={onClose}
        fullWidth
    >
        <DialogTitle className={classes.dialog_title}>
            <BooleanIcon />
            Boolean operators
        </DialogTitle>
        <DialogContent className={classes.dialog_content}>
            <FormControl fullWidth margin="dense">
                <ContourSelect
                    label="First structure"
                    contours={contours}
                    value={state.source1ContourId}
                    onChange={value => setState(s => ({ ...s, source1ContourId: value }))}
                />
            </FormControl>

            <FormControl fullWidth margin="dense" >
                <Typography>Operator</Typography>

                <Box className={classes.boolean_btnRow}>
                    <BooleanButton type="union" value={state.mode}
                        title="make union between the first and second structure"
                        icon={<BooleanUnionIcon color="secondary" />}
                        onClick={onModeChange}
                    />
                    <BooleanButton type="intersection" value={state.mode}
                        title="keep the elements of the first and second structure overlapping"
                        icon={<BooleanIntersectionIcon color="secondary" />}
                        onClick={onModeChange}
                    />
                    <BooleanButton type="soustraction" value={state.mode}
                        title="subtract the second structure from the first"
                        icon={<BooleanSoustractionIcon color="secondary" />}
                        onClick={onModeChange}
                    />
                    <BooleanButton type="exclusion" value={state.mode}
                        title="keep the elements of the first and second structure that do not overlap"
                        icon={<BooleanExclusionIcon color="secondary" />}
                        onClick={onModeChange}
                    />
                </Box>
            </FormControl>

            <FormControl fullWidth margin="dense">
                <ContourSelect
                    label="Second structure"
                    contours={contours}
                    value={state.source2ContourId}
                    onChange={value => setState(s => ({ ...s, source2ContourId: value }))}
                />
            </FormControl>

            <FormControl fullWidth margin="dense">
                <Typography variant="caption">
                    Create a binary expressionby selecting two structures and an operator.
                </Typography>
            </FormControl>

            <FormControl fullWidth margin="dense">
                <ContourSelect
                    label="Structure target"
                    contours={contours}
                    value={state.targetContourId}
                    onChange={value => setState(s => ({ ...s, targetContourId: value }))}
                />
            </FormControl>
        </DialogContent>
        <DialogActions className={classes.dialog_actions}>
            <Button variant="outlined" onClick={onClose}>
                close
            </Button>
            <Spacer />
            <Button
                variant="contained"
                color="primary"
                disabled={applyDisabled}
                onClick={onApplyHandle}
            >
                Apply
            </Button>
        </DialogActions>
    </Dialog>
}

// --------------------------------------------------------

export interface DrawingToolsProps {
    contours: Contour[];
}

export default function DrawingTools({ contours }: DrawingToolsProps) {
    const classes = useStyles();

    const MIN_TOOL_SIZE = 1;
    const MAX_TOOL_SIZE = 20;

    const { success, info } = useNotificationContext();
    const {
        data: viewerData,
        dispatcher: viewerDispatcher,
        app: viewerApp
    } = useViewerContext();

    const [drawingSize, setDrawingSize] = useState(viewerData.defaultDrawingSize);
    const [drawingType, setDrawingType] = useState(viewerData.defaultDrawingType);
    const drawingContour = viewerData.getContourDrawing()
    const drawingId = drawingContour ? drawingContour.id : EmptyGuid();

    const [marginOpen, setMarginOpen] = useState(false);
    const [booleanOpen, setBooleanOpen] = useState(false);

    const onTypeHandle = useCallback((value: DrawingType) => {
        setDrawingType(value);
        viewerDispatcher({
            type: "DRAWING_TYPE",
            drawingType: value,
        });
    }, [viewerDispatcher]);

    const onSizeHandle = useCallback((_e: any, value: any) => {
        setDrawingSize(value);
        viewerDispatcher({
            type: "DRAWING_SIZE",
            size: value as number,
        }, "viewer");
    }, [viewerDispatcher]);

    const onCloseHandle = useCallback((_value: any) => {
        viewerDispatcher({
            type: "CONTOUR_DRAWING",
            contourId: null,
        });
    }, [viewerDispatcher]);

    const onSliderWheelHandle = useCallback((e: React.WheelEvent<HTMLElement>) => {
        const newSize = drawingSize + (e.deltaY > 0 ? -1 : 1)
        if (newSize >= MIN_TOOL_SIZE && newSize <= MAX_TOOL_SIZE) {
            setDrawingSize(newSize);
            viewerDispatcher({
                type: "DRAWING_SIZE",
                size: newSize,
            }, "viewer");
        }
    }, [viewerDispatcher, drawingSize]);

    const onActionHandle = useCallback(async (type: ProcessType) => {
        switch (type) {
            case "undo":
                await viewerApp.undo();
                break;
            case "redo":
                await viewerApp.redo();
                break;
            case "fillSlice":
                const contour = drawingContour ? viewerApp.getContourById(drawingContour.id) : undefined;
                if (!contour) return;

                const nbSlice = await contour.fillSlice();
                if (nbSlice > 0) {
                    success(`${nbSlice} slice${nbSlice > 1 ? "s" : ""} has been interpolated`);
                }
                else {
                    info("no slice to interpolate !");
                }
                break;
            case "margin":
                setMarginOpen(true);
                break;
            case "boolean":
                setBooleanOpen(true);
                break;
            default:
                console.log("no action defined for type ", type);
        }
    }, [drawingContour, info, success, viewerApp]);

    const onProcessHandle = useCallback(async (process: ProcessProps) => {
        switch (process.type) {
            case "margin":
                await viewerApp.margin(process.margins, process.targetContourId,
                    process.sourceContourId);
                setMarginOpen(false);
                break;
            case "boolean":
                await viewerApp.boolean(process.mode, process.targetContourId,
                    process.source1ContourId, process.source2ContourId);
                setBooleanOpen(false);
                break;
            default:
                console.log("no handler found for process", process);
        }
    }, [viewerApp]);

    return <>
        <ThemeProvider theme={lightTheme}>
            <Box className={classes.container}>

                <ActionButton type="margin" label="Margins on the structure" icon={<MarginIcon />}
                    onClick={onActionHandle}
                />
                <ActionButton type="boolean" label="Boolean operators" icon={<BooleanIcon />}
                    onClick={onActionHandle}
                />
                <ActionButton type="fillSlice" label="Interpolate fill slices" icon={<FillSliceIcon />}
                    onClick={onActionHandle}
                />

                <LightTooltip
                    arrow
                    placement="left"
                    title={<Typography noWrap>{`Tool size ${drawingSize}`}</Typography>}
                >
                    <Box className={classes.sliderWrapper} onWheel={onSliderWheelHandle}>
                        <Slider
                            orientation="vertical"
                            min={MIN_TOOL_SIZE}
                            max={MAX_TOOL_SIZE}
                            value={drawingSize}
                            onChange={onSizeHandle}
                        />
                    </Box>
                </LightTooltip>

                <ActionButton type="pencil" label="Pencil" icon={<PencilIcon />}
                    current={drawingType} onClick={onTypeHandle}
                />
                <ActionButton type="rubber" label="Rubber" icon={<RubberIcon />}
                    current={drawingType} onClick={onTypeHandle}
                />
                <ActionButton type="undo" label="Undo" icon={<UndoIcon />}
                    onClick={onActionHandle}
                />
                <ActionButton type="redo" label="Redo" icon={<RedoIcon />}
                    onClick={onActionHandle}
                />
                <ActionButton type="close" label="Close" icon={<CloseIcon />}
                    className={classes.close_btn}
                    onClick={onCloseHandle}
                />

            </Box>
        </ThemeProvider>

        <MarginDialog
            open={marginOpen}
            onClose={() => setMarginOpen(false)}
            contours={contours}
            drawingId={drawingId}
            onProcess={onProcessHandle}
        />

        <BooleanDialog
            open={booleanOpen}
            onClose={() => setBooleanOpen(false)}
            contours={contours}
            drawingId={drawingId}
            onProcess={onProcessHandle}
        />
    </>
}
