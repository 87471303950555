//Generated code, do not modify

export enum FromEmailType {
  None = 0,
  NoReply = 1,
  Contact = 2,
  Dpo = 3,
}

export const FromEmailTypeOptions = [
  {
    label: "None",
    value: FromEmailType.None,
  },
  {
    label: "NoReply",
    value: FromEmailType.NoReply,
  },
  {
    label: "Contact",
    value: FromEmailType.Contact,
  },
  {
    label: "Dpo",
    value: FromEmailType.Dpo,
  },
];
