import { immerable } from "immer";
import { ItkImage, ReadOnlyRoi } from "dline-viewer/dist/data";

import { EmptyGuid, Guid } from "domain/static/Guid";
import { ImagingDefault } from "domain/static/ImagingDefault";
import { Range } from "domain/static/Range";
import { FusionType } from "domain/static/FusionType";

export type DrawingType = "rubber" | "pencil";

export default class ViewerData {
  [immerable] = true;

  defaultDrawingSize = 5;
  defaultDrawingType = "pencil" as DrawingType;
  defaultFusionType = FusionType.Opacity;

  loading: boolean;
  images: ImageData[];
  contours: ContourData[];

  constructor(init: ViewerData | undefined = undefined) {
    this.loading = init ? init.loading : false;
    this.images = init ? init.images.map((x) => new ImageData(x)) : [];
    this.contours = init ? init.contours.map((x) => new ContourData(x)) : [];
  }

  get numberOfImages() {
    return this.images.length;
  }

  get numberOfContours() {
    return this.contours.length;
  }

  getImageById(id: Guid) {
    return this.images.find((x) => x.id === id);
  }

  getImageByImagingDefault(imagingDefault: ImagingDefault) {
    return this.images.find((x) => x.default === imagingDefault);
  }

  getImagePrimary() {
    const img = this.getImageByImagingDefault(ImagingDefault.Primary);
    return img ? img : (this.images[0] as ImageData);
  }

  getImageOverlay() {
    return this.getImageByImagingDefault(ImagingDefault.Overlay);
  }

  getImageOverlayOptions() {
    return this.images.filter((x) => x.default !== ImagingDefault.Primary);
  }

  getContourById(id: Guid) {
    return this.contours.find((x) => x.id === id);
  }

  getContourDrawing() {
    return this.contours.find((x) => x.drawing === true);
  }
}

export class ImageData {
  [immerable] = true;

  id: Guid;
  windowing: Range;
  lut: string;
  default: ImagingDefault;
  data: any | null;

  constructor(init: ImageData | undefined = undefined) {
    this.id = init ? init.id : EmptyGuid();
    this.windowing = init ? new Range(init.windowing) : new Range();
    this.lut = init ? init.lut : "Grayscale";
    this.default = init ? init.default : ImagingDefault.None;
    this.data = init ? init.data : null;
  }

  getData() {
    return this.data as ItkImage;
  }
}

export class ContourData {
  [immerable] = true;

  id: Guid;
  color: string;
  visible: boolean;
  drawing: boolean;
  data: any | null;

  constructor(init: ContourData | undefined = undefined) {
    this.id = init ? init.id : EmptyGuid();
    this.color = init ? init.color : "#ffffff";
    this.visible = init ? init.visible : true;
    this.drawing = init ? init.drawing : false;
    this.data = init ? init.data : null;
  }

  getData() {
    return this.data as ReadOnlyRoi;
  }
}
