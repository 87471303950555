/* eslint-disable no-unused-vars */
import React, { useCallback, useState, useMemo } from "react";
import { IFormWidgetPropsBase } from "cocoreact";
import AutoCompleteFormWidget, { AutoCompleteOption } from "./AutoCompleteFormWidget";
import { SelectItemType } from "domain/static/SelectItemType";
import { CreateSelectItemFormDialog  } from "pages/SelectItems";
import { Guid, IsGuid, EmptyGuid, IsEmptyGuid } from "domain/static/Guid";
import { createFilterOptions, FilterOptionsState } from "@material-ui/lab";
import useListSelectItems from "domain/hooks/useListSelectItems";

const CREATE_CUSTOM_ITEM_SALT = "create-item-";

function encodeCustomValue(v: string) {
	return CREATE_CUSTOM_ITEM_SALT + encodeURIComponent(v);
}

function decodeCustomValue(v: string | string[]): string {
	if (typeof v === "string") {
		let tmp = v.replace(CREATE_CUSTOM_ITEM_SALT, "");
		return decodeURIComponent(tmp);
	}
	else if (Array.isArray(v)) {
		const tmp = v.find(x => !IsGuid(x));
		if (tmp) {
			return decodeCustomValue(tmp);
		}
	}
	throw new Error("invalid input type");
}

function isCustomValue(v: string | string[]): boolean {
	if (typeof v === "string") {
		return v.startsWith(CREATE_CUSTOM_ITEM_SALT);
	}
	else if (Array.isArray(v)) {
		const reducer = (acc: boolean, value: string) => acc || isCustomValue(value);
		return v.reduce(reducer, false);
	}
	return false;
}

export interface SelectItemFormWidgetProps extends IFormWidgetPropsBase<any> {
	selectItemType: SelectItemType;
	defaultValue?: AutoCompleteOption | AutoCompleteOption[];
	multiple?: boolean;
}

export default function SelectItemFormWidget({
	selectItemType, ...props
}: SelectItemFormWidgetProps
) {
	if (selectItemType === undefined) {
		throw new Error(`you msut provide a valid 'selectItemType' for field name '${props.name}'`)
	}

	const [loading, setLoading] = useState(false);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [dialogName, setDialogName] = useState<string>("");
	const [loadingItems, items, updateItems] = useListSelectItems(selectItemType);

	const closeDialogHandle = useCallback(() => setDialogOpen(false), []);

	const filterOptions = useMemo(() => createFilterOptions({
		stringify: (option: AutoCompleteOption) => option.label,
	}), []);

	const defaultOptions = useMemo(() => {
		if (props.defaultValue) {
			return Array.isArray(props.defaultValue) ? props.defaultValue : [props.defaultValue];
		}
		return [];
	}, [props.defaultValue]);

	const options = useMemo(() => {
		const options = items.map(x => ({ label: x.name, value: x.id } as AutoCompleteOption));

		if (!props.multiple) {
			options.unshift({
				label: "None",
				value: EmptyGuid(),
			} as AutoCompleteOption);
		}

		defaultOptions.forEach(opt => {
			if (options.findIndex(x => x.value === opt.value) < 0) {
				options.push({
					label: `${opt.label} [archived]`,
					value: opt.value
				});
			}
		});

		return options;
	}, [items, props.multiple, defaultOptions]);

	const handleFilterOptions = useCallback((options: AutoCompleteOption[], state: FilterOptionsState<AutoCompleteOption>) => {
		let opts = filterOptions(options, state);
		if (state.inputValue !== "") {
			opts.push({
				label: `Create ${props.label ? props.label.toLowerCase() + " " : ""}"${state.inputValue}"`,
				value: encodeCustomValue(state.inputValue),
			});
		}
		return opts;
	}, [filterOptions, props.label]);

	const handleGetOptionDisabled = useCallback((option: AutoCompleteOption) => {
		return !IsEmptyGuid(option.value) && items.findIndex(x => x.id === option.value) < 0 && !isCustomValue(option.value);
	}, [items]);

	const handleChange = useCallback(async (name: string, data: any) => {
		if (isCustomValue(data)) {
			const value = decodeCustomValue(data);
			setDialogName(value);
			setDialogOpen(true);
		}
		else {
			props.onChange && props.onChange(name, data)
		}
	}, [props]);

	const onAfterSaveHandle = useCallback((id: Guid) => {
		setLoading(true);

		updateItems();

		let newValue = id as Guid | Guid[];
		if (Array.isArray(props.value)) {
			const tmp = props.value.filter(x => IsGuid(x));
			tmp.push(id);
			newValue = tmp;
		}

		props.onChange && props.onChange(props.name, newValue);

		setLoading(false);
		setDialogOpen(false);

	}, [props, updateItems]);

	return <>
		<AutoCompleteFormWidget
			{...props}
			loading={loadingItems || loading}
			options={options}
			getOptionDisabled={handleGetOptionDisabled}
			noOptionsText="no item found"
			filterOptions={handleFilterOptions}
			onChange={handleChange}
		/>

		<CreateSelectItemFormDialog
			open={dialogOpen}
			name={dialogName}
			type={selectItemType}
			onClose={closeDialogHandle}
			onAfterSave={onAfterSaveHandle}
		/>
	</>
}
